import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActionModel } from '../action.model';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { MatPaginator, MatSort } from '@angular/material';
import { UtilsService } from 'src/app/shared/utils.service';
import { ActionsNotesEditComponent } from '../actions-notes-edit/actions-notes-edit.component';
import { tap } from 'rxjs/operators';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-actions-notes',
  templateUrl: './actions-notes.component.html',
  styleUrls: ['./actions-notes.component.scss']
})
export class ActionsNotesComponent implements OnInit {

  @Input() action: ActionModel;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  dataSource = new ActionsNotesDataSource(this.app, this.api);

  displayedColumns = [
    'id',
    'note',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];

  constructor(
    private app: AppService,
    private api: ApiService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.actionId = this.action.id;
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if (this.sort.active == "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      }

      this.dataSource.getData(false); // TBD: Need to add sorting to the API endpoint.
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onEdit(noteId: number = null) {
    this.utils.showComponentDialog(ActionsNotesEditComponent, {
      action: this.action,
      noteId: noteId
    }, {}, () => {
      this.dataSource.getData(true);
    });
  }

  onDelete(noteId: number) {
    this.utils.showModal('Delete Note', 'Are you sure you want to delete the note?', () => {
      this.api.laravelApiRequest('delete', `actions/${this.action.id}/notes/${noteId}`, {}, {},
        (resposne: any) => {
          this.utils.showToast('Note Deleted.');
          this.dataSource.getData();
        }
      );
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class ActionsNotesDataSource extends CustomDataSource {

  actionId: number;

  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset = false) {
    this.getDataFromLaravelAPI(
      `actions/${this.actionId}/notes`,
      resetOffset
    );
  }
}
