import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatCardModule,
  MatExpansionModule,
  MatRadioModule,
  MatSlideToggleModule
} from '@angular/material';
import { DirectivesModule } from '../directives/directives.module';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { PipesModule } from '../pipes/pipes.module';
import { FilesModule } from '../files/files.module';
import { SharedModule } from '../shared/shared.module';
import { InductionsComponent } from './inductions/inductions.component';
import { InductionsEditComponent } from './inductions-edit/inductions-edit.component';
import { InductionsViewComponent } from './inductions-view/inductions-view.component';
import { InductionModulesComponent } from './induction-modules/induction-modules/induction-modules.component';
import { InductionModulesEditComponent } from './induction-modules/induction-modules-edit/induction-modules-edit.component';
import { InductionModulesViewComponent } from './induction-modules/induction-modules-view/induction-modules-view.component';
import { InductionsRoutingModule } from './inductions-routing.module';

@NgModule({
  declarations: [
    InductionsComponent,
    InductionsEditComponent,
    InductionsViewComponent,
    InductionModulesComponent,
    InductionModulesEditComponent,
    InductionModulesViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    InductionsRoutingModule,
    DragDropModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatBadgeModule,
    MatCardModule,
    MatExpansionModule,
    MatRadioModule,
    MatSlideToggleModule,
    PipesModule,
    FilesModule,
    SharedModule,
    MatDatepickerModule
  ],
  entryComponents: [
    SitesSelectorComponent
  ]
})
export class InductionsModule {}
