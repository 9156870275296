import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from '../../../app.service';
import { ApiService } from '../../../shared/api.service';
import { UtilsService } from '../../../shared/utils.service';
import { CustomDataSource } from '../../../utils/custom-data-source';
import { CourseModuleModel } from 'src/app/models/course-module.model';
import { CoursesModulesEditComponent } from '../courses-modules-edit/courses-modules-edit.component';
import { CoursesModulesViewComponent } from '../courses-modules-view/courses-modules-view.component';

@Component({
  selector: 'app-courses-modules',
  templateUrl: './courses-modules.component.html',
  styleUrls: ['./courses-modules.component.scss']
})
export class CoursesModulesComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new ModulesDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // get the site id
    this.dataSource.parent_id = Number(this.route.snapshot.params['course_id']);

    if (this.dataSource.parent_id) {
      // get the list of codes
      this.dataSource.getData();
    } else {
      // redirect back to sites because the site id is not valid
      this.router.navigate(['courses']);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC Columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new CourseModuleModel());
  }

  onView(courseModule: CourseModuleModel) {
    this.dialog
      .open(CoursesModulesViewComponent, {
        width: '900px',
        data: courseModule
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onEdit(courseModule: CourseModuleModel) {
    this.dialog
      .open(CoursesModulesEditComponent, {
        width: '900px',
        data: { module: {...courseModule}, parent_id: this.dataSource.parent_id }
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Module',
      'Are you sure you want to remove this induction module?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.dataSource.parent_id}/modules/${id}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The induction module is deleted.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Module',
      'Are you sure you want to remove the selected induction modules?',
      () => {
        const selectedModules = this.dataSource.selection.selected.join(',');

        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.dataSource.parent_id}/modules/${selectedModules}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The selected induction modules is deleted.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          }
        );
      }
    );
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class ModulesDataSource extends CustomDataSource {
  parent_id = 0;

  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      `inductions/${this.parent_id}/modules`,
      resetOffset
    );
  }
}
