import { HazardControlModel } from './hazard-control.model';
import { FileModel } from '../shared/file.model';
import { Auditable } from '../shared/auditable.model';
import {SiteModel} from "./site.model";
import {UserModel} from "./user.model";

export class HazardModel extends Auditable {
  id = 0;

  site_id = 0;
  site: SiteModel;

  name = '';

  status = 'Unverified';
  description = '';

  /**
   * Initial risk assessment
   */
  ira_likelihood: 1 | 2 | 3 | 4 | 5 = 2;
  ira_severity: 1 | 2 | 3 | 4 | 5 = 2;

  /**
   * Residual risk assessment
   */
  rra_likelihood: 1 | 2 | 3 | 4 | 5 = 2;
  rra_severity: 1 | 2 | 3 | 4 | 5 = 2;

  controls: HazardControlModel[] = [new HazardControlModel()];

  for_discussion = 'No';
  for_discussion_id = 0;

  /**
   * The selected list of sites this hazard is attached to.
   */
  selected_site_ids: number[] = [];
  copy_to_all_sites = false;

  files: FileModel[] = [];

  /**
   * The full name of the user who created this Hazard.
   */
  created_by_name = '';

  /**
   * Unique hash used for User Public Profile of the user who created this Hazard.
   */
  created_by_hash = '';

  /**
   * Unique hash used for publicly accessible links.
   */
  hash = '';


  /**
   * Who reviewed this Hazard?
   */
  reviewer: UserModel;
  reviewer_id: number = 0;
  reviewer_date: number = 0;

  /**
   * The Hazard ID from which the new Hazard is created.
   */
  create_from_id = 0;

  /**
   * This is not stored in the hazards table. It is simply passed along with the form data
   * to associate the hazard with the given safety observation.
   */
   safety_observation_id: number;

  /**
   * This is used for icon displays in the web portal.
   * The API determines if there are multiple sites being queried and whether the associated site has a parent site or not.
   */
  contains_parent_child_sites: boolean = false;

  /**
    * This is used for linking Incident to Hazard?
  */
  incident_link_id: number = 0;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          if (key == 'controls') {
            if ( typeof data[key] == 'string' ) {
              data[key] = JSON.parse(data[key]);
            }
            this.controls.length = 0;
            for (let i = 0; i < data[key].length; i++) {
              this.controls.push(new HazardControlModel().apply(data[key][i]));
            }
          } else {
            this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;

    this.site_id = 0;

    this.name = '';

    this.ira_likelihood = 2;
    this.ira_severity = 2;
    // residual risk assessment
    this.rra_likelihood = 2;
    this.rra_severity = 2;

    this.for_discussion = 'No';
    this.for_discussion_id = 0;

    this.controls = [];

    this.selected_site_ids.length = 0;
    this.copy_to_all_sites = false;
    this.files = [];
    this.created_by_name = '';
    this.hash = '';

    this.incident_link_id = 0;
  }

  createFrom(data: any) {
    this.create_from_id = data.id;

    this.name = data.name;
    this.description = data.description;

    this.ira_likelihood = data.ira_likelihood;
    this.ira_severity = data.ira_severity;
    // residual risk assessment
    this.rra_likelihood = data.rra_likelihood;
    this.rra_severity = data.rra_severity;

    this.controls = data.controls;
  }

}
