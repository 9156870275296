import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from '../../../../app.service';
import { ApiService } from '../../../../shared/api.service';
import { UtilsService } from '../../../../shared/utils.service';
import { CustomDataSource } from '../../../../utils/custom-data-source';
import { CourseModuleSectionModel } from 'src/app/models/course-module-section.model';
import { CoursesModulesSectionsViewComponent } from '../course-modules-sections-view/courses-modules-sections-view.component';
import { CoursesModulesSectionsEditComponent } from '../course-modules-sections-edit/courses-modules-sections-edit.component';

@Component({
  selector: 'app-courses-modules-sections',
  templateUrl: './courses-modules-sections.component.html',
  styleUrls: ['./courses-modules-sections.component.scss']
})
export class CoursesModulesSectionsComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'status',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new SectionsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // get the site id
    this.dataSource.course_id = Number(this.route.snapshot.params['course_id']);
    this.dataSource.module_id = Number(this.route.snapshot.params['module_id']);

    if (this.dataSource.course_id && this.dataSource.module_id) {
      // get the list of codes
      this.dataSource.getData();
    } else {
      // redirect back to sites because the site id is not valid
      this.router.navigate([
        'courses',
        this.dataSource.course_id,
        'modules',
        this.dataSource.module_id,
        'sections'
      ]);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns based on their corresponding date fields.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new CourseModuleSectionModel());
  }

  onView(section: CourseModuleSectionModel) {
    this.dialog
      .open(CoursesModulesSectionsViewComponent, {
        width: '900px',
        data: section
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onEdit(section: CourseModuleSectionModel) {
    this.dialog
      .open(CoursesModulesSectionsEditComponent, {
        width: '900px',
        data: {
          section: {...section},
          course_id: this.dataSource.course_id,
          module_id: this.dataSource.module_id
        }
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Sub-Section',
      'Are you sure you want to remove this sub-section?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.dataSource.course_id}/modules/${
            this.dataSource.module_id
          }/sections/${id}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The sub-section was removed.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected Sub-Sections',
      'Are you sure you want to remove the selected sub-sections?',
      () => {
        const selectedSections = this.dataSource.selection.selected.join(',');

        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.dataSource.course_id}/modules/${
            this.dataSource.module_id
          }/sections/${selectedSections}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The selected sub-sections was removed.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          }
        );
      }
    );
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class SectionsDataSource extends CustomDataSource {
  course_id = 0;
  module_id = 0;

  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      `inductions/${this.course_id}/modules/${this.module_id}/sections`,
      resetOffset
    );
  }
}
