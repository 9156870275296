import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {UtilsService} from "../../shared/utils.service";

@Component({
  selector: 'app-actions-upload-mapping-dialog',
  templateUrl: './actions-upload-mapping-dialog.component.html',
  styleUrls: ['./actions-upload-mapping-dialog.component.scss']
})
export class ActionsUploadMappingDialogComponent implements OnInit {
  fields: string[] = [];
  action: any = {};
  dataToOutput: any;

  mapped_fields = [];
  selected_fields = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ActionsUploadMappingDialogComponent>,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.fields = Object.keys(this.data.actionsData[0]);
    this.mapped_fields = this.data.actionsMappingFields;
  }

  /**
   * This is used to validate the user's column mappings.
   * The user must at least have the task name column mapped.
   */
  hasValidColumnMappings() {
    // Default validation is true.
    let isValid: boolean = true;

    // The user must select at least one column mapped.
    if ( Object.keys(this.action).length == 0 ) {
      isValid = false;
    }

    // The user must have the task name mapping set.
    if ( Object.values(this.action).indexOf('name') == -1 ) {
      isValid = false;
    }

    return isValid;
  }

  hasDupliValue() {
    let seen = new Set();
    let hasDuplicates = this.getValueArray(this.action).some((item) => seen.size === seen.add(item).size);
    return hasDuplicates;
  }

  getValueArray(obj: object) {
    const valueArray: any[] = [];
    for (let prop in obj) {
      if (obj[prop] !== '-1') {
        valueArray.push(obj[prop]);
      }
    }

    return valueArray;
  }

  getDupliValues(arr: any[]) {
    return arr.filter((item, index) => arr.indexOf(item) !== index);
  }

  getValue(index: number) {
    const data =  this.selected_fields.filter((item) => item.index === index);
    if (data.length !== 0) {
      return data[0].value;
    } else {
      return null;
    }
  }

  onSelectionChange($event, index) {
    const data = {index: index, value: $event.value};
    if (this.selected_fields.length === 0) {
      this.selected_fields.push(data);
    } else {
      const indexArray = this.selected_fields.map(item => item.index);
      if (!indexArray.includes(index)) {
        this.selected_fields.push(data);
      } else {
        this.selected_fields.forEach((item) => {
          if (item.index === index) {
            item.value = $event.value;
          }
        })
      }
    }

  }

  onSaveAndClose() {
    // Validate column mappings.
    if ( !this.hasValidColumnMappings() ) {
      this.utils.showModal('Required Columns Not Mapped', 'Please make sure you map the task name column.');
      return;
    }

    let response: any;

    if (this.hasDupliValue()) {
      this.utils.showModal(
        'Duplicate Fields',
        'Please make sure no duplicate fields durring mapping.'
      );
    } else {
      this.dataToOutput = this.data.actionsData.map((item: any) => {
        const data = {};
        for (let prop in this.action) {
          if (this.action[prop] !== '-1') {
            data[this.action[prop]] = item[prop];
          }
        }
        return data;
      })

      response = {
        dataToOutput: this.dataToOutput

      }
    }

    this.dialogRef.close(response);
  }

}
