import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {ActionsService} from "../actions.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";

@Component({
  selector: 'app-actions-filter',
  templateUrl: './actions-filter.component.html',
  styleUrls: ['./actions-filter.component.scss']
})
export class ActionsFilterComponent implements OnInit {

  filters: ActionsFilters = {
    site_ids: [],
    assigned_users: [],
    priority: '',
    status: '',
    date_range: [],
  } as ActionsFilters;

  // The default list of priorities.
  priorities: string[] = ['Low', 'Medium', 'High', 'Critical'];

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ActionsFilterComponent>
  ) { }

  ngOnInit(): void {
    // Set default values if not provided.
    this.filters.site_ids = this.data['site_ids'] || [];
    this.filters.assigned_users = this.data['assigned_users'] || [];
    this.filters.priority = this.data['priority'] || '';
    this.filters.status = this.data['status'] || '';
    this.filters.date_range = this.data['date_range'] || '';
  }

  /**
   * Sets the output date range based on the provided event value.
   *
   * @param {any} event - The event value to set the date range.
   * @return {void} - No return value.
   */
  getOutputDateRange(event: any): void {
    this.filters.date_range = event;
  }

  /**
   * Opens the sites' selector dialog and updates the selected site IDs.
   *
   * @returns {void}
   */
  onSelectSites(): void {
    // Open the sites' selector.
    this.utils.showComponentDialog(SitesSelectorComponent, {
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }, (results: any): void => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      }
    );
  }

  /**
   * Open the networked users' selector and update the assigned users filters.
   *
   * @return {void}
   */
  onSelectUsers(): void {
    // Open the networked users' selector.
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
        selected: this.filters.assigned_users,
        multiple: true,
        visitors_from_all_sites: true
      }, {
        width: '1024px'
      }, (results: any): void => {
        if ( typeof results != 'undefined' ) {
          this.filters.assigned_users = results;
        }
      }
    );
  }

  /**
   * Clears the filters and closes the form.
   *
   * @return {void}
   */
  onClearAndClose(): void {
    // Clear the filters.
    this.filters.site_ids = [];
    this.filters.assigned_users = [];
    this.filters.priority = "";
    this.filters.status = "";
    this.filters.date_range = [];

    this.onSaveAndClose();
  }

  /**
   * Saves the filters and closes the dialog.
   *
   * @return {void}
   */
  onSaveAndClose(): void {
    this.dialogRef.close(this.filters);
  }

}

export interface ActionsFilters {
  site_ids: number[];
  assigned_users: number[];
  priority: string;
  status: string;
  date_range: Date[];
}

