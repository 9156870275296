import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { UtilsService } from '../../shared/utils.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { OnsiteRecordsFilterComponent } from "../onsite-records-filter/onsite-records-filter.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../../shared/api-request.service";
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-onsite-records',
  templateUrl: './onsite-records.component.html',
  styleUrls: ['./onsite-records.component.css']
})
export class OnsiteRecordsComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'id',
    'contact_person',
    'entity_name',
    'site_name',
    'method',
    'reason',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource: OnsiteRecordsDataSource = new OnsiteRecordsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  contractors: any[] = [];

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService,
    public app: AppService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    // Subscribe to the paginator tap events.
    this.paginator.page.pipe(tap(() => {
      // Set the list properties.
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order = this.sort.direction;
      // Sorting for UTC time by parsing original time.
      if ( this.sort.active === "date_created_UTC" ) {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }
      // Make a request to get the data.
      this.dataSource.getData();
    })).subscribe();

    // Subscribe to the sort change tap events.
    this.sort.sortChange.pipe(tap(() => {
      // Set the list properties.
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;
      // Make a request to get the data, resetting the offset.
      this.dataSource.getData(true);
    })).subscribe();
  }

  onTakeOffsite(time_onsite_id: number) {
    this.utils.showModal(
      'Take User Offsite',
      'Are you sure you want to take this user offsite?',
      () => {
        this.api.makeRequest('put', `v1/onsite_records/${time_onsite_id}/offsite`).then((response) => {
          this.dataSource.getData();
        });
      }
    );
  }

  composeNewMessage(userId: number) {
    if (!userId) {
      this.utils.showToast('We are unable to open the message composer. Please refresh the page and try again.');
      return; // Early termination.
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: [userId]
    })
      .then(() => {
        this.utils.showToast('You can view the message stats in the messages section.');
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      OnsiteRecordsFilterComponent,
      {
        selected_site_ids: this.dataSource.site_ids,
        selected_contractor_ids: this.dataSource.contractor_ids,
        selected_user_ids: this.dataSource.user_ids,
        selected_employee_ids: this.dataSource.selected_employee_ids
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.contractor_ids = results.contractor_ids ? results.contractor_ids : this.dataSource.contractor_ids;
          this.dataSource.user_ids = results.user_ids ? results.user_ids : this.dataSource.user_ids;
          this.dataSource.selected_employee_ids = results.selected_employee_ids ? results.selected_employee_ids : this.dataSource.selected_employee_ids;
          this.dataSource.getData();
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

  /**
   * Export the onsite records to a csv file.
   */
  onExport() {
    // Make an API request to export the data.
    this.dataSource.exportData()
      .then((response) => {
        // Save the binary response as a CSV file.
        saveAs(response, 'Onsite Records.csv');
      })
      .catch((errorResponse) => {
        // Show a standard error message.
        this.utils.showModal('Export Error', 'We could not export your onsite records. Please try again and contact support if the issue persists.');
      });
  }

}

export class OnsiteRecordsDataSource extends ApiDataSource {

  order_by: string = 'id';
  order: string = 'desc';

  site_ids: number[] = [];
  contractor_ids: number[] = [];
  user_ids: number[] = [];
  date_range: Date[] = [];
  selected_site_ids: number[] = [];
  selected_contractor_ids: number[] = [];
  selected_user_ids: number[] = [];
  selected_employee_ids: number[] = [];

  filter_date_range: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date()
  ];

  /**
   * This prepares the query params for the API requests to list or export the data.
   */
  prepareQueryParams() {
    return {
      site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
      user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
      employee_ids: this.selected_employee_ids.length ? this.selected_employee_ids.join(',') : '',
      date_range: this.filter_date_range && this.filter_date_range.length > 1 ? (this.filter_date_range[0].getTime() / 1000) + ',' + (this.filter_date_range[1].getTime() / 1000) : 0
    };
  }

  /**
   * Make a request to get list data.
   * @param resetOffset This will reset the offset to load data from the beginning.
   */
  getData(resetOffset: boolean = false) {
    return this.makeRequest(`v1/onsite_records`, resetOffset, this.prepareQueryParams());
  }

  /**
   * Make a request to export the data to a CSV file.
   */
  exportData() {
    return this.api.makeDownloadRequest(`v1/onsite_records/export-csv`, {}, this.prepareQueryParams());
  }
}
