import { Component, Inject, OnInit } from '@angular/core';
import { UtilsService } from "../../../shared/utils.service";
import { ApiRequestService } from "../../../shared/api-request.service";
import { TradeModel } from "../trades.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-trades-edit',
  templateUrl: './trades-edit.component.html',
  styleUrls: ['./trades-edit.component.scss']
})
export class TradesEditComponent implements OnInit {

  inProgress = false;

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public trade: TradeModel,
    private dialogRef: MatDialogRef<TradesEditComponent>,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    if(this.trade.id) {
      this.find(this.trade.id);
    }
  }

  onSubmit() {
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;
    !this.trade.id ? this.create() : this.update();
  }

  private find(trade_id: number) {
    this.api.makeRequest('get', `v2/admin/trades/${trade_id}`)
      .then((response) => {
        this.trade = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete() {
    this.utils.showModal(
      'Remove Job Title',
      `Are you sure you want to remove the "${this.trade.name}" job title?`,
      () => {
        this.api.makeRequest('delete', `v2/admin/trades/${this.trade.id}`)
          .then((response) => {
            this.utils.showToast(`"${this.trade.name}" has been deleted.`);
            this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update() {
    this.api
      .makeRequest('put', `v2/admin/trades/${this.trade.id}`, this.trade)
      .then((response: TradeModel) => {
        this.onSuccess(`The job title "${response.name}" was updated.`);
      })
      .finally(() => (this.inProgress = false));
  }

  private create() {
    this.api
      .makeRequest('post', 'v2/admin/trades', this.trade)
      .then((response: TradeModel) => {
        this.onSuccess(`The new job title "${response.name}" was created.`);
      })
      .finally(() => this.inProgress = false);
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }

}
