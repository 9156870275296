import {
  Component,
  ElementRef,
  OnInit,
  Renderer,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { RemoteService } from '../shared/remote.service';
import { UtilsService } from '../shared/utils.service';

import * as CONST from '../app.const';

@Component({
  selector: 'app-mailchimp',
  templateUrl: './mailchimp.component.html',
  styleUrls: ['./mailchimp.component.scss']
})
export class MailchimpComponent implements OnInit {
  list: any = [];
  selectedListId: any = '-';

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  selectedFile: any;

  serviceUrl: string = CONST.SERVICE_URL;

  constructor(
    public remote: RemoteService,
    public renderer: Renderer,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.refreshTheList();
  }

  refreshTheList() {
    // clear the current list
    this.list.length = 0;
    // make the remote request
    this.remote.serviceAPI(
      'get',
      'mailchimp/get-list',
      {},
      {},
      {
        success: (response: any) => {
          response.data.lists.forEach((item) => {
            this.list.push(item);
          });
        }
      }
    );
  }

  /**
   *
   * @param evt
   *
   * click an ion-button that in turn triggers the file input type to select a file.
   * when a file is selected, it is uploaded.
   *
   * http://pjsdev.blogspot.co.nz/2016/05/ionic-2-beta-6-angular-2-styled-upload.html
   */
  onTriggerFileSelector(evt: Event) {
    const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInput.nativeElement,
      'dispatchEvent',
      [clickEvt]
    );
  }

  // when a fileis selected, store it.
  onFileSelected(evt: any) {
    this.selectedFile = evt.target.files[0]; // there must be a file selected to trigger this function.
  }

  onListItemChanged() {}

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.remote.upload(
        'mailchimp/import/' + this.selectedListId,
        this.selectedFile,
        {},
        {
          success: (response) => {
            this.utils.showToast(response.message);

            this.selectedFile = null;

            this.selectedListId = '-';
          }
        }
      );
    }
  }
}
