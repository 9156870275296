import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitorsRoutingModule } from './visitors-routing.module';
import {VisitorsComponent} from "./visitors.component";
import {VisitorsViewComponent} from "./visitors-view/visitors-view.component";
import {PipesModule} from "../pipes/pipes.module";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatSelectModule,
  MatDatepickerModule,
  MatSidenavModule,
  MatCardModule,
  MatProgressSpinnerModule
} from '@angular/material';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    VisitorsComponent,
    VisitorsViewComponent
  ],
  imports: [
    CommonModule,
    VisitorsRoutingModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatCardModule,
    MatProgressSpinnerModule,
    PipesModule,
    FormsModule
  ],
  entryComponents: [
    VisitorsViewComponent
  ]
})
export class VisitorsModule { }
