import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-master-charts-popup-dialog',
  templateUrl: './master-charts-popup-dialog.component.html',
  styleUrls: ['./master-charts-popup-dialog.component.scss']
})
export class MasterChartsPopupDialogComponent implements OnInit {

  // The selected tab index which is 0 by default.
  selectedTabIndex: number = 0;

  // Keep this list in the same order as its placement in the html page.
  listedTabs: string[] = [
    'Account Inductions',
    'Forms',
    'Hazards & Risks',
    'Incidents',
    'Inspections & Audits',
    'Messages',
    'Safety Observations',
    'Access Activities',
    this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites'),
    'Tasks',
    this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')
  ];

  // Set the realtime data polling to false.
  enable_realtime_data: boolean = false;
  // Set the default realtime data polling interval to 60 seconds..
  realtime_data_interval_seconds: number = 60;

  // Set the default query type to single. This can only be single or multiple.
  query_type: string = 'single';

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MasterChartsPopupDialogComponent>
  ) { }

  ngOnInit() {
    // Check if the selected tab text is provided.
    if ( typeof this.data['selectedTab'] != 'undefined' && this.data['selectedTab'] != '') {
      // Get the selected tab index.
      const selectedTabIndex: number = this.listedTabs.indexOf(this.data['selectedTab']);
      // Check if the index is valid.
      if ( selectedTabIndex > -1 ) {
        // Set it as the default index.
        this.selectedTabIndex = selectedTabIndex;
      }
    }
  }
}
