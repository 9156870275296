import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatAutocompleteModule
} from '@angular/material';
import { DirectivesModule } from '../directives/directives.module';
import { ContractorsDetailsComponent } from './contractors-details/contractors-details.component';
import { ContractorsDocsComponent } from './contractors-docs/contractors-docs.component';
import { ContractorsAddComponent } from './contractors-add/contractors-add.component';
import { ContractorsEmployeesComponent } from './contractors-employees/contractors-employees.component';
import { ContractorsItemsComponent } from './contractors-items/contractors-items.component';
import { ContractorsPrequalificationsEditComponent } from './contractors-prequalifications/contractors-prequalifications-edit/contractors-prequalifications-edit.component';
import { ContractorsPrequalificationsComponent } from './contractors-prequalifications/contractors-prequalifications.component';
import { ContractorsRoutingModule } from './contractors-routing.module';
import { ContractorsSelectorComponent } from './contractors-selector/contractors-selector.component';
import { ContractorsSitesComponent } from './contractors-sites/contractors-sites.component';
import { ContractorsTabsComponent } from './contractors-tabs/contractors-tabs.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { PipesModule } from '../pipes/pipes.module';
import { FilesModule } from '../files/files.module';
import { ContractorsItemsViewComponent } from './contractors-items/contractors-items-view/contractors-items-view.component';
import { ContractorsItemsEditComponent } from './contractors-items/contractors-items-edit/contractors-items-edit.component';
import { SharedModule } from '../shared/shared.module';
import { ContractorsRelatedAccountsComponent } from './contractors-related-accounts/contractors-related-accounts.component';
import {ContractorsImporterComponent} from "./contractors-importer/contractors-importer.component";
import { ContractorsContractorsComponent } from './contractors-contractors/contractors-contractors.component';


@NgModule({
  declarations: [
    ContractorsComponent,
    ContractorsDetailsComponent,
    ContractorsDocsComponent,
    ContractorsAddComponent,
    ContractorsEmployeesComponent,
    ContractorsItemsComponent,
    ContractorsPrequalificationsEditComponent,
    ContractorsPrequalificationsComponent,
    ContractorsSitesComponent,
    ContractorsTabsComponent,
    ContractorsSelectorComponent,
    ContractorsItemsViewComponent,
    ContractorsItemsEditComponent,
    ContractorsRelatedAccountsComponent,
    ContractorsImporterComponent,
    ContractorsContractorsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ContractorsRoutingModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatBadgeModule,
    PipesModule,
    FilesModule,
    SharedModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatAutocompleteModule
  ],
  entryComponents: [
    SitesSelectorComponent,
    ContractorsAddComponent,
    ContractorsDetailsComponent,
    ContractorsItemsEditComponent
  ]
})
export class ContractorsModule {}
