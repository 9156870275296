import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { UtilsService } from '../../shared/utils.service';
import { ToolboxTalksEditComponent } from '../toolbox-talks-edit/toolbox-talks-edit.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ToolboxTalkModel } from "../../models/toolbox-talk.model";
import { ToolboxTalksFilterComponent } from "../toolbox-talks-filter/toolbox-talks-filter.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-toolbox-talks',
  templateUrl: './toolbox-talks.component.html',
  styleUrls: ['./toolbox-talks.component.css']
})
export class ToolboxTalksComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'site',
    'name',
    'created_by_user',
    'updated_by_user',
    'reported_by_user',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];
  dataSource = new ToolboxTalksDataSource(this.app, this.oldApi);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    // public service: HazardsService,
    public utils: UtilsService,
    public app: AppService,
    public oldApi: ApiService,
    private api: ApiRequestService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      } else if (this.sort.active === "date_modified_UTC") {
        this.dataSource.sort_by = "date_modified";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'toolbox-talks/' + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' was removed.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onEdit(id: number) {
    this.utils.showComponentDialog(ToolboxTalksEditComponent, {
      id: id,
      path: ''
    }, {}, () => {
      this.dataSource.getData();
    });
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks'),
      'Are you sure you want to remove the selected ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + '?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'toolbox-talks/' + this.dataSource.selection.selected.join(','),
          {},
          {},
          (response) => {
            this.utils.showToast('The selected ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' was removed.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  // Duplicate function in sites-toolbox-talks.component.ts
  onExport(type: string = 'pdf', id?: number) {

    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.dataSource.selection.selected);
    }

    // if ( ids.length == 0 ) {
    //   this.utils.showModal('Error Message', 'Please select one or more Toolbox Talks to export.');
    //   return;
    // }

    this.api.makeDownloadRequest(`v1/toolbox-talks/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      site_ids: this.dataSource.site_ids.length ? this.dataSource.site_ids.join(',') : "",
      reported_by_ids: this.dataSource.reported_by_ids.length ? this.dataSource.reported_by_ids.join(',') : "",
      employee_ids: this.dataSource.employee_ids.length ? this.dataSource.employee_ids.join(',') : "",
      attendee_ids: this.dataSource.attendee_ids.length ? this.dataSource.attendee_ids.join(',') : "",
      date_range: this.dataSource.date_range && this.dataSource.date_range.length > 1 ? [
        this.dataSource.date_range[0].getTime() / 1000,
        this.dataSource.date_range[1].getTime() / 1000
      ].join(',') : "",
      sort_by: this.dataSource.sort_by,
      sort_order: this.dataSource.sort_order
    })
      .then((response) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = (this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')) + ` - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.showModal('Error', errorResponse.message);
      });
  }

  onCreateFrom(toolboxTalk: ToolboxTalkModel) {
    const newToolboxTalk: ToolboxTalkModel = new ToolboxTalkModel();
    newToolboxTalk.createFrom(toolboxTalk);
    this.utils.showComponentDialog(ToolboxTalksEditComponent, {
      toolbox_talk: newToolboxTalk,
      site_id: toolboxTalk.site_id
    })
      .then(() => {
        this.dataSource.getData();
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      ToolboxTalksFilterComponent,
      {
        site_ids: this.dataSource.site_ids,
        reported_by_ids: this.dataSource.reported_by_ids,
        employee_ids: this.dataSource.employee_ids,
        attendee_ids: this.dataSource.attendee_ids,
        date_range: this.dataSource.date_range
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.reported_by_ids = results.reported_by_ids ? results.reported_by_ids : this.dataSource.reported_by_ids;
          this.dataSource.employee_ids = results.employee_ids ? results.employee_ids : this.dataSource.employee_ids;
          this.dataSource.attendee_ids = results.attendee_ids ? results.attendee_ids : this.dataSource.attendee_ids;
          this.dataSource.date_range = results.date_range ? results.date_range : this.dataSource.date_range;
          this.dataSource.getData();
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class ToolboxTalksDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'date_created';
  sort_order = 'desc';

  site_ids = [];
  reported_by_ids = [];
  employee_ids = [];
  attendee_ids = [];
  date_range: Date[] = [];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('toolbox-talks', resetOffset, {}, {
      site_ids: this.site_ids.length ? this.site_ids.join(',') : "",
      reported_by_ids: this.reported_by_ids.length ? this.reported_by_ids.join(',') : "",
      employee_ids: this.employee_ids.length ? this.employee_ids.join(',') : "",
      attendee_ids: this.attendee_ids.length ? this.attendee_ids.join(',') : "",
      eager_load: true,
      date_range: this.date_range && this.date_range.length > 1 ? [
        this.date_range[0].getTime() / 1000,
        this.date_range[1].getTime() / 1000
      ].join(',') : ""
    });
  }
}
