import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { IncidentsWitnessesEditComponent } from '../incidents-witnesses-edit/incidents-witnesses-edit.component';
import { IncidentWitnessService } from '../incident-witness.service';

@Component({
  selector: 'app-incidents-witnesses',
  templateUrl: './incidents-witnesses.component.html',
  styleUrls: ['./incidents-witnesses.component.scss']
})
export class IncidentsWitnessesComponent implements OnInit {
  @Input() private incidentId: number;

  displayedColumns: string[] = [
    'select',
    'id',
    'full_name',
    'company_name',
    'email',
    'phone',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new IncidentsWitnessesDataSource(this.app, this.api);

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    public api: ApiService,
    public utils: UtilsService,
    private witnessService: IncidentWitnessService
  ) {}

  ngOnInit() {
    this.dataSource.incidentId = this.incidentId;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Resets the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns based on their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit('new');
  }

  onEdit(id: number | string) {
    this.utils.showComponentDialog(
      IncidentsWitnessesEditComponent,
      { witnessId: id, incidentId: this.incidentId },
      { width: '700px' },
      (success: boolean) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      }
    );
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Witness',
      'Are you sure you want to remove this witness?',
      () => {
        this.witnessService.remove([id]).subscribe(() => {
          this.dataSource.selection.deselect(id);
          this.dataSource.getData();
          this.utils.showToast('The witness was removed');
        });
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected Witnesses',
      'Are you sure you want to remove the selected witnesses?',
      () => {
        this.witnessService
          .remove(this.dataSource.selection.selected)
          .subscribe(() => {
            this.dataSource.selection.clear();
            this.dataSource.getData();
            this.utils.showToast('The witness was removed');
          });
      }
    );
  }
}

export class IncidentsWitnessesDataSource extends CustomDataSource {
  sort_by = 'full_name';
  sort_order = 'asc';
  incidentId: number;

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('incident-witnesses', resetOffset, () => {}, {
      incident_id: this.incidentId
    });
  }
}
