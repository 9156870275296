import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoauthPrequalificationsComponent } from './noauth-prequalifications/noauth-prequalifications.component';
import { NoauthRoutingModule } from './noauth-routing.module';
import { MyItemsModule } from '../my-items/my-items.module';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { NoauthUserPublicProfileComponent } from './noauth-user-public-profile/noauth-user-public-profile.component';
import {FilesModule} from "../files/files.module";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTabsModule} from "@angular/material/tabs";
import {SharedModule} from "../shared/shared.module";
import {MatListModule} from "@angular/material/list";
import {PipesModule} from "../pipes/pipes.module";
import {DirectivesModule} from "../directives/directives.module";
import {
  NoauthUserPublicProfileSitesWorkedOnComponent
} from "./noauth-user-public-profile-sites-worked-on/noauth-user-public-profile-sites-worked-on.component";
import {MatComponentsModule} from "../mat-components/mat-components.module";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    NoauthPrequalificationsComponent,
    NoauthUserPublicProfileComponent,
    NoauthUserPublicProfileSitesWorkedOnComponent
  ],
    imports: [
        CommonModule,
        NoauthRoutingModule,
        MyItemsModule,
        MatButtonModule,
        MatIconModule,
        FilesModule,
        MatDialogModule,
        MatTabsModule,
        SharedModule,
        MatListModule,
        PipesModule,
        DirectivesModule,
      MatComponentsModule,
      FormsModule
    ]
})
export class NoauthModule { }
