import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { DynamicFormsCategoriesEditComponent } from '../dynamic-forms-categories-edit/dynamic-forms-categories-edit.component';
import { DynamicFormsCategoriesViewComponent } from '../dynamic-forms-categories-view/dynamic-forms-categories-view.component';
import { DynamicFormsCategoryModel } from '../dynamic-forms-category.model';
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-dynamic-forms-categories',
  templateUrl: './dynamic-forms-categories.component.html',
  styleUrls: ['./dynamic-forms-categories.component.scss']
})
export class DynamicFormsCategoriesComponent implements OnInit {

  // Columns to display in table.
  displayedColumns: string[] = [
    'select',
    'id',
    'category',
    'forms_count',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];

  dataSource = new DynamicFormsCategoriesDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private route: ActivatedRoute,
    public utils: UtilsService,
    private router: Router,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns by their corresponding date columns.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      if ( sorter['active'] == 'date_modified_UTC' ) {
        this.dataSource.order_by = 'date_modified';
      }

      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Open the form category to view its information.
   * @param category_id The category id.
   */
  onView(category_id: number) {
    this.utils.showComponentDialog(DynamicFormsCategoriesViewComponent, {
      category_id: category_id
    }, {
      width: '500px'
    })
    .then(() => {
      this.dataSource.getData();
    });
  }

  /**
   * Open the category to allow the user to edit the form category information.
   * @param category_id The form category id.
   */
  onEdit(category_id?: number) {
    this.utils.showComponentDialog(DynamicFormsCategoriesEditComponent, {
      category_id: category_id
    }, {
      width: '400px'
    })
    .then(() => {
      this.dataSource.getData();
    });
  }

  /**
   * Archive the form category once confirmation is received.
   * @param category The form category information.
   * @param evt The html element reference to use for showing the quick actions dialog.
   */
  onArchive(category: DynamicFormsCategoryModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to archive the "${category.category}" category?`, [
      {
        text: 'Yes',
        handler: () => {
          this.archiveSelected([category.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Archive the selected form categories as soon as confirmation is received.
   */
  onArchiveSelected() {
    this.utils.showModal(
      'Archive Selected Categories',
      'Are you sure you want to archive the selected categories?',
      () => {
        const archived = this.archiveSelected(this.dataSource.selection.selected);
        if ( archived != null ) {
          archived.then(() => {
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Send an API request to archive the selected form categories.
   * @param categoryIds The list of category ids.
   * @returns
   */
  private archiveSelected(categoryIds: number[]): null|Promise<any> {
    if ( !categoryIds.length ) {
      this.utils.showModal('Archiving Selected Categories', 'You need to select at least one category to archive.');
      return;
    }
    return this.api.makeRequest('delete', `v2/dynamic-forms-categories/${categoryIds.join(',')}`)
    .then(() => {
      this.dataSource.getData();
      this.utils.showToast('The selected categories were archived.');
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  /**
   * Open a quick actions dialog to confirm the user's form category restoration request.
   * @param category The category information.
   * @param evt The html element reference to show the quick actions dialog.
   */
  onRestore(category: DynamicFormsCategoryModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to restore the "${category.category}" category?`, [
      {
        text: 'Yes',
        handler: () => {
          this.restoreSelected([category.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Restore selected from categories as soon as confirmation is received.
   */
  onRestoreSelected() {
    this.utils.showModal(
      'Restore Selected Categories',
      'Are you sure you want to restore the selected categories?',
      () => {
        const request = this.restoreSelected(this.dataSource.selection.selected);
        if ( request != null ) {
          request.then(() => {
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Send a request to the API to restore the selected categories.
   * @param categoryIds The selected category ids to restore.
   * @returns
   */
  private restoreSelected(categoryIds: number[]): null|Promise<any> {
    if ( !categoryIds.length ) {
      this.utils.showModal('Restoring Selected Categories', 'You need to select at least one category to restore.');
      return;
    }
    return this.api.makeRequest('put', `v2/dynamic-forms-categories/restore/${categoryIds.join(',')}`)
    .then(() => {
      this.dataSource.getData();
      this.utils.showToast('The selected categories were restored.');
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  /**
   * Navigate the user to the list of form templates filtered by the selected category.
   * @param category_id The category id to filter by.
   */
  onShowFormTemplates(category_id: number) {
    this.router.navigate(['dynamic-forms'], {
      queryParams: {
        category_id: category_id
      }
    });
  }

  /**
   * Navigate the user to the list of assigned forms filtered by the selected category.
   * @param category_id The category id to filter by.
   */
  onShowAssignedForms(category_id: number) {
    this.router.navigate(['dynamic-forms-assigned'], {
      queryParams: {
        category_id: category_id
      }
    });
  }

}

export class DynamicFormsCategoriesDataSource extends ApiDataSource {

  order_by = 'category';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/dynamic-forms-categories`, resetOffset);
  }
}
