import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatListModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { SitesSelectorComponent } from '../../shared/sites-selector/sites-selector.component';
import { MultiAccountDashboardRoutingModule } from './multi-account-dashboard-routing.module';
import { MultiAccountDashboardComponent } from './multi-account-dashboard.component';
import {ChartsModule} from "../../charts/charts.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatMenuModule} from "@angular/material/menu";
import {MultiAccountDashboardFilterComponent} from "./multi-account-dashboard-filter/multi-account-dashboard-filter.component";
import {SharedModule} from "../../shared/shared.module";
import {MultiAccountSelectorComponent} from "./multi-account-selector/multi-account-selector.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSortModule} from "@angular/material/sort";

@NgModule({
  declarations: [
    MultiAccountDashboardComponent,
    MultiAccountDashboardFilterComponent,
    MultiAccountSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MultiAccountDashboardRoutingModule,
    BsDatepickerModule.forRoot(),
    MatIconModule,
    MatPaginatorModule,
    MatCardModule,
    MatBadgeModule,
    BsDatepickerModule,
    MatButtonModule,
    DirectivesModule,
    MatListModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    PipesModule,
    ChartsModule,
    DragDropModule,
    MatExpansionModule,
    MatMenuModule,
    SharedModule,
    MatCheckboxModule,
    MatSortModule
  ],
  entryComponents: [
    MultiAccountSelectorComponent,
    MultiAccountDashboardFilterComponent
  ]
})
export class MultiAccountDashboardModule {}
