import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatListModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { UserInductionModulesComponent } from './widgets/user-induction/user-induction-modules/user-induction-modules.component';
import { UserInductionComponent } from './widgets/user-induction/user-induction/user-induction.component';
import {ChartsModule} from "../charts/charts.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatMenuModule} from "@angular/material/menu";
import {DashboardFilterComponent} from "./dashboard-filter/dashboard-filter.component";
import {SharedModule} from "../shared/shared.module";
import {UserInductionsWidgetComponent} from "./widgets/user-inductions-widget/user-inductions-widget.component";
import {LegacyDashboardWidgetComponent} from "./widgets/legacy-dashboard-widget/legacy-dashboard-widget.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSortModule} from "@angular/material/sort";

@NgModule({
  declarations: [
    DashboardComponent,
    UserInductionComponent,
    UserInductionModulesComponent,
    DashboardFilterComponent,
    UserInductionsWidgetComponent,
    LegacyDashboardWidgetComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        DashboardRoutingModule,
        BsDatepickerModule.forRoot(),
        MatIconModule,
        MatPaginatorModule,
        MatCardModule,
        MatBadgeModule,
        BsDatepickerModule,
        MatButtonModule,
        DirectivesModule,
        MatListModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatTableModule,
        MatTooltipModule,
        PipesModule,
        ChartsModule,
        DragDropModule,
        MatExpansionModule,
        MatMenuModule,
        SharedModule,
        MatCheckboxModule,
        MatSortModule
    ],
  entryComponents: [
    UserInductionModulesComponent,
    SitesSelectorComponent,
    UserInductionComponent,
    DashboardFilterComponent
  ]
})
export class DashboardModule {}
