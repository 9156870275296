import { Component, OnInit, Inject } from '@angular/core';
import { ActionModel } from '../action.model';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActionsService } from '../actions.service';

@Component({
  selector: 'app-actions-view-tabs',
  templateUrl: './actions-view-tabs.component.html',
  styleUrls: ['./actions-view-tabs.component.scss']
})
export class ActionsViewTabsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {action: ActionModel},
    private actionsService: ActionsService
  ) { }

  ngOnInit() {
    this.actionsService
      .find(this.data.action.id)
      .subscribe((res: any) => (this.data.action = res.data));
  }

}
