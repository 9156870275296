import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import {ContractorsSelectorComponent} from "../../contractors/contractors-selector/contractors-selector.component";
import {StorageService} from "../../shared/storage.service";
import {AppService} from "../../app.service";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-incidents-filter',
  templateUrl: './incidents-filter.component.html',
  styleUrls: ['./incidents-filter.component.scss']
})
export class IncidentsFilterComponent implements OnInit {

  // The filters object.
  filters: IncidentsFilter = {
    site_ids: [],
    user_ids: [],
    contractor_ids: [],
    type: '',
    status: '',
    date_range: []
  };

  faHardHat = faHardHat;

  datepickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<IncidentsFilterComponent>
  ) { }

  ngOnInit(): void {
    this.datepickerConfig = Object.assign(
      {},
      {
        containerClass: 'theme-orange',
        dateInputFormat: 'DD MMM YYYY',
        rangeInputFormat: 'DD MMM YYYY',
        showWeekNumbers: true
      }
    );
    // Get and apply the incidents filters from storage.
    this.filters = {
      site_ids: this.data['site_ids'] || [],
      user_ids: this.data['user_ids'] || [],
      contractor_ids: this.data['contractor_ids'] || [],
      type: this.data['type'] || '',
      status: this.data['status'] || '',
      date_range: this.data['date_range'] || []
    }
    // Remap the dates as new Date objects. Storing it in storage stores it as string.
    this.filters.date_range = this.filters.date_range.map((date: Date|string) => new Date(date));
  }

  /**
   * Sets the incident date in the filters object based on the provided event.
   *
   * @param {any} $event - The event object containing the incident date.
   * @return {void}
   */
  getOutputDateRange($event): void {
    // Get the incident date from the date selector.
    this.filters.date_range = $event;
  }

  /**
   * Opens the sites selector and allows the user to select multiple sites.
   * Extracts the selected site ids and updates the filters with the result.
   *
   * @return {void}
   */
  onSelectSites(): void {
    // Open the sites selector and extract the site ids.
    this.utils.showComponentDialog(SitesSelectorComponent, {
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      }
    );
  }

  /**
   * Opens the networked users selector and allows the user to select multiple users.
   *
   * @return {void}
   */
  onSelectUsers(): void {
    // Open the networked users selector and extract the user ids.
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
        selected: this.filters.user_ids,
        multiple: true,
        visitors_from_all_sites: true
      }, {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.filters.user_ids = results;
        }
      }
    );
  }

  /**
   * Opens the contractors selector dialog and updates the selected contractor ids.
   *
   * @return {void}
   */
  onSelectContractors(): void {
    // Open the contractors selector dialog and extract contractor ids.
    this.utils.showComponentDialog(ContractorsSelectorComponent, {
      selected: this.filters.contractor_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results) => {
        if (typeof results !== 'undefined') {
          this.filters.contractor_ids = results;
        }
    });
  }

  /**
   * Clears all filter preferences and closes the dialog.
   * @return {void}
   */
  onClearAndClose(): void {
    // Reset the incidents filter preferences.
    this.filters.site_ids = [];
    this.filters.user_ids = [];
    this.filters.contractor_ids = [];
    this.filters.type = '';
    this.filters.status = '';
    this.filters.date_range = [];
    // Save and close the dialog.
    this.onSaveAndClose();
  }

  /**
   * Saves the filter preferences and closes the dialog.
   *
   * @returns {void}
   */
  onSaveAndClose(): void {
    // Close the dialog and send the filters back.
    this.dialogRef.close(this.filters);
  }

  /**
   * Clears the date range for the incident.
   *
   * @returns {void}
   */
  onClearDateRange(): void {
    this.filters.date_range = [];
  }
}

/**
 * Interface representing the filtering options for incidents.
 * @interface
 */
export interface IncidentsFilter {
  parent_site_id?: number,
  site_ids: number[];
  user_ids: number[];
  contractor_ids: number[];
  tag_ids?: number[];
  type: string;
  status: string;
  date_range: Date[];
}
