import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ItemsService } from 'src/app/items/items.service';
import { ItemPivotModel } from 'src/app/items/item-pivot.model';
import { FileService } from 'src/app/shared/file.service';
import { UtilsService } from 'src/app/shared/utils.service';
import * as moment from 'moment';

@Component({
  selector: 'app-contractors-items-edit',
  templateUrl: './contractors-items-edit.component.html',
  styleUrls: ['./contractors-items-edit.component.scss']
})
export class ContractorsItemsEditComponent implements OnInit {
  newFiles: FileList;
  pivot = {} as ItemPivotModel;

  now: moment.Moment = moment();
  expiry: moment.Moment;

  inProgress: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private pivotId: number,
    private itemService: ItemsService,
    private fileService: FileService,
    private dialogRef: MatDialogRef<ContractorsItemsEditComponent>,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.getItem();
  }

  getItem() {
    this.itemService
    .findPivot(this.pivotId)
    .subscribe((response) => {
      this.pivot = response.data;
      if ( this.pivot.expiry ) {
        this.expiry = moment.unix(this.pivot.expiry)
      }
    });
  }

  async onSave() {

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;
    
    const pivotCopy = { ...this.pivot } as ItemPivotModel;
    const createdFiles = await this.fileService.createManyFromForm(
      this.newFiles
    );
    pivotCopy.files = createdFiles;

    if ( this.expiry ) {
      pivotCopy.expiry = this.expiry.unix();
    }

    this.itemService.updatePivot(this.pivot.id, pivotCopy).then(() => {
      this.inProgress = false;
      this.utils.showToast('Pre-Qualification Item Updated.');
      this.getItem();
    }).catch(() => {
      this.inProgress = false;
      this.utils.showToast('Failed to update the pre-qualification item.');
    });
    
    this.newFiles = null;
    
  }

  onRemoveExpiryDate(evt: any) {
    this.utils.showQuickActions(evt.target, 'Would you like to remove the expiry date? <br>Remember to save after removing it.', [
      {
        text: 'Yes',
        handler: () => {
          this.expiry = null;
          this.pivot.expiry = null;
        }
      }, 
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  onClose() {
    this.close(true);
  }

  private close(isSuccessful: boolean) {
    this.dialogRef.close(isSuccessful);
  }
}
