import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {FileService} from "../../shared/file.service";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {PpeSelectorComponent} from "../../shared/ppe-selector/ppe-selector.component";
import {WorkPermitsSelectorComponent} from "../../shared/work-permits-selector/work-permits-selector.component";
import {ToolsSelectorComponent} from "../../shared/tools-selector/tools-selector.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NetworkedEmployeesSelectorComponent} from "../../shared/networked-employees-selector/networked-employees-selector.component";

@Component({
  selector: 'app-task-analyses-revised-add',
  templateUrl: './task-analyses-revised-add.component.html',
  styleUrls: ['./task-analyses-revised-add.component.scss']
})
export class TaskAnalysesRevisedAddComponent implements OnInit {

  inProgress = false;
  selectedFiles: File[] = [];
  newFiles: FileList;
  path: string;
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private fileService: FileService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedAddComponent>,
    public router: Router,
    public renderer: Renderer
  ) { }

  ngOnInit() {
    this.ta_revised = this.dialogData.ta_revised as TaskAnalysesRevisedModel;

    this.ta_revised.ppe = this.dialogData.ta_revised.ppe ? [...this.dialogData.ta_revised.ppe] : [];
    this.ta_revised.ppe_ids = this.ta_revised.ppe ? this.ta_revised.ppe.map((ppe) => ppe.id) : [];

    this.ta_revised.work_permits = this.dialogData.ta_revised.work_permits ? [...this.dialogData.ta_revised.work_permits] : [];
    this.ta_revised.work_permit_ids = this.ta_revised.work_permits ? this.ta_revised.work_permits.map((work_permit) => work_permit.id) : [];

    this.ta_revised.tools = this.dialogData.ta_revised.tools ? [...this.dialogData.ta_revised.tools] : [];
    this.ta_revised.tool_ids = this.ta_revised.tools ? this.ta_revised.tools.map((tool) => tool.id) : [];
  }

  async onSubmit(form: NgForm, continueWhenDone: boolean = false) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(!this.ta_revised.id) {
      this.create(continueWhenDone);
    }
  }

  private create(continueWhenDone: boolean = false) {
    let request: Promise<any>;

    request = this.api
      .makeRequest('post', 'v2/task-analyses-revised', this.ta_revised, {})
      .then((response) => {
        this.onSuccess(`New TA/JSA/SWMS Template "${response.task_name}" created.`);
        this.ta_revised.apply(response);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised/${this.ta_revised.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            this.createClose(continueWhenDone);
          });
      } else {
        this.createClose(continueWhenDone);
      }
    });

  }

  private createClose(continueWhenDone: boolean = false) {
    // If this is a new task analysis, open it in viewing mode.
    if ( continueWhenDone ) {
      this.router.navigate([`/task-analyses-revised/${this.ta_revised.id}/edit/`]);
    }

    this.inProgress = false;

    // Close the editing dialog.
    this.dialogRef.close();
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }

  selectPPE() {
    this.utils.showComponentDialog(
      PpeSelectorComponent,
      {
        selected: this.ta_revised.ppe_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.ppe_ids = results;
      }
    );
  }

  selectWorkPermits() {
    this.utils.showComponentDialog(
      WorkPermitsSelectorComponent,
      {
        selected: this.ta_revised.work_permit_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.work_permit_ids = results;
      }
    );
  }

  selectTools() {
    this.utils.showComponentDialog(
      ToolsSelectorComponent,
      {
        selected: this.ta_revised.tool_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.tool_ids = results;
      }
    );
  }

  /**
   * If it is a new task analysis, add the files to the selected files array.
   * Existing task analysis files will be handled by the file manager.
   * @param files? List of files.
   */
  onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
    }
  }

  onSelectApprover() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.approver_id]
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.ta_revised.approver_id = userId;
      }
    );
  }

  onSelectAuthorizer() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.authorizer_id]
        // selectedAccountId: this.app.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.ta_revised.authorizer_id = userId;
      }
    );
  }

  onSelectSupervisor() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.supervisor_id]
        // selectedAccountId: this.app.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.ta_revised.supervisor_id = userId;
      }
    );
  }

}
