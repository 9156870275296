import { Component, OnInit, ViewChild } from '@angular/core';
import { SlaveAccountsSelectorComponent } from '../shared/slave-accounts-selector/slave-accounts-selector.component';
import { UtilsService } from '../shared/utils.service';
import { ApiDataSource } from '../utils/api-data-source';
import { tap } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';

@Component({
  selector: 'app-related-slave-accounts',
  templateUrl: './related-slave-accounts.component.html',
  styleUrls: ['./related-slave-accounts.component.scss']
})
export class RelatedSlaveAccountsComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'actions'
  ];

  dataSource = new RelatedSlaveAccountsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();

  }

  onUnlinkSelected() {
    this.unlink(this.dataSource.selection.selected);
  }

  onSelectSlaveAccounts() {
    this.utils.showComponentDialog(SlaveAccountsSelectorComponent, {
      selected: [],
      multiple: true
    }, {
      width: '1024px'
    },
    (results: number[]) => {
      if (!results || !results.length) { return; }
      this.utils.showModal('Account Link Confirmation', `Are you sure you want to link the selected account(s) to "${this.app.account.name}"? "${this.app.account.name}" will synchronise its data to the selected account(s).`, () => {
        this.api.makeRequest('put', 'v2/related-accounts/link', {
          ids: results
        }).then((response: any) => {
          this.dataSource.getData();
        });
      });
    });
  }

  onSync() {

  }

  onUnlink(accountId: number) {
    this.unlink([accountId]);
  }

  unlink(accountIds: number[]) {
    if ( !accountIds.length ) { return; }
    this.utils.showModal('Account Unlink Confirmation', `Are you sure you want to unlink the selected account(s) from "${this.app.account.name}"? Data from "${this.app.account.name}" will no longer synchronise with it.`, () => {
      this.api.makeRequest('put', `v2/related-accounts/unlink`, {
        ids: accountIds
      }).then((response: any) => {
        this.dataSource.getData();
        accountIds.forEach((accountId) => {
          this.dataSource.selection.deselect(accountId);
        });
      });
    });
  }

}

export class RelatedSlaveAccountsDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/related-accounts', resetOffset);
  }
}
