import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { CustomDataSource } from 'src/app/utils/custom-data-source';

@Component({
  selector: 'app-employees-linked-sites',
  templateUrl: './employees-linked-sites.component.html',
  styleUrls: ['./employees-linked-sites.component.scss']
})
export class EmployeesLinkedSitesComponent implements OnInit, AfterViewInit {
  employee_id: number;

  // columns to show and the data source
  displayedColumns: string[] = ['select', 'name', 'actions'];
  dataSource: SiteUserLinkDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.employee_id = Number(this.route.parent.snapshot.params['user_id']);

    if (!this.employee_id) {
      this.router.navigate(['employees']);
      return;
    }

    this.dataSource = new SiteUserLinkDataSource(this.app, this.api, true, {
      employee_id: this.employee_id
    });

    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onSelectSites() {
    // show the site selector
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        multiple: true,
        selected: this.dataSource.site_ids
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          // store the newly selected sites.
          this.dataSource.site_ids = results;

          // todo create contractor site link endpoint
          this.api.laravelApiRequest(
            'put',
            'employees/' + this.employee_id + '/linked-sites',
            {
              site_ids: results
            },
            {},
            (response) => {
              this.api.utils.showToast(
                'The selected ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' were linked to the ' + this.utils.getLangTerm('employees.singular', 'Employee') + '.'
              );

              // reload the data and reset the offset
              this.dataSource.getData(true);
            }
          );
        }
      }
    );
  }

  onUnlinkSite(site_id: number) {
    this.onRequestUnlinkSites([site_id]);
  }

  onUnlinkSelectedSites() {
    this.onRequestUnlinkSites(this.dataSource.selection.selected, () => {
      this.dataSource.selection.clear(); // clear the selection afterwards
    });
  }

  private onRequestUnlinkSites(site_ids: number[], callback?: any) {
    this.api.laravelApiRequest(
      'delete',
      'employees/' + this.employee_id + '/linked-sites/' + site_ids.join(','),
      {},
      {},
      (response) => {
        this.utils.showToast('The ' + this.utils.getLangTerm('employees.singular', 'Employee') + ' was removed');

        if (typeof callback === 'function') {
          callback(response);
        }

        this.dataSource.getData(true);
      },
      (error) => {
        this.utils.showModal(
          'Error',
          'The ' + this.utils.getLangTerm('employees.singular', 'Employee') + ' was not removed: ' + error.message
        );
      }
    );
  }
}

export class SiteUserLinkDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  // used to pass into the site selector component
  site_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'employees/' + this['employee_id'] + '/linked-sites',
      resetOffset,
      (response) => {
        this.site_ids.length = 0;
        this.site_ids.push(...response.meta.site_ids);
      }
    );
  }
}
