import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatPaginatorModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatCardModule,
  MatInputModule,
  MatAutocompleteModule
} from '@angular/material';
import { PipesModule } from '../pipes/pipes.module';
import { RepoSearchComponent } from './repo-search/repo-search.component';
import { SitesSelectorComponent } from './sites-selector/sites-selector.component';
import { NetworkedAccountsSelectorComponent } from './networked-accounts-selector/networked-accounts-selector.component';
import { NetworkedUsersSelectorComponent } from './networked-users-selector/networked-users-selector.component';
import { RatingComponent } from './rating/rating.component';
import { GlobalAccountsSelectorComponent } from './global-accounts-selector/global-accounts-selector.component';
import { SlaveAccountsSelectorComponent } from './slave-accounts-selector/slave-accounts-selector.component';
import { QuickActionsComponent } from './quick-actions/quick-actions.component';
import { NetworkedEmployeesSelectorComponent } from './networked-employees-selector/networked-employees-selector.component';
import { PpeSelectorComponent } from './ppe-selector/ppe-selector.component';
import { MatListModule } from "@angular/material/list";
import { DirectivesModule } from "../directives/directives.module";
import { WorkPermitsSelectorComponent } from './work-permits-selector/work-permits-selector.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { TradesSelectorComponent } from './trades-selector/trades-selector.component';
import { IndustriesSelectorComponent } from './industries-selector/industries-selector.component';
import { ToolsSelectorComponent } from './tools-selector/tools-selector.component';
import { TaskAnalysesRevisedSelectorComponent } from './task-analyses-revised-selector/task-analyses-revised-selector.component';
import { SiteHazardsSelectorComponent } from './site-hazards-selector/site-hazards-selector.component';
import { AccountSitesHazardsSelectorComponent } from './account-sites-hazards-selector/account-sites-hazards-selector.component';
import { TimezoneSelectComponent } from './timezone-select/timezone-select.component';
import { UpstreamAccountsSelectorComponent } from './upstream-accounts-selector/upstream-accounts-selector.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import { TableTimezoneSelectComponent } from './table-timezone-select/table-timezone-select.component';
import { UserPublicProfileComponent } from './user-public-profile/user-public-profile.component';
import {ClipboardModule} from "ngx-clipboard";
import {FilesModule} from "../files/files.module";
import { UserPublicProfileDetailsComponent } from './user-public-profile-details/user-public-profile-details.component';
import { UserPublicProfileTrainingDocsComponent } from './user-public-profile-training-docs/user-public-profile-training-docs.component';
import {MatTabsModule} from "@angular/material/tabs";
import { UserPublicProfileTrainingDocFilesComponent } from './user-public-profile-training-doc-files/user-public-profile-training-doc-files.component';
import { PasswordStrengthIndicatorComponent } from './password-strength-indicator/password-strength-indicator.component';
import { TagsSelectorComponent } from './tags-selector/tags-selector.component';
import { TagsFilterComponent } from './tags-filter/tags-filter.component';
import {MatChipsModule} from "@angular/material/chips";
import { UserPublicProfileTrainingDocLinksComponent } from './user-public-profile-training-doc-links/user-public-profile-training-doc-links.component';
import {DataImportComponent} from "./data-import/data-import.component";
import {
  UserPublicProfileSitesVisitedComponent
} from "./user-public-profile-sites-visited/user-public-profile-sites-visited.component";
import {RouterModule} from "@angular/router";
import {
  UserPublicProfileSitesWorkedOnComponent
} from "./user-public-profile-sites-worked-on/user-public-profile-sites-worked-on.component";
import {MatComponentsModule} from "../mat-components/mat-components.module";
import { AdminEmployeesSelectorComponent } from './admin-employees-selector/admin-employees-selector.component';
import {
  UserPublicProfileAssignedFormsComponent
} from "./user-public-profile-assigned-forms/user-public-profile-assigned-forms.component";
import { RiskMatrixComponent } from './risk-matrix/risk-matrix.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { DigitalSignatureDialogComponent } from './digital-signature-dialog/digital-signature-dialog.component';
import { DigitalSignatureImageComponent } from './digital-signature-image/digital-signature-image.component';

@NgModule({
  declarations: [
    RepoSearchComponent,
    SitesSelectorComponent,
    NetworkedAccountsSelectorComponent,
    NetworkedUsersSelectorComponent,
    RatingComponent,
    GlobalAccountsSelectorComponent,
    SlaveAccountsSelectorComponent,
    QuickActionsComponent,
    NetworkedEmployeesSelectorComponent,
    PpeSelectorComponent,
    WorkPermitsSelectorComponent,
    IndustriesSelectorComponent,
    TradesSelectorComponent,
    ToolsSelectorComponent,
    TaskAnalysesRevisedSelectorComponent,
    SiteHazardsSelectorComponent,
    AccountSitesHazardsSelectorComponent,
    TimezoneSelectComponent,
    UpstreamAccountsSelectorComponent,
    DateRangePickerComponent,
    TableTimezoneSelectComponent,
    UserPublicProfileComponent,
    UserPublicProfileDetailsComponent,
    UserPublicProfileTrainingDocsComponent,
    UserPublicProfileTrainingDocFilesComponent,
    PasswordStrengthIndicatorComponent,
    TagsSelectorComponent,
    TagsFilterComponent,
    UserPublicProfileTrainingDocLinksComponent,
    DataImportComponent,
    UserPublicProfileSitesVisitedComponent,
    UserPublicProfileSitesWorkedOnComponent,
    AdminEmployeesSelectorComponent,
    UserPublicProfileAssignedFormsComponent,
    RiskMatrixComponent,
    PhoneInputComponent,
    DigitalSignatureComponent,
    DigitalSignatureDialogComponent,
    DigitalSignatureImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    PipesModule,
    MatTooltipModule,
    MatCardModule,
    MatListModule,
    DirectivesModule,
    MatFormFieldModule,
    MatSelectModule,
    BsDatepickerModule,
    ClipboardModule,
    FilesModule,
    MatTabsModule,
    MatChipsModule,
    ReactiveFormsModule,
    RouterModule,
    MatComponentsModule,
    NgxIntlTelInputModule
  ],
  exports: [
    TimezoneSelectComponent,
    RatingComponent,
    DateRangePickerComponent,
    TableTimezoneSelectComponent,
    UserPublicProfileDetailsComponent,
    UserPublicProfileTrainingDocsComponent,
    PasswordStrengthIndicatorComponent,
    TagsFilterComponent,
    DataImportComponent,
    UserPublicProfileSitesVisitedComponent,
    UserPublicProfileSitesWorkedOnComponent,
    UserPublicProfileAssignedFormsComponent,
    PhoneInputComponent,
    RiskMatrixComponent,
    DigitalSignatureComponent,
    DigitalSignatureImageComponent
  ],
  entryComponents: [
    GlobalAccountsSelectorComponent,
    SlaveAccountsSelectorComponent,
    QuickActionsComponent,
    NetworkedEmployeesSelectorComponent,
    IndustriesSelectorComponent,
    TradesSelectorComponent,
    UpstreamAccountsSelectorComponent,
    UserPublicProfileTrainingDocFilesComponent,
    TagsSelectorComponent,
    UserPublicProfileTrainingDocLinksComponent,
    AdminEmployeesSelectorComponent,
    PhoneInputComponent,
    DigitalSignatureDialogComponent
  ]
})
export class SharedModule {}
