import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { DynamicFormsCategoryModel } from '../dynamic-forms-category.model';

@Component({
  selector: 'app-dynamic-forms-categories-view',
  templateUrl: './dynamic-forms-categories-view.component.html',
  styleUrls: ['./dynamic-forms-categories-view.component.scss']
})
export class DynamicFormsCategoriesViewComponent implements OnInit {

  category: DynamicFormsCategoryModel = {} as DynamicFormsCategoryModel;

  constructor(
    private utils: UtilsService,
    private api: ApiRequestService,
    private dialogRef: MatDialogRef<DynamicFormsCategoriesViewComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Get the category id
    const category_id = Number(this.dialogData['category_id']);
    if ( category_id ) {
      this.api.makeRequest('get', `v2/dynamic-forms-categories/${category_id}`)
      .then((response: DynamicFormsCategoryModel) => {
        this.category = response;
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      this.utils.showToast('The provided category id is not valid.');
      this.dialogRef.close();
    }
  }

}
