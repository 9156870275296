import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatCardModule,
  MatExpansionModule,
  MatRadioModule,
  MatSlideToggleModule
} from '@angular/material';
import { DirectivesModule } from '../directives/directives.module';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { PipesModule } from '../pipes/pipes.module';
import { FilesModule } from '../files/files.module';
import { SharedModule } from '../shared/shared.module';
import { DynamicFormsComponent } from './dynamic-forms/dynamic-forms.component';
import { DynamicFormsEditComponent } from './dynamic-forms-edit/dynamic-forms-edit.component';
import { DynamicFormsRoutingModule } from './dynamic-forms-routing.module';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { DynamicFormsAssignedComponent } from './dynamic-forms-assigned/dynamic-forms-assigned.component';
import { DynamicFormsAssignedUsersComponent } from './dynamic-forms-assigned-users/dynamic-forms-assigned-users.component';
import { DynamicFormsAssignedUsersFormViewComponent } from './dynamic-forms-assigned-users-form-view/dynamic-forms-assigned-users-form-view.component';
import { DynamicFormsFilterComponent } from './dynamic-forms-filter/dynamic-forms-filter.component';
import { DynamicFormsCloneComponent } from './dynamic-forms-clone/dynamic-forms-clone.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DynamicFormsNotificationUsersComponent } from './dynamic-forms-notification-users/dynamic-forms-notification-users.component';
import {
  DynamicFormsAssignedUsersAuditLogsComponent
} from "./dynamic-forms-assigned-users-audit-logs/dynamic-forms-assigned-users-audit-logs.component";
import {ChartsModule} from "../charts/charts.module";
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  declarations: [
    DynamicFormsComponent,
    DynamicFormsAssignedComponent,
    DynamicFormsEditComponent,
    DynamicFormsAssignedUsersComponent,
    DynamicFormsAssignedUsersFormViewComponent,
    DynamicFormsFilterComponent,
    DynamicFormsCloneComponent,
    DynamicFormsNotificationUsersComponent,
    DynamicFormsAssignedUsersAuditLogsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    DynamicFormsRoutingModule,
    DragDropModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatBadgeModule,
    MatCardModule,
    MatExpansionModule,
    MatRadioModule,
    MatSlideToggleModule,
    PipesModule,
    FilesModule,
    SharedModule,
    MatDatepickerModule,
    TourMatMenuModule,
    EditorModule,
    ChartsModule,
    UtilsModule
  ],
  entryComponents: [
    SitesSelectorComponent,
    DynamicFormsEditComponent,
    DynamicFormsAssignedUsersComponent,
    DynamicFormsAssignedUsersFormViewComponent,
    DynamicFormsFilterComponent,
    DynamicFormsCloneComponent,
    DynamicFormsAssignedUsersAuditLogsComponent
  ]
})
export class DynamicFormsModule {}
