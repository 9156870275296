import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InductionService } from 'src/app/dashboard/induction.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-user-induction',
  templateUrl: './user-induction.component.html',
  styleUrls: ['./user-induction.component.scss']
})
export class UserInductionComponent implements OnInit {

  module_index: number;

  constructor(
    public inductionService: InductionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserInductionComponent>,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.module_index = this.data.module_index;
  }

  onSelectionChange(evt: any) {
    const stepperTop = document.getElementById('mat-dialog-content');
    if (stepperTop) {
      stepperTop.scrollTop = 0;
    }
  }

  onSaveAnswers() {
    this.inductionService.saveAnswers(() => {
      // mark as opened and saved
      this.inductionService.data.modules[this.module_index].was_saved = true;
      this.dialogRef.close();
    });
  }
}
