import { Component, Inject, OnInit } from '@angular/core';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/shared/utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuditsFilterComponent } from 'src/app/audits/audits-filter/audits-filter.component';
import {
  AuditsTemplatesSelectorComponent
} from "../../audits/audits-templates-selector/audits-templates-selector.component";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";

@Component({
  selector: 'app-sites-audits-filter',
  templateUrl: './sites-audits-filter.component.html',
  styleUrls: ['./sites-audits-filter.component.scss']
})
export class SitesAuditsFilterComponent implements OnInit {

  filters: SiteSiteAuditsFilter = {
    parent_site_id: null,
    site_ids: [],
    template_ids: [],
    date_range: []
  } as SiteSiteAuditsFilter;

  include_template_selector: boolean = true;

  faHardHat = faHardHat;

  constructor(
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AuditsFilterComponent>
  ) { }

  ngOnInit() {
    // Store inbound filter properties.
    this.filters.parent_site_id = this.data['parent_site_id'] || null;
    this.filters.site_ids = this.data['site_ids'] || [];
    this.filters.date_range = this.data['date_range'] || [];
    this.filters.template_ids = this.data['template_ids'] || [];

    // Check if the audit templates selector should be shown or not.
    if ( typeof this.data['include_template_selector'] != 'undefined' ) {
      this.include_template_selector = this.data['include_template_selector'];
    } else {
      this.include_template_selector = true;
    }
  }

  getOutputDateRange(event: any): void {
    this.filters.date_range = event;
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        parent_site_id: this.filters.parent_site_id,
        selected: this.filters.site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (site_ids: number[]): void => {
        if (typeof site_ids !== 'undefined') {
          this.filters.site_ids = site_ids;
        }
      }
    );
  }

  /**
   * Open the site audit template selector.
   */
  onSelectSiteAuditTemplate() {
    // Open the audits templates selector and allow the selection of multiple templates.
    this.utils.showComponentDialog(AuditsTemplatesSelectorComponent, {
      selected: this.filters.template_ids,
      multiple: true
    })
      .then((response) => {
        // If there are no response, ignore it.
        if ( !response ) {
          return;
        }
        // The response is expected to be an array.
        this.filters.template_ids = response;
      });
  }

  onClearAndClose(): void {
    // Clear the filter object properties.
    this.filters.site_ids = [];
    this.filters.date_range = [];
    this.filters.template_ids = [];

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }
}

export interface SiteSiteAuditsFilter {
  parent_site_id: number;
  site_ids: number[];
  date_range: Date[];
  template_ids: number[];
}
