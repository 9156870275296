import { Component, OnInit, Inject } from '@angular/core';
import { ItemPivotModel } from 'src/app/items/item-pivot.model';
import { ItemsService } from 'src/app/items/items.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-contractors-items-view',
  templateUrl: './contractors-items-view.component.html',
  styleUrls: ['./contractors-items-view.component.scss']
})
export class ContractorsItemsViewComponent implements OnInit {
  pivot: ItemPivotModel = new ItemPivotModel();
  public name: string;

  constructor(
    private itemService: ItemsService,
    @Inject(MAT_DIALOG_DATA) private id: number
  ) {}

  ngOnInit() {
    this.itemService
      .findPivot(this.id)
      .subscribe((response) => (this.pivot = response.data));
  }
}
