import { Component, OnInit, ViewChild, ElementRef, Renderer, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AuditItemDataSource } from '../audit-item.data.source';
import { AuditItemsService } from '../audit-items.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { tap } from 'rxjs/operators';
import { AuditItemModel } from '../audit-item.model';
import { AuditItemsTemplateEditComponent } from '../audit-items-template-edit/audit-items-template-edit.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';

declare var saveAs: any;

@Component({
  selector: 'app-audit-items-template',
  templateUrl: './audit-items-template.component.html',
  styleUrls: ['./audit-items-template.component.scss']
})
export class AuditItemsTemplateComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource = new AuditItemDataSource(this.app, this.oldApi);
  displayedColumns = [
    'select',
    'id',
    'name',
    'section',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];
  sections: string[];
  selectedSection: string;
  query: string;

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  constructor(
    private auditItemService: AuditItemsService,
    private utils: UtilsService,
    public app: AppService,
    private oldApi: ApiService,
    private dialog: MatDialog,
    public renderer: Renderer,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    this.dataSource.template = true;
    this.dataSource.getData(false);
    this.auditItemService
      .getSections()
      .subscribe((response) => (this.sections = response.data));
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  add() {
    this.edit(new AuditItemModel());
  }

  edit(item: AuditItemModel) {
    this.dialog
      .open(AuditItemsTemplateEditComponent, {
        width: '500px',
        data: {...item}
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  filterSection() {
    this.dataSource.section = this.selectedSection;
    this.dataSource.getData(true);
    // this.dataSource.getDataForSection(false, this.selectedSection);
  }

  onRemoveSelected() {
    const selected = this.dataSource.selection.selected;
    this.utils.showModal(
      'Remove Inspection/Audit Questions',
      `Are you sure you want to remove ${selected.length} Inspection/Audit questions?`,
      () => this.remove(selected)
    );
  }

  private remove(ids: number[]) {
    this.auditItemService.remove(ids).subscribe(() => {
      this.utils.showToast('Inspection/Audit questions have been removed.');
      this.dataSource.selection.clear();
      this.dataSource.getData(false);
    });
  }

  onRemove(item: AuditItemModel) {
    this.utils.showModal(
      'Remove Inspection/Audit Question',
      `Are you sure you want to remove the "${item.name}" Inspection/Audit question?`,
      () => this.remove([item.id])
    );
  }

  onExportSelected(type: string = 'csv') {
    // Send a request to download audit items.
    this.api.makeDownloadRequest(`v1/audit-items/export/${type}`, {}, {
      template: 1,
      order_by: this.dataSource.sort_by,
      order: this.dataSource.sort_order
    })
    .then((response) => {
      const date = new Date();
      saveAs(response, `Inspection/Audit Questions - ${this.app.account.name} - ${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${type}`);
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  onTriggerFileSelector(evt: Event) {
    const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInput.nativeElement,
      'dispatchEvent',
      [clickEvt]
    );
  }

  onImport(evt: any) {
    if (evt.target.files.length > 0) {

      const files: any = [];
      for ( let i = 0; i < evt.target.files.length; i++ ) {
        files.push(evt.target.files[i]);
      }

      this.utils.showModal('Import Inspection/Audit Questions', 'Are you sure you want to import the Inspection/Audit questions from the selected spreadsheet? It is recommended to use the same format as the Inspection/Audit questions exports. You will need to remove any duplicated audit items from the list after importing.', () => {
        this.oldApi.laravelApiUploadFiles('audit-items/import', files, (response) => {
          this.utils.showToast('The Inspection/Audit questions import completed.');
          this.dataSource.getData(true);
        });
      });

      // clear the target value
      evt.target.value = '';
    }
  }
}
