import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { IncidentCauseService } from '../incident-cause.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/utils.service';
import { IncidentsCausesEditComponent } from './incidents-causes-edit/incidents-causes-edit.component';
import { IncidentCauseModel } from '../incident-cause.model';

@Component({
  selector: 'app-incidents-causes',
  templateUrl: './incidents-causes.component.html',
  styleUrls: ['./incidents-causes.component.scss']
})
export class IncidentsCausesComponent implements OnInit, AfterViewInit {
  @Input() private incidentId: number;
  @Input() public incidentStatus: string;
  @Output() public incidentChange = new EventEmitter();

  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'description',
    'type',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new IncidentCausesDataSource(this.app, this.api);

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    public api: ApiService,
    public utils: UtilsService,
    private incidentCauseService: IncidentCauseService
  ) {}

  ngOnInit() {
    this.dataSource.incident_id = this.incidentId;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Resets the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by its corresponding date column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit({ incident_id: this.incidentId } as IncidentCauseModel);
  }

  onEdit(incidentCause: IncidentCauseModel) {

    this.utils.showComponentDialog(
      IncidentsCausesEditComponent,
      incidentCause,
      {width: '600px'},
      (success: boolean) => {
        if (!success) { return; }
        this.dataSource.getData(true);
        this.incidentChange.emit();
      }
    );
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Incident',
      'Are you sure you want to remove this incident?',
      () => {
        this.incidentCauseService.remove([id]).subscribe(() => {
          this.dataSource.selection.deselect(id);
          this.dataSource.getData();
          this.utils.showToast('The cause was removed');
          this.incidentChange.emit();
        });
      }
    );
  }

  onDeleteSelected() {

    this.utils.showModal(
      'Remove Selected Incidents',
      'Are you sure you want to remove the selected incidents?',
      () => {
        this.incidentCauseService
          .remove(this.dataSource.selection.selected)
          .subscribe(() => {
            this.dataSource.selection.clear();
            this.dataSource.getData();
            this.utils.showToast('The selected incidents was removed.');
            this.incidentChange.emit();
          });
      }
    );
  }
}

export class IncidentCausesDataSource extends CustomDataSource {
  sort_by = 'name';
  sort_order = 'asc';
  incident_id: number;

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'incident-causes',
      resetOffset,
      {},
      {
        incident_id: this.incident_id
      }
    );
  }
}
