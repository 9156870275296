import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { CourseModel } from '../../models/course.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-courses-view',
  templateUrl: './courses-view.component.html',
  styleUrls: ['./courses-view.component.scss']
})
export class CoursesViewComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public induction: CourseModel,
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  onRemove() {
    this.utils.showModal(
      'Remove Induction',
      'Are you sure you want to remove this induction?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.induction.id}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The induction was removed.');
            this.router.navigate(['courses']);
          }
        );
      }
    );
  }
}
