import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PpeEditComponent } from './ppe-edit/ppe-edit.component';
import { PpeViewComponent } from './ppe-view/ppe-view.component';
import {PpeComponent} from "./ppe.component";
import {MatComponentsModule} from "../../mat-components/mat-components.module";
import {FormsModule} from "@angular/forms";
import {MATERIAL_MODULES} from "../../app.const";
import {PipesModule} from "../../pipes/pipes.module";
import {FilesModule} from "../../files/files.module";
import {MatIconModule, MatMenuModule} from "@angular/material";
import {RouterModule} from "@angular/router";
import {DirectivesModule} from "../../directives/directives.module";


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatComponentsModule,
    FormsModule,
    ...MATERIAL_MODULES,
    PipesModule,
    FilesModule,
    RouterModule,
    DirectivesModule
  ],
  declarations: [
    PpeComponent,
    PpeViewComponent,
    PpeEditComponent
  ],
  entryComponents: [
    PpeViewComponent,
    PpeEditComponent
  ]
})
export class PpeModule { }
