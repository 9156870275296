import {
  Component, EventEmitter, HostListener, Input,
  OnInit, Output
} from '@angular/core';
import {StorageService} from "../../../shared/storage.service";
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {UtilsService} from "../../../shared/utils.service";
import {document} from "ngx-bootstrap/utils";
import {AppService} from "../../../app.service";
import _ from 'lodash';
import {SiteAuditsFilter} from "../../../audits/audits-filter/audits-filter.component";
import {SitesAuditsFilterComponent} from "../../../sites/sites-audits-filter/sites-audits-filter.component";
import {debounceTime, takeUntil} from "rxjs/operators";
import {fromEvent, Subject} from "rxjs";

/**
 * Represents the configuration for site audits charts.
 * @interface
 */
interface SiteAuditsChartsConfig {
  rows: {
    cols: number,
    charts: { id: string, chart: string }[]
  }[];
  filters: SiteAuditsFilter;
  isPanelExpanded: boolean;
}

@Component({
  selector: 'app-site-audits-charts-container',
  templateUrl: './site-audits-charts-container.component.html',
  styleUrls: ['./site-audits-charts-container.component.css']
})
export class SiteAuditsChartsContainerComponent implements OnInit {

  // The base storage key to use for this charts container instance.
  @Input('baseStorageKey') baseStorageKey: string;

  // Config storage key. This must be unique when multiple containers are stored on one page.
  configStorageKey: string;

  // Available charts for the user to select from.
  availableCharts: any[] = [
    {
      chartName: 'Table: Historical',
      chart: 'SiteAuditsHistoricalComboChartComponent'
    },
    {
      chartName: 'Table: Stats',
      chart: 'SiteAuditsTemplatesStatsTableComponent'
    }
  ];

  // The config for this container.
  config: SiteAuditsChartsConfig = {
    rows: [],
    filters: {
      site_ids: [],
      template_ids: [],
      date_range: []
    } as SiteAuditsFilter,
    isPanelExpanded: false
  } as SiteAuditsChartsConfig;

  // Separate rows to store chart references in. This does not have to persist.
  rows: {
    chartRefs: any[]
  }[] = [];

  // The config storage object to temporarily store config pulled from persisted storage.
  configStorageObject: any = {};

  // Disable the panel toggle capabilities.
  @Input('disableExpansionPanel') disableExpansionPanel: boolean;

  // Disable the panel toggle capabilities.
  @Input('parent_filters') parent_filters: any;

  // Used to pass a reference to this component back to the parent component.
  @Output() referenceEvent: EventEmitter<SiteAuditsChartsContainerComponent> = new EventEmitter<SiteAuditsChartsContainerComponent>();

  // Used to trigger an event in the parent component.
  @Output() removeContainer: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Input to show or hide the remove container button.
  @Input('show_remove_container_button') show_remove_container_button: boolean;

  // Input to switch near realtime data polling on or off.
  @Input('enable_realtime_data') enable_realtime_data: boolean;
  // Input to change how long between intervals in seconds.
  @Input('realtime_data_interval_seconds') realtime_data_interval_seconds: number;

  // Input to switch near realtime data polling on or off. This can only be single or multiple.
  @Input('query_type') query_type: string;

  // Set the title for this container.
  containerTitle: string = 'Inspections & Audits (Charts)';

  // Used to resize charts when the browser is resized.
  resizer: Subject<void> = new Subject<void>();

  constructor(
    private storage: StorageService,
    private utils: UtilsService,
    private app: AppService
  ) { }

  async ngOnInit(): Promise<any> {
    // Set the storage keys.
    if ( !this.baseStorageKey ) {
      this.baseStorageKey = 'site-audits';
    }

    // Set the config storage key.
    this.configStorageKey = this.baseStorageKey + '-' + this.app.account.id + '-config';

    // Validate the default config.
    this.validateDefaultConfig();

    // Enable the panel toggle by default.
    if ( typeof this.disableExpansionPanel == 'undefined' ) {
      this.disableExpansionPanel = false;
    }

    // Get the stored object from persistent storage.
    this.configStorageObject = await this.storage.dbGet('charts', this.configStorageKey);

    // Override the rows in the config from storage.
    this.config.rows = this.configStorageObject ? this.configStorageObject.rows : [{
      cols: 1,
      charts: [
        {
          id: this.generateUniqueId(),
          chart: 'SiteAuditsHistoricalComboChartComponent'
        }
      ]
    }];

    // Override the panel expansion state from storage.
    this.config.isPanelExpanded = this.configStorageObject ? this.configStorageObject.isPanelExpanded : false;
    // Override the stored expansion panel status if the expansion panel is disabled.
    if ( this.disableExpansionPanel ) {
      this.config.isPanelExpanded = true;
    }

    // Check if any filters were passed through from the parent component.
    if ( !this.parent_filters ) {
      this.parent_filters = {};
    }

    // Override the filters from storage.
    this.config.filters = this.configStorageObject ? this.configStorageObject.filters : {
      only_archived: 'false',
      category_ids: [],
      site_ids: [],
      contractor_ids: [],
      user_ids: [],
      industry_ids: [],
      trade_ids: [],
      date_range: []
    };

    // Apply parent filter overrides.
    this.config.filters = {
      ...this.config.filters,
      ...this.getParentFiltersForOverrides(this.parent_filters)
    };

    // Remap the dates as new Date objects. Storing it in storage stores it as string.
    this.config.filters.date_range = this.config.filters.date_range.map((date: Date|string) => new Date(date));

    // Send a reference to the parent component.
    this.referenceEvent.emit(this);
  }

  /**
   * Handles the window resize event.
   *
   * @param {Event} event - The resize event object.
   *
   * @return {void}
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    // Trigger the resizer when the browser resize event fires. Delay it by 300 ms.
    fromEvent(window, 'resize')
      .pipe(debounceTime(300), takeUntil(this.resizer))
      .subscribe((): void => {
        // Loop through all rows and get chart refs.
        this.rows.forEach((row): void => {
          // Get the row's chart refs.
          row.chartRefs.forEach((chartRef: any): void => {
            // Check if the chart have a draw charts function.
            if ( typeof chartRef.drawChart == 'function' ) {
              // Redraw the chart.
              chartRef.drawChart();
            }
          });
        });
      });
  }

  /**
   * Lifecycle hook called when the component is about to be destroyed.
   * It clears the near realtime data interval if it was set.
   */
  ngOnDestroy(): void {
    // Stop the resizer subject.
    this.resizer.next();
    this.resizer.complete();
  }

  /**
   * Validates the configuration of a chart.
   * Ensures that certain properties are defined and sets default values if not provided.
   *
   * @return {void}
   */
  validateDefaultConfig(): void {
    // Ensure realtime data is defined and set to false if not provided.
    if ( typeof this.enable_realtime_data == 'undefined' ) {
      this.enable_realtime_data = false;
    }
    // Ensure realtime data interval seconds is defined and set to 60 if not provided.
    if ( typeof this.realtime_data_interval_seconds == 'undefined' ) {
      this.realtime_data_interval_seconds = 60;
    }
    // Ensure the query type is defined and set to 'single' if not provided. It can be 'multiple'.
    if ( typeof this.query_type == 'undefined' ) {
      this.query_type = 'single';
    }
  }

  /**
   * Returns the parent filters for overrides.
   *
   * @param {any} parentFilters - The parent filters object.
   *
   * @return {any | {}} - The parent filters object if the site_ids or date_range properties are set and not empty, otherwise an empty object.
   */
  getParentFiltersForOverrides(parentFilters: any): any | {} {
    // Check if the site_ids or date_range properties are set and return the filters.
    if ( 'site_ids' in parentFilters && parentFilters.site_ids.length > 0 || 'date_range' in parentFilters && parentFilters.date_range.length ) {
      return parentFilters;
    }
    return {};
  };

  /**
   * Filter the data based on the provided filters and update the chart visuals.
   *
   * @param {any} event - The event object.
   * @returns {void}
   */
  onFilter(event: any): void {
    // Stop event bubbling.
    this.onStopEventPropagation(event);
    // Show the filters component.
    this.utils.showComponentDialog(SitesAuditsFilterComponent, {
      include_template_selector: false,
      site_ids: this.config.filters.site_ids,
      template_ids: this.config.filters.template_ids,
      date_range: this.config.filters.date_range
    }, {
      width: '350px'
    }, async (filters: any): Promise<any> => {
      // Check if we received a valid filters object.
      if ( typeof filters !== 'undefined' && filters != false ) {
        // Update or reset the filters.
        this.config.filters.site_ids = filters.site_ids;
        this.config.filters.template_ids = filters.template_ids;
        this.config.filters.date_range = filters.date_range || [];

        // Apply parent config overrides.
        this.config.filters = {
          ...this.config.filters,
          ...this.getParentFiltersForOverrides(this.parent_filters)
        };

        // Update the config in persistent storage.
        await this.storeConfig();
        // Get the data from the API.
        this.refreshChartVisuals();
      }
    });
  }

  /**
   * Checks if all filters, excluding specified properties, are empty.
   *
   * @param {string[]} propertiesToExclude - The list of property names to exclude from checking.
   * @returns {boolean} - True if all filters are empty, false otherwise.
   */
  isFiltersEmptyExcludingTags(propertiesToExclude: string[] = ['only_archived']): boolean {
    // Get a list of property names to check in the filters.
    const filteredProperties = _.difference(Object.keys(this.config.filters), propertiesToExclude);
    // Check if all filters are empty.
    return _.every(filteredProperties, (key: string) => _.isEmpty(this.config.filters[key]));
  }

  /**
   * Checks if all filters, excluding specified properties, are empty.
   *
   * Note: This was used to show/hide the container's filter button. Might be useful in the future if needed.
   *
   * @param {string[]} propertiesToExclude - The list of property names to exclude from checking.
   * @returns {boolean} - True if all filters are empty, false otherwise.
   */
  isParentFiltersEmptyExcluding(propertiesToExclude: string[] = []): boolean {
    // Get a list of property names to check in the filters.
    const filteredProperties = _.difference(Object.keys(this.parent_filters), propertiesToExclude);
    // Check if all filters are empty.
    return _.every(filteredProperties, (key: string) => _.isEmpty(this.parent_filters[key]));
  }

  /**
   * Updates the chart reference in the rows object based on the given event, row index, and chart index.
   *
   * @param {any} chartRef - The event object containing the chart reference.
   * @param {number} rowIndex - The index of the row in the rows array.
   * @param {number} chartIndex - The index of the chart in the chartRefs array of the specified row.
   */
  onStoreChartRef(chartRef: any, rowIndex: number, chartIndex: number): void {
    // Check if the row was initialised.
    if ( typeof this.rows[rowIndex] == 'undefined' ){
      // Create a new row for chart refs if it was not initialised.
      this.rows[rowIndex] = {
        chartRefs: []
      };
    }
    // Add the chart reference.
    this.rows[rowIndex].chartRefs[chartIndex] = chartRef;
  }

  /**
   * Retrieves the bootstrap column configuration based on the number of columns.
   *
   * @param {number} cols - The number of columns to generate the configuration for.
   * @returns {string} - The bootstrap column configuration.
   */
  getColsConfig(cols: number): string {
    // Return bootstrap column config.
    return 'col-sm-12 col-md-6 col-lg-' + 12/cols;
  }

  /**
   * Updates the data and visuals of chart references for a given row index and stores user preferences.
   *
   * @param {number} rowIndex - The index of the row. Is not used due to two-way binding.
   * @return {void}
   */
  async onChangeColsConfig(rowIndex: number): Promise<any> {
    // Refresh the charts visuals.
    this.refreshChartVisuals();
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Update the filters from the parent component.
   *
   * @param {any} filters - The filters to update.
   * @return {void}
   */
  updateFiltersFromParentComponent(filters: any): void {
    // Update the filters.
    this.config.filters = filters;
  }

  /**
   * Refreshes the visuals of all charts in the rows.
   *
   * @returns {void}
   */
  refreshChartVisuals(): void {
    // Apply parent config overrides.
    this.config.filters = {
      ...this.config.filters,
      ...this.getParentFiltersForOverrides(this.parent_filters)
    };
    // Loop through each row to extract chart refs.
    this.rows.forEach((row) => {
      // Loop through each chart ref to refresh data.
      row.chartRefs.forEach((chartRef) => {
        // Update the child component's filters.
        if ( typeof chartRef.updateFiltersFromParentComponent == 'function' ) {
          chartRef.updateFiltersFromParentComponent(this.config.filters);
        }
        // Refresh the chart data.
        if ( typeof chartRef.getData == 'function' ) {
          chartRef.getData();
        }
      });
    });
  }

  /**
   * Performs the necessary actions when a container is being removed.
   *
   * @param {any} event - The event object that triggered the method.
   *
   * @return {void}
   */
  onRemoveContainer(event: any): void {
    // Stop event propagation.
    this.onStopEventPropagation(event);
    // Get confirmation from the user.
    this.utils.showQuickActions(event, `Are you sure you want to remove this container?`, [
      {
        text: 'Yes',
        handler: async (): Promise<any> => {
          // Emit a value of true to the parent component.
          this.removeContainer.emit(true);
        }
      },
      {
        text: 'No',
        handler: () => {
          // Emit a value of false to the parent component.
          this.removeContainer.emit(false);
        }
      }
    ]);
  }

  /**
   * Adds a new row to the grid with the provided number of columns.
   *
   * @param {number} cols - The number of columns in the new row.
   *
   * @return {void}
   */
  async onAddRow(cols: number): Promise<any> {
    // Add a new row with the provided number of cols.
    this.config.rows.unshift({
      cols: cols,
      charts: []
    });
    // Add a row for chart refs.
    this.rows.unshift({
      chartRefs: []
    });
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Remove a row from the table.
   *
   * @param {any} event - The event that triggered the method.
   * @param {number} rowIndex - The index of the row to remove.
   *
   * @return {void}
   */
  async onRemoveRow(event: any, rowIndex: number): Promise<any> {
    // Get the charts count for the row.
    const chartCount = this.config.rows[rowIndex].charts.length;
    // Get confirmation.
    if ( chartCount > 0 ) {
      this.utils.showQuickActions(event, `Are you sure you want to remove this row? There are ${chartCount} chart(s) in it.`, [
        {
          text: 'Yes',
          handler: async (): Promise<any> => {
            // Remove the row.
            this.config.rows.splice(rowIndex, 1);
            // Do some pruning.
            await this.pruneStorage(rowIndex);
            // Remove the corresponding chart refs.
            this.rows.splice(rowIndex, 1);
            // Store the user preferences.
            await this.storeConfig();
          }
        },
        {
          text: 'No',
          handler: () => {
            // Do nothing.
          }
        }
      ]);
      return;
    }
    // Remove the row.
    this.config.rows.splice(rowIndex, 1);
    // Do some pruning.
    await this.pruneStorage(rowIndex);
    // Remove the corresponding chart refs.
    this.rows.splice(rowIndex, 1);
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Adds a chart to a specific row in the current layout.
   *
   * @param {any} chart - The chart object to be added.
   * @param {number} rowIndex - The index of the row where the chart will be added.
   * @return {void}
   */
  async onAddChart(chart: any, rowIndex: number): Promise<any> {
    // Check if the maximum number of charts were reached.
    if ( this.config.rows[rowIndex].charts.length >= this.config.rows[rowIndex].cols ) {
      this.utils.showToast('You have reached the maximum number of charts for this row. Increase the columns of the row to add more charts.');
      return;
    }
    // Add the chart.
    this.config.rows[rowIndex].charts.push({
      id: this.generateUniqueId(),
      chart: chart
    });
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Removes a chart from a specific row.
   *
   * @param {Object} event - The event object.
   * @param {number} rowIndex - The index of the row to remove the chart from.
   * @param {number} chartIndex - The index of the chart to remove.
   * @return {void}
   */
  onRemoveChart(event: any, rowIndex: number, chartIndex: number): void {
    // Get confirmation from the user.
    this.utils.showQuickActions(event, `Are you sure you want to remove this chart?`, [
      {
        text: 'Yes',
        handler: async (): Promise<any> => {
          // Remove the chart from the row.
          this.config.rows[rowIndex].charts.splice(chartIndex, 1);
          // Do some pruning.
          await this.pruneStorage(rowIndex, chartIndex);
          // Remove the chartRef too.
          this.rows[rowIndex].chartRefs.splice(chartIndex, 1);
          // Store the user preferences.
          await this.storeConfig();
        }
      },
      {
        text: 'No',
        handler: () => {
          // Do nothing.
        }
      }
    ]);
  }

  /**
   * Handles the drag and drop functionality for charts.
   *
   * @param {number} rowIndex - The index of the row in which the chart is being dragged and dropped.
   * @param {Event} event - The drag and drop event.
   * @returns {void}
   */
  async onDragAndDrop(event: any, rowIndex: number): Promise<any> {
    // Move the chart.
    moveItemInArray(this.config.rows[rowIndex].charts, event.previousIndex, event.currentIndex);
    // Move the chart's reference.
    moveItemInArray(this.rows[rowIndex].chartRefs, event.previousIndex, event.currentIndex);
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Stops event propagation.
   *
   * @param {any} event - The event object.
   *
   * @return {void} - This method does not return any value.
   */
  onStopEventPropagation(event: any): void {
    // Stop bubbling.
    event.stopPropagation();
  }

  /**
   * Updates the expansion state of the charts panel and stores it.
   *
   * @param {boolean} isPanelExpanded - The new expansion state of the charts panel.
   * @return {Promise<any>} A Promise that resolves when the charts panel expansion state is stored.
   */
  async onStoreChartsPanelExpandedState(isPanelExpanded: boolean): Promise<any> {
    // Store the new charts panel expansion state.
    this.config.isPanelExpanded = isPanelExpanded;
    // Store the config.
    await this.storeConfig();
  }

  /**
   * Stores the user preferences.
   *
   * @returns {Promise<any>} - A promise that resolves when the user preferences are stored.
   */
  async storeConfig(): Promise<any> {
    // Store the user preferences.
    this.configStorageObject = await this.storage.dbUpdateOrCreate('charts', { ...this.config, id: this.configStorageKey});
  }

  /**
   * Cleans up any data from storage that needs to be removed.
   *
   * @returns {Promise<void>} A Promise that resolves when the cleanup is completed.
   */
  async pruneStorage(rowIndex?: number, chartIndex?: number): Promise<any> {
    // Prune the storage of an individual chart.
    if ( typeof rowIndex != 'undefined' && typeof chartIndex != 'undefined' ) {
      if ( typeof this.rows[rowIndex].chartRefs[chartIndex].pruneStorage == 'function' ) {
        await this.rows[rowIndex].chartRefs[chartIndex].pruneStorage();
      }
      return;
    }
    // Prune the storage of all containers in a row.
    if ( typeof rowIndex != 'undefined' ) {
      this.rows[rowIndex].chartRefs.forEach((chartRef) => {
        if ( typeof chartRef.pruneStorage == 'function' ) {
          chartRef.pruneStorage();
        }
      });
      return;
    }
    // DANGER: Prune the storage of all rows and charts.
    this.rows.forEach((row) => {
      row.chartRefs.forEach((chartRef) => {
        if ( typeof chartRef.pruneStorage == 'function' ) {
          chartRef.pruneStorage();
        }
      });
    });
    // Clean any data from storage that need to be removed.
    await this.storage.dbDelete('charts', this.configStorageKey);
  }

  /**
   * Generates a unique ID by generating a random string of alphanumeric characters.
   *
   * @return {string} A unique ID.
   */
  generateUniqueId(): string {
    return Math.random().toString(36).substring(2,9);
  }

  /**
   * Retrieves the name of a chart based on the provided row index and chart index.
   *
   * @param {number} rowIndex - The index of the row containing the chart.
   * @param {number} chartIndex - The index of the chart within the row.
   * @returns {string} The name of the chart. Returns an empty string if the chart is inaccessible.
   */
  getCardTitle(rowIndex: number, chartIndex: number): string {
    // Check if the chart is accessible.
    if ( typeof this.rows[rowIndex] != 'undefined' && typeof this.rows[rowIndex].chartRefs[chartIndex] != 'undefined' ) {
      // Get the card title.
      return this.rows[rowIndex].chartRefs[chartIndex].cardTitle;
    }
    return '';
  }

  /**
   * Refreshes the data of a chart.
   *
   * @param {number} rowIndex - The index of the row which contains the chart.
   * @param {number} chartIndex - The index of the chart within the row.
   * @return {void} - The chart's updated data.
   */
  onRefreshChart(rowIndex: number, chartIndex: number): void {
    // Check if the chart is accessible.
    if ( typeof this.rows[rowIndex] != 'undefined' && typeof this.rows[rowIndex].chartRefs[chartIndex] != 'undefined' ) {
      // Refresh the chart data.
      return this.rows[rowIndex].chartRefs[chartIndex].getData();
    }
  }

  /**
   * Downloads a chart image.
   *
   * @param {number} rowIndex - The index of the row containing the chart.
   * @param {number} chartIndex - The index of the chart within the row.
   *
   * @return {void} - This method does not return a value.
   */
  onDownloadChart(rowIndex: number, chartIndex: number): void {
    // Get the chart ref.
    const chartRef = this.rows[rowIndex].chartRefs[chartIndex];
    // Check if the chart can be downloaded.
    if ( typeof chartRef.canBeDownloaded != 'undefined' && chartRef.canBeDownloaded ) {
      // Redraw the chart to include the title.
      chartRef.drawChart(true);
      // Get the Image URI of the chart.
      const chartImageData = chartRef.chart.getImageURI();
      // Create and trigger a download request for the image.
      const a = document.createElement('a');
      a.href = chartImageData;
      a.download = chartRef.chartDownloadFilename + '.png';
      a.click();
      // Redraw the chart to exclude the title.
      chartRef.drawChart();
    }
  }

  /**
   * Downloads all charts in a specific row.
   *
   * @param {number} rowIndex - The index of the row containing the charts to be downloaded.
   * @return {void}
   */
  onDownloadRowOfCharts(rowIndex: number): void {
    // Get the row's chart refs.
    const chartRefs: any[] = this.rows[rowIndex].chartRefs;
    chartRefs.forEach((chartRef: any, chartIndex: number): void => {
      // Download the chart.
      this.onDownloadChart(rowIndex, chartIndex);
    });
  }

  /**
   * Handles the event to download all charts.
   *
   * @param event - The event object.
   * @returns {void}
   */
  onDownloadAllCharts(event: any): void {
    // Stop bubbling.
    event.stopPropagation();
    // Loop through all rows and download the charts.
    this.rows.forEach((row, rowIndex: number): void => {
      // Download the row of charts.
      this.onDownloadRowOfCharts(rowIndex);
    });
  }

  /**
   * Checks if a row has downloadable charts.
   *
   * @param {number} rowIndex - The index of the row to check.
   * @return {boolean} Returns true if the row has downloadable charts, false otherwise.
   */
  doesRowHaveDownloadableCharts(rowIndex: number): boolean {
    // Check if there are any rows to process.
    if ( this.rows.length > 0 && typeof this.rows[rowIndex] != 'undefined' && typeof this.rows[rowIndex].chartRefs != 'undefined' ) {
      // Check if this row have some downloadable charts.
      return this.rows[rowIndex].chartRefs.some(chartRef => typeof chartRef.canBeDownloaded != 'undefined' && chartRef.canBeDownloaded);
    }
    return false;
  }

  /**
   * Checks if all rows have downloadable charts.
   *
   * @returns {boolean} - true if all rows have downloadable charts, false otherwise.
   */
  doesRowsHaveDownloadableCharts(): boolean {
    // Check if there are some rows that have downloadable charts.
    return this.rows.some((row, rowIndex: number) => this.doesRowHaveDownloadableCharts(rowIndex));
  }
}
