<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>View Workflow Step Hazard/Risk</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>
  <ng-container *ngIf="ta_revised_workflow_hazard.name">
    <h6>Hazard/Risk</h6>
    <p id="name">{{ ta_revised_workflow_hazard.name }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.description">
    <h6>Description</h6>
    <p id="description">{{ ta_revised_workflow_hazard.description }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.status">
    <h6>Status</h6>
    <p id="status">{{ ta_revised_workflow_hazard.status }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.ira_likelihood && ta_revised_workflow_hazard.ira_severity">
    <h6>Initial Risk Assessment</h6>
    <p id="ira">
      <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(ta_revised_workflow_hazard.ira_likelihood, ta_revised_workflow_hazard.ira_severity))">
        {{ utils.getRiskAssessmentText(ta_revised_workflow_hazard.ira_likelihood, ta_revised_workflow_hazard.ira_severity) }}
      </span>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.rra_likelihood && ta_revised_workflow_hazard.rra_severity">
    <h6>Residual Risk Assessment</h6>
    <p id="rra">
      <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(ta_revised_workflow_hazard.rra_likelihood, ta_revised_workflow_hazard.rra_severity))">
        {{ utils.getRiskAssessmentText(ta_revised_workflow_hazard.rra_likelihood, ta_revised_workflow_hazard.rra_severity) }}
      </span>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.controls.length">
    <h6>Hazard/Risk Controls</h6>

    <p id="controls" *ngFor="let control of ta_revised_workflow_hazard.controls; let i = index">
      <b>{{ i + 1 }} - {{ control.control }}</b> <br />
      {{ loc_list[control.loc - 1] }}
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.reviewer">
    <h6>Reviewed By</h6>
    <p id="reviewed-by">
      <a (click)="onUserPublicView(ta_revised_workflow_hazard.reviewer.hash)"
        matTooltip="View User Public Profile">
        {{ ta_revised_workflow_hazard.reviewer.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.reviewer_date">
    <h6>Reviewed Date</h6>
    <p id="reviewed-date">{{ ta_revised_workflow_hazard.reviewer_date * 1000 | timezoneConvertMedium }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.reference">
    <h6>Reference</h6>
    <p id="reference">
      <b>ID #:</b> {{ ta_revised_workflow_hazard.reference_id }} <br />
      <b>Hazard/Risk Name:</b> {{ ta_revised_workflow_hazard.reference.name }} <br />
      <b>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}:</b> {{ ta_revised_workflow_hazard.reference.site.name }}
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.created_by_user">
    <h6>Created By</h6>
    <p>
      <a (click)="onUserPublicView(ta_revised_workflow_hazard.created_by_user.hash)"
        matTooltip="View User Public Profile">
        {{ ta_revised_workflow_hazard.created_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.date_created">
    <h6>Created At</h6>
    <p>{{ ta_revised_workflow_hazard.date_created * 1000 | timezoneConvertMedium }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.updated_by_user">
    <h6>Last Updated By</h6>
    <p>
      <a (click)="onUserPublicView(ta_revised_workflow_hazard.updated_by_user.hash)"
        matTooltip="View User Public Profile">
        {{ ta_revised_workflow_hazard.updated_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.date_modified">
    <h6>Last Updated At</h6>
    <p>{{ ta_revised_workflow_hazard.date_modified * 1000 | timezoneConvertMedium }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow_hazard.files?.length">
    <h6>Files</h6>
    <app-files-list
      id="files"
      [files]="ta_revised_workflow_hazard.files"
      [view]="true">
    </app-files-list>
  </ng-container>
</mat-dialog-content>
