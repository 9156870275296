import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiRequestService} from 'src/app/shared/api-request.service';
import {MatTableDataSource} from "@angular/material/table";
import {UtilsService} from "../../../shared/utils.service";
import {MatPaginator} from "@angular/material/paginator";
import {tap} from "rxjs/operators";
import {StorageService} from "../../../shared/storage.service";
import {SiteAuditsFilter} from "../../../audits/audits-filter/audits-filter.component";
import {ChartsUtilsService} from "../../charts-utils.service";

@Component({
  selector: 'app-inductions-stats-table',
  templateUrl: './inductions-stats-table.component.html',
  styleUrls: ['./inductions-stats-table.component.css']
})
export class InductionsStatsTableComponent implements OnInit, AfterViewInit {

  // Config storage key. This must be unique when multiple containers are stored on one page.
  @Input('configStorageKey') configStorageKey: string;

  // The default limit storage key.
  defaultLimitStorageKey: string;

  // The filters to use for API requests.
  @Input('filters') filters: SiteAuditsFilter;

  // Input to switch near realtime data polling on or off. This can only be single or multiple.
  @Input('query_type') query_type: string;

  dataSource = new MatTableDataSource<InductionStatistic>();
  displayedColumns: string[] = ['name', 'total', 'stat_completed', 'stat_not_completed', 'percent_completed'];

  // Used to pass a reference to this component back to the parent component.
  @Output() referenceEvent: EventEmitter<InductionsStatsTableComponent> = new EventEmitter<InductionsStatsTableComponent>();

  // Determine if the chart can be downloaded or not.
  @Input('canBeDownloaded') canBeDownloaded: boolean;

  // The paginator instance.
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;

  // The total number of records in the database.
  total: number = 0;

  // How many records to display.
  limit: number = 15;

  // The offset to get and display record from.
  offset: number = 0

  // The page size options.
  pageSizeOptions: number[] = [1, 5, 15, 30, 60, 90, 120];

  // Store the card title for the parent component.
  cardTitle: string = 'Inductions Stats';

  constructor(
    private api: ApiRequestService,
    private utils: UtilsService,
    private storage: StorageService,
    public chartsUtils: ChartsUtilsService
  ) { }

  async ngOnInit() {
    // Define the default limit storage key.
    this.defaultLimitStorageKey = this.configStorageKey + '-default-limit';
    // Validate the default config.
    await this.validateDefaultConfig();
    // Get the data from the API.
    this.getData();
    // Send a reference to the parent component.
    this.referenceEvent.emit(this);
  }

  ngAfterViewInit(): void {
    // Define the paginator tap events.
    const paginatorTap = tap(async(paginator) => {
      // Get the page index and size.
      this.paginator.pageIndex = paginator['pageIndex'];
      this.paginator.pageSize = paginator['pageSize'];
      // Set the limit and offset.
      this.limit = paginator['pageSize'];
      this.offset = paginator['pageIndex'];
      // Update the default limit in storage.
      await this.storage.dbUpdateOrCreate('charts', { limit: this.limit , id: this.defaultLimitStorageKey});
      // Make a request to the API.
      this.getData();
    });
    // Subscribe to the paginator tap events.
    this.paginator.page.pipe(paginatorTap).subscribe();
  }

  /**
   * Validates the configuration of a chart.
   * Ensures that certain properties are defined and sets default values if not provided.
   *
   * @return {void}
   */
  async validateDefaultConfig(): Promise<void> {
    // Ensure the query type is defined and set to 'single' if not provided. It can be 'multiple'.
    if ( typeof this.query_type == 'undefined' ) {
      this.query_type = 'single';
    }
    // Ensure "can be downloaded" is defined and set to true if not provided.
    if ( typeof this.canBeDownloaded == 'undefined' ) {
      this.canBeDownloaded = false;
    }
    // Get the default limit from storage.
    const defaultLimitStorageObject = await this.storage.dbGet('charts', this.defaultLimitStorageKey);
    // Get the default limit from storage. Set as 30 if it was not found.
    this.limit = defaultLimitStorageObject ? defaultLimitStorageObject.limit : 15;
  }

  /**
   * Updates the filters from the parent component.
   *
   * @param {any} filters - The new filters to update.
   * @return {void}
   */
  updateFiltersFromParentComponent(filters: any): void {
    // Update the filters.
    this.filters = filters;
  }

  /**
   * Retrieves data from the API and populates the dataSource.
   *
   * @return {void} The method does not return a value.
   */
  getData(): void {
    // Normalize the query params.
    const queryParams:{[p: string]: any} = this.utils.normalizeQueryParams({
      ...this.filters,
      query_type: this.query_type,
      date_range: this.filters.date_range.map((date: Date) => {
        return date.getTime() / 1000;
      }).join(','),
      limit: this.limit,
      offset: this.offset
    });
    // Make a request to get the data from the API.
    this.api.makeRequest('get', 'v2/inductions/charts/inductions-stats', {}, queryParams).then((response) => {
      this.dataSource.data = response.data;
      this.total = response.total;
    });
  }
}

/**
 * Interface representing a form statistic.
 * @interface
 */
interface InductionStatistic {
  name: string;
  total: number;
  stat_completed: number;
  stat_not_completed: number;
  percent_completed: string;
}
