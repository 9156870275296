import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
    MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatMenuModule,
  MatTooltipModule
} from '@angular/material';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DirectivesModule } from '../directives/directives.module';
import { AccessRecordsComponent } from './access-records/access-records.component';
import { OnsiteRecordsComponent } from './onsite-records/onsite-records.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { TimeOnsiteRecordsComponent } from './time-onsite-records/time-onsite-records.component';
import { ContractorsSelectorComponent } from '../contractors/contractors-selector/contractors-selector.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { PandemicRecordsComponent } from './pandemic-records/pandemic-records.component';
import { ManageTimeOnsiteRecordComponent } from '../dialog-components/manage-time-onsite-record/manage-time-onsite-record.component';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { AccessRecordsFilterComponent } from './access-records-filter/access-records-filter.component';
import { OnsiteRecordsFilterComponent } from './onsite-records-filter/onsite-records-filter.component';
import { TimeOnsiteRecordsFilterComponent } from './time-onsite-records-filter/time-onsite-records-filter.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { PandemicRecordsFilterComponent } from './pandemic-records-filter/pandemic-records-filter.component';
import { PipesModule } from '../pipes/pipes.module';
import {SharedModule} from "../shared/shared.module";
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  declarations: [
    AccessRecordsComponent,
    OnsiteRecordsComponent,
    TimeOnsiteRecordsComponent,
    PandemicRecordsComponent,
    ManageTimeOnsiteRecordComponent,
    AccessRecordsFilterComponent,
    OnsiteRecordsFilterComponent,
    TimeOnsiteRecordsFilterComponent,
    PandemicRecordsFilterComponent
  ],
    imports: [
        CommonModule,
        ReportsRoutingModule,
        FormsModule,
        BsDatepickerModule,
        DirectivesModule,
        MatSortModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatIconModule,
        MatTableModule,
        MatBadgeModule,
        MatButtonModule,
        MatMenuModule,
        MatTooltipModule,
        MatComponentsModule,
        FontAwesomeModule,
        PipesModule,
        SharedModule,
        ChartsModule,
    ],
  entryComponents: [
    ContractorsSelectorComponent,
    SitesSelectorComponent,
    ManageTimeOnsiteRecordComponent,
    AccessRecordsFilterComponent,
    OnsiteRecordsFilterComponent,
    TimeOnsiteRecordsFilterComponent,
    PandemicRecordsFilterComponent
  ]
})
export class ReportsModule {}
