import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatCardModule,
  MatExpansionModule,
  MatRadioModule,
  MatSlideToggleModule
} from '@angular/material';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { FilesModule } from '../files/files.module';
import { SharedModule } from '../shared/shared.module';
import { DynamicFormsCategoriesComponent } from './dynamic-forms-categories/dynamic-forms-categories.component';
import { DynamicFormsCategoriesEditComponent } from './dynamic-forms-categories-edit/dynamic-forms-categories-edit.component';
import { DynamicFormsCategoriesViewComponent } from './dynamic-forms-categories-view/dynamic-forms-categories-view.component';
import { DynamicFormsCategoriesRoutingModule } from './dynamic-forms-categories-routing.module';
import { DynamicFormsCategoriesSelectorComponent } from './dynamic-forms-categories-selector/dynamic-forms-categories-selector.component';
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  declarations: [
    DynamicFormsCategoriesComponent,
    DynamicFormsCategoriesEditComponent,
    DynamicFormsCategoriesViewComponent,
    DynamicFormsCategoriesSelectorComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,
        DynamicFormsCategoriesRoutingModule,
        DragDropModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatSelectModule,
        MatTabsModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatBadgeModule,
        MatCardModule,
        MatExpansionModule,
        MatRadioModule,
        MatSlideToggleModule,
        PipesModule,
        FilesModule,
        SharedModule,
        MatDatepickerModule,
        ChartsModule
    ],
  entryComponents: [
    DynamicFormsCategoriesEditComponent,
    DynamicFormsCategoriesViewComponent,
    DynamicFormsCategoriesSelectorComponent
  ]
})
export class DynamicFormsCategoriesModule {}
