import { Component, Inject, OnInit, Optional, Renderer } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { HazardsEditComponent } from 'src/app/hazards/hazards-edit/hazards-edit.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ErpsEditComponent } from '../erps-edit/erps-edit.component';
import { FileService } from 'src/app/shared/file.service';

@Component({
  selector: 'app-erps-edit-dialog',
  templateUrl: './erps-edit-dialog.component.html',
  styleUrls: ['./erps-edit-dialog.component.scss']
})
export class ErpsEditDialogComponent extends ErpsEditComponent
  implements OnInit {
  constructor(
    public app: AppService,
    api: ApiService,
    public router: Router,
    public renderer: Renderer,
    public route: ActivatedRoute,
    public utils: UtilsService,
    @Optional() public dialogRef: MatDialogRef<HazardsEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    fileService: FileService
  ) {
    super(app, api, router, renderer, route, utils, fileService);

    // set the hazard and site ids
    this.erp.id = Number(this.dialogData['erp_id']);
    this.erp.site_id = Number(this.dialogData['site_id']);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async onSubmit(form: NgForm) {
    super.onSubmit(form, () => {
      this.onClose();
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
