import {
  Component,
  ElementRef,
  OnInit,
  Renderer,
  ViewChild,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskAnalysisModel } from 'src/app/models/task-analysis.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { FileModel } from 'src/app/shared/file.model';
import { MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-task-analyses-view',
  templateUrl: './task-analyses-view.component.html',
  styleUrls: ['./task-analyses-view.component.scss']
})
export class TaskAnalysesViewComponent implements OnInit {
  task_analysis: TaskAnalysisModel = new TaskAnalysisModel();

  inline_doc: FileModel = {};

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  files: any = [];

  dialogData: any;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public renderer: Renderer,
    public utils: UtilsService,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) private id: number
  ) {}

  ngOnInit() {
    // get the site id
    this.task_analysis.id = this.id;

    this.api.laravelApiRequest(
      'get',
      `task-analysis/${this.task_analysis.id}`,
      {},
      {},
      (response) => {
        this.task_analysis.apply(response.data);
      }
    );
  }

  onRemove() {
    this.utils.showModal(
      'Remove Task Analysis Document',
      'Are you sure you want to remove this Task Analysis Document?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'task-analysis/' + this.task_analysis.id,
          {},
          {},
          () => {
            this.utils.showToast('The Task Analysis Document was removed.');
            this.router.navigate(['task-analysis']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }
}
