import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {PpeModel} from "../ppe.model";
import {MAT_DIALOG_DATA} from "@angular/material";
import {UserPublicProfileComponent} from "../../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-ppe-view',
  templateUrl: './ppe-view.component.html',
  styleUrls: ['./ppe-view.component.scss']
})
export class PpeViewComponent implements OnInit {

  ppe: PpeModel = new PpeModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const ppe_id = this.id;
    if(ppe_id) {
      this.getPPE(ppe_id);
    }
  }

  private getPPE (ppe_id: number) {
    this.api.makeRequest('get', `v2/admin/ppe/${ppe_id}`)
      .then((response) => {
        this.ppe = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onOpenUrl(url: string) {
    this.utils.openUrl(url);
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
