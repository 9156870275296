import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { MessageViewComponent } from 'src/app/messages/message-view/message-view.component';
import { Message } from 'src/app/models/message.model';
import { SafetyObservation } from 'src/app/models/safety-observation.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import * as moment from 'moment';

@Component({
  selector: 'app-safety-observations-view-messages',
  templateUrl: './safety-observations-view-messages.component.html',
  styleUrls: ['./safety-observations-view-messages.component.scss']
})
export class SafetyObservationsViewMessagesComponent implements OnInit, OnDestroy {

  @Input('safety_observation_id') safety_observation_id: number;
  @Input('safety_observation') safety_observation: SafetyObservation;

  dataSource: SafetyObservationsMessagesDataSource = new SafetyObservationsMessagesDataSource(this.app, this.api);

  listColumns: string[] = [
    'id',
    'subject',
    'type',
    'status',
    'priority',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  refreshMessageListInterval: any;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.safety_observation_id = this.safety_observation_id;
    this.dataSource.getData();

    // Refresh the list of messages every 30 seconds.
    this.refreshMessageListInterval = setInterval(() => {
      this.dataSource.getData();
    }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.refreshMessageListInterval);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC dates by their corresponding date columns.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Open the message for editing. Only drafts and cancelled messages can be edited.
   * See canEditMessage() in this class.
   * @param message_id The message id.
   */
  onEditMessage(message_id?: number) {
    this.utils.showComponentDialog(MessageEditComponent, {
      messageId: message_id,
      safetyObservationId: this.safety_observation_id, // This is only associated when new records are created.
      prePopulateSubject: !message_id ? 'Safety Observation Reported On ' + moment.unix(this.safety_observation.date_created).toString() : '',
      prePopulateMessage: !message_id ? ('Hi ,<br/><br/><b>Safety Observation:</b><br/>' + this.safety_observation.so_description + '<br/><br/><b>Action Taken:</b> <br/>' + (this.safety_observation.so_action_taken || '') + '<br/><br/><b>Category:</b> <br/>' + this.safety_observation.so_category + '<br/><br/><b>Type:</b> <br/>' + this.safety_observation.so_type + '<br/><br/><b>Priority:</b> <br/>' + this.safety_observation.so_priority + '<br/><br/><b>Status:</b> <br/>' + this.safety_observation.so_status + '<br/><br/><b>Rating:</b> <br/>' + this.safety_observation.so_rating) : ''
    })
    .then(() => {
      this.dataSource.getData();
    });
  }

  /**
   * Open the message for viewing. Only sent messages can be viewed.
   * @param message_id The message id.
   * @returns
   */
  onViewMessage(message_id?: number) {
    if ( !message_id ) {
      this.utils.showModal('Error', 'Please click on the view message button to view a message.');
      return;
    }
    this.utils.showComponentDialog(MessageViewComponent, {
      messageId: message_id
    })
    .then(() => {
      // Reload the list after viewing.
      this.dataSource.getData();
    });
  }

  /**
   * Check if the message can be edited.
   * @param message The message to check.
   * @returns boolean.
   */
  canEditMessage(message: Message) {
    return ['draft', 'cancelled'].indexOf(message.status) > -1;
  }

}

export class SafetyObservationsMessagesDataSource extends ApiDataSource {
  // Order the records by the date it was created.
  order_by = 'date_created';
  order = 'desc';

  safety_observation_id: number;

  type: string = '';
  status: string = '';
  priority: string = '';

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/safety-observations/${this.safety_observation_id}/messages`, resetOffset, {
      type: this.type,
      status: this.status,
      priority: this.priority
    });
  }
}
