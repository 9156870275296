import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {ActionsService} from "../../actions/actions.service";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-toolbox-talks-corrective-actions-filter',
  templateUrl: './toolbox-talks-corrective-actions-filter.component.html',
  styleUrls: ['./toolbox-talks-corrective-actions-filter.component.scss']
})
export class ToolboxTalksCorrectiveActionsFilterComponent implements OnInit {

  dataSource = new ToolboxTalksCorrectiveActionsFilterDataSource(); // No API calls needed
  priorities = ['Low', 'Medium', 'High', 'Critical'];

  constructor(
    private app: AppService,
    public location: Location,
    private utils: UtilsService,
    private actionsService: ActionsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ToolboxTalksCorrectiveActionsFilterComponent>
  ) {

    // set a default assigned users selection of nothing if no selected values are passed through
    if (typeof this.data['selected_assigned_users'] === 'undefined') {
      this.data['selected_assigned_users'] = [];
    }

    // set a default priority of nothing if no values are passed through
    if (typeof this.data['filter_priority'] === 'undefined') {
      this.data['filter_priority'] = "";
    }

    // set a default status of nothing if no values are passed through
    if (typeof this.data['filter_status'] === 'undefined') {
      this.data['filter_status'] = "";
    }

    // set a default due date of nothing if no values are passed through
    if (typeof this.data['due_date_range'] === 'undefined') {
      this.data['due_date_range'] = [];
    }
  }

  ngOnInit() {
    this.dataSource.selected_assigned_users = this.data['selected_assigned_users'];
    this.dataSource.filter_priority = this.data['filter_priority'];
    this.dataSource.filter_status = this.data['filter_status'];
    this.dataSource.due_date_range = this.data['due_date_range'];
  }

  getOutputDateRange($event) {
    this.dataSource.due_date_range = $event;
  }

  onSelectUsers() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: this.dataSource.selected_assigned_users,
        multiple: true,
        visitors_from_all_sites: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.selected_assigned_users = results;
        }
      }
    );
  }

  onClearAndClose() {
    this.dataSource.selected_assigned_users = [];
    this.dataSource.filter_priority = "";
    this.dataSource.filter_status = "";
    this.dataSource.due_date_range = [];

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      user_ids: this.dataSource.selected_assigned_users,
      priority: this.dataSource.filter_priority,
      status: this.dataSource.filter_status,
      due_date: this.dataSource.due_date_range
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class ToolboxTalksCorrectiveActionsFilterDataSource {

  selected_assigned_users: number[] = [];
  filter_priority: string = "";
  filter_status: string = "";
  due_date_range: Date[] = [];

  constructor() {
    this.selected_assigned_users = [];
    this.filter_priority = "";
    this.filter_status = "";
    this.due_date_range = []
  }

}
