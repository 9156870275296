import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {NgForm} from "@angular/forms";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {TaskAnalysesRevisedWorkflowNoteModel} from "../../models/task-analyses-revised-workflow-note.model";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-notes-edit',
  templateUrl: './sites-task-analyses-revised-workflows-notes-edit.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-notes-edit.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsNotesEditComponent implements OnInit {

  inProgress = false;

  path: string;
  site_id: number;
  ta_revised_id: number;
  ta_revised_workflow_id: number;
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();
  ta_revised_workflow_note: TaskAnalysesRevisedWorkflowNoteModel = new TaskAnalysesRevisedWorkflowNoteModel();

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedWorkflowsNotesEditComponent>,
    public router: Router,
    public renderer: Renderer
  ) { }

  ngOnInit() {
    // get the task analysis workflow note id
    this.ta_revised_workflow_note.id = this.dialogData.ta_revised_workflow_note.id;

    // get the task analysis workflow id
    this.ta_revised_workflow_id = this.dialogData.ta_revised_workflow_id;

    // get the task analysis id
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id
    this.site_id = this.dialogData.site_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.ta_revised_workflow_note.id) {
      this.find(this.ta_revised_workflow_note.id);
    }

    if(this.ta_revised_id) {
      this.findTa(this.ta_revised_id);
    }
  }

  private find(ta_revised_workflow_note_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/notes/${ta_revised_workflow_note_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised_workflow_note = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private findTa(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  async onSubmit(form: NgForm) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(!this.ta_revised_workflow_note.id) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this.api
      .makeRequest('post', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/notes`, this.ta_revised_workflow_note, {
        site_id: this.site_id
      })
      .then((response) => {
        this.onSuccess(`A new TA/JSA/SWMS Workflow Step Note was created.`);
        this.ta_revised_workflow_note = response;
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private update() {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/notes/${this.ta_revised_workflow_note.id}`, this.ta_revised_workflow_note, {
        site_id: this.site_id
      })
      .then((response) => {
        this.onSuccess(`The TA/JSA/SWMS Workflow Step Note was updated.`);
        this.ta_revised_workflow_note = response;
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  public onDelete() {
    this.utils.showModal('Delete TA/JSA/SWMS Workflow Step Note', 'Are you sure you want to delete the TA/JSA/SWMS Workflow Step Note?', () => {
      this.api.makeRequest('delete', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/notes/${this.ta_revised_workflow_note.id}`, [], {
        site_id: this.site_id
      })
        .then(() => {
          this.onSuccess('The TA/JSA/SWMS Workflow Step Note was deleted.');
        });
    });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }

}
