import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountsEditComponent } from './accounts/accounts-edit/accounts-edit.component';
import { AccountsViewComponent } from './accounts/accounts-view/accounts-view.component';
import { UsersComponent } from './users/users.component';
import { UsersEditComponent } from './users/users-edit/users-edit.component';
import { UsersViewComponent } from './users/users-view/users-view.component';
import { AccountsSelectorComponent } from './accounts/accounts-selector/accounts-selector.component';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from '../guards/auth.guard';
import { UsersSelectorComponent } from './users/users-selector/users-selector.component';
import { AccountsMergerComponent } from './accounts/accounts-merger/accounts-merger.component';
import { MATERIAL_MODULES } from '../app.const';
import { TradesComponent } from './trades/trades.component';
import { TradesModule } from "./trades/trades.module";
import { IndustriesComponent } from "./industries/industries.component";
import { IndustriesModule } from "./industries/industries.module";
import { PpeComponent } from './ppe/ppe.component';
import { PpeModule } from "./ppe/ppe.module";
import { WorkPermitsComponent } from "./work-permits/work-permits.component";
import { WorkPermitsModule } from "./work-permits/work-permits.module";
import { TaskAnalysesRevisedComponent } from './task-analyses-revised/task-analyses-revised.component';
import { TaskAnalysesRevisedModule } from "./task-analyses-revised/task-analyses-revised.module";
import { PpeSelectorComponent } from "../shared/ppe-selector/ppe-selector.component";
import { WorkPermitsSelectorComponent } from "../shared/work-permits-selector/work-permits-selector.component";
import { PipesModule } from '../pipes/pipes.module';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { AdminSitesComponent } from './sites/admin-sites/admin-sites.component';
import { AdminSiteTabsComponent } from './sites/admin-site-tabs/admin-site-tabs.component';
import { AdminSitesFilterComponent } from './sites/admin-sites-filter/admin-sites-filter.component';
import { AdminSiteDetailsEditComponent } from './sites/admin-site-details-edit/admin-site-details-edit.component';
import { AdminSiteSettingsComponent } from './sites/admin-site-settings/admin-site-settings.component';
import { AccountCopyTemplatesDialogComponent } from './accounts/account-copy-templates-dialog/account-copy-templates-dialog.component';
import { MatDatepickerModule, MatProgressSpinnerModule, MatSidenavModule, MatSlideToggleModule } from '@angular/material';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { SharedModule } from '../shared/shared.module';
import {UsersDocumentsImporterComponent} from "./users/users-documents-importer/users-documents-importer.component";
import {AccountsFilterComponent} from "./accounts/accounts-filter/accounts-filter.component";
import { AdminSiteDetailsTabsComponent } from './sites/admin-site-details-tabs/admin-site-details-tabs.component';
import { AdminSiteDetailsMapComponent } from './sites/admin-site-details-map/admin-site-details-map.component';

const ADMIN_ROUTES: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: 'accounts', component: AccountsComponent },
      { path: 'accounts/:id', pathMatch: 'full', redirectTo: '/admin/accounts' },
      { path: 'accounts/:id/edit', component: AccountsEditComponent },
      { path: 'accounts/:id/view', component: AccountsViewComponent },
      { path: 'users', component: UsersComponent },
      { path: 'users/documents/importer', component: UsersDocumentsImporterComponent },
      { path: 'users/:id', pathMatch: 'full', redirectTo: '/admin/users' },
      { path: 'users/:id/edit', component: UsersEditComponent },
      { path: 'users/:id/view', component: UsersViewComponent },
      { path: 'trades', component: TradesComponent },
      { path: 'industries', component: IndustriesComponent },
      { path: 'ppe', component: PpeComponent },
      { path: 'work-permits', component: WorkPermitsComponent },
      { path: 'task-analyses-revised', component: TaskAnalysesRevisedComponent },
      { path: 'sites', component: AdminSitesComponent },
      { path: 'sites/:site_id', pathMatch: 'full', redirectTo: '/admin/sites' },
      {
        path: 'sites/:site_id',
        component: AdminSiteTabsComponent,
        children: [
          { path: 'details', component: AdminSiteDetailsTabsComponent }
        ]
      }

    ]
  }
];

@NgModule({
  declarations: [
    AdminComponent,
    AccountsSelectorComponent,
    UsersSelectorComponent,
    AccountsMergerComponent,
    AccountsMergerComponent,
    AdminSitesComponent,
    AdminSiteTabsComponent,
    AdminSiteDetailsEditComponent,
    AdminSitesFilterComponent,
    AdminSiteSettingsComponent,
    AccountCopyTemplatesDialogComponent,
    UsersDocumentsImporterComponent,
    AccountsFilterComponent,
    AdminSiteDetailsTabsComponent,
    AdminSiteDetailsMapComponent
  ],
  entryComponents: [
    AccountsSelectorComponent,
    UsersSelectorComponent,
    AccountsMergerComponent,
    PpeSelectorComponent,
    WorkPermitsSelectorComponent,
    AdminSitesFilterComponent,
    AccountCopyTemplatesDialogComponent,
    AccountsFilterComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(ADMIN_ROUTES),
    FormsModule,
    ...MATERIAL_MODULES,
    MatSidenavModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    TradesModule,
    IndustriesModule,
    PpeModule,
    WorkPermitsModule,
    NgxMatDatetimePickerModule,
    TaskAnalysesRevisedModule,
    PipesModule,
    SharedModule
  ],
  exports: [
    RouterModule
  ]
})
export class AdminModule { }
