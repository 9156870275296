import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {TaskAnalysesRevisedModel} from "../task-analyses-revised.model";
import {PpeSelectorComponent} from "../../../shared/ppe-selector/ppe-selector.component";
import {WorkPermitsSelectorComponent} from "../../../shared/work-permits-selector/work-permits-selector.component";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-task-analyses-revised-edit',
  templateUrl: './task-analyses-revised-edit.component.html',
  styleUrls: ['./task-analyses-revised-edit.component.scss']
})
export class TaskAnalysesRevisedEditComponent implements OnInit {

  inProgress = false;

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public ta_revised: TaskAnalysesRevisedModel,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedEditComponent>,
    private api: ApiRequestService,
    public renderer: Renderer
  ) { }

  ngOnInit() {
    if(this.ta_revised.id) {
      this.find(this.ta_revised.id);
    }
  }

  onSubmit(form: NgForm, closeDialog: boolean = true) {
    // Do not process if already in prgress.
    if ( this.inProgress ) {
      return;
    }

    // Perform form validation.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    !this.ta_revised.id ? this.create() : this.update(closeDialog);
  }

  private find(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/admin/task-analyses-revised/${ta_revised_id}`)
      .then((response) => {
        this.ta_revised = response;

        // Extract PPE ids.
        this.ta_revised.ppe_ids = this.ta_revised.ppe ? this.ta_revised.ppe.map((ppe) => ppe.id) : [];

        // Extract Work Permit ids.
        this.ta_revised.work_permit_ids = this.ta_revised.work_permits ? this.ta_revised.work_permits.map((work_permit) => work_permit.id) : [];
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete() {
    this.utils.showModal(
      'Archive TA/JSA/SWMS Template',
      `Are you sure you want to archive the "${this.ta_revised.task_name}" TA/JSA/SWMS template?`,
      () => {
        this.api.makeRequest('delete', `v2/admin/task-analyses-revised/${this.ta_revised.id}`)
        .then((response) => {
          this.utils.showToast(`"${this.ta_revised.task_name}" TA/JSA/SWMS template has been archived.`);
          this.dialogRef.close(true);
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
      }
    );
  }

  private update(closeDialog: boolean = true) {
    this.api
      .makeRequest('put', `v2/admin/task-analyses-revised/${this.ta_revised.id}`, this.ta_revised)
      .then((response) => {
        this.onSuccess(`Updated: "${response.task_name}"`, closeDialog);
      })
      .finally(() => (this.inProgress = false));
  }

  private create() {
    this.api
      .makeRequest('post', 'v2/admin/task-analyses-revised', this.ta_revised)
      .then((response) => {
        this.onSuccess(`New TA/JSA/SWMS template "${response.task_name}" created.`);
      })
      .finally(() => this.inProgress = false);
  }

  private onSuccess(message: string, closeDialog: boolean = true) {
    this.utils.showToast(message);
    if(closeDialog) {
      this.dialogRef.close(true);
    }
  }

  selectPPE() {
    this.utils.showComponentDialog(
      PpeSelectorComponent,
      {
        selected: this.ta_revised.ppe_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.ppe_ids = results;
      }
    );
  }

  selectWorkPermits() {
    this.utils.showComponentDialog(
      WorkPermitsSelectorComponent,
      {
        selected: this.ta_revised.work_permit_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.work_permit_ids = results;
      }
    );
  }

}
