import {Component, ElementRef, Input, OnInit, Renderer, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {UtilsService} from "../utils.service";
import {ApiService} from "../api.service";
import {Location} from "@angular/common";
import {ApiRequestService} from "../api-request.service";

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss']
})
export class DataImportComponent implements OnInit {

  // Accepts the import type e.g. contractors, employees, sites or user-documents.
  @Input() type: string;

  // The display value for the type.
  displayType: string = '';

  // The file input and selected file refs.
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  selectedFile: any;

  // The uploaded file name for processing. Data received from uploaded CSV analysis.
  uploaded_file_name = '';
  // Possible headers from the uploaded CSV file. Data received from uploaded CSV analysis.
  possible_headers: any = [];
  // Sample data to map columns against. Data received from uploaded CSV analysis.
  sample_data: any = [];
  // The fields that can be imported. Data received from uploaded CSV analysis.
  importable_fields: any = [];

  // The mapped fields. Default values are -1. This means unmapped.
  mapped_fields: any = [];

  // Used to treat the first row as headers.
  first_row_headers = true;

  // Used to treat the first row (user) as an administrator.
  first_row_admin = false;

  // Used to determine if notifications should be sent.
  send_notifications = true;

  // Used to keep track of importing status.
  is_importing = false;

  // The parent site id when importing child sites.
  parent_site_id: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public renderer: Renderer,
    public utils: UtilsService,
    private oldApi: ApiService,
    private _location: Location,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Update the display type.
    switch(this.type) {
      case 'contractors':
        this.displayType = this.utils.getLangTerm('contractors.plural', 'Contractors');
        break;
      case 'employees':
        this.displayType = this.utils.getLangTerm('employees.plural', 'Employees');
        break;
      case 'sites':
        // Get the parent site id if it exists.
        const parent_site_id: number = Number(this.route.snapshot.queryParams['parent_site_id']);
        if ( parent_site_id ) {
          this.parent_site_id = parent_site_id;
          this.displayType = this.utils.getLangTerm('child-sites.plural', 'Subsites');
        } else {
          this.displayType = this.utils.getLangTerm('sites.plural', 'Sites');
        }
        break;
      case 'user-documents':
        this.displayType = 'User Documents';
        break;
      case 'hazards':
        this.displayType = 'Hazards & Risks';
        break;
      default:
        // Do nothing.
    }
  }

  /**
   * Click the button that in turn triggers the file input type to select a file.
   * When a file is selected, it is uploaded for analysis.
   */
  onTriggerFileSelector(evt: Event) {
    const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInput.nativeElement,
      'dispatchEvent',
      [clickEvt]
    );
  }

  /**
   * When a file is selected, it is sent to the API for analysis.
   * @param evt
   */
  onFileSelected(evt: any) {
    // Terminate early if no files were selected.
    if ( evt.target.files.length === 0 ) {
      this.utils.showModal('Error', 'Please select a CSV file for analysis.');
      return;
    }
    // Get the selected file.
    this.selectedFile = evt.target.files[0];
    // Upload the file to the API for analysis.
    this.oldApi.laravelApiUploadFiles('imports/analyse/' + this.type, [this.selectedFile], (response) => {
      // Populate the values for column mapping.
      this.uploaded_file_name = response.data.uploaded_file_name;
      this.possible_headers = response.data.possible_headers;
      this.sample_data = response.data.sample_data;
      this.importable_fields = response.data.importable_fields;

      // Set a default value of -1 (unmapped) for each possible column that can be mapped.
      for (let i = 0; i < this.possible_headers.length; i++) {
        this.mapped_fields.push(-1);
      }
    });
  }

  /**
   * This is used to check if required fields are mapped.
   * If there are no required fields, then at least 1 field must be mapped.
   */
  isValidMapping() {
    // Find the indexes of the required fields.
    let indexes: number[] = [];
    this.importable_fields.forEach((importable_field: string, index: number) => {
      if ( importable_field.includes('*') ) {
        indexes.push(index);
      }
    });
    // Set the default mapping to true if there are required fields.
    let is_mapped: boolean = indexes.length > 0;
    // If the mapping is not true, then it means there are no required fields.
    // We will then loop through the mapped fields and check if any fields were mapped.
    if ( !is_mapped ) {
      for ( let i = 0; i < this.mapped_fields.length; i++ ) {
        if ( this.mapped_fields[i] > -1 ) {
          is_mapped = true;
          break;
        }
      }
    } else {
      // If the mapping is true, then we want to ensure all required fields are mapped.
      // Required fields are stored in the indexes array.
      for ( let i = 0; i < indexes.length; i++ ) {
        if ( !this.mapped_fields.includes(indexes[i]) ) {
          is_mapped = false;
          break;
        }
      }
    }
    return is_mapped;
  }

  /**
   * Send the mapped data to the API to process the CSV.
   */
  onSubmit(form: NgForm) {
    this.oldApi.laravelApiRequest('post', 'imports/import/' + this.type, {
        mapped_fields: this.mapped_fields,
        uploaded_file_name: this.uploaded_file_name,
        first_row_headers: this.first_row_headers,
        first_row_admin: this.first_row_admin,
        send_notifications: this.send_notifications,
        parent_site_id: this.parent_site_id
      },
      {},
      (response) => {
        // Show the user the API response.
        this.utils.showToast(response.message);
        // Redirect the user to the base lists.
        if ( ['contractors', 'employees', 'sites'].indexOf(this.type) > -1 ) {
          // Check if we need to redirect to the child site list of a parent site.
          if ( this.type === 'sites' && this.parent_site_id ) {
            this.router.navigate(['/', this.type, this.parent_site_id, 'children']);
          } else {
            this.router.navigate(['/', this.type]);
          }
        } else {
          // Try navigating back with the browser.
          this._location.back();
        }
      }
    );
  }

  /**
   * Show a warning message to the user.
   */
  onToggleCompanyAdmin() {
    if (this.first_row_admin) {
      this.utils.showModal('Warning', 'When you enable this option, the first person will be added as a ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' and the rest as ' + this.utils.getLangTerm('employees.plural', 'Employees') + ' of the same account. Please ensure you have the primary contact for the ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' listed in the first row.');
    }
  }

  /**
   * Download a CSV template for chosen import type.
   */
  onDownloadImportTemplate() {
    // Get the current date object
    const date = new Date();

    this.api.makeDownloadRequest(`v2/${this.type}/import-template`, {}, {})
      .then((file) => {
        let file_name = `Import template - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.csv`;
        let msg_type = '';
        if(this.type === 'contractors') {
          file_name = `${ this.utils.getLangTerm('contractors.plural', 'Contractors') } ${ file_name }`;
          msg_type = ` for ${ this.utils.getLangTerm('contractors.plural', 'Contractors') }`;
        } else if(this.type === 'employees') {
          file_name = `${ this.utils.getLangTerm('employees.plural', 'Employees') } ${ file_name }`;
          msg_type = ` for ${ this.utils.getLangTerm('employees.plural', 'Employees') }`;
        }
        this.utils.showToast(`CSV template${ msg_type } has been downloaded.`);
        saveAs(file, file_name);
      });
  }

}
