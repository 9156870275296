import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {ApiService} from "../api.service";
import {ApiRequestService} from "../api-request.service";
import {UtilsService} from "../utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {tap} from "rxjs/operators";
import {ApiDataSource} from "../../utils/api-data-source";
import {UserPublicProfileComponent} from "../user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-admin-employees-selector',
  templateUrl: './admin-employees-selector.component.html',
  styleUrls: ['./admin-employees-selector.component.scss']
})
export class AdminEmployeesSelectorComponent implements OnInit {

  // Columns to show in the view
  displayedColumns: string[] = [
    'contact_person',
    'email',
    'mobile',
    'date_created'
  ];

  // The data source
  dataSource:AdminEmployeesSelectorDataSource;

  // The paginator and sorter objects
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    public oldApi: ApiService,
    public api: ApiRequestService,
    public dialogRef: MatDialogRef<AdminEmployeesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: UtilsService
  ) {
    // Set multiple if it is not present and default it to false
    if ( typeof this.data['multiple'] == 'undefined' || typeof this.data['multiple'] != 'boolean' ) {
      this.data['multiple'] = false;
    }

    if ( this.data['multiple'] ) {
      this.displayedColumns.unshift('select');
    } else {
      this.displayedColumns.push('actions');
    }

    // Construct the datasource
    this.dataSource = new AdminEmployeesSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // Set deselect if it is not present and default it to true
    if ( typeof this.data['deselect'] == 'undefined' || typeof this.data['deselect'] != 'boolean' ) {
      this.data['deselect'] = true;
    }

    // Set a default selection if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined' || (this.data['selected'].length == 1 && !this.data['selected'][0])) {
      this.data['selected'] = [];
    }

    // Loop and select the values in the data source
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(this.data['selected'][i]);
    }

    // Set accountId if it is not present and default it to 0
    if ( typeof this.data['accountId'] == 'undefined' || typeof this.data['accountId'] != 'number' ) {
      this.data['accountId'] = 0;
    }

    this.dataSource.accountId = this.data['accountId'];

    // Get the data
    this.dataSource.getData();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onSelectSingle(id: number) {
    this.dialogRef.close(id);
  }

  onDeselectSingle() {
    this.dataSource.selection.clear();
    this.dialogRef.close(0);
  }

  onSelectMultiple() {
    this.dialogRef.close(this.dataSource.selection.selected);
  }

  onSelectAllRecords() {
    this.dialogRef.close(this.dataSource.ids);
  }

  onDeselectAllRecords() {
    this.dataSource.selection.clear();
  }

  onUserPublicView(hash: string, event: Event) {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();

    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

/**
 * The admin employees selector data source.
 */
export class AdminEmployeesSelectorDataSource extends ApiDataSource {

  order_by = 'contact_person';
  order = 'asc';

  accountId: number;
  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/admin/users-accounts`, resetOffset, {
      admin_account_id: this.accountId
    });
  }
}
