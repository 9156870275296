import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { DynamicFormsCategoriesSelectorComponent } from 'src/app/dynamic-forms-categories/dynamic-forms-categories-selector/dynamic-forms-categories-selector.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { ContractorsSelectorComponent } from 'src/app/contractors/contractors-selector/contractors-selector.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { IndustriesSelectorComponent } from 'src/app/shared/industries-selector/industries-selector.component';
import { TradesSelectorComponent } from 'src/app/shared/trades-selector/trades-selector.component';

@Component({
  selector: 'app-dynamic-forms-filter',
  templateUrl: './dynamic-forms-filter.component.html',
  styleUrls: ['./dynamic-forms-filter.component.scss']
})
export class DynamicFormsFilterComponent implements OnInit {

  // Define the filter options.
  filter_options: DynamicFormsFilter = {
    only_archived: 'false',
    category_ids: [],
    site_ids: [],
    contractor_ids: [],
    user_ids: [],
    industry_ids: [],
    trade_ids: [],
    date_range: []
  };

  // Used to only show the date picker.
  show_date_picker_only: boolean = false;

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DynamicFormsFilterComponent>
  ) {

    // set a default category of nothing if no values are passed through
    if (typeof this.data['only_archived'] == 'undefined') {
      this.data['only_archived'] = 'false';
    }

    // set a default type of nothing if no values are passed through
    if (typeof this.data['category_ids'] == 'undefined') {
      this.data['category_ids'] = [];
    }

    // set a default type of nothing if no values are passed through
    if (typeof this.data['site_ids'] == 'undefined') {
      this.data['site_ids'] = [];
    }

    // set a default type of nothing if no values are passed through
    if (typeof this.data['contractor_ids'] == 'undefined') {
      this.data['contractor_ids'] = [];
    }

    // set a default type of nothing if no values are passed through
    if (typeof this.data['user_ids'] == 'undefined') {
      this.data['user_ids'] = [];
    }

    // set a default type of nothing if no values are passed through
    if (typeof this.data['industry_ids'] == 'undefined') {
      this.data['industry_ids'] = [];
    }

    // set a default type of nothing if no values are passed through
    if (typeof this.data['trade_ids'] == 'undefined') {
      this.data['trade_ids'] = [];
    }

    // Set a default type of an empty array if no values are passed through.
    if (typeof this.data['date_range'] == 'undefined') {
      this.data['date_range'] = [];
    }

    // Set a default value of false if no values are passed through.
    if (typeof this.data['show_date_picker_only'] == 'undefined') {
      this.data['show_date_picker_only'] = false;
    }
  }

  ngOnInit() {
    // The constructor makes sure the values are set and present.
    this.filter_options.only_archived = this.data['only_archived'];
    this.filter_options.category_ids = this.data['category_ids'];
    this.filter_options.site_ids = this.data['site_ids'];
    this.filter_options.contractor_ids = this.data['contractor_ids'];
    this.filter_options.user_ids = this.data['user_ids'];
    this.filter_options.industry_ids = this.data['industry_ids'];
    this.filter_options.trade_ids = this.data['trade_ids'];
    this.filter_options.date_range = this.data['date_range'];
    // Store the setting to only show the date picker or all filters.
    this.show_date_picker_only = this.data['show_date_picker_only'];
  }

  /**
   * Open the categories selector.
   */
  onSelectCategories() {
    this.utils.showComponentDialog(DynamicFormsCategoriesSelectorComponent, {
      multiple: true,
      selected: this.filter_options.category_ids
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        this.filter_options.category_ids = response;
      }
    });
  }

  /**
   * Open the sites selector.
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
      multiple: true,
      selected: this.filter_options.site_ids
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        this.filter_options.site_ids = response;
      }
    });
  }

  /**
   * Open the contractors selector.
   */
  onSelectContractors() {
    this.utils.showComponentDialog(ContractorsSelectorComponent, {
      multiple: true,
      selected: this.filter_options.contractor_ids
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        this.filter_options.contractor_ids = response;
      }
    });
  }

  /**
   * Open the networked users selector.
   */
  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      multiple: true,
      selected: this.filter_options.user_ids,
      visitors_from_all_sites: true
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        this.filter_options.user_ids = response;
      }
    });
  }

  /**
   * Open the industries selector.
   */
  onSelectIndustries() {
    this.utils.showComponentDialog(IndustriesSelectorComponent, {
      multiple: true,
      selected: this.filter_options.industry_ids
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        this.filter_options.industry_ids = response;
      }
    });
  }

  /**
   * Open the trades selector.
   */
  onSelectTrades() {
    this.utils.showComponentDialog(TradesSelectorComponent, {
      multiple: true,
      selected: this.filter_options.trade_ids
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        this.filter_options.trade_ids = response;
      }
    });
  }

  /**
   * Sets the forms date range in the filters object based on the provided event.
   *
   * @param {any} $event - The event object containing the incident date.
   * @return {void}
   */
  getOutputDateRange($event: any): void {
    // Get the incident date from the date selector.
    this.filter_options.date_range = $event;
  }

  /**
   * Clear the filter options and send the response back.
   */
  onClearAndClose() {
    // Clear all filter options.
    this.filter_options.only_archived = 'false';
    this.filter_options.category_ids = [];
    this.filter_options.site_ids = [];
    this.filter_options.contractor_ids = [];
    this.filter_options.user_ids = [];
    this.filter_options.industry_ids = [];
    this.filter_options.trade_ids = [];
    this.filter_options.date_range = [];

    this.onSaveAndClose();
  }

  /**
   * Close the dialog and send the selected options as the response.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filter_options);
  }
}

/**
 * Interface representing a filter for dynamic forms.
 */
export interface DynamicFormsFilter {
  only_archived: string;
  category_ids: number[];
  site_ids: number[];
  contractor_ids: number[];
  user_ids: number[];
  industry_ids: number[];
  trade_ids: number[];
  date_range: Date[];
}
