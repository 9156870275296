import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FolderService } from 'src/app/shared/folder.service';
import {UtilsService} from "../../shared/utils.service";

@Component({
  selector: 'app-folders-view',
  templateUrl: './folders-view.component.html',
  styleUrls: ['./folders-view.component.scss']
})
export class FoldersViewComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private folderService: FolderService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.folderService
      .find(this.data.folder.id)
      .subscribe((response) => (this.data.folder = response.data));
  }

  view(url: string) {
    window.open(url, '_blank');
  }
}
