import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { FileModel } from 'src/app/shared/file.model';
import { FileService } from 'src/app/shared/file.service';

@Component({
  selector: 'app-files-add',
  templateUrl: './files-add.component.html',
  styleUrls: ['./files-add.component.scss']
})
export class FilesAddComponent {
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  @Output() change = new EventEmitter<FileModel[]>();

  constructor(private fileService: FileService) {}

  async changeFiles() {
    const files = this.fileInput.nativeElement.files;
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const _data = await this.fileService.getBase64(file);
      newFiles.push({name: file.name, mime: file.type, data: _data});
    }
    this.change.emit(newFiles);
  }
}
