import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";

@Component({
  selector: 'app-hazards-filter',
  templateUrl: './hazards-filter.component.html',
  styleUrls: ['./hazards-filter.component.scss']
})
export class HazardsFilterComponent implements OnInit {

  // Set the default hazards filters.
  filters: HazardsFilters = {
    site_ids: [],
    date_range: [],
    archived: 'false'
  } as HazardsFilters;

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<HazardsFilterComponent>
  ) { }

  ngOnInit() {
    this.filters.site_ids = this.data['site_ids'] || [];
    this.filters.date_range = this.data['date_range'] || [];
    this.filters.archived = this.data['archived'] || 'false';
  }

  /**
   * Displays the sites' selector dialog and updates the selected sites.
   *
   * @return {void}
   */
  onSelectSites() {
    // Show the sites' selector.
    this.utils.showComponentDialog(SitesSelectorComponent, {
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }, (results: any[]): void => {
        if (typeof results != 'undefined') {
          this.filters.site_ids = results;
        }
      }
    );
  }

  /**
   * Clears the filters and invokes the onSaveAndClose method.
   *
   * @return {void}
   */
  onClearAndClose(): void {
    // Clear the filters.
    this.filters.site_ids = [];
    this.filters.date_range = [];
    this.filters.archived = 'false';
    // Save and close.
    this.onSaveAndClose();
  }

  /**
   * Saves the filters and closes the dialog.
   *
   * @return {void}
   */
  onSaveAndClose(): void {
    this.dialogRef.close(this.filters);
  }
}

export interface HazardsFilters {
  site_ids: number[];
  archived: string;
  date_range: Date[];
}
