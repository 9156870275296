import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {RISK_LIKELIHOODS} from "../../shared/risk-likelihoods";
import {RISK_SEVERITIES} from "../../shared/risk-severities";
import {RISK_LEVEL_OF_CONTROLS} from "../../shared/risk-level-of-controls";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {TaskAnalysesRevisedWorkflowHazardModel} from "../../models/task-analyses-revised-workflow-hazard.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {FileService} from "../../shared/file.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {HazardControlModel} from "../../models/hazard-control.model";

@Component({
  selector: 'app-task-analyses-revised-workflows-hazards-edit',
  templateUrl: './task-analyses-revised-workflows-hazards-edit.component.html',
  styleUrls: ['./task-analyses-revised-workflows-hazards-edit.component.scss']
})
export class TaskAnalysesRevisedWorkflowsHazardsEditComponent implements OnInit {

  inProgress = false;

  likelihood_list: string[] = RISK_LIKELIHOODS;
  severity_list: string[] = RISK_SEVERITIES;
  loc_list: string[] = RISK_LEVEL_OF_CONTROLS;

  path: string;
  selectedFiles: File[] = [];
  newFiles: FileList;
  ta_revised_id: number;
  ta_revised_workflow_id: number;
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();
  ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel = new TaskAnalysesRevisedWorkflowHazardModel();

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private fileService: FileService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedWorkflowsHazardsEditComponent>,
    public router: Router,
    public renderer: Renderer
  ) { }

  ngOnInit() {

    // get the task analysis workflow note id
    this.ta_revised_workflow_hazard.id = this.dialogData.ta_revised_workflow_hazard.id;

    // get the task analysis workflow id
    this.ta_revised_workflow_id = this.dialogData.ta_revised_workflow_id;

    // get the task analysis id
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.ta_revised_workflow_hazard.id) {
      this.find(this.ta_revised_workflow_hazard.id);
    }

    if(this.ta_revised_id) {
      this.findTa(this.ta_revised_id);
    }
  }

  private redirectBack() {
      this.router.navigate([`/task-analyses-revised/`]);
  }

  private find(ta_revised_workflow_hazard_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${ta_revised_workflow_hazard_id}`, [], {})
      .then((response) => {
        this.ta_revised_workflow_hazard = response;

        if (typeof this.ta_revised_workflow_hazard.controls == 'string') {
          this.ta_revised_workflow_hazard.controls = JSON.parse(this.ta_revised_workflow_hazard.controls);
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private findTa(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {})
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  async onSubmitHazard(form: NgForm, closeDialog:boolean = true) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(!this.ta_revised_workflow_hazard.id) {
      this.create();
    } else {
      this.update(closeDialog);
    }
  }

  onSelectReviewer() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised_workflow_hazard.reviewer_id],
        selectedAccountId: this.app.account.id,
        visitors_from_all_sites: true
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.ta_revised_workflow_hazard.reviewer_id = userId;
      }
    );
  }

  onRestoreFromRef() {
    this.utils.showModal('Restore Step Hazard/Risk from ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' Hazard/Risk used to create it', 'Are you sure you want to restore the TA/JSA/SWMS Template Workflow Step Hazard/Risk? This will overwrite all data and replace with Reference data!', () => {
      this.inProgress = true;
      this.api
        .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards-restore`, this.ta_revised_workflow_hazard, {})
        .then((response) => {
          this.utils.showToast(`The TA/JSA/SWMS Template Workflow Step Hazard has been restored.`);
        })
        .finally(() => {
          this.find(this.ta_revised_workflow_hazard.id);
          this.inProgress = false;
        });
    });
  }

  getRiskAssessmentColorChange(likelihood: number, severity: number) {
    const riskAssessment = this.utils.getRiskAssessmentText(
      likelihood,
      severity
    );

    if (['Very Low', 'Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High', 'Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  private create() {
    let request: Promise<any>;

    request = this.api
      .makeRequest('post', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards`, this.ta_revised_workflow_hazard, {})
      .then((response) => {
        this.onSuccess(`A new TA/JSA/SWMS Template Workflow Step Hazard/Risk was created.`);
        this.ta_revised_workflow_hazard = response;

        // Convert hazard controls from a JSON encoded string to an array.
        if (typeof this.ta_revised_workflow_hazard.controls == 'string') {
          this.ta_revised_workflow_hazard.controls = JSON.parse(this.ta_revised_workflow_hazard.controls);
        }
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_step_hazard/${this.ta_revised_workflow_hazard.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            this.inProgress = false;
            this.close(false);
          });
      } else {
        this.inProgress = false;
        this.close(false);
      }
    });
  }

  private update(closeDialog:boolean = true) {
    let request: Promise<any>;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${this.ta_revised_workflow_hazard.id}`, this.ta_revised_workflow_hazard, {})
      .then((response) => {
        this.onSuccess(`The TA/JSA/SWMS Template Workflow Step Hazard/Risk was updated.`);
        this.ta_revised_workflow_hazard = response;

        // Convert hazard controls from a JSON encoded string to an array.
        if (typeof this.ta_revised_workflow_hazard.controls == 'string') {
          this.ta_revised_workflow_hazard.controls = JSON.parse(this.ta_revised_workflow_hazard.controls);
        }
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_step_hazard/${this.ta_revised_workflow_hazard.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            if(!closeDialog) {
              this.find(this.ta_revised_workflow_hazard.id);
              this.inProgress = false;
            } else {
              this.close(false);
            }
          });
      } else {
        if(!closeDialog) {
          this.find(this.ta_revised_workflow_hazard.id);
          this.inProgress = false;
        } else {
          this.close(false);
        }
      }
    });
  }

  public onDelete() {
    this.utils.showModal('Delete TA/JSA/SWMS Template Workflow Step Hazard/Risk', 'Are you sure you want to delete the TA/JSA/SWMS Template Workflow Step Hazard/Risk?', () => {
      this.api.makeRequest('delete', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${this.ta_revised_workflow_hazard.id}`, [], {})
        .then(() => {
          this.onSuccess('The TA/JSA/SWMS Template Workflow Step Hazard/Risk was deleted.');
          this.close(false);
        });
    });
  }

  onAddControl() {
    this.ta_revised_workflow_hazard.controls.push(new HazardControlModel());
  }

  onRemoveControl(i: number) {
    this.ta_revised_workflow_hazard.controls.splice(i, 1);
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    // this.dialogRef.close(true);
  }

  private close(shouldCloseAll: boolean = false) {
    this.inProgress = false;

    // Close all dialogs when a new TA version is created.
    if ( shouldCloseAll ) {
      this.dialog.closeAll();
    } else {
      this.dialogRef.close();
    }

  }

  /**
   * If it is a new task analysis, add the files to the selected files array.
   * Existing task analysis files will be handled by the file manager.
   * @param files? List of files.
   */
  onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
    }
  }

}
