import {AfterViewInit, Component, ElementRef, OnInit, Renderer, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {AccountTagsEditComponent} from "../account-tags-edit/account-tags-edit.component";
import {TagModel} from "../../models/tag.model";
import {AccountTagsViewComponent} from "../account-tags-view/account-tags-view.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {AccountTagsFilterComponent} from "../account-tags-filter/account-tags-filter.component";

@Component({
  selector: 'app-account-tags',
  templateUrl: './account-tags.component.html',
  styleUrls: ['./account-tags.component.scss']
})
export class AccountTagsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource = new AccountTagsDataSource(this.app, this.api);

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  displayedColumns = [
    'select',
    'id',
    'title',
    'created_by_user',
    'date_created',
    'buttons'
  ];

  constructor(
    private utils: UtilsService,
    private app: AppService,
    private api: ApiRequestService,
    private dialog: MatDialog,
    public renderer: Renderer
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }


      this.dataSource.getData(true);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new TagModel());
  }

  onEdit(tag: TagModel) {
    this.dialog
      .open(AccountTagsEditComponent, {
        width: '900px',
        data: tag
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      AccountTagsViewComponent,
      id,
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Archive Tag(s)',
      `Are you sure you want to archive ${this.dataSource.selection.selected.length} tag(s)?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(tag: TagModel) {
    this.utils.showModal(
      'Archive Tag',
      `Are you sure you want to archive "${tag.title}"?`,
      () => {
        this.remove([tag.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/tags/${ids.join(',')}`)
      .then((response) => {
        this.utils.showToast('Tags have been archived');
        this.dataSource.selection.clear();
        this.dataSource.getData(true);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      AccountTagsFilterComponent,
      {
        filter_archived: this.dataSource.archived
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.archived = results.archived ? results.archived : this.dataSource.archived;
          this.dataSource.getData(true);
        }
      }
    );
  }

  onRestore(id: number) {
    this.utils.showModal(
      'Restore Tag',
      'Are you sure you want to restore this tag?',
      () => {
        this.api.makeRequest('put', `v2/tags/${id}/restore`)
          .then((response) => {
            this.utils.showToast('The tag was restored.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  onRestoreSelected() {
    this.utils.showModal(
      'Restore Selected Tags',
      'Are you sure you want to restore the selected tags?',
      () => {
        this.api.makeRequest('put', `v2/tags/${this.dataSource.selection.selected.join(',')}/restore`)
          .then((response) => {
            this.utils.showToast('The selected tags was restored.');
            this.dataSource.selection.clear();
            this.dataSource.getData(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  /**
   * Exports the list of tools into the specified format and sends a download request to the browser.
   * @param type csv, xls, xlsx, pdf.
   */
  onExportSelected(type: string = 'csv') {
    this.dataSource.makeDownloadRequest(`v2/tags/export/${type}` + (this.dataSource.selection.selected.length ? '/' + this.dataSource.selection.selected.join(',') : ''), {
      searchBy: this.dataSource.searchBy,
      archived: this.dataSource.archived
    })
      .then((response) => {
        // For IE and Edge browsers.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `tags-${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onTriggerFileSelector(evt: Event) {
    const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInput.nativeElement,
      'dispatchEvent',
      [clickEvt]
    );
  }

  onImport(evt: any) {
    if (evt.target.files.length > 0) {

      const files: any = [];
      for (let i = 0; i < evt.target.files.length; i++) {
        files.push(evt.target.files[i]);
      }

      this.utils.showModal('Import Tags', 'Are you sure you want to import the tags from the selected spreadsheet? It is recommended to use the same format as the tag exports. Any duplicate tags will be ignored.', () => {
        this.api.makeUploadRequest(`v2/tags/import`, files)
          .then((response) => {
            this.utils.showToast(response);
            this.dataSource.getData(true);
          })
          .catch((errorResponse) => {
            this.utils.showModal('Error Message', errorResponse.error);
          });
      });

      // clear the target value
      evt.target.value = '';
    }
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class AccountTagsDataSource extends ApiDataSource {
  order_by = 'id';
  order = 'asc';
  searchBy = 'title';
  archived = "false";

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/tags', resetOffset, {
      searchBy: this.searchBy,
      archived: this.archived
    });
  }
}
