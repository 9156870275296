import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ItemModel } from 'src/app/items/item.model';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { PrequalificationService } from '../prequalification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-prequalifications-edit',
  templateUrl: './prequalifications-edit.component.html',
  styleUrls: ['./prequalifications-edit.component.scss']
})
export class PrequalificationsEditComponent implements OnInit {
  selectedItems: string[];
  items: ItemModel[];
  template = new PrequalificationModel();
  templates: PrequalificationModel[];

  /**
   * Is the prequalification already being created?
   */
  inProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public prequalification: PrequalificationModel,
    private api: ApiService,
    public util: UtilsService,
    private router: Router,
    private location: Location,
    private prequalService: PrequalificationService,
    private dialogRef: MatDialogRef<PrequalificationsEditComponent>
  ) { }

  ngOnInit() {
    this.api
      .laravelApiObservable('get', 'items')
      .subscribe((items: ItemModel[]) => (this.items = items));
    if (!this.prequalification.items) { return; }
    // this.selectedItems = this.prequalification.items.map((item) => item.name);
    this.prequalification.items = this.prequalification.items.map((item) => (item.id || item));
  }

  onSubmit() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    // this.mapItems();
    this.prequalification.template = true;
    if (!this.prequalification.id) {
      this.api
        .laravelApiObservable(
          'post',
          'prequalifications',
          this.prequalification
        )
        .subscribe((prequalification) => {
          this.onSubmitSuccess(prequalification);
          this.dialogRef.close(true);
        },
        (_error) => this.inProgress = false,
        () => this.inProgress = false
      );
    }
    else {
      this.api
        .laravelApiObservable(
          'put',
          `prequalifications/${this.prequalification.id}`,
          this.prequalification
        )
        .subscribe((prequalification) =>
          this.onSubmitSuccess(prequalification),
          (_error) => this.inProgress = false,
          () => this.inProgress = false
        );
    }
  }

  public delete() {
    this.util.showModal(
      'Remove Pre-Qualification',
      `Are you sure you want to remove the "${this.prequalification.name}" Pre-Qualification template?`,
      () => {
        this.prequalService
          .remove([this.prequalification.id])
          .subscribe((result) => {
            this.util.showToast(
              `The Pre-Qualification template "${result.name}" was removed`
            );
            this.router.navigate(['/prequalifications']);
          });
      }
    );
  }

  public templateChosen() {
    this.prequalification = Object.assign({}, this.template);
    this.prequalification.id = null;
    this.prequalification.template = false;
    this.selectedItems = this.template.items.map((item) => item.name);
  }

  public back() {
    this.location.back();
  }

  // private mapItems() {
  //   if (!this.selectedItems) { return; }
  //   this.prequalification.items = this.items
  //     .filter((item) =>
  //       this.selectedItems.some((selection) => item.name === selection)
  //     )
  //     .map((item) => item.id);
  // }

  private onSubmitSuccess(prequalification: PrequalificationModel): void {
    this.util.showToast(`The Pre-Qualification template "${prequalification.name}" was saved.`);
    // this.dialogRef.close(true);
  }

  onToggleItem(itemId: number) {
    const index = this.prequalification.items.indexOf(itemId);
    if (index === -1) {
      this.prequalification.items.push(itemId);
    } else {
      this.prequalification.items.splice(index, 1);
    }
  }

  isItemSelected(itemId: number) {
    if (this.prequalification.items && this.prequalification.items.length) {
      return this.prequalification.items.indexOf(itemId) > -1
    } else {
      return false;
    }
  }
}
