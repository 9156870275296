import { CurrentTimezoneStateService } from './../../shared/current-timezone-state.service';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { GlobalAccountsSelectorComponent } from 'src/app/shared/global-accounts-selector/global-accounts-selector.component';
import { AccountModel } from 'src/app/models/account.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import {TradeModel} from "../../admin/trades/trades.model";
import { StorageService } from './../../shared/storage.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  defaultAccount = new AccountModel();

  // property to hold current timezone
  currentTimezone: string;

  inProgress: boolean = false;

  trades: TradeModel[] = [];

  // Mobile field Country Code selector values
  mobile_input_id: string = 'phone-1';
  mobile_number: string = this.app.user.mobile;
  mobile_country_code: string = this.app.user.mobile_country_code;
  mobile_dial_code: string = this.app.user.mobile_dial_code;
  mobile_e164: string = this.app.user.mobile_e164;
  mobile_error_state: boolean = false;

  constructor(
    public renderer: Renderer,
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private storage: StorageService,
    private cTmzState: CurrentTimezoneStateService
  ) {}

  ngOnInit() {
    this.getEmployer();

    // Get trades from the API.
    this.getTrades();

    this.currentTimezone = this.cTmzState.getCurrentTimezone();
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.mobile_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.mobile_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.mobile_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.mobile_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.mobile_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  private async getTrades() {
    this.trades = await this.utils.getTrades();
  }

  /**
   * Open a dialog to allow the user to search for their employer.
   * The user can select an existing account or enter a new account name in the search box.
   */
  onSelectEmployer() {
    this.utils.showComponentDialog(GlobalAccountsSelectorComponent, {
      selected: [this.app.user.account_id],
      multiple: false,
      search: this.app.user.employer.name
    },
    {
      width: '1024px'
    },
    (response: number|string) => {
      // Terminate the request if the dialog was closed with selecting or entering a value.
      if ( typeof response == 'undefined' ) {
        return;
      }
      // Do final confirmation.
      this.utils.showModal('Confirmation Required', 'Are you sure you want to change your employer?', () => {
        // Make a request to update the user's employer.
        this.api.makeRequest('put', 'v2/user/employer', {
          account_id: typeof response === 'number' ? response : null,
          search: typeof response !== 'number' ? response : null
        })
        .then((response) => {
          this.utils.showToast('Your employer was successfully changed.');
          this.getEmployer();
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });

      });
    });
  }

  /**
   * Submit the user profile update form.
   * @param form The NgForm instance.
   */
  onSubmit( form: NgForm ) {
    // Check form validation.
    if (!form.valid) {
      this.utils.showModal('Error', 'Please enter all required fields.');
      return;
    }

    // Check Error states of phone input then continue submission
    if (this.mobile_error_state) {
      if(this.mobile_number.length < 3) {
        this.utils.showModal('Error','A Phone number must be at least 3 characters long.');
      } else {
        this.utils.showModal('Error', 'Please enter all required fields.');
      }
      return;
    }

    // Check if another request is already in progress.
    if ( this.inProgress ) {
      return;
    }

    // Set in progress state to true.
    this.inProgress = true;
    // Set the current timezone in local storage.
    this.storage.set(this.cTmzState.timezoneKey, this.currentTimezone);
    // Update teh timezone state.
    this.cTmzState.setCurrentTimezone(this.currentTimezone);

    // Update User details with Mobile field Country Code selector values
    this.app.user.mobile = this.mobile_number;
    this.app.user.mobile_country_code = this.mobile_country_code;
    this.app.user.mobile_dial_code = this.mobile_dial_code;
    this.app.user.mobile_e164 = this.mobile_e164;

    // Make the API request to update the user profile.
    this.api.makeRequest('put', 'v2/user', this.app.user)
    .then((response) => {
      // Apply the response data.
      this.app.user.apply(response);
      this.utils.showToast('Your user profile was updated.');
      this.inProgress = false;
    })
    .catch((errorResponse) => {
      // Handle API errors.
      this.utils.handleAPIErrors(errorResponse);
      this.inProgress = false;
    });
  }

  private getEmployer() {
    this.api.makeRequest('get', 'v2/user/employer')
    .then((response) => {
      this.app.user.employer.apply(response);
      this.app.user.account_id = response.id;
    });
  }

  /**
   *
   * @param evt
   *
   * Click an ion-button that in turn triggers the file input type to select a file.
   * when a file is selected, it is uploaded.
   *
   * http://pjsdev.blogspot.co.nz/2016/05/ionic-2-beta-6-angular-2-styled-upload.html
   */
  onTriggerFileSelector(evt: Event) {
    const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInput.nativeElement,
      'dispatchEvent',
      [clickEvt]
    );
  }

  // When a file is selected, store it.
  onUploadSelectedFile(evt: any) {
    this.api.makeUploadRequest('v2/user/profile-picture', evt.target.files[0])
    .then((response) => {
      this.app.user.apply(response);
      this.utils.showToast('Your profile picture was updated.');
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  // When a file is selected, store it.
  onRemoveProfilePicture() {
    this.api.makeRequest('delete', 'v2/user/profile-picture')
    .then((response) => {
      this.app.user.apply(response);
      this.utils.showToast('Your profile picture was removed.');
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  getOutputTimezone($event) {
    this.currentTimezone = $event;
  }

  removeSelectedTimezone() {
    //remove timezone from localstorage
    this.storage.delete(this.cTmzState.timezoneKey);
    // reset current timezone to local timezone
    this.currentTimezone = this.cTmzState.localTimezone;
    // reset timezone state to local timezone
    this.cTmzState.setCurrentTimezone(this.cTmzState.localTimezone);
  }

}
