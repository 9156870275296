import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {TaskAnalysesRevisedWorkflowModel} from "../../models/task-analyses-revised-workflow.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {FileService} from "../../shared/file.service";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-task-analyses-revised-workflows-edit',
  templateUrl: './task-analyses-revised-workflows-edit.component.html',
  styleUrls: ['./task-analyses-revised-workflows-edit.component.scss']
})
export class TaskAnalysesRevisedWorkflowsEditComponent implements OnInit {

  inProgress = false;
  selectedFiles: File[] = [];
  newFiles: FileList;
  path: string;
  ta_revised_id: number;
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();
  ta_revised_workflow: TaskAnalysesRevisedWorkflowModel = new TaskAnalysesRevisedWorkflowModel();

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private fileService: FileService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedWorkflowsEditComponent>,
    public router: Router,
    public renderer: Renderer
  ) { }

  ngOnInit() {
    this.ta_revised_workflow.id = this.dialogData.ta_revised_workflow.id;

    // get the task analysis id from the parent route params
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.ta_revised_workflow.id) {
      this.find(this.ta_revised_workflow.id);
    }

    if(this.ta_revised_id) {
      this.findTa(this.ta_revised_id);
    }
  }

  async onSubmit(form: NgForm, closeDialog:boolean = true) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(!this.ta_revised_workflow.id) {
      this.create();
    } else {
      this.update(closeDialog);
    }
  }

  getRiskAssessmentColorChange(likelihood: number, severity: number) {
    const riskAssessment = this.utils.getRiskAssessmentText(
      likelihood,
      severity
    );

    if (['Very Low', 'Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High', 'Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  private find(ta_revised_workflow_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${ta_revised_workflow_id}`, [], {})
      .then((response) => {
        this.ta_revised_workflow = response;

        // Extract File ids.
        this.ta_revised_workflow.file_ids = this.ta_revised_workflow.files ? this.ta_revised_workflow.files.map((file) => file.id) : [];

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private findTa(ta_revised_id: number) {

    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {})
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private create() {
    let request: Promise<any>;
    let upload_ta_revised_workflow_id: number = 0;

    request = this.api
      .makeRequest('post', `v2/task-analyses-revised/${this.ta_revised_id}/workflows`, this.ta_revised_workflow, {})
      .then((response) => {
        this.onSuccess(`New TA/JSA/SWMS Template Workflow Step created.`);
        this.ta_revised_workflow.apply(response);
        upload_ta_revised_workflow_id = response.id;
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_workflow/${upload_ta_revised_workflow_id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            this.close(true);
          });
      } else {
        this.close(true);
      }
    });
  }

  private update(closeDialog:boolean = true) {
    let request: Promise<any>;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow.id}`, this.ta_revised_workflow, {})
      .then((response) => {
        this.onSuccess(`TA/JSA/SWMS Template Workflow Step updated.`);
        this.ta_revised_workflow.apply(response);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_workflow/${this.ta_revised_workflow.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            if(!closeDialog) {
              this.find(this.ta_revised_workflow.id);
              this.inProgress = false;
            } else {
              this.close(false);
            }
          });
      } else {
        if(!closeDialog) {
          this.find(this.ta_revised_workflow.id);
          this.inProgress = false;
        } else {
          this.close(false);
        }
      }
    });
  }

  private redirectBack() {
    this.router.navigate([`/task-analyses-revised/`]);

    // Close all dialogs when a new TA version is created.
    this.dialog.closeAll();
  }

  private close(shouldCloseAll: boolean = false) {
    this.inProgress = false;

    // Close all dialogs when a new TA version is created.
    if ( shouldCloseAll ) {
      this.dialog.closeAll();
    } else {
      this.dialogRef.close();
    }

  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    // this.dialogRef.close(true);
  }

  /**
   * If it is a new task analysis, add the files to the selected files array.
   * Existing task analysis files will be handled by the file manager.
   * @param files? List of files.
   */
  onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
    }
  }

}
