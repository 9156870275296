import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatDialogModule,
  MatMenuModule,
  MatRadioModule,
  MatSlideToggleModule
} from '@angular/material';
import { ItemsModule } from '../items/items.module';
import { PipesModule } from '../pipes/pipes.module';
import { PrequalificationsEditComponent } from './prequalifications-edit/prequalifications-edit.component';
import { PrequalificationsRoutingModule } from './prequalifications-routing.module';
import { PrequalificationsViewComponent } from './prequalifications-view/prequalifications-view.component';
import { PrequalificationsComponent } from './prequalifications.component';
import { DirectivesModule } from '../directives/directives.module';
import { MyItemsModule } from '../my-items/my-items.module';

export const MATERIAL_MODULES = [
  MatIconModule,
  MatSelectModule,
  MatButtonModule,
  MatInputModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatListModule,
  MatCheckboxModule,
  MatDialogModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PrequalificationsRoutingModule,
    ...MATERIAL_MODULES,
    PipesModule,
    ItemsModule,
    DirectivesModule,
    MyItemsModule,
    MatMenuModule,
    MatSlideToggleModule
  ],
  declarations: [
    PrequalificationsComponent,
    PrequalificationsEditComponent,
    PrequalificationsViewComponent
  ],
  exports: [PrequalificationsComponent],
  entryComponents: [PrequalificationsEditComponent]
})
export class PrequalificationsModule {}
