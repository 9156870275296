import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { EmployeesSelectorComponent } from 'src/app/employees/employees-selector/employees-selector.component';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-managers',
  templateUrl: './sites-managers.component.html',
  styleUrls: ['./sites-managers.component.scss']
})
export class SitesManagersComponent implements OnInit, AfterViewInit {
  // the site id must be present
  parent_id: number;
  child_id: number;
  site_id: number;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'contact_person',
    'email',
    'mobile',
    'trade',
    'actions'
  ];
  dataSource: SitesManagersDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    // public service: ContractorsService,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
      // load a multi select data source object
      this.dataSource = new SitesManagersDataSource(this.app, this.api, true, {
        site_id: this.site_id
      });
      // get the data only when the site id is present
      this.dataSource.getData();
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   *
   * @param id the contractor id
   *
   * unlink a single contractor
   */
  onRemove(id: number) {
    this.utils.showModal(
      'Unlink Manager',
      'Are you sure you want to unlink this Manager?',
      () => {
        this.onUpdateSiteManagers(
          this.dataSource.employee_ids.filter(
            (manager_id) => ![id].includes(manager_id)
          ),
          (response) => {
            this.dataSource.selection.deselect(id);
          }
        );
      }
    );
  }

  /**
   * unlink selected contractors from the specified site
   */
  onDeleteSelected() {
    this.utils.showModal(
      'Unlink Selected Managers',
      'Are you sure you want to unlink the selected Managers?',
      () => {
        this.onUpdateSiteManagers(
          this.dataSource.employee_ids.filter(
            (contractor_id) =>
              !this.dataSource.selection.selected.includes(contractor_id)
          ),
          (response) => {
            this.dataSource.selection.clear();
          }
        );
      }
    );
  }

  /**
   * open a dialog allowing the user to select contractors to be linked to sites.
   */
  onLinkContractorBusiness() {
    // show the site selector
    this.utils.showComponentDialog(EmployeesSelectorComponent, {
        multiple: true,
        selected: this.dataSource.employee_ids
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.employee_ids = results;

          if (this.site_id) {
            this.onUpdateSiteManagers(this.dataSource.employee_ids);
          }
        }
      }
    );
  }

  onUpdateSiteManagers(employee_ids: any, callback?: any) {
    if (this.site_id) {
      // link all contractor businesses
      this.api.laravelApiRequest('put', 'sites/' + this.site_id + '/managers', {
          selected_employee_ids: employee_ids
        },
        {},
        (response) => {
          // reload the data and reset the offset
          this.dataSource.getData(true);

          if (typeof callback === 'function') {
            callback(response);
          }
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }

  composeNewMessage() {
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showToast('You need to select some Managers first.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: this.dataSource.selection.selected
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }
}

export class SitesManagersDataSource extends CustomDataSource {

  sort_by = 'contact_person';
  sort_order = 'asc';

  employee_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('sites/' + this['site_id'] + '/managers', resetOffset, (response) => {
      this.employee_ids = response.meta.user_ids;
    });
  }
}
