import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { tap } from 'rxjs/operators';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ApiRequestService } from '../api-request.service';

@Component({
  selector: 'app-upstream-accounts-selector',
  templateUrl: './upstream-accounts-selector.component.html',
  styleUrls: ['./upstream-accounts-selector.component.scss']
})
export class UpstreamAccountsSelectorComponent implements OnInit {

  // Columns to show in the view
  displayedColumns: string[] = [
    'name'
  ];

  // The data source
  dataSource: UpstreamAccountsSelectorDataSource;

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public dialogRef: MatDialogRef<UpstreamAccountsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    // Set multiple if it is not present and default it to false
    if ( typeof this.data['multiple'] == 'undefined' || typeof this.data['multiple'] != 'boolean' ) {
      this.data['multiple'] = false;
    }

    if ( this.data['multiple'] ) {
      this.displayedColumns.unshift('select');
    } else {
      this.displayedColumns.push('actions');
    }

    // Construct the datasource
    this.dataSource = new UpstreamAccountsSelectorDataSource(this.app, this.api, this.data['multiple']);

    // Set a default selection if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // Loop and select the values in the data source
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(this.data['selected'][i]);
    }

    // Get the data
    this.dataSource.getData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData(true);
    })).subscribe();
  }

  onSelectSingle(id: number) {
    this.dialogRef.close(id);
  }

  onSelectMultiple() {
    this.dialogRef.close(this.dataSource.selection.selected);
  }

  // onSelectAllRecords() {
  //   this.dialogRef.close(this.dataSource.ids);
  // }

  onDeselectAllRecords() {
    this.dataSource.selection.clear();
  }
}

/**
 * The networked accounts selector data source.
 */
export class UpstreamAccountsSelectorDataSource extends ApiDataSource {

  order_by = 'title';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/user/upstream-accounts`, resetOffset);
  }
}
