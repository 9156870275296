import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input, Renderer
} from '@angular/core';

@Component({
  selector: 'app-files-selector',
  templateUrl: './files-selector.component.html',
  styleUrls: ['./files-selector.component.scss']
})
export class FilesSelectorComponent {

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  @Output() selected = new EventEmitter<FileList>();
  @Input() raisedButton = true;

  @Input() selectedFilesCount: number = 0;

  constructor(
    private renderer: Renderer
  ) {
  }

  /**
   * Trigger the file input event.
   * @param evt
   */
  onTriggerFileSelector(evt: Event) {
    const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.fileInput.nativeElement,
      'dispatchEvent',
      [clickEvt]
    );
  }

  async changeFiles(evt: any) {
    this.selected.emit(evt.target.files);
  }
}
