import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {IndustriesModel} from "../../admin/industries/industries.model";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../api-request.service";
import {UtilsService} from "../utils.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort} from "@angular/material";
import {tap} from "rxjs/operators";
import { ToolsEditComponent } from 'src/app/tools/tools-edit/tools-edit.component';
import { ToolModel } from 'src/app/tools/tools.model';

@Component({
  selector: 'app-tools-selector',
  templateUrl: './tools-selector.component.html',
  styleUrls: ['./tools-selector.component.scss']
})
export class ToolsSelectorComponent implements OnInit {

  industries: IndustriesModel[] = [];

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'description',
    'industry',
    'date_created',
    // 'date_created_UTC',
  ];
  dataSource: ToolsSelectorDataSource;
  returnAllSelected = false;

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<ToolsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // set multiple if it is not present to false
    if (typeof this.data['multiple'] === 'undefined') {
      this.data['multiple'] = false;
    }

    // construct the datasource
    this.dataSource = new ToolsSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // set a default selection of nothing if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // loop and select the values
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }

    this.returnAllSelected = this.data.returnAllSelected;
  }

  ngOnInit() {
    // Get Industries
    this.getIndustries();

    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Sort UTC by its corresponding date column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  // clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * before the dialog close we want to store the new active work permit id
   */
  onSaveAndClose() {
    if (this.returnAllSelected) {
      return this.dialogRef.close({
        selected: this.dataSource.selection.selected,
        isAllSelected: this.dataSource.isAllSelected()
      });
    }

    let response: number | number[];

    if (this.data['multiple']) {
      response = this.dataSource.selection.selected;
    } else {
      if (this.dataSource.selection.selected.length) {
        response = this.dataSource.selection.selected[0];
      } else {
        response = 0;
      }
    }

    this.dialogRef.close(response);
  }

  onCreateNewTool() {
    // Create a new tool.
    this.utils.showComponentDialog(ToolsEditComponent, new ToolModel())
    .then((response) => {
      // Check if the response is a tool with an id
      if ( response && typeof response.id != 'undefined' && response.id ) {
        this.dataSource.selection.select(response.id);
      }

      // Refresh the tools selector list.
      this.dataSource.getData();
    });
  }

}

export class ToolsSelectorDataSource extends ApiDataSource {
  order_by = 'title';
  order = 'asc';
  searchBy = 'title';
  industry: string = '';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/tools', resetOffset, {
      searchBy: this.searchBy,
      include_archived: true,
      industryFilter: this.industry
    });
  }
}
