import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UserModel } from 'src/app/models/user.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { DynamicFormsAssignedUsersFormViewComponent } from '../dynamic-forms-assigned-users-form-view/dynamic-forms-assigned-users-form-view.component';
import * as moment from 'moment';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-dynamic-forms-assigned-users',
  templateUrl: './dynamic-forms-assigned-users.component.html',
  styleUrls: ['./dynamic-forms-assigned-users.component.scss']
})
export class DynamicFormsAssignedUsersComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'contact_person',
    'email',
    'dynamic_form_status',
    'dynamic_form_verified',
    'assigned_by_user',
    'assigned_on',
    // 'assigned_on_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];

  dataSource = new DynamicFormsAssignedUsersDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<DynamicFormsAssignedUsersComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { form_id?: number }
  ) { }

  ngOnInit() {
    if ( this.dialogData.form_id ) {
      this.dataSource.form_id = this.dialogData.form_id;
      this.dataSource.getData(true)
      .then(() => {
        // If the user id is present in the query params, then open the user's submitted answers.
        const user_id: number = Number(this.route.snapshot.queryParamMap.get('user_id'));
        if ( user_id ) {
          // Open the user's submitted answers.
          this.onViewUserSubmittedAnswers(user_id);
          // Remove the user id from the browser location query params.
          this.utils.removeBrowserLocationQueryParam('user_id');
        }
      });
    } else {
      this.utils.showToast('The form id is not valid.');
      this.dialogRef.close();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC column by its corresponding date column.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      // Sort UTC column by its corresponding date column.
      if ( sorter['active'] == 'date_modified_UTC' ) {
        this.dataSource.order_by = 'date_modified';
      }

      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Open the form view that will show user submitted answers.
   * @param user_id The user id that you want to view.
   */
  onViewUserSubmittedAnswers(user_id: number = 0) {
    // Open the dialog.
    this.utils.showComponentDialog(DynamicFormsAssignedUsersFormViewComponent, {
      form_id: this.dataSource.form_id,
      user_id: user_id
    }, {
      minWidth: '60%'
    })
    .then(() => {
      // Refresh the list when the dialog closes.
      this.dataSource.getData();
    });
  }

  /**
   * Open the networked accounts selector to allow the user to filter users by account.
   */
  onFilterByAccount() {
    // Open the networked accounts selector.
    this.utils.showComponentDialog(NetworkedAccountsSelectorComponent, {
      multiple: true,
      selected: this.dataSource.account_ids
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        // Store the account ids before refreshing the list.
        this.dataSource.account_ids = response;
        // Reload the list
        this.dataSource.getData(true);
      }
    });
  }

  /**
   * Open the networked users selector to allow admins to assign users to this form.
   */
  onAssignUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      multiple: true,
      visitors_from_all_sites: true
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        // Make a request to assign users to the form.
        this.api.makeRequest('post', `v2/dynamic-forms/${this.dialogData.form_id}/assign-users/${response.join(',')}`)
        .then((response) => {
          this.utils.showToast(response.message);
          // Reload the list
          this.dataSource.getData(true);
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
      }
    });
  }

  /**
   * Show a quick action confirmation dialog before unassigning the user from the form.
   * @param user The user data.
   * @param evt The referenced http element to attach the quick action to.
   */
   onUnassignUser(user: UserModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to unassign "${user.contact_person}" from this form?`, [
      {
        text: 'Yes',
        handler: () => {
          this.unassignSelectedUsers([user.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Open a confirmation dialog before unassigning the selected users.
   * userIds can either be an array of collected ids or an array of collected strings.
   * The array of collected strings should only contain one item called ['all'].
   */
  onUnassignSelectedUsers(userIds: number[] | string[] = this.dataSource.selection.selected) {
    this.utils.showModal(
      'Unassign Selected Users',
      'Are you sure you want to unassign the selected users from this form?',
      () => {
        const unassigned = this.unassignSelectedUsers(userIds);
        if ( unassigned != null ) {
          unassigned.then(() => {
            // Clear the list selection after archiving the selected forms.
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Unassign the selected users from this form.
   * @param userIds The selected user ids.
   * @returns a Promise which is used for callbacks.
   */
  private unassignSelectedUsers(userIds: number[] | string[]): null|Promise<any> {
    if ( !userIds.length ) {
      this.utils.showModal('Unassigning Selected Users', 'You need to select at least one user to unassign.');
      return;
    }

    return this.api.makeRequest('delete', `v2/dynamic-forms/${this.dialogData.form_id}/unassign-users/${userIds.join(',')}`)
    .then(() => {
      this.dataSource.getData();
      this.utils.showToast('The selected users were unassigned.');
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  /**
   * Validates and checks what the status of the form submission is. It then
   * returns a corresponding color e.g.
   * - red = Assigned or Rejected.
   * - orange/yellow = In Progress or Work in Progress.
   * - green = Submitted or Completed.
   * - blue = Approved.
   * @param user The user data to validate and check.
   * @returns
   */
   getFormSubmissionStatusColors(user: UserModel) {
    return {
      'text-danger': user && user.pivot && user.pivot.dynamic_form_status && ['Assigned', 'Rejected'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-warning': user && user.pivot && user.pivot.dynamic_form_status && ['Pending', 'In Progress', 'Approved: Work in Progress'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-success': user && user.pivot && user.pivot.dynamic_form_status && ['Submitted', 'Work Completed'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-info': user && user.pivot && user.pivot.dynamic_form_status && ['Approved'].indexOf(user.pivot.dynamic_form_status) > -1
    };
  }

  /**
   * Export the selected forms captured data into a PDF or CSV for reporting.
   * CSVs are exported in the background and combined into one XLSX file.
   * @returns
   */
   onExportFormsData(export_type: string = 'pdf') {
    // Check if there are any forms selected.
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showModal('Export Form Structures', 'You need to select users to export their form submissions.');
      return;
    }
    // Make a request to export both CSV and PDFs in the background.
    this.api.makeRequest('get', `v2/dynamic-forms/export-data/${export_type}/${this.dataSource.form_id}`, {}, {
      user_ids: this.dataSource.selection.selected.join(',')
    })
      .then((response) => {
        this.utils.showToast(response.message);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class DynamicFormsAssignedUsersDataSource extends ApiDataSource {

  order_by = 'contact_person';
  order = 'asc';

  form_id: number;

  dynamic_form_status: string = '';

  // List of accounts to filter the users by.
  account_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    return this.makeRequest(`v2/dynamic-forms/assigned-users/${this.form_id}`, resetOffset, {
      dynamic_form_status: this.dynamic_form_status,
      account_ids: this.account_ids.join(',')
    });
  }
}
