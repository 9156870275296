<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Safety Observations</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close the Safety Observations filter." mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 200px;">
        <mat-select placeholder="Category" [(ngModel)]="dataSource.filter_so_category"
                    name="category"
                    matTooltip="Filter safety observations by category.">
          <mat-option value="">All Categories</mat-option>
          <mat-option *ngFor="let so_category of utils.getSafetyObservationCategories()" [value]="so_category">
            {{so_category}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 200px;">
        <mat-select placeholder="Type" [(ngModel)]="dataSource.filter_so_type"
                    name="type"
                    matTooltip="Filter safety observations by type.">
          <mat-option value="">All Types</mat-option>
          <mat-option *ngFor="let so_type of utils.getSafetyObservationTypes()" [value]="so_type">
            {{so_type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="priority">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Priority</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 130px;">
        <mat-select placeholder="Priority" [(ngModel)]="dataSource.filter_so_priority"
                    name="priority"
                    matTooltip="Filter safety observations by priority.">
          <mat-option value="">All Priorities</mat-option>
          <mat-option *ngFor="let so_priority of utils.getSafetyObservationPriorities()" [value]="so_priority">
            {{so_priority}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 150px;">
        <mat-select placeholder="Status" [(ngModel)]="dataSource.filter_so_status"
                    name="status"
                    matTooltip="Filter safety observations by status.">
          <mat-option value="">All Statuses</mat-option>
          <mat-option *ngFor="let so_status of utils.getSafetyObservationStatuses()" [value]="so_status">
            {{so_status}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="rating">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Rating</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 100px;">
        <mat-select placeholder="Rating" [(ngModel)]="dataSource.filter_so_rating"
                    name="rating"
                    matTooltip="Filter safety observations by rating.">
          <mat-option value="">Ratings</mat-option>
          <mat-option [value]="1">Very Bad</mat-option>
          <mat-option [value]="2">Bad</mat-option>
          <mat-option [value]="3">Neutral</mat-option>
          <mat-option [value]="4">Good</mat-option>
          <mat-option [value]="5">Very Good</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectSites()"
        [matBadge]="'' + (dataSource.selected_site_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectContractors()"
        [matBadge]="'' + (dataSource.selected_contractor_ids.length || 'All')"
        class="mx-3 mt-3"
        color="" >
        <fa-icon [icon]="faHardHat"></fa-icon> {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectUsers()"
        [matBadge]="'' + (dataSource.selected_user_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>people</mat-icon> Assigned Users
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectReportedBy()"
        [matBadge]="'' + (dataSource.selected_reported_by_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>people</mat-icon> Reported By
      </button>
    </td>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter Safety Observations by date range." [dateRangeInput]="dataSource.date_filter_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </td>
  </ng-container>
</div>
