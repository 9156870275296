import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaskAnalysesRevisedRoutingModule } from './task-analyses-revised-routing.module';
import {TaskAnalysesRevisedComponent} from "./task-analyses-revised.component";
import {MatComponentsModule} from "../mat-components/mat-components.module";
import {FormsModule} from "@angular/forms";
import {MATERIAL_MODULES} from "../app.const";
import {PipesModule} from "../pipes/pipes.module";
import {FilesModule} from "../files/files.module";
import {DirectivesModule} from "../directives/directives.module";
import {TourMatMenuModule} from "ngx-tour-md-menu";
import {MatIconModule, MatMenuModule} from "@angular/material";
import { TaskAnalysesRevisedEditComponent } from './task-analyses-revised-edit/task-analyses-revised-edit.component';
import { TaskAnalysesRevisedViewComponent } from './task-analyses-revised-view/task-analyses-revised-view.component';
import { TaskAnalysesRevisedFilterComponent } from './task-analyses-revised-filter/task-analyses-revised-filter.component';
import {ToolsSelectorComponent} from "../shared/tools-selector/tools-selector.component";
import { TaskAnalysesRevisedTabsComponent } from './task-analyses-revised-tabs/task-analyses-revised-tabs.component';
import {UtilsModule} from "../utils/utils.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import { TaskAnalysesRevisedWorkflowsComponent } from './task-analyses-revised-workflows/task-analyses-revised-workflows.component';
import { TaskAnalysesRevisedAddComponent } from './task-analyses-revised-add/task-analyses-revised-add.component';
import { TaskAnalysesRevisedWorkflowsTabsComponent } from './task-analyses-revised-workflows-tabs/task-analyses-revised-workflows-tabs.component';
import { TaskAnalysesRevisedWorkflowsEditComponent } from './task-analyses-revised-workflows-edit/task-analyses-revised-workflows-edit.component';
import { TaskAnalysesRevisedWorkflowsViewComponent } from './task-analyses-revised-workflows-view/task-analyses-revised-workflows-view.component';
import { TaskAnalysesRevisedWorkflowsStepsComponent } from './task-analyses-revised-workflows-steps/task-analyses-revised-workflows-steps.component';
import { TaskAnalysesRevisedWorkflowsHazardsComponent } from './task-analyses-revised-workflows-hazards/task-analyses-revised-workflows-hazards.component';
import { TaskAnalysesRevisedWorkflowsHazardsViewComponent } from './task-analyses-revised-workflows-hazards-view/task-analyses-revised-workflows-hazards-view.component';
import { TaskAnalysesRevisedWorkflowsHazardsEditComponent } from './task-analyses-revised-workflows-hazards-edit/task-analyses-revised-workflows-hazards-edit.component';
import {AccountSitesHazardsSelectorComponent} from "../shared/account-sites-hazards-selector/account-sites-hazards-selector.component";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {SharedModule} from "../shared/shared.module";


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatComponentsModule,
    FormsModule,
    ...MATERIAL_MODULES,
    PipesModule,
    FilesModule,
    DirectivesModule,
    TourMatMenuModule,
    TaskAnalysesRevisedRoutingModule,
    UtilsModule,
    DragDropModule,
    BsDatepickerModule,
    SharedModule
  ],
  declarations: [
    TaskAnalysesRevisedComponent,
    TaskAnalysesRevisedEditComponent,
    TaskAnalysesRevisedViewComponent,
    TaskAnalysesRevisedFilterComponent,
    TaskAnalysesRevisedTabsComponent,
    TaskAnalysesRevisedWorkflowsComponent,
    TaskAnalysesRevisedAddComponent,
    TaskAnalysesRevisedWorkflowsTabsComponent,
    TaskAnalysesRevisedWorkflowsEditComponent,
    TaskAnalysesRevisedWorkflowsViewComponent,
    TaskAnalysesRevisedWorkflowsStepsComponent,
    TaskAnalysesRevisedWorkflowsHazardsComponent,
    TaskAnalysesRevisedWorkflowsHazardsViewComponent,
    TaskAnalysesRevisedWorkflowsHazardsEditComponent
  ],
  entryComponents: [
    TaskAnalysesRevisedFilterComponent,
    TaskAnalysesRevisedAddComponent,
    TaskAnalysesRevisedWorkflowsTabsComponent,
    TaskAnalysesRevisedWorkflowsViewComponent,
    TaskAnalysesRevisedWorkflowsStepsComponent,
    TaskAnalysesRevisedWorkflowsHazardsViewComponent,
    TaskAnalysesRevisedWorkflowsHazardsEditComponent,
    AccountSitesHazardsSelectorComponent,
    ToolsSelectorComponent
  ]
})
export class TaskAnalysesRevisedModule { }
