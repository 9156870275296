import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FileModel } from 'src/app/shared/file.model';
import { FileService } from 'src/app/shared/file.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-files-remove',
  templateUrl: './files-remove.component.html',
  styleUrls: ['./files-remove.component.scss']
})
export class FilesRemoveComponent {
  @Output() remove = new EventEmitter<FileModel[]>();

  constructor(
    private dialogRef: MatDialogRef<FilesRemoveComponent>,
    @Inject(MAT_DIALOG_DATA) public files: FileModel[],
    private fileService: FileService,
    private utils: UtilsService
  ) {}

  // This code looks redundant and should be removed if not used by this or any child components.
  onRemove(id: number) {
    this.fileService.remove(id).subscribe(() => {
      this.utils.showToast('Deleted a File.');
      this.files = Array.from(this.files).filter((file) => file.id !== id);
      this.remove.emit(this.files);
    });
  }

  onUpdateFiles(event: any) {
    this.files = event;
    if ( this.files.length === 0 ) {
      this.onClose();
    }
  }

  onClose() {
    this.dialogRef.close(this.files);
  }
}
