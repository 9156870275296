import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import { FolderModel } from 'src/app/shared/folder.model';
import { FoldersViewComponent } from 'src/app/folders/folders-view/folders-view.component';
import { UtilsService } from 'src/app/shared/utils.service';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-contractors-docs',
  templateUrl: './contractors-docs.component.html',
  styleUrls: ['./contractors-docs.component.css']
})
export class ContractorsDocsComponent implements OnInit, AfterViewInit {
  // user_id: number;

  // columns to show and the data source
  displayedColumns: string[] = [
    'id',
    'name',
    'folder_type',
    'expiry',
    // 'expiry_UTC',
    'created_at',
    // 'created_at_UTC',
    'actions'
  ];
  dataSource = new ContractorDocsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    // public service: DocsService,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource.contractor_id = Number(
      this.route.parent.snapshot.params.contractor_id
    );

    if (this.dataSource.contractor_id) {
      this.dataSource.getData();
    } else {
      this.router.navigate(['contractors']);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns based on its corresponding date column.
      if ( this.sort.active == 'expiry_UTC' ) {
        this.dataSource.sort_by = 'expiry';
      }

      // Sort UTC columns based on its corresponding date column.
      if ( this.sort.active == 'created_at_UTC' ) {
        this.dataSource.sort_by = 'created_at';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onView(folder: FolderModel) {
    this.dialog
      .open(FoldersViewComponent, {
        width: '750px',
        data: {
          folder: folder,
          is_training_doc: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData(true);
      });
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class ContractorDocsDataSource extends CustomDataSource {
  contractor_id = 0;

  //// record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('folders', resetOffset, () => {}, {
      account: true,
      contractor_id: this.contractor_id
    });
  }
}
