import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ToolboxTalkModel} from "../../models/toolbox-talk.model";
import {ApiDataSource} from "../../utils/api-data-source";
import {tap} from "rxjs/operators";
import {ActionModel} from "../../actions/action.model";
import {ActionsEditComponent} from "../../actions/actions-edit/actions-edit.component";
import {ActionsService} from "../../actions/actions.service";
import {ToolboxTalksCorrectiveActionsFilterComponent} from "../toolbox-talks-corrective-actions-filter/toolbox-talks-corrective-actions-filter.component";

@Component({
  selector: 'app-toolbox-talks-corrective-actions',
  templateUrl: './toolbox-talks-corrective-actions.component.html',
  styleUrls: ['./toolbox-talks-corrective-actions.component.scss']
})
export class ToolboxTalksCorrectiveActionsComponent implements OnInit {

  @Input() private toolboxTalksId: number;
  @Input() private toolboxTalksSiteId: number;
  @Input() private toolboxTalksUserIds: number[];

  path: string;

  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'priority',
    'due',
    // 'due_UTC',
    'completed',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  dataSource = new ToolboxTalksCorrectiveActionsDataSource(this.app, this.api);
  toolbox_talk: ToolboxTalkModel = new ToolboxTalkModel();

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private actionsService: ActionsService
  ) { }

  ngOnInit() {
    this.dataSource.toolbox_talk_id = this.toolboxTalksId;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns based on its corresponding date fields.
      if ( sorter['active'] == 'due_UTC' ) {
        this.dataSource.order_by = 'due';
      }

      // Sort UTC columns based on its corresponding date fields.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Create new or update existing corrective actions.
   * @param correctiveActionData The corrective action data from the list.
   */
  onEditCorrectiveAction(correctiveActionData?: ActionModel) {
    // Create a new action and apply the data if any.
    let action: ActionModel = new ActionModel();
    if ( correctiveActionData ) {
      action = correctiveActionData as ActionModel;
    }
    // Assign the action to the toolbox talk to the corrective action.
    action.toolbox_talk_id = this.toolboxTalksId; // This will only be stored when the corrective action is created, not updated.

    // Set the corrective action text, site id and users if any.
    action.site_id = this.toolboxTalksSiteId;
    action.user_ids = this.toolboxTalksUserIds;
    // The above fields will be overridden when editing an existing action.

    this.utils.showComponentDialog(ActionsEditComponent, action)
      .then((response) => {
        // This response is a boolean response value.
        if ( response ) {
          this.dataSource.getData();
        }
      });
  }

  /**
   * Remove the corrective action. This also removes it from the task list of the associated users.
   */
  onRemove(action: ActionModel) {
    this.utils.showModal(
      'Remove Corrective Action',
      `Are you sure you want to remove the corrective action?`,
      () => {
        this.actionsService.remove([action.id]).subscribe(() => {
          this.utils.showToast('The corrective action(s) were removed.');
          this.dataSource.getData();
        });
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove Selected Corrective Actions',
      'Are you sure you want to remove the selected Corrective Actions?',
      () => {
        this.api.makeRequest('delete', `v1/actions/${this.dataSource.selection.selected.join(',')}`, [], {})
          .then((response) => {
            this.utils.showToast(`The Corrective Actions was removed`);
            this.dataSource.selection.clear();
            this.dataSource.getData();
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  onFilter() {
    this.utils.showComponentDialog(
      ToolboxTalksCorrectiveActionsFilterComponent,
      {
        selected_assigned_users: this.dataSource.user_ids,
        filter_priority: this.dataSource.priority,
        filter_status: this.dataSource.status,
        due_date_range: this.dataSource.due_date
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.user_ids = results.user_ids ? results.user_ids : this.dataSource.user_ids;
          this.dataSource.priority = typeof results.priority !== 'undefined' ? results.priority : this.dataSource.priority;
          this.dataSource.status = typeof results.status !== 'undefined' ? results.status : this.dataSource.status;
          this.dataSource.due_date = results.due_date ? results.due_date : this.dataSource.due_date;
          this.dataSource.getData();
        }
      }
    );
  }

}

export class ToolboxTalksCorrectiveActionsDataSource extends ApiDataSource {
  // Order the records by the date it was created.
  order_by = 'date_created';
  order = 'desc';

  toolbox_talk_id: number;

  user_ids: number[] = [];
  priority: string = "";
  status: string = "";
  due_date: Date[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v1/toolbox-talks/${this.toolbox_talk_id}/corrective-actions`, resetOffset, {
      user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
      priority: this.priority ? this.priority : '',
      status: this.status ? this.status : '',
      due_date_range: this.due_date && this.due_date.length > 1 ? [
        this.due_date[0].getTime() / 1000,
        this.due_date[1].getTime() / 1000
      ].join(',') : ''
    });
  }
}
