import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  // MatProgressBarModule,
  //   MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatChipsModule, MatRadioModule, MatSliderModule
} from '@angular/material';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

export const exportModules = [
  LayoutModule,
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatTabsModule,
  MatDialogModule,
  MatToolbarModule,
  MatSelectModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatBadgeModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatMenuModule,
  MatCardModule,
  OverlayModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatRadioModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  MatSliderModule
];

@NgModule({
  imports: [CommonModule, ...exportModules],
  exports: [...exportModules],
  declarations: []
})
export class MatComponentsModule {}
