import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { FolderService } from 'src/app/shared/folder.service';
import { FolderModel } from 'src/app/shared/folder.model';
import { FoldersViewComponent } from 'src/app/folders/folders-view/folders-view.component';
import {AccountFoldersTabsComponent} from "../account-folders-tabs/account-folders-tabs.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-account-user-folders',
  templateUrl: './account-user-folders.component.html',
  styleUrls: ['./account-user-folders.component.scss']
})
export class AccountUserFoldersComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'name',
    'folder_type',
    'visibility',
    'expiry',
    // 'expiry_UTC',
    'actions'
  ];
  dataSource = new MyFoldersDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    private folderService: FolderService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource.user_id = this.app.user.id;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "expiry_UTC") {
        this.dataSource.sort_by = "expiry";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit({ user_id: this.app.user.id } as FolderModel);
  }

  onView(folder: FolderModel) {
    this.dialog
      .open(FoldersViewComponent, {
        width: '750px',
        data: {
          folder: folder,
          is_training_doc: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData(true);
      });
  }

  onEdit(folder: FolderModel) {
    this.dialog
      .open(AccountFoldersTabsComponent, {
        width: '1024px',
        data: {
          folder: folder,
          is_training_doc: true,
          show_document_types_list_for: 'user',
          show_document_visibility: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Document',
      'Are you sure you want to remove this document?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'folders/' + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The document was removed');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected Documents',
      'Are you sure you want to remove the selected documents?',
      () => {
        this.folderService
          .deleteMany(this.dataSource.selection.selected)
          .subscribe(() => {
            this.utils.showToast('The selected documents was removed');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          });
      }
    );
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class MyFoldersDataSource extends CustomDataSource {
  user_id = 0;

  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'folders/user/' + this.user_id,
      resetOffset,
      () => { },
      { eager_load: true }
    );
  }
}
