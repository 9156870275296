import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AccountsDataSource } from '../accounts.component';
import { MatPaginator, MatSort, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { tap } from 'rxjs/operators';
import { ApiRequestService } from 'src/app/shared/api-request.service';

@Component({
  selector: 'app-accounts-selector',
  templateUrl: './accounts-selector.component.html',
  styleUrls: ['./accounts-selector.component.scss']
})
export class AccountsSelectorComponent implements OnInit {

  // Columns to show in the view
  displayedColumns: string[] = [
    'id',
    'name',
    'subscription',
    'date_created'
  ];

  // The data source
  dataSource: AccountsDataSource;

  // The paginator and sorter objects
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public dialogRef: MatDialogRef<AccountsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    // Set multiple if it is not present and default it to false
    if ( typeof this.data['multiple'] == 'undefined' || typeof this.data['multiple'] != 'boolean' ) {
      this.data['multiple'] = false;
    }

    // Should object be returned instead?
    if ( typeof this.data['returnObjects'] == 'undefined' || typeof this.data['returnObjects'] != 'boolean' ) {
      this.data['returnObjects'] = false;
    }

    if ( this.data['multiple'] ) {
      // Show the single record selector
      this.displayedColumns.unshift('select');
    } else {
      // Show the multiple records selector
      this.displayedColumns.push('actions');
    }

    // Construct the datasource
    this.dataSource = new AccountsDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // Set a default selection if no selected values are passed through
    if (typeof this.data['selected'] == 'undefined') {
      this.data['selected'] = [];
    }

    // Loop and select the values in the data source
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(
        this.data['returnObjects'] ? this.data['selected'][i].id : this.data['selected'][i]
      );
    }

    // Get the data
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onSelectSingle(value: any) {
    this.dialogRef.close(value);
  }

  onSelectMultiple() {
    if ( this.data['returnObjects'] ) {
      if ( this.dataSource.selection.selected.length > 0 ) {
        this.dataSource.dataSubject.subscribe((records) => {
          this.dialogRef.close(records.filter((record) => {
            return this.dataSource.selection.selected.indexOf(record.id) > -1
          }));
        });
      } else {
        this.dialogRef.close([]);
      }
    } else {
      this.dialogRef.close(this.dataSource.selection.selected);
    }
  }

  onSelectAllRecords() {
    this.dialogRef.close({
      'allRecords': true,
      'ids': this.dataSource.ids
    });
  }

  onDeselectAllRecords() {
    this.dataSource.selection.clear();
  }

}
