import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ToolboxTalksSelectorComponent } from 'src/app/toolbox-talks/toolbox-talks-selector/toolbox-talks-selector.component';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ToolboxTalkModel } from "../../models/toolbox-talk.model";
import { ToolboxTalksEditComponent } from "../../toolbox-talks/toolbox-talks-edit/toolbox-talks-edit.component";
import { SitesToolboxTalksFilterComponent } from "../sites-toolbox-talks-filter/sites-toolbox-talks-filter.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-toolbox-talks',
  templateUrl: './sites-toolbox-talks.component.html',
  styleUrls: ['./sites-toolbox-talks.component.scss']
})
export class SitesToolboxTalksComponent implements OnInit, AfterViewInit {

  parent_id: number;
  child_id: number;
  site_id: number;

  path: string;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'site',
    'name',
    'created_by_user',
    'updated_by_user',
    'reported_by_user',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];
  dataSource: SiteToolboxTalksDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private oldApi: ApiService,
    public route: ActivatedRoute,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
      // get sites path edit/view
      this.path = this.route.parent.snapshot.routeConfig.path;

      // init the data source
      this.dataSource = new SiteToolboxTalksDataSource(this.app, this.oldApi, true);
      this.dataSource.site_ids.push(this.site_id);

      // get the data
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      } else if (this.sort.active === "date_modified_UTC") {
        this.dataSource.sort_by = "date_modified";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onEdit(toolbox_talk_id: number | string) {
    // open the hazard edit dialog for editing
    this.utils.showComponentDialog(
      ToolboxTalksEditComponent,
      {
        id: toolbox_talk_id,
        site_id: this.site_id,
        redirect_url: this.utils.prepareSiteRelatedLink(this.site_id, this.child_id ? this.parent_id : null, 'edit/toolbox-talks/' + toolbox_talk_id + '/view')
      },
      {},
      (response) => {
        // response is not used
        this.dataSource.getData(true);
      }
    );
  }

  /**
   * @param id the id of the toolbox talk.
   * Remove a single toolbox talk.
   */
  onRemove(id: number) {
    this.utils.showModal(
      'Delete ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk'),
      'Are you sure you want to delete this ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '?',
      () => {
        this.onRemoveToolboxTalks([id], (response) => {
          this.dataSource.selection.deselect(id);
        });
      }
    );
  }

  /**
   * Remove selected toolbox talks from the specified site
   */
  onDeleteSelected() {
    this.utils.showModal(
      'Delete Selected ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks'),
      'Are you sure you want to delete the selected ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + '?',
      () => {
        this.onRemoveToolboxTalks(
          this.dataSource.selection.selected,
          (response) => {
            this.dataSource.selection.clear();
          }
        );
      }
    );
  }

  /**
   * open a dialog allowing the user to select toolbox talks.
   */
  onSelectToolboxTalks() {
    // show the hazard selector
    this.utils.showComponentDialog(
      ToolboxTalksSelectorComponent,
      {
        multiple: true
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.copyToolboxTalksToSite(results);
        }
      }
    );
  }

  copyToolboxTalksToSite(selected_toolbox_talk_ids) {
    this.oldApi.laravelApiRequest(
      'put',
      'sites/' + this.site_id + '/toolbox-talks',
      {
        selected_toolbox_talk_ids: selected_toolbox_talk_ids
      },
      {},
      (response) => {
        this.dataSource.getData(true);
        this.utils.showToast(this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' templates were copied.');
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }

  onRemoveToolboxTalks(toolbox_talk_ids: number[], callback?: any) {
    if (this.site_id) {
      // Delete the toolbox talk.
      this.oldApi.laravelApiRequest('delete', 'toolbox-talks/' + toolbox_talk_ids.join(',') + '?site_id=' + this.site_id,
        {},
        {},
        (response) => {
          // reload the data and reset the offset
          this.dataSource.getData(true);

          if (typeof callback === 'function') {
            callback(response);
          }
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }

  // Duplicate function in toolbox-talks.component.ts
  onExport(type: string = 'pdf', id?: number) {

    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.dataSource.selection.selected);
    }

    this.api.makeDownloadRequest(`v1/toolbox-talks/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      site_ids: [this.site_id],
      reported_by_ids: this.dataSource.reported_by_ids ? this.dataSource.reported_by_ids.join(',') : "",
      employee_ids: this.dataSource.employee_ids ? this.dataSource.employee_ids.join(',') : "",
      attendee_ids: this.dataSource.attendee_ids ? this.dataSource.attendee_ids.join(',') : "",
      date_range: this.dataSource.date_range && this.dataSource.date_range.length > 1 ? [
        this.dataSource.date_range[0].getTime() / 1000,
        this.dataSource.date_range[1].getTime() / 1000
      ].join(',') : "",
      sort_by: this.dataSource.sort_by,
      sort_order: this.dataSource.sort_order
    })
      .then((response) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ` - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.showModal('Error', errorResponse.message);
      });
  }

  onCreateFrom(toolboxTalk: ToolboxTalkModel) {
    const newToolboxTalk: ToolboxTalkModel = new ToolboxTalkModel();
    newToolboxTalk.createFrom(toolboxTalk);
    this.utils.showComponentDialog(ToolboxTalksEditComponent, {
      site_id: this.site_id,
      toolbox_talk: newToolboxTalk
    })
      .then(() => {
        this.dataSource.getData();
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      SitesToolboxTalksFilterComponent,
      {
        reported_by_ids: this.dataSource.reported_by_ids,
        employee_ids: this.dataSource.employee_ids,
        attendee_ids: this.dataSource.attendee_ids,
        date_range: this.dataSource.date_range,
        site_ids: this.dataSource.site_ids,
        parent_site_id: this.parent_id
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.reported_by_ids = results.reported_by_ids || this.dataSource.reported_by_ids;
          this.dataSource.employee_ids = results.employee_ids || this.dataSource.employee_ids;
          this.dataSource.attendee_ids = results.attendee_ids || this.dataSource.attendee_ids;
          this.dataSource.date_range = results.date_range || this.dataSource.date_range;

          this.dataSource.site_ids = results.site_ids;
          if ( this.dataSource.site_ids.length === 0 ) {
            this.dataSource.site_ids.push(this.site_id);
          }

          // Reload the list.
          this.dataSource.getData(true);
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class SiteToolboxTalksDataSource extends CustomDataSource {

  sort_by = 'date_created';
  sort_order = 'desc';

  site_ids = []; // The site_ids array must manually be added to the outdated API call.

  reported_by_ids = [];
  employee_ids = [];
  attendee_ids = [];

  date_range: Date[] = [];

  /**
   * Update 08/04/2021: Due to the endpoint being changed from `sites/x/toolbox-talks` to `toolbox-talks` additional info need to be sent to the API to filter records.
   * @param resetOffset Should the results be reset.
   */
  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('toolbox-talks', resetOffset, () => { }, {
      site_ids: this.site_ids ? this.site_ids.join(',') : "",
      reported_by_ids: this.reported_by_ids ? this.reported_by_ids.join(',') : "",
      employee_ids: this.employee_ids ? this.employee_ids.join(',') : "",
      attendee_ids: this.attendee_ids ? this.attendee_ids.join(',') : "",
      date_range: this.date_range && this.date_range.length > 1 ? [
        this.date_range[0].getTime() / 1000,
        this.date_range[1].getTime() / 1000
      ].join(',') : ""
    });
  }
}
