import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {IndustriesModel} from "../../industries/industries.model";
import {WorkPermitModel} from "../work-permits.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-work-permits-edit',
  templateUrl: './work-permits-edit.component.html',
  styleUrls: ['./work-permits-edit.component.scss']
})
export class WorkPermitsEditComponent implements OnInit {

  inProgress = false;

  industries: IndustriesModel[] = [];

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public workPermit: WorkPermitModel,
    private dialogRef: MatDialogRef<WorkPermitsEditComponent>,
    private api: ApiRequestService,
    public renderer: Renderer
  ) { }

  ngOnInit() {
    if(this.workPermit.id) {
      this.find(this.workPermit.id);
    }

    this.getIndustries();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  onSubmit() {
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;
    !this.workPermit.id ? this.create() : this.update();
  }

  private find(work_permit_id: number) {
    this.api.makeRequest('get', `v2/admin/work-permits/${work_permit_id}`)
      .then((response) => {
        this.workPermit = response;

        // Industry - Default to zero - All
        if(this.workPermit.industry_id == null) {
          this.workPermit.industry_id = 0;
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete() {
    this.utils.showModal(
      'Remove Work Permit',
      `Are you sure you want to remove "${this.workPermit.title}"?`,
      () => {
        this.api.makeRequest('delete', `v2/admin/work-permits/${this.workPermit.id}`)
          .then((response) => {
            this.utils.showToast(`"${this.workPermit.title}" has been deleted.`);
            this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update() {
    this.api
      .makeRequest('put', `v2/admin/work-permits/${this.workPermit.id}`, this.workPermit)
      .then((response: WorkPermitModel) => {
        this.workPermit = response;
        this.onSuccess(`Updated: "${response.title}"`);
      })
      .finally(() => (this.inProgress = false));
  }

  private create() {
    this.api
      .makeRequest('post', 'v2/admin/work-permits', this.workPermit)
      .then((response: WorkPermitModel) => {
        this.workPermit = response;
        this.onSuccess(`New Work Permits "${response.title}" created.`);
      })
      .finally(() => this.inProgress = false);
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(this.workPermit);
  }

}
