import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {UtilsService} from "../utils.service";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../api-request.service";
import {SitesSelectorComponent} from "../sites-selector/sites-selector.component";

@Component({
  selector: 'app-user-public-profile-sites-worked-on',
  templateUrl: './user-public-profile-sites-worked-on.component.html',
  styleUrls: ['./user-public-profile-sites-worked-on.component.scss']
})
export class UserPublicProfileSitesWorkedOnComponent implements OnInit {

  // The user hash that will be used to get the info.
  @Input() hash: string;

  // Used to determine if admin links should be included or not.
  @Input() includeAdminActions: boolean = false;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  // The datasource object for list data.
  dataSource = new UserPublicProfileSitesWorkedOnDataSource(this.app, this.api, true);

  // The columns to display.
  displayedColumns: string[] = [
    'id',
    'owner',
    'name',
    'location'
  ];

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Check if admin actions should be enabled.
    if ( this.includeAdminActions ) {
      this.displayedColumns.unshift('select');
      this.displayedColumns.push('actions');
    }
    // Store the hash.
    this.dataSource.hash = this.hash;
    // Do not load data if the hash is missing.
    if ( this.dataSource.hash ) {
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Remove the site link from the user.
   * @param id The site id.
   */
  onRemove(site_id: number) {
    this.removeSites([site_id]);
  }

  /**
   * Remove selected sites from the user.
   */
  onRemoveSelected() {
    this.removeSites(this.dataSource.selection.selected);
  }

  /**
   * Open a dialog to allow the admin user to select sites to add to the list.
   */
  onAddSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
      multiple: true
    })
      .then((site_ids: number[]) => {
        // Do nothing if the response is undefined.
        if ( typeof site_ids === 'undefined' ) {
          return;
        }
        // Do nothing if no users were selected.
        if ( !site_ids || site_ids.length == 0 ) {
          return;
        }
        // Make an API request to add the users.
        this.api.makeRequest('put', `v2/users/${this.hash}/sites-worked-on`, {
          site_ids: site_ids
        })
          .then((response) => {
            // Refresh the list.
            this.dataSource.getData(true);
            // Show toast message
            this.utils.showToast(response.message);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      });
  }

  /**
   * Remove sites from the list.
   * @param site_ids The site ids to remove.
   * @private
   */
  private removeSites(site_ids: number[]) {
    // Terminate early if no site id is present.
    if ( !this.hash ) {
      return;
    }
    // Check if there are any users to remove.
    if ( site_ids.length === 0 ) {
      this.utils.showModal(`Select ${this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites')}`, `You need to select some ${this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites')} before they can be removed.`);
      return;
    }
    // Get Confirmation
    this.utils.showModal(`Remove ${this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites')}`, `Are you sure you want to remove ${site_ids.length} ${this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites')}?`, () => {
      // Make the API request to remove the users.
      this.api.makeRequest('delete', `v2/users/${this.hash}/sites-worked-on`, {}, {
        site_ids: site_ids.join(',')
      })
        .then((response) => {
          // Refresh the list.
          this.dataSource.getData(true);
          // Show toast message
          this.utils.showToast(`${site_ids.length} ${this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites')} were removed.`);
          // Clear selected items.
          this.dataSource.selection.deselect(...site_ids);
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
    });
  }

  /**
   * Export the list of sites the user worked on.
   */
  onExport() {
    this.api.makeDownloadRequest(`v2/users/${this.hash}/sites-worked-on`, {}, {
      ids: this.dataSource.selection.selected.join(','),
      export: 'csv'
    })
      .then((response) => {
        // Workaround for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `${this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites')} Worked On - ${date.getFullYear()} / ${(date.getMonth() + 1)} / ${date.getDate()}.csv`;
        // Initiate the download
        downloadLink.click();

        // For FireFox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 milliseconds.
      })
      .catch((errorResponse) => {
        this.utils.showModal('Error', errorResponse.message);
      });
  }
}

export class UserPublicProfileSitesWorkedOnDataSource extends ApiDataSource {

  hash: string = '';

  order_by: string = 'name';
  order: string = 'asc';

  getData(resetOffset: boolean = false) {
    if ( this.hash ) {
      this.makeRequest( `v2/users/${this.hash}/sites-worked-on`, resetOffset);
    }
  }
}
