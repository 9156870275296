import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { ApiService } from '../api.service';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import {ApiRequestService} from "../api-request.service";

@Component({
  selector: 'app-networked-accounts-selector',
  templateUrl: './networked-accounts-selector.component.html',
  styleUrls: ['./networked-accounts-selector.component.scss']
})
export class NetworkedAccountsSelectorComponent implements OnInit {

  // Columns to show in the view
  displayedColumns: string[] = [
    'name',
    'date_created'
  ];

  // The data source
  dataSource: NetworkedAccountsSelectorDataSource;

  // The paginator and sorter objects
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    public api: ApiService,
    public dialogRef: MatDialogRef<NetworkedAccountsSelectorComponent>,
    public apiRequest: ApiRequestService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    // Set multiple if it is not present and default it to false
    if ( typeof this.data['multiple'] === 'undefined' || typeof this.data['multiple'] !== 'boolean' ) {
      this.data['multiple'] = false;
    }

    if ( this.data['multiple'] ) {
      this.displayedColumns.unshift('select');
    } else {
      this.displayedColumns.push('actions');
    }

    // Construct the datasource
    this.dataSource = new NetworkedAccountsSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // Set a default selection if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // Loop and select the values in the data source
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(this.data['selected'][i]);
    }

    // Get the data
    this.dataSource.getData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onSelectSingle(id: number) {
    this.dialogRef.close(id);
  }

  onSelectMultiple() {
    this.dialogRef.close(this.dataSource.selection.selected);
  }

  onSelectAllRecords() {
    this.apiRequest.makeRequest('get', `v1/account/all-networked-accountids`, {}, {
      search: this.dataSource.search
    })
      .then((res) => {
        this.dialogRef.close(res);
      });
  }

  onDeselectAllRecords() {
    this.dataSource.selection.clear();
  }
}

/**
 * The networked accounts selector data source.
 */
export class NetworkedAccountsSelectorDataSource extends CustomDataSource {

  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('account/networked-accounts', resetOffset);
  }
}
