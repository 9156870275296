import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DynamicFormModel } from '../dynamic-form.model';

@Component({
  selector: 'app-dynamic-forms-clone',
  templateUrl: './dynamic-forms-clone.component.html',
  styleUrls: ['./dynamic-forms-clone.component.scss']
})
export class DynamicFormsCloneComponent implements OnInit {

  // The form clone data that will be sent to the API.
  clone_data: {
    form_id?: number,
    form_title?: string,
    form_description?: string
  } = {};

  constructor(
    private utils: UtilsService,
    private api: ApiRequestService,
    private dialogRef: MatDialogRef<DynamicFormsCloneComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Get the form id.
    const form_id = Number(this.dialogData['form_id']);
    if ( !form_id ) {
      this.utils.showModal("Form Error", 'We could not find the form you want to clone. Please try again.');
      this.dialogRef.close();
      return;
    }

    // Update the clone data.
    this.clone_data.form_id = form_id;
    this.clone_data.form_title = this.dialogData['form_title'];
    this.clone_data.form_description = this.dialogData['form_description'];
  }

  /**
   * Validate the form input and save the data.
   * @param form The NgForm object that is used for validation.
   * @returns Nothing.
   */
  onSubmit(form: NgForm) {
    // Validate the submitted form.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    // Make sure the form id is present.
    if ( !this.clone_data.form_id ) {
      this.utils.showModal('Error', 'The form id is not present. Please close this dialog and try again by clicking on the clone button in the list.');
      return;
    }

    // Send the form to clone's id and the new form title and description to the API.
    this.api.makeRequest('post', `v2/dynamic-forms/clone/${this.clone_data.form_id}`, this.clone_data)
    .then((response: DynamicFormModel) => {
      this.utils.showToast(`The form was cloned. You can find the cloned form in your form templates.`);
      // The API responds with the cloned form if successful. Send it in the dialog close response.
      this.onClose(response);
    })
    .catch((errorResponse: any) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  /**
   * Close the dialog and respond with the category object.
   */
  onClose(form?: DynamicFormModel) {
    this.dialogRef.close(form);
  }

}
