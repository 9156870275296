import {Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import {AppService} from "../app.service";
import {ApiService} from "../shared/api.service";
import {UtilsService} from "../shared/utils.service";
import {ApiRequestService} from "../shared/api-request.service";
import {ApiDataSource} from "../utils/api-data-source";
import {tap} from "rxjs/operators";
import {UserPublicProfileComponent} from "../shared/user-public-profile/user-public-profile.component";
import {VisitorsViewComponent} from "./visitors-view/visitors-view.component";

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.scss']
})
export class VisitorsComponent implements OnInit {

  // Columns to display in the list
  listColumns: string[] = [
    'select',
    'contact_person',
    'email',
    'mobile',
    'date_created',
    'actions'
  ];
  listDataSource = new VisitorsDataSource(this.app, this.api);

  // The paginator and sorter for the list
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.listDataSource.limit = this.paginator.pageSize;
      this.listDataSource.offset = this.paginator.pageIndex;
      this.listDataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.listDataSource.order_by = "date_created";
      } else {
        this.listDataSource.order_by = this.sort.active;
      }

      this.listDataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onExport(type: string = 'csv', id?: number) {
    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.listDataSource.selection.selected);
    }

    // Get the current date object
    const date = new Date();

    this.api.makeDownloadRequest(`v2/visitors/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      order_by: this.listDataSource.order_by,
      order: this.listDataSource.order,
      search: this.listDataSource.search
    })
      .then((file) => {
        saveAs(file, `Visitors - ${this.app.account.name} - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`);
      });
  }

  /**
   * View the visitor record.
   */
  onView(visitorId: number) {
    this.utils.showComponentDialog(
      VisitorsViewComponent,
      visitorId,
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.listDataSource.getData(true);
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.listDataSource.getData();
      }
    );
  }

}

export class VisitorsDataSource extends ApiDataSource {

  order_by = 'contact_person';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/visitors', resetOffset);
  }
}
