import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/utils.service';
import { AppService } from '../../app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import {PandemicRecordsFilterComponent} from "../pandemic-records-filter/pandemic-records-filter.component";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../../shared/api-request.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-pandemic-records',
  templateUrl: './pandemic-records.component.html',
  styleUrls: ['./pandemic-records.component.scss']
})
export class PandemicRecordsComponent implements OnInit {

  // columns to show and the data source
  displayedColumns: string[] = [
    'id',
    'contact_person',
    'status-icons',
    'entity_name',
    'site_name',
    'type',
    'method',
    'pandemic_have_fever',
    // 'body_temperature',
    'pandemic_declaration',
    'date_created',
    // 'date_created_UTC',
    'export'
  ];
  dataSource = new PandemicRecordsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  contractors: any[] = [];

  constructor(
    public app: AppService,
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onExport() {
    this.dataSource.exportData()
      .then((data) => {
        saveAs(data, 'Covid-19 Access Records.csv');
      })
      .catch((errorResponse) => {
        // Show a standard error message.
        this.utils.showModal('Export Error', 'We could not export your records. Please try again and contact support if the issue persists.');
      });
  }

  composeNewMessage(userId: number) {
    if ( !userId ) {
      this.utils.showToast('We are unable to open the message composer. Please refresh the page and try again.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: [userId]
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }

  onFilter() {
    this.utils.showComponentDialog(
      PandemicRecordsFilterComponent,
      {
        selected_site_ids: this.dataSource.site_ids,
        selected_contractor_ids: this.dataSource.contractor_ids,
        selected_user_ids: this.dataSource.user_ids,
        selected_employee_ids: this.dataSource.employee_ids,
        filter_date_range: this.dataSource.pandemic_date_range
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.contractor_ids = results.contractor_ids ? results.contractor_ids : this.dataSource.contractor_ids;
          this.dataSource.user_ids = results.user_ids ? results.user_ids : this.dataSource.user_ids;
          this.dataSource.employee_ids = results.employee_ids ? results.employee_ids : this.dataSource.employee_ids;
          this.dataSource.pandemic_date_range = results.pandemic_date_range ? results.pandemic_date_range : this.dataSource.pandemic_date_range;
          this.dataSource.getData();
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class PandemicRecordsDataSource extends ApiDataSource {

  order_by: string = 'id';
  order: string = 'desc';

  filter_type: string = ''; // Can only be: in, out, in/out or location.

  site_ids: number[] = [];
  contractor_ids: number[] = [];
  user_ids: number[] = [];
  employee_ids: number[] = [];
  pandemic_date_range: Date[] = [];

  /**
   * This prepares the query params for the API requests to list or export the data.
   */
  prepareQueryParams() {
    return {
      type: this.filter_type ? this.filter_type : '',
      site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
      user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
      employee_ids: this.employee_ids.length ? this.employee_ids.join(',') : '',
      date_range: this.pandemic_date_range && this.pandemic_date_range.length > 1 ? (this.pandemic_date_range[0].getTime() / 1000) + ',' + (this.pandemic_date_range[1].getTime() / 1000) : 0
    };
  }

  /**
   * Make a request to get list data.
   * @param resetOffset This will reset the offset to load data from the beginning.
   */
  getData(resetOffset: boolean = false) {
    return this.makeRequest(`v2/access-records`, resetOffset, this.prepareQueryParams());
  }

  /**
   * Make a request to export the data to a CSV file.
   */
  exportData() {
    return this.api.makeDownloadRequest(`v2/access-records/export`, {}, this.prepareQueryParams());
  }
}
