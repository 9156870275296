import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { CourseModuleSectionModel } from '../../../../models/course-module-section.model';
import { ApiService } from '../../../../shared/api.service';
import { UtilsService } from '../../../../shared/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-courses-modules-sections-edit',
  templateUrl: './courses-modules-sections-edit.component.html',
  styleUrls: ['./courses-modules-sections-edit.component.scss']
})
export class CoursesModulesSectionsEditComponent implements OnInit {
  course_id: number; // used for navigation after adding a record
  module_id: number;

  section: CourseModuleSectionModel = new CourseModuleSectionModel();

  inProgress = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      section: CourseModuleSectionModel;
      course_id: number;
      module_id: number;
    },
    private dialogRef: MatDialogRef<CoursesModulesSectionsEditComponent>
  ) {}

  ngOnInit() {
    this.course_id = this.data.course_id;
    this.module_id = this.data.module_id;
    this.section = this.data.section;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      this.utils.showModal(
        'Form Validation Error',
        'Please make sure you enter all fields with valid information.'
      );
      return;
    }

    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    this.section.id ? this.update() : this.create();
  }

  create() {
    this.api.laravelApiRequest(
      'post',
      `inductions/${this.course_id}/modules/${this.module_id}/sections`,
      this.section,
      {},
      (response) => {
        this.utils.showToast('The section was added');
        this.dialogRef.close(true);
        this.inProgress = false;
      }, 
      (_error: any) => this.inProgress = false
    );
  }

  update() {
    this.api.laravelApiRequest(
      'put',
      `inductions/${this.course_id}/modules/${this.module_id}/sections/${
        this.section.id
      }`,
      this.section,
      {},
      (response) => {
        this.utils.showToast('The section was updated');
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (_error: any) => this.inProgress = false
    );
  }

  onRemove() {
    this.utils.showModal(
      'Remove Sub-Section',
      'Are you sure you want to remove this sub-section?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.course_id}/modules/${this.module_id}/sections/${
            this.section.id
          }`,
          {},
          {},
          (response) => {
            this.utils.showToast('The sub-section was removed.');
            this.dialogRef.close(true);
          }
        );
      }
    );
  }
}
