import {Component, OnInit, ViewChild} from '@angular/core';
import { AppService } from '../../../app.service';
import { UtilsService } from '../../../shared/utils.service';
import {UserInductionModulesComponent} from "../user-induction/user-induction-modules/user-induction-modules.component";
import {InductionService} from "../../induction.service";
import {tap} from "rxjs/operators";
import {ApiDataSource} from "../../../utils/api-data-source";
import {MatPaginator} from "@angular/material/paginator";
import { MatSort } from '@angular/material';
import {ApiRequestService} from "../../../shared/api-request.service";

@Component({
  selector: 'app-user-inductions-widget',
  templateUrl: './user-inductions-widget.component.html',
  styleUrls: ['./user-inductions-widget.component.scss']
})
export class UserInductionsWidgetComponent implements OnInit {

  // The list of columns to display.
  listColumns: string[] = [
    'name',
    'company_name',
    'status',
    'actions'
  ];

  // The datasource for the data table.
  dataSource: UserInductionsDataSource = new UserInductionsDataSource(this.app, this.api, false);

  // Define the paginator and sorter.
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public inductionService: InductionService,
  ) {}

  ngOnInit(): void {
    // Get the data from the datasource.
    this.dataSource.getData();
  }

  ngAfterViewInit(): void {
    // Reset the paginator when sorting takes place.
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator.pageIndex = paginator['pageIndex'];
      this.paginator.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Opens a course for the user.
   *
   * @param {number} course_id - The id of the course to be opened.
   * @return {void}
   */
  onOpenCourse(course_id: number): void {
    this.inductionService.get(course_id, () => {
      this.utils.showComponentDialog(UserInductionModulesComponent, {
        course_id: course_id
        }, {}, () => {
          this.dataSource.getData();
      });
    });
  }
}

export class UserInductionsDataSource extends ApiDataSource {

  // Set the default sorting by name in ascending order.
  order_by: string = 'name';
  order: string = 'asc';

  // Override the page size options for this widget.
  pageSizeOptions: number[] = [5, 10, 20];

  // Set the default limit to 10 for this widget.
  limit: number = 10;

  /**
   * Retrieves data from the server.
   *
   * @param {boolean} [resetOffset=false] - Optional parameter to indicate whether to reset the offset.
   * @return {void}
   */
  getData(resetOffset: boolean = false): void {
    this.makeRequest('v1/user/incomplete-inductions', resetOffset, {
      order: this.order,
      order_by: this.order_by,
      limit: this.limit,
      search: this.search
    });
  }
}

