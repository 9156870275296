import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DynamicFormModel } from 'src/app/dynamic-forms/dynamic-form.model';
import { DynamicFormFieldAnswerModel } from 'src/app/dynamic-forms/dynamic-form-field-answer.model';
import { DynamicFormFieldOptionAnswerModel } from 'src/app/dynamic-forms/dynamic-form-field-option-answer.model';
import { DynamicFormFieldModel } from 'src/app/dynamic-forms/dynamic-form-field.model';
import { DynamicFormFieldOptionModel } from 'src/app/dynamic-forms/dynamic-form-field-option.model';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import { AppService } from '../../app.service';
import { DigitalSignatureMeta } from '../../shared/digital-signature/digital-signature-meta';
import {
  NetworkedUsersSelectorComponent
} from '../../shared/networked-users-selector/networked-users-selector.component';

@Component({
  selector: 'app-user-dynamic-forms-edit',
  templateUrl: './user-dynamic-forms-edit.component.html',
  styleUrls: ['./user-dynamic-forms-edit.component.scss']
})
export class UserDynamicFormsEditComponent implements OnInit {

  form: DynamicFormModel = {
    is_template: true,
    is_active: false,
    is_recurring: false,
    schedule: 'day-of-month',
    schedule_minute: 0,
    schedule_hour: 8,
    schedule_days: [],
    schedule_months: [],
    schedule_weeks: [],
    expires_at: 0,
    form_type: 'form',
    fields: []
  } as DynamicFormModel;

  currentDate: Date = moment().toDate();

  // A variable to store files that should be uploaded.
  selectedFilesToUpload: File[][] = [];

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<UserDynamicFormsEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { form_id?: number },
    public app: AppService
  ) { }

  ngOnInit() {
    // Get the form id.
    // const form_id = Number(this.route.snapshot.params['form_id']);
    if ( this.dialogData.form_id ) {
      // Make an API request to get the form data.
      this.api.makeRequest('get', `v2/user/assigned-forms/${this.dialogData.form_id}`)
      .then((response: DynamicFormModel) => {
        // Store the form.
        this.form = response;
        // Prepare form field answers.
        this.prepareFormFieldAnswers();
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      // Close the dialog if the form id is not present.
      this.dialogRef.close();
    }
  }

  /**
   * Send a request to the API to update the form status.
   */
  onCompleteWork() {
    // Check if the form id is present.
    if ( !this.dialogData.form_id ) {
      this.utils.showModal('Error', 'We were unable to change the status of the form to "Work Completed". Please re-open the form and try again.');
      return;
    }

    // Change the status of the form to Work Completed.
    this.form.pivot.dynamic_form_status = 'Work Completed';

    // Make an API request to get the form data.
    this.api.makeRequest('put', `v2/user/assigned-forms/${this.dialogData.form_id}/status-change`, this.form.pivot)
      .then((response: DynamicFormModel) => {
        // Store the form.
        this.form = response;
        // Prepare form field answers.
        this.prepareFormFieldAnswers();
        // Alert the user.
        this.utils.showToast(`The form status was successfully changed to "${this.form.pivot.dynamic_form_status}".`);
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Prepare the form field answers.
   * Fields with no answers gets a default answer of an empty string.
   * @private
   */
  private prepareFormFieldAnswers(): void {
    this.form.fields.forEach((field): void => {
      // Initialise new field answer models if none is present.
      if ( typeof field.field_answer == 'undefined' || !field.field_answer ) {
        field.field_answer = {
          dynamic_form_field_id: field.id,
          answer: '',
          meta: {}
        } as DynamicFormFieldAnswerModel;
      } else {
        // Convert null answers to empty string answers.
        if ( field.field_answer.answer == null ) {
          // If the answer evaluated to null we need to replace it with an empty string.
          field.field_answer.answer = '';
        }
        // Make sure the meta property is set.
        if ( typeof field.field_answer.meta == 'undefined' || !field.field_answer.meta ) {
          field.field_answer.meta = {};
        }
      }

      // Create field option answers if none is present.
      field.field_options.forEach((fieldOption) => {
        if ( typeof fieldOption.option_answer == 'undefined' || !fieldOption.option_answer ) {
          fieldOption.option_answer = {
            dynamic_form_field_option_id: fieldOption.id,
            answer: false
          } as DynamicFormFieldOptionAnswerModel;
        }
      });
    });
  }

  // A variable to store the invalid form field labels.
  invalidFieldErrorMessages: string[] = [];

  /**
   * This stores the invalid field label as an error messages.
   * @param message
   */
  addInvalidFieldErrorMessage(message: string) {
    // Check if the form field label already exists.
    if ( this.invalidFieldErrorMessages.indexOf(message) == -1 ) {
      // Add teh form field label.
      this.invalidFieldErrorMessages.push(message);
    }
  }

  /**
   * This is used to evaluate if all required options for checkboxes and toggle options are selected.
   * The required properties on checkboxes and toggle options does not work by default.
   * @returns
   */
   validateFieldOptions() {
    let valid: boolean = true;
    if ( typeof this.form.fields != 'undefined' && this.form.fields.length > 0 ) {
      this.form.fields.forEach((field: DynamicFormFieldModel) => {
        // If the field is hidden, we want to skip it.
        if ( !this.evaluateConditions(field) ) {
          return;
        }

        if ( ['checkbox', 'toggle'].indexOf(field.field_type) > -1 ) {
          field.field_options.forEach((field_option: DynamicFormFieldOptionModel) => {
            if ( field_option.option_is_required && !(field_option.option_answer.answer == (true || 1)) ) {
              this.addInvalidFieldErrorMessage(field.field_label);
              valid = false;
            }
          });
        } else {
          // Check if the signature field is required and filled in or not.
          if ( ['signature'].indexOf(field.field_type) > -1 && field.field_is_required === true ) {
            if ( typeof field.field_answer.meta == 'undefined' || !field.field_answer.meta['base64_image_data'] ) {
              this.addInvalidFieldErrorMessage(field.field_label);
              valid = false;
            }
          } else {
            // Check if the user-selector was populated.
            if ( ['user-selector'].indexOf(field.field_type) > -1 && field.field_is_required === true ) {
              if ( typeof field.field_answer.meta == 'undefined' || (!field.field_answer.meta['user_ids'] || (Array.isArray(field.field_answer.meta['user_ids']) && !field.field_answer.meta['user_ids'].length)) ) {
                this.addInvalidFieldErrorMessage(field.field_label);
                valid = false;
              }
            } else {
              // Check if the field should be checked and is required or not.
              if ( ['paragraph', 'wysiwyg', 'filepicker'].indexOf(field.field_type) === -1 && field.field_is_required === true ) {
                // Check if the field answer is empty.
                if ( !field.field_answer.answer ) {
                  this.addInvalidFieldErrorMessage(field.field_label);
                  valid = false;
                } else {
                  // Do additional validation for different input types.
                  if ( field.field_type === 'input') {
                    // Check if the email address input is valid.
                    if ( field.field_input_type === 'email' ) {
                      if ( !field.field_answer.answer || !this.utils.isValidEmailAddress(field.field_answer.answer) ) {
                        this.addInvalidFieldErrorMessage('Format of: '  + field.field_label);
                        valid = false;
                      }
                    }
                    // Check if the contact number input is valid.
                    if ( field.field_input_type === 'tel' ) {
                      if ( !field.field_answer.answer || !this.utils.isValidContactNumber(field.field_answer.answer) ) {
                        this.addInvalidFieldErrorMessage('Format of: '  + field.field_label);
                        valid = false;
                      }
                    }
                    // Check if the url input is valid.
                    if ( field.field_input_type === 'url' ) {
                      if ( !field.field_answer.answer || !this.utils.isValidURL(field.field_answer.answer) ) {
                        this.addInvalidFieldErrorMessage('Format of: '  + field.field_label);
                        valid = false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
    return valid;
  }

  /**
   * Evaluate field conditions to show/hide the field.
   * @param conditions Field conditions.
   * @returns boolean. True to show the field and false to hide the field.
   */
  evaluateConditions(formField?: DynamicFormFieldModel) {
    if ( formField.field_conditions && formField.field_conditions.length > 0 ) {
      let conditions_array: string[] = [];
      formField.field_conditions.forEach((condition, i) => {
        this.form.fields.forEach((targetField, j) => {
          if ( targetField.id == condition.dynamic_form_target_field_id && condition.condition_operator != "" ) {
            if ( ['checkbox', 'toggle'].indexOf(targetField.field_type) > -1 ) {
              targetField.field_options.forEach((fieldOption, k) => {
                if ( fieldOption.option_text == condition.condition_value ) {
                  conditions_array.push('this.form.fields['+j+'].field_options['+k+'].option_answer.answer ' + condition.condition_operator + ' ' + (condition.condition_checked ? 'true' : 'false'));
                }
              });
            } else {
              // If no field answer is provided, the field should remain hidden.
              if ( typeof targetField.field_answer != 'undefined' && targetField.field_answer != null && typeof targetField.field_answer.answer != 'undefined' && targetField.field_answer.answer != null ) {
                // Compare numeric field values without quotes.
                if ( targetField.field_type == 'input' && targetField.field_input_type == 'number' ) {
                  // Numeric values should be present.
                  if ( targetField.field_answer.answer != '' ) {
                    conditions_array.push('this.form.fields['+j+'].field_answer.answer ' + condition.condition_operator + ' ' + Number(condition.condition_value));
                  } else {
                    // Hide if the target numeric field is unanswered.
                    conditions_array.push('false');
                  }
                } else {
                  conditions_array.push('this.form.fields['+j+'].field_answer.answer ' + condition.condition_operator + ' "' + (condition.condition_value ? condition.condition_value : '') + '"');
                }
              } else {
                // Hide if the target field is unanswered.
                conditions_array.push('false');
              }
            }
          }
        });
      });
      // If any conditions are constructed, run the evaluation.
      if ( conditions_array.length > 0 ) {
        return eval(conditions_array.join(' ' + formField.field_logical_operator + ' '));
      }
    }
    return true;
  }

  /**
   * Extracts phone details from the given event and updates the corresponding field in the form.
   * @param {any} event - The event containing the phone details.
   * @param {number} fieldIndex - The index of the field in the form.
   * @return {void}
   */
  extractPhoneDetails(event: any, fieldIndex: number): void {
    if ( event ) {
      // Extract the phone input field details.
      this.form.fields[fieldIndex].field_answer.answer = event.hasOwnProperty('number') && event.number !== null ? event.number : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_country_code'] = event.hasOwnProperty('countryCode') && event.countryCode !== null ? event.countryCode : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_dial_code'] = event.hasOwnProperty('dialCode') && event.dialCode !== null ? event.dialCode : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_e164'] = event.hasOwnProperty('e164Number') && event.e164Number !== null ? event.e164Number : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_error_state'] = event.hasOwnProperty('errorState') && event.errorState !== null ? event.errorState : true;
    }
  }

  /**
   * This receives files from the file manager to store as files that need to be uploaded.
   * @param files List of files to upload.
   * @param index The form field index to group files to upload.
   */
  onSelectFiles(files?: any[], index?: number): void {
    // Check if there are any files to upload.
    if ( files.length > 0 ) {
      // Create the array if it does not exist.
      if ( typeof this.selectedFilesToUpload[index] == 'undefined' ) {
        this.selectedFilesToUpload[index] = [];
      }
      // Push the files that should be uploaded into the array.
      this.selectedFilesToUpload[index].push(...files);
    }
  }

  /**
   * Remove the file from the list of uploaded files.
   * @param i The form field list index.
   * @param j The form field answer file list index.
   * @param field_answer_id The form field answer id.
   * @param file_id The form field answer file id.
   */
   onRemoveUploadedFile(i: number, j: number, field_answer_id: number, file_id: number, evt: any) {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to remove the file?', [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('delete', `v2/file-manager/dynamic_form_field_answer/${field_answer_id}/${file_id}`)
          .then((response: any) => {
            // Remove the file from the array.
            this.form.fields[i].field_answer.files.splice(j, 1);
            this.utils.showToast('The file was removed.');
          })
          .catch((errorResponse: any) => {
            // The file could not be removed.
            this.utils.showModal('Remove File', 'We could not remove the file. Please try again.');
          });
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Saves the form progress.
   * @param form
   * @returns
   */
  onSaveForm(form: NgForm) {
    // Skip form validation in favour of saving user answer progress.
    if ( this.form.id ) {
      // Stop saving if the user does not confirm saving the progress.
      if ( ['Assigned', 'In Progress', 'Rejected'].indexOf(this.form.pivot.dynamic_form_status) == -1 ) {
        this.utils.showModal('Confirmation Needed', 'Are you sure you want to save your form progress? You will need to resubmit it afterwards.', () => {
          this.saveAnswers();
        });
        return;
      }
      this.saveAnswers();
    }
  }

  /**
   * Submits the form for completion.
   * @param form
   * @returns
   */
  onSubmitForm(form: NgForm, autoCloseForm: boolean = false) {
    // Clear form validation errors.
    this.invalidFieldErrorMessages.length = 0;
    // Keep validation since all required fields must be filled in before the form can be submitted.
    if ( !this.validateFieldOptions() || !form.valid ) {
      this.utils.showFormValidationError(this.invalidFieldErrorMessages.length > 0 ? '<p>The following field(s) are required/invalid:</p><ul><li>' + this.invalidFieldErrorMessages.join('</li><li>') + '</li></ul>' : '');
      return;
    }

    if ( this.form.id ) {
      this.saveAnswers().then(() => {
        this.api.makeRequest('put', `v2/user/assigned-forms/${this.form.id}/submit`, this.form)
        .then((response: DynamicFormModel) => {
          // Closing the dialog form after a successful save.
          if ( autoCloseForm ) {
            this.dialogRef.close();
          }
        })
        .catch((errorResponse: any) => {
          this.utils.handleAPIErrors(errorResponse);
        });
      });
    }
  }

  /**
   * Saves the user's answers.
   * @returns Returns a promise.
   */
  private saveAnswers(): Promise<void> {
    return this.api.makeRequest('put', `v2/user/assigned-forms/${this.form.id}`, this.form)
      .then((response: DynamicFormModel) => {
        // Store the form.
        this.form = response;
        // Prepare form field answers.
        this.prepareFormFieldAnswers();
        // Check if there are any files to upload.
        if ( this.selectedFilesToUpload.length > 0 ) {
          this.form.fields.forEach((field: DynamicFormFieldModel, i: number) => {
            // Only process the file picker field.
            if ( field.field_type == 'filepicker' ) {
              this.selectedFilesToUpload.forEach((files: File[], j: number) => {
                // If the index of the field matches the stored index based on field indexes (j), then we want to process file uploads.
                if ( i == j ) {
                  // Make the request to upload the files.
                  this.api.makeUploadRequest(`v2/file-manager/dynamic_form_field_answer/${field.field_answer.id}`, files)
                  .then((response) => {
                    this.utils.showToast('Your files successfully uploaded.');
                  })
                  .finally(() => {
                    // Clear the list to prevent double uploads.
                    this.selectedFilesToUpload[j].length = 0;
                  });
                }
              });
            }
          });
        }
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Check if the form can be saved.
   * Forms can only be saved if they are in any of the following statuses:
   * - Assigned
   * - In Progress
   * - Pending
   * - Submitted
   * - Rejected
   */
  canSaveForm(): boolean {
    // Check if any of the pivot data is missing.
    if ( !this.form || !this.form.pivot || !this.form.pivot.dynamic_form_status ) {
      return false;
    }
    return ['Assigned', 'In Progress', 'Pending', 'Submitted', 'Rejected'].indexOf(this.form.pivot.dynamic_form_status) > -1;
  }

  /**
   * Check if the user is allowed to change the form status to "Work Completed".
   * The user can only change the form status if it is any of the following:
   * - Approved: Work in Progress
   */
  canCompleteWork() {
    if ( !this.form || !this.form.pivot || !this.form.pivot.dynamic_form_status ) {
      return false;
    }
    return ['Approved: Work in Progress'].indexOf(this.form.pivot.dynamic_form_status) > -1;
  }

  /**
   * Handles the action when a user's public profile is viewed.
   *
   * @param {string} hash - The unique hash identifier of the user.
   *
   * @returns {void} - This method does not return a value.
   */
  onUserPublicView(hash: string): void {
    this.utils.showComponentDialog(UserPublicProfileComponent, hash, {
      width: '90%'
      }, () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

  /**
   * Updates the meta property of the field answer with the provided base64 image data.
   *
   * @param {number} field_index - The index of the field in the form.
   * @param {DigitalSignatureMeta} meta_output - The base64 image data to be added to the meta property.
   * @return {void}
   */
  onLockSignature(field_index: number, meta_output: DigitalSignatureMeta): void {
    // Check if the meta property of the field is defined or not.
    if ( typeof this.form.fields[field_index].field_answer.meta != 'undefined' ) {
      // Add the base64 image data to the meta property.
      this.form.fields[field_index].field_answer.meta = meta_output;
    }
  }

  /**
   * Opens the account's connected network users selector. This is only for testing the field in the form builder.
   * Selected users are not shown to assigned users.
   *
   * @return {void}
   * @param field_index - The field index to reference.
   */
  onOpenUsersSelectorForUserSelectorField(field_index: number): void {
    // Make sure the users_id property is set.
    if ( typeof this.form.fields[field_index].field_answer.meta['user_ids'] == 'undefined' ) {
      this.form.fields[field_index].field_answer.meta['user_ids'] = [];
      this.form.fields[field_index].field_answer.meta['user_objects'] = [];
    }
    // Open the users selector and store the results in the field meta.
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      multiple: true,
      selected: this.form.fields[field_index].field_answer.meta['user_ids'],
      selected_objects: this.form.fields[field_index].field_answer.meta['user_objects'],
      visitors_from_all_sites: true,
      return_objects: true
    })
      .then((response: number[]): void => {
        if ( typeof response != 'undefined' ) {
          // Store user objects.
          this.form.fields[field_index].field_answer.meta['user_objects'] = response;
          // Extract and store user ids.
          this.form.fields[field_index].field_answer.meta['user_ids'] = response.map((user: any) => user.id);
        }
      });
  }

  /**
   * Removes a user object for a user selector field.
   *
   * @return {void}
   * @param field_index - The field index to reference.
   * @param user_object_index - The user object index to reference.
   * @param event
   */
  onRemoveUserObjectForUserSelectorField(field_index: number, user_object_index: number, event: any): void {
    // Stop event propagation and default behaviour.
    event.preventDefault();
    event.stopPropagation();
    // Get user object at index.
    const user_object = this.form.fields[field_index].field_answer.meta['user_objects'][user_object_index];
    // Remove the user id from user ids by user id.
    const user_id_index = this.form.fields[field_index].field_answer.meta['user_ids'].indexOf(user_object.id);
    if ( user_id_index > -1 ) {
      this.form.fields[field_index].field_answer.meta['user_ids'].splice(user_id_index, 1);
    }
    // Remove the user_object by index.
    this.form.fields[field_index].field_answer.meta['user_objects'].splice(user_object_index, 1);
  }
}
