import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { IncidentModel } from 'src/app/models/incident.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { IncidentsLtisEditComponent } from '../incidents-ltis-edit/incidents-ltis-edit.component';

@Component({
  selector: 'app-incidents-ltis',
  templateUrl: './incidents-ltis.component.html',
  styleUrls: ['./incidents-ltis.component.scss']
})
export class IncidentsLtisComponent implements OnInit, AfterViewInit {

  @Input() incident: IncidentModel;

  @Output() closed_incident_lti_evt: EventEmitter<any> = new EventEmitter();

  displayedColumns: string[] = [
    'select',
    'incident_lti_id',
    'affected_user_contact_person',
    'affected_user_email',
    'affected_user_phone',
    'actual_lti_days',
    'actual_lti_hours',
    'actions'
  ];
  dataSource = new IncidentLtiDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
  ) { }

  ngOnInit() {
    this.dataSource.incident_id = this.incident.id;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData();
    })).subscribe();
  }

  onEditLTI(incident_lti_id: number = null) {
    this.utils.showComponentDialog(IncidentsLtisEditComponent, {
      incident: this.incident,
      incident_lti_id: incident_lti_id
    })
    .then(() => {
      this.dataSource.getData();
      this.closed_incident_lti_evt.emit();
    });
  }

  onRemove(incident_lti_id: number) {
    this.utils.showModal('Delete Incident LTI Record', 'Are you sure you want to delete the incident LTI record?', () => {
      this.api.makeRequest('delete', `v2/incidents/${this.incident.id}/ltis/${incident_lti_id}`)
      .then(() => {
        this.utils.showToast('The incident LTI record was deleted.');
      })
      .finally(() => {
        this.dataSource.getData();
        this.closed_incident_lti_evt.emit();
      });
    });
  }

  onRemoveSelected() {
    this.utils.showModal('Delete Incident LTI Records', 'Are you sure you want to delete the selected incident LTI records?', () => {
      const incident_lti_ids = this.dataSource.selection.selected.join(',');
      this.api.makeRequest('delete', `v2/incidents/${this.incident.id}/ltis/${incident_lti_ids}`)
      .then(() => {
        this.utils.showToast('The selected incident LTI records were deleted.');
      })
      .finally(() => {
        this.dataSource.getData();
        this.closed_incident_lti_evt.emit();
      });
    });
  }

}

export class IncidentLtiDataSource extends ApiDataSource {

  incident_id: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/incidents/${this.incident_id}/ltis`, resetOffset);
  }
}
