import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatIconModule,
  MatDialogModule,
  MatListModule,
  MatBadgeModule,
  MatButtonModule,
  MatInputModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatMenuModule,
  MatCheckboxModule
} from '@angular/material';
import { FoldersViewComponent } from './folders-view/folders-view.component';
import { FoldersEditComponent } from './folders-edit/folders-edit.component';
import { FormsModule } from '@angular/forms';
import { FoldersComponent } from './folders.component';
import { Routes, RouterModule } from '@angular/router';
import { FilesModule } from '../files/files.module';
import { DirectivesModule } from '../directives/directives.module';
import { AuthGuard } from '../guards/auth.guard';
import {MatTooltipModule} from "@angular/material/tooltip";
import { PipesModule } from '../pipes/pipes.module';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {SharedModule} from "../shared/shared.module";

const routes: Routes = [
  {
    path: 'folders',
    component: FoldersComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
    declarations: [
        FoldersViewComponent,
        FoldersEditComponent,
        FoldersComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
        MatListModule,
        MatBadgeModule,
        MatButtonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatCheckboxModule,
        FilesModule,
        DirectivesModule,
        MatTooltipModule,
        PipesModule,
        MatDatepickerModule,
        MatOptionModule,
        MatSelectModule,
        SharedModule,
    ],
    exports: [
        FoldersEditComponent
    ],
    entryComponents: [
        FoldersViewComponent,
        FoldersEditComponent
    ]
})
export class FoldersModule {}
