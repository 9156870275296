import {Component, Inject, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-sites-filter',
  templateUrl: './sites-filter.component.html',
  styleUrls: ['./sites-filter.component.scss']
})
export class SitesFilterComponent implements OnInit {

  filters: SitesFilter = {
    site_type: '',
    site_status: '',
    date_range: []
  } as SitesFilter;

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesFilterComponent>
  ) { }

  ngOnInit(): void {
    // Store the filter values.
    this.filters.site_type = this.data['site_type'] || '';
    this.filters.site_status = this.data['site_status'] || '';
    this.filters.date_range = this.data['date_range'] || [];
  }

  /**
   * Clears the filter values and saves the changes.
   *
   * @return {void}
   */
  onClearAndClose(): void {
    // Clear the filter values.
    this.filters.site_type = '';
    this.filters.site_status = '';
    this.filters.date_range = [];
    // Save and close the dialog.
    this.onSaveAndClose();
  }

  /**
   * Sets the value of the `date_range` property in the `filters` object.
   *
   * @param {any} event - The event object containing the new selected date range.
   * @return {void}
   */
  getOutputDateRange(event: any): void {
    this.filters.date_range = event;
  }

  /**
   * Saves and closes the dialog.
   *
   * @return {void}
   */
  onSaveAndClose(): void {
    // Close the dialog and respond with the filters.
    this.dialogRef.close(this.filters);
  }
}

export interface SitesFilter {
  site_type: string;
  site_status: string;
  date_range: Date[];
}
