import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatPaginator,
  MatSort
} from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { CustomDataSource } from 'src/app/utils/custom-data-source';

@Component({
  selector: 'app-erps-selector',
  templateUrl: './erps-selector.component.html',
  styleUrls: ['./erps-selector.component.scss']
})
export class ErpsSelectorComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = ['select', 'name'];
  dataSource: ERPSelectorDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<SitesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // set multiple if it is not present to false
    if (typeof this.data['multiple'] === 'undefined') {
      this.data['multiple'] = false;
    }

    // construct the datasource
    this.dataSource = new ERPSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // set a default selection of nothing if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // loop and select the values
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }

    // get the list of sites
    this.dataSource.getData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  // clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * before the dialog close we want to store the new active site id
   */
  onSaveAndClose() {
    let response: number | number[];

    if (this.data['multiple']) {
      response = this.dataSource.selection.selected;
    } else {
      if (this.dataSource.selection.selected.length) {
        response = this.dataSource.selection.selected[0];
      } else {
        response = 0;
      }
    }

    this.dialogRef.close(response);
  }
}

export class ERPSelectorDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('erps', resetOffset);
  }
}
