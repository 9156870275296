import { NgModule } from '@angular/core';
import { MyPrequalificationsComponent } from './my-prequalifications.component';
import {
  MatButtonModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatIconModule,
  MatDialogModule,
  MatTooltipModule
} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MyPrequalificationsEditComponent } from './my-prequalifications-edit/my-prequalifications-edit.component';
import { MyPrequalificationsViewComponent } from './my-prequalifications-view/my-prequalifications-view.component';
import { MyPrequalificationsRoutingModule } from './my-prequalifications-routing.module';
import { MyItemsModule } from '../my-items/my-items.module';
import { ClipboardModule } from 'ngx-clipboard';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    MyPrequalificationsComponent,
    MyPrequalificationsEditComponent,
    MyPrequalificationsViewComponent
  ],
  imports: [
    CommonModule,
    MyPrequalificationsRoutingModule,
    MatButtonModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatDialogModule,
    MyItemsModule,
    ClipboardModule,
    MatTooltipModule,
    DirectivesModule,
    PipesModule
  ],
  entryComponents: [
    MyPrequalificationsViewComponent,
    MyPrequalificationsEditComponent
  ]
})
export class MyPrequalificationsModule {}
