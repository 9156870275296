import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { AuditsModel } from '../audits.model';
import { AuditsService } from '../audits.service';
import { AuditsTemplatesEditComponent } from '../audits-templates-edit/audits-templates-edit.component';
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-audits-templates',
  templateUrl: './audits-templates.component.html',
  styleUrls: ['./audits-templates.component.scss']
})
export class AuditsTemplatesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource = new AuditsDataSource(this.app, this.api);
  displayedColumns = [
    'select',
    'id',
    'name',
    'date_created',
    'buttons'
  ];
  query: string;

  constructor(
    private auditsService: AuditsService,
    public utils: UtilsService,
    private app: AppService,
    private api: ApiService,
    private dialog: MatDialog,
    public chartsUtils: ChartsUtilsService
  ) {}

  ngOnInit() {
    this.dataSource.getData(false);
  }

  add() {
    this.edit(new AuditsModel());
  }

  edit(audit: AuditsModel) {
    this.dialog
      .open(AuditsTemplatesEditComponent, {
        width: '500px',
        data: audit
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onRemoveSelected() {
    const selected = this.dataSource.selection.selected;
    this.utils.showModal(
      'Remove Selected Inspection/Audit Templates',
      `Are you sure you want to remove ${selected.length} Inspection/Audit templates?`,
      () => this.remove(selected)
    );
  }

  private remove(ids: number[]) {
    this.auditsService.remove(ids).subscribe(() => {
      this.utils.showToast('Inspection/Audit templates have been removed.');
      this.dataSource.selection.clear();
      this.dataSource.getData(false);
    });
  }

  onRemove(audit: AuditsModel) {
    this.utils.showModal(
      'Remove Inspection/Audit',
      `Are you sure you want to remove the "${audit.name}" Inspection/Audit?`,
      () => this.remove([audit.id])
    );
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns based on their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }
}

export class AuditsDataSource extends CustomDataSource {
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset = false) {
    this.getDataFromLaravelAPI('audits?template=true', resetOffset);
  }
}
