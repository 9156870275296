import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MAT_DIALOG_DATA } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { DynamicFormModel } from 'src/app/dynamic-forms/dynamic-form.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';

@Component({
  selector: 'app-user-dynamic-forms-template-selector',
  templateUrl: './user-dynamic-forms-template-selector.component.html',
  styleUrls: ['./user-dynamic-forms-template-selector.component.scss']
})
export class UserDynamicFormsTemplateSelectorComponent implements OnInit {

  displayedColumns: string[] = [
    'id',
    'title',
    'from',
    'date_created',
    'date_modified'
  ];

  dataSource = new UserDynamicFormsTemplateSelectorDataSource(this.app, this.api, this.data['multiple'] || false);

  // The paginator and list sorter.
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<UserDynamicFormsTemplateSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      selected?: number[],
      multiple?: boolean,
      return_object?: boolean, // Only works when multiple is false.
      selected_site_ids?: number[]
    }
  ) { }

  ngOnInit() {
    // Set a default selection of nothing if no selected values are passed through.
    if (typeof this.data['selected'] == 'undefined') {
      this.data['selected'] = [];
    }

    // Loop and select the selected values.
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }

    // Add the select column if multiple is enabled.
    if ( (typeof this.data['multiple'] != 'undefined' && this.data['multiple'] == true) || this.dataSource.multiple ) {
      this.displayedColumns.unshift('select');
    } else {
      // Alternatively, show the actions column where the user can select one record.
      this.displayedColumns.push('actions');
    }

    // Store the selected site ids for query limits.
    if ( typeof this.data['selected_site_ids'] != 'undefined' && this.data['selected_site_ids'].length > 0 ) {
      this.dataSource.selected_site_ids = this.data['selected_site_ids'];
    }

    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place.
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Select the form from the list and determine to send the id or object data as a response.
   * @param form The form to select.
   * @param event
   */
  onSelectForm(form: DynamicFormModel, event: Event) {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();

    // Check if the site object should be retruned.
    if ( (typeof this.data['return_object'] != 'undefined' && this.data['return_object'] == true) ) {
      // Return the site object.
      this.dialogRef.close(form);
    } else {
      // Return the site object.
      this.dialogRef.close(form.id);
    }
  }

  /**
   * Send the selected ids back as a response.
   */
  onSelectForms() {
    // Return the selected ids.
    this.dialogRef.close(this.dataSource.selection.selected);
  }
}

export class UserDynamicFormsTemplateSelectorDataSource extends ApiDataSource {

  order_by = 'title';
  order = 'asc';

  // The list of sites to filter by.
  selected_site_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/user/form-templates`, resetOffset, {
      selected_site_ids: this.selected_site_ids.join(',')
    });
  }
}
