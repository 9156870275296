import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contractors-related-accounts',
  templateUrl: './contractors-related-accounts.component.html',
  styleUrls: ['./contractors-related-accounts.component.scss']
})
export class ContractorsRelatedAccountsComponent implements OnInit {

  displayedColumns: string[] = [
    'name',
    'actions'
  ];

  dataSource = new LinkedSlaveAccountsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  contractorId: number;

  associatedAccountIds: number[] = [];

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.contractorId = Number(this.route.parent.snapshot.params['contractor_id']);

    if ( !this.contractorId ) {
      this.router.navigate(['contractors']);
      return; // Stop further execution
    }

    this.onRefresh();
  }

  onRefresh(resetOffset: boolean = false) {
    this.api.makeRequest('get', `v1/contractors/${this.contractorId}/related-accounts`)
    .then((_associatedAccountIds) => {
      this.associatedAccountIds.length = 0;
      this.associatedAccountIds.push(..._associatedAccountIds);
    })
    .finally(() => {
      this.dataSource.getData(resetOffset);
    });
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();

  }

  onTogglelink(accountId: number, elementRef: any) {

    const isAssociated: boolean  = this.isAssociated(accountId);

    const action = isAssociated ? 'unlink' : 'link';
    const message = isAssociated ? 'remove the ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' from' : 'add the ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' to';

    this.utils.showQuickActions(elementRef.target, `Are you sure you want to ${action} the ${this.utils.getLangTerm('contractors.singular', 'Contractor')}? <br>This will ${message} the related account.`, [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('put', `v1/contractors/${this.contractorId}/related-accounts`, {
            account_id: accountId
          })
          .then(() => {
            this.utils.showToast(`The ${this.utils.getLangTerm('contractors.singular', 'Contractor')} was successfully ${action}ed.`);
            this.onRefresh();
          })
          .catch((error) => {
            this.utils.showModal('Failed', 'We were unable to link/unlink the account. Please make sure that the account is linked as a related account.');
          });
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  isAssociated(accountId: number) {
    return this.associatedAccountIds.indexOf(accountId) >= 0;
  }

}

export class LinkedSlaveAccountsDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/related-accounts', resetOffset);
  }
}
