import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilsService } from 'src/app/shared/utils.service';
import { IncidentsCausesEditComponent } from '../../incidents-causes/incidents-causes-edit/incidents-causes-edit.component';
import { IncidentNoteModel } from '../../incident-note.model';
import { IncidentNoteService } from '../incident-note.service';

@Component({
  selector: 'app-incident-notes-edit',
  templateUrl: './incident-notes-edit.component.html',
  styleUrls: ['./incident-notes-edit.component.scss']
})
export class IncidentNotesEditComponent {
  inProgress = false;

  constructor(
    private noteService: IncidentNoteService,
    @Inject(MAT_DIALOG_DATA) public note: IncidentNoteModel,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<IncidentsCausesEditComponent>
  ) {}

  onSubmit() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    this.note.id ? this.update() : this.create();
  }

  update() {
    this.noteService.update(this.note).subscribe(
      () => {
        this.utils.showToast('Updated note');
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (_error: any) => (this.inProgress = false)
    );
  }

  create() {
    this.noteService.create(this.note).subscribe(
      () => {
        this.utils.showToast('Created note');
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (_error: any) => (this.inProgress = false)
    );
  }

  onDelete() {
    this.utils.showModal(
      'Remove Note',
      'Are you sure you want to remove this note?',
      () => {
        this.noteService.remove([this.note.id]).subscribe(() => {
          this.utils.showToast('Removed note.');
          this.dialogRef.close(true);
        });
      }
    );
  }
}
