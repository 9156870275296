import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InductionService } from 'src/app/dashboard/induction.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { UserInductionComponent } from '../user-induction/user-induction.component';

@Component({
  selector: 'app-user-induction-modules',
  templateUrl: './user-induction-modules.component.html',
  styleUrls: ['./user-induction-modules.component.scss']
})
export class UserInductionModulesComponent implements OnInit {

  course_id: number;

  constructor(
    public inductionService: InductionService,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserInductionModulesComponent>
  ) {}

  ngOnInit() {
    this.course_id = this.data.course_id;
  }

  onOpenModule(index: number) {
    this.utils.showComponentDialog(
      UserInductionComponent,
      { module_index: index },
      { width: '800px' }
    );
  }

  canSubmit() {
    let can_submit = true;
    if (this.inductionService.data.modules) {
      this.inductionService.data.modules.forEach((module) => {
        if (typeof module.was_saved === 'undefined') {
          can_submit = false;
        }
      });
    } else {
      can_submit = false;
    }

    return can_submit;
  }

  onSubmitAnswers() {
    this.inductionService.submitAnswers(this.course_id, () => {
      this.dialogRef.close();
    });
  }
}
