import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';
import { UtilsService } from '../shared/utils.service';
import { ApiDataSource } from '../utils/api-data-source';
import { MessageEditComponent } from './message-edit/message-edit.component';
import { Message } from '../models/message.model';
import { MessageViewComponent } from './message-view/message-view.component';
import { MessagesFilterComponent } from "./messages-filter/messages-filter.component";
import {UserPublicProfileComponent} from "../shared/user-public-profile/user-public-profile.component";
import {ChartsUtilsService} from "../charts/charts-utils.service";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  listColumns: string[] = [
    'select',
    'id',
    'subject',
    'type',
    'status',
    'priority',
    'reply_to',
    'sent_to',
    'date_modified',
    // 'date_modified_UTC',
    'send_at',
    // 'send_at_UTC',
    'actions'
  ];
  listDataSource = new MessagesDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.listDataSource.limit = paginator['pageSize'];
      this.listDataSource.offset = paginator['pageIndex'];
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.listDataSource.order = sorter['direction'];

      // sorting for utc time by parsing original time
      if (sorter['active'] === "date_modified_UTC") {
        this.listDataSource.order_by = "date_modified";
      } else if (sorter['active'] === "send_at_UTC") {
        this.listDataSource.order_by = "send_at";
      } else {
        this.listDataSource.order_by = sorter['active'];
      }

      this.listDataSource.getData();
    })).subscribe();
  }

  onComposeMessage(messageId: number = null) {
    this.utils.showComponentDialog(MessageEditComponent, {
      messageId: messageId
    })
      .then(() => {
        this.listDataSource.getData();
      });
  }

  onViewMessage(messageId: number = null) {
    this.utils.showComponentDialog(MessageViewComponent, {
      messageId: messageId
    })
      .then(() => { });
  }

  // Remove a message by id.
  onRemove(messageId: number, evt: any) {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to remove this message?', [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('delete', `v2/messages/${messageId}`)
            .then(() => {
              this.listDataSource.getData();
              this.utils.showToast('The message was removed.');
            })
            .catch((error) => {
              this.utils.handleAPIErrors(error);
            });
        }
      },
      {
        text: 'No',
        handler: () => { }
      }
    ]);
  }

  // Remove multiple selected messages.
  onRemoveSelected() {
    this.utils.showModal(
      'Remove Selected Messages',
      'Are you sure you want to remove the selected messages?',
      () => {
        const messageIds = this.listDataSource.selection.selected.join(',');
        this.api.makeRequest('delete', `v2/messages/${messageIds}`)
          .then(() => {
            this.listDataSource.selection.clear();
            this.listDataSource.getData();
            this.utils.showToast('The selected messages was removed.');
          })
          .catch((error) => {
            this.utils.handleAPIErrors(error);
          });
      }
    );
  }

  canEditMessage(message: Message) {
    return ['draft', 'cancelled'].indexOf(message.status) > -1;
  }

  canQueueMessage(message: Message) {
    return message.status == 'draft';
  }

  canDequeueMessage(message: Message) {
    return message.status == 'queued';
  }

  onQueueMessage(message: Message, evt: any) {
    if (!this.canQueueMessage(message)) {
      this.utils.showModal('Queueing Message Failed', 'The message cannot be queued. Please make sure you saved the message and added at least 1 ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ', ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' or user.');
      return;
    }
    // Confirm it.
    this.utils.showQuickActions(evt.target, 'Are you sure you want to queue this message?', [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('put', `v2/messages/${message.id}/queue`)
            .then((response: Message) => {
              this.utils.showToast('Your message is queued and will be processed soon.');
              this.listDataSource.getData();
            })
            .catch((errors) => {
              this.utils.handleAPIErrors(errors);
            });
        }
      },
      {
        text: 'No',
        handler: () => { }
      }
    ]);
  }

  onDequeueMessage(message: Message, evt: any) {
    if (!this.canDequeueMessage(message)) {
      this.utils.showModal('Dequeueing Message Failed', 'The message cannot be dequeued. Please refresh the page and try again.');
      return;
    }

    this.utils.showQuickActions(evt.target, 'Are you sure you want to dequeue this message?', [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('put', `v2/messages/${message.id}/dequeue`)
            .then((response: Message) => {
              this.utils.showToast('Your message is dequeued and will no longer be processed.');
              this.listDataSource.getData();
            })
            .catch((errors) => {
              this.utils.handleAPIErrors(errors);
            });
        }
      },
      {
        text: 'No',
        handler: () => { }
      }
    ]);
  }

  onCreateFrom(message: Message) {
    const newMessage: Message = new Message();
    newMessage.createFrom(message);
    this.utils.showComponentDialog(MessageEditComponent, {
      message: { ...newMessage }
    })
      .then(() => {
        this.listDataSource.getData();
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      MessagesFilterComponent,
      {
        type: this.listDataSource.type,
        status: this.listDataSource.status,
        priority: this.listDataSource.priority,
        date_filter: this.listDataSource.messages_date,
        date_range: this.listDataSource.date_range
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.listDataSource.type = typeof results.type != 'undefined' ? results.type : this.listDataSource.type;
          this.listDataSource.status = typeof results.status != 'undefined' ? results.status : this.listDataSource.status;
          this.listDataSource.priority = typeof results.priority != 'undefined' ? results.priority : this.listDataSource.priority;
          this.listDataSource.messages_date = typeof results.date_filter != 'undefined' ? results.date_filter : this.listDataSource.messages_date;
          this.listDataSource.date_range = results.date_range ? results.date_range : this.listDataSource.date_range;
          this.listDataSource.getData();
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.listDataSource.getData();
      }
    );
  }

}

export class MessagesDataSource extends ApiDataSource {

  order_by = 'date_created';
  order = 'desc';

  type: string = '';
  status: string = '';
  priority: string = '';

  messages_date: string = 'all';
  date_range: Date[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/messages', resetOffset, {
      type: this.type ? this.type : "",
      status: this.status ? this.status : "",
      priority: this.priority ? this.priority : "",
      date_filter: this.messages_date ? this.messages_date : "all",
      date_filter_range: this.date_range && this.date_range.length > 1 ? [
        this.date_range[0].getTime() / 1000,
        this.date_range[1].getTime() / 1000
      ].join(',') : ""
    });
  }
}
