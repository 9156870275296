import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { ContractorsAddComponent } from '../contractors-add/contractors-add.component';
import { ContractorModel } from 'src/app/models/contractor.model';
import { ContractorsDetailsComponent } from '../contractors-details/contractors-details.component';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

declare var saveAs: any;

@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.css']
})
export class ContractorsComponent implements OnInit, AfterViewInit {

  listColumns: string[] = [
    'select',
    'icons',
    'name',
    'contact_person',
    'industry' // Score and actions are appended programatically
  ];
  listDataSource = new ContractorsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    private dialog: MatDialog,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    // Exclude the score from free accounts
    if (this.app.account.subscription != 'Free') {
      this.listColumns.push('pre_qualification_score');
    }
    // Add list actions
    this.listColumns.push('date_created');
    // this.listColumns.push('date_created_UTC');
    this.listColumns.push('actions');

    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.listDataSource.limit = this.paginator.pageSize;
      this.listDataSource.offset = this.paginator.pageIndex;
      this.listDataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.listDataSource.order_by = "date_created";
      } else {
        this.listDataSource.order_by = this.sort.active;
      }

      this.listDataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Adding a contractor.
   */
  onAddContractor() {
    this.utils.showComponentDialog(ContractorsAddComponent, {}, {
      width: '800px'
    }, () => {
      this.listDataSource.getData();
    });
  }

  onExport(type: string = 'csv', id?: number) {
    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.listDataSource.selection.selected);
    }

    // Get the current date object
    const date = new Date();

    this.oldApi.makeDownloadRequest(`contractors/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      order_by: this.listDataSource.order_by,
      order: this.listDataSource.order
    }).then((response) => {
      saveAs(response, `${ this.utils.getLangTerm('contractors.plural', 'Contractors') } - ${this.app.account.name} - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`);
    });
  }

  onView(contractor: ContractorModel) {
    this.dialog
      .open(ContractorsDetailsComponent, {
        width: '500px',
        data: contractor
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.listDataSource.getData(true);
      });
  }

  /**
   *
   * @param contractor_account_id
   *
   * remove a contractor from the actions list.
   */
  onRemove(contractorAccountId: number) {
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm('contractors.singular', 'Contractor'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + '?',
      () => {
        this.api.makeRequest('delete', `v2/contractors/${contractorAccountId}`)
          .then(() => {
            this.listDataSource.getData();
            this.utils.showToast('The ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' was removed.');
          })
          .catch((error) => {
            this.utils.handleAPIErrors(error);
          });
      }
    );
  }

  /**
   * remove a contractor from the list of selected checkboxes.
   */
  onRemoveSelected() {
    this.utils.showModal(
      'Remove Selected ' + this.utils.getLangTerm('contractors.plural', 'Contractors'),
      'Are you sure you want to remove the selected ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + '?',
      () => {
        const contractorAccountIds = this.listDataSource.selection.selected.join(',');
        this.api.makeRequest('delete', `v2/contractors/${contractorAccountIds}`)
          .then(() => {
            this.listDataSource.selection.clear();
            this.listDataSource.getData();
            this.utils.showToast('The selected ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + ' was removed.');
          })
          .catch((error) => {
            this.utils.handleAPIErrors(error);
          });
      }
    );
  }

  composeNewMessage() {
    if (this.listDataSource.selection.selected.length == 0) {
      this.utils.showToast('You need to select some ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + ' first.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectAccountIds: this.listDataSource.selection.selected
    })
      .then(() => {
        this.utils.showToast('You can view the message stats in the messages section.');
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.listDataSource.getData();
      }
    );
  }
}

export class ContractorsDataSource extends ApiDataSource {

  order_by = 'name';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/contractors', resetOffset);
  }
}
