import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {MasterChartsPopupDialogComponent} from "./master-charts-popup-dialog/master-charts-popup-dialog.component";
import {MatDialogRef} from "@angular/material/dialog";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class ChartsUtilsService {

  constructor(
    public dialog: MatDialog
  ) { }

  /**
   * Returns the default system chart colors.
   *
   * @returns {string[]} An array of string values representing the default system chart colors.
   */
  defaultSystemChartColors(): string[] {
    return [
      "#001f3f", // Navy Blue
      "#0074D9", // A slightly brighter blue
      "#228B22", // Forest Green
      "#013220", // Darker Forest Green
      "#614051", // Eggplant (deep purple)
      "#3d0c02", // Very dark brown (adds sophistication)
      "#4f2683", // Deep Indigo
      "#36454f", // Charcoal Gray
      "#4338CA", // Deep muted blue
      "#3B6978", // Dark Teal
      "#317873", // Darker muted teal
      "#353535",  // Very dark gray
    ];
  }

  /**
   * Opens the master charts popup dialog.
   *
   * @param {string} tabName - The selected tab name to display in the popup dialog.
   * @param {MatDialogConfig} dialogOptions - The options to configure the popup dialog. Default value is an empty object.
   *
   * @return {MatDialogRef<MasterChartsPopupDialogComponent>} The reference to the opened popup dialog instance.
   */
  onOpenMasterCharts(tabName: string, dialogOptions: MatDialogConfig = {}): MatDialogRef<MasterChartsPopupDialogComponent> {
    // Check if dialog options were provided.
    if ( !dialogOptions ) {
      // Define default dialog options.
      dialogOptions = {
        width: '90%',
        data: {
          selectedTab: tabName
        },
        autoFocus: false
      };
    } else {
      // Check if the width is specified.
      if ( !dialogOptions['width'] ) {
        dialogOptions['width'] = '90%';
      }
      // Pass data through.
      dialogOptions['data'] = {
        selectedTab: tabName
      };
      // Set autofocus to false.
      dialogOptions['autoFocus'] = false;
    }
    // Return the dialog instance.
    return this.dialog.open(MasterChartsPopupDialogComponent, dialogOptions);
  }

  /**
   * Gets the formatted date range in a string format.
   *
   * @param {Date[]} date_range - An array containing two Date objects representing the start and end dates.
   * @return {string} - The formatted date range string in the format "ddd, D MMM to ddd, D MMM".
   */
  getFormattedDateRange(date_range: Date[]): string {
    return `${moment(date_range[0]).format('ddd, D MMM')} to ${moment(date_range[1]).format('ddd, D MMM')}`;
  }
}
