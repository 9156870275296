import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {ToolModel} from "../tools.model";
import {MAT_DIALOG_DATA} from "@angular/material";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-tools-view',
  templateUrl: './tools-view.component.html',
  styleUrls: ['./tools-view.component.scss']
})
export class ToolsViewComponent implements OnInit {

  tool: ToolModel = new ToolModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const tool_id = this.id;
    if(tool_id) {
      this.getTool(tool_id);
    }
  }

  private getTool (tool_id: number) {
    this.api.makeRequest('get', `v2/tools/${tool_id}`)
      .then((response) => {
        this.tool = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
