<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Sign In/Out Records</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter Sign In/Out Records" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectSites()"
        [matBadge]="'' + (filters.site_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectContractors()"
        [matBadge]="'' + (filters.contractor_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <fa-icon [icon]="faHardHat"></fa-icon> {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectUsers()"
        [matBadge]="'' + (filters.user_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> Users
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectEmployees()"
        [matBadge]="'' + (filters.employee_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> {{ utils.getLangTerm('employees.plural', 'Employees') }}
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field style="width: 60px;" class="mx-3 mt-3">
        <mat-select placeholder="Type" [(ngModel)]="filters.type" name="type" matTooltip="Filter records by Type">
          <mat-option value="">All</mat-option>
          <mat-option value="In">In</mat-option>
          <mat-option value="Out">Out</mat-option>
          <mat-option value="Location">Location</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter Sign In/Out Date by date range." [dateRangeInput]="filters.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        class="mr-3"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>

      <button
        type="button"
        mat-raised-button
        class="mr-3"
        color="secondary"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>

      <button
        type="button"
        mat-raised-button
        *ngIf="filters.date_range.length == 2"
        color="secondary"
        (click)="onClearDateRange()">
        <mat-icon>clear</mat-icon> Clear Date Range
      </button>
    </td>
  </ng-container>
</div>

