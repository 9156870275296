import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UtilsService} from "../utils.service";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-risk-matrix',
  templateUrl: './risk-matrix.component.html',
  styleUrls: ['./risk-matrix.component.scss']
})
export class RiskMatrixComponent implements OnInit {

  // Get the risk matrix ref. It is used to convert html2canvas and download a png file.
  @ViewChild('risk_matrix_ref', { static: false }) risk_matrix_ref: ElementRef;

  // The default grid data.
  @Input('gridData') gridData: any[];

  // The risk matrix title. Also used for downloading the image.
  @Input('riskMatrixTitle') riskMatrixTitle: string;

  enableTitleForDownload: boolean = false;

  // Used to pass a reference to this component back to the parent component.
  @Output() referenceEvent: EventEmitter<RiskMatrixComponent> = new EventEmitter<RiskMatrixComponent>();

  // Used to pass a reference to this component back to the parent component.
  @Output() selectedRiskLevel: EventEmitter<RiskLevel> = new EventEmitter<RiskLevel>();

  // The filters to use for API requests.
  @Input('enableInteractiveMode') enableInteractiveMode: boolean;

  constructor(
    public utils: UtilsService
  ) { }

  ngOnInit(): void {
    // Check if the risk matrix title is set.
    if ( !this.riskMatrixTitle ) {
      this.riskMatrixTitle = 'Risk Matrix';
    }
    // Send a reference to the parent component.
    this.referenceEvent.emit(this);
  }

  /**
   * Checks if the provided 5x5 array is valid.
   *
   * @param {any[][]} gridData - The 5x5 array to be validated.
   * @return {boolean} - Returns true if the array is valid, otherwise false.
   */
  isValid5x5Array(gridData: any[][]): boolean {
    // Check if the input is an array
    if ( !Array.isArray(gridData) ) {
      return false;
    }
    // Check if the grid contains 5 elements (arrays) inside of it.
    if ( gridData.length != 5 ) {
      return false;
    }
    // Validate each inner array has 5 elements.
    for ( let innerGridData of gridData ) {
      if ( !Array.isArray(innerGridData) || innerGridData.length != 5 ) {
        return false;
      }
    }
    // All checks passed!
    return true;
  }

  /**
   * Downloads a copy of the HTML element as a PNG image using html2canvas.
   *
   * @returns {void}
   */
  public onDownload(): void {
    // Using html2canvas, download a copy of the html as a png.
    html2canvas(this.risk_matrix_ref.nativeElement).then((canvas) => {
      // Get the Image URI of the chart.
      const chartImageData = canvas.toDataURL('image/png');
      // Create and trigger a download request for the image.
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = chartImageData;
      a.download = this.riskMatrixTitle + '.png';
      a.click();
    });
  }

  /**
   * Retrieves the severity index in reverse order.
   *
   * @param {number} index - The original severity index.
   * @return {number} - The severity index in reverse order.
   */
  getSeverityIndexInReverseOrder(index: number): number {
    return [4,3,2,1,0].indexOf(index);
  }

  /**
   * Returns background color based on the risk level.
   *
   * @param {string} riskLevel - The risk level.
   * @return {string} The background color associated with the risk level. If risk level is not valid, an empty string is returned.
   */
  getBackgroundColors(riskLevel: string) {
    switch ( riskLevel ) {
      case 'Very Low':
        return 'bg-light-green';
      case 'Low':
        return 'bg-light-blue';
      case 'Moderate':
        return 'bg-yellow';
      case 'High':
        return 'bg-orange';
      case 'Critical':
        return 'bg-red';
      default:
        return '';
    }
  }

  /**
   * Selects the risk level based on the given likelihood and severity.
   *
   * @param {number} likelihood - The likelihood of the risk.
   * @param {number} severity - The severity of the risk.
   * @return {void}
   */
  onSelectRiskLevel(likelihood: number, severity: number): void {
    // Check if interactive mode is enabled.
    if ( typeof this.enableInteractiveMode != 'undefined' && this.enableInteractiveMode == true ) {
      // Send the selected risk level back to the parent component.
      this.selectedRiskLevel.emit({
        likelihood: likelihood,
        severity: severity
      } as RiskLevel);
    }
  }
}

export interface RiskLevel {
  likelihood: number;
  severity: number;
}
