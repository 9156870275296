import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {UtilsService} from "../../shared/utils.service";
import {tap} from "rxjs/operators";
import {MatDialog, MatPaginator, MatSort} from "@angular/material";
import {ActivatedRoute, Router} from "@angular/router";
import {TaskAnalysesRevisedWorkflowModel} from "../../models/task-analyses-revised-workflow.model";
import {SitesTaskAnalysesRevisedWorkflowsViewComponent} from "../sites-task-analyses-revised-workflows-view/sites-task-analyses-revised-workflows-view.component";
import {SitesTaskAnalysesRevisedWorkflowsTabsComponent} from "../sites-task-analyses-revised-workflows-tabs/sites-task-analyses-revised-workflows-tabs.component";
import {SitesTaskAnalysesRevisedVersioningComponent} from "../sites-task-analyses-revised-versioning/sites-task-analyses-revised-versioning.component";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {SitesTaskAnalysesRevisedWorkflowsStepsComponent} from "../sites-task-analyses-revised-workflows-steps/sites-task-analyses-revised-workflows-steps.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows',
  templateUrl: './sites-task-analyses-revised-workflows.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsComponent implements OnInit, AfterViewInit {

  @Input() private taRevisedId: number;
  @Input('site_id') site_id: number;

  @Input() public taRevisedPath: string;

  path: string;

  displayedColumns: string[] = [
    'select',
    'id',
    'step_no',
    'description',
    'created_by_user',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  dataSource = new SitesTaskAnalysesRevisedWorkflowsDataSource(this.app, this.api);
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // get the ta revised id and name from the params
    this.dataSource.ta_revised_id = this.taRevisedId;

    // get the site id from the parent route params
    this.dataSource.site_id = this.site_id; // Number(this.route.parent.snapshot.params['site_id']);

    // get sites path edit/view
    this.path = this.route.parent.snapshot.routeConfig.path;

    if(this.dataSource.ta_revised_id) {
      this.dataSource.getData();
      this.findTA(this.dataSource.ta_revised_id);
    } else {
      this.redirectBack();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns based on their corresponding date fields.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  private redirectBack() {
    if(this.dataSource.site_id && this.path) {
      this.router.navigate([`/sites/${this.dataSource.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  private findTA(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.dataSource.site_id
    })
      .then((response) => {
        this.ta_revised = response;

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onAdd() {
    this.onEdit(new TaskAnalysesRevisedWorkflowModel());
  }

  onView(ta_revised_workflow: TaskAnalysesRevisedWorkflowModel) {
    this.utils.showComponentDialog(
        SitesTaskAnalysesRevisedWorkflowsViewComponent,
      {
          ta_revised_workflow: ta_revised_workflow,
          site_id: this.dataSource.site_id,
          ta_revised_id: this.dataSource.ta_revised_id,
          path: this.path
        },
        { width: '700px' },
        () => {
          // Refresh the list regardless of how the dialog is closed.
          this.dataSource.getData(true);
        }
    );
  }

  onEdit(ta_revised_workflow: TaskAnalysesRevisedWorkflowModel) {
    this.dialog
      .open(SitesTaskAnalysesRevisedWorkflowsTabsComponent, {
        width: '1024px',
        data: {
          ta_revised_workflow: ta_revised_workflow,
          site_id: this.dataSource.site_id,
          ta_revised_id: this.dataSource.ta_revised_id,
          path: this.path
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if(result == "version") {
          // Do Not Refresh Data
        } else {
          // Refresh the list regardless of how the dialog is closed.
          this.dataSource.getData(true);
        }
      });
  }

  onReorderSteps() {
    this.dialog
      .open(SitesTaskAnalysesRevisedWorkflowsStepsComponent, {
        width: '700px',
        data: {
          ta_revised_id: this.dataSource.ta_revised_id,
          site_id: this.dataSource.site_id,
          path: this.path
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if(result == "version") {
          // Do Not Refresh Data
        } else {
          // Refresh the list regardless of how the dialog is closed.
          this.dataSource.getData(true);
        }
      });
  }

  onRemove(id: number) {
    this.utils.showModal('Delete TA/JSA/SWMS Workflow Step', 'Are you sure you want to delete the TA/JSA/SWMS Workflow Step?', () => {
      this.acknowledgementCheck("delete", id);
    });
  }

  onRemoveSelected() {
    this.utils.showModal('Delete TA/JSA/SWMS Workflow Steps', 'Are you sure you want to delete the selected TA/JSA/SWMS Workflow Steps?', () => {
      this.acknowledgementCheck("delete");
    });
  }

  continueOnRemove(id: number) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${id}`, {}, {
      site_id: this.dataSource.site_id
    })
      .then(() => {
        // If the item was added to the list selection, remove it after deleting the record.
        if ( this.dataSource.isSelected(id) ) {
          this.dataSource.toggleRowSelection(id);
        }
        this.utils.showToast('The TA/JSA/SWMS Workflow Step was deleted.');
      })
      .finally(() => {
        this.dataSource.getData();
      });
  }

  continueOnRemoveSelected() {
    const ids = this.dataSource.selection.selected.join(',');
    this.api.makeRequest('delete', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${ids}`, {}, {
      site_id: this.dataSource.site_id
    })
      .then(() => {
        // Remove all selected records on successful deletion.
        this.dataSource.deselectAll();
        this.utils.showToast('The selected TA/JSA/SWMS Workflow Steps were deleted.');
      })
      .finally(() => {
        this.dataSource.getData();
      });
  }

  private acknowledgementCheck(crud_action: string, id: number = 0) {
    this.api
      .makeRequest('get', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/acknowledgement`, {}, {
        site_id: this.dataSource.site_id
      })
      .then((response) => {
        if(response.data.acknowledgementsDone) {
          this.beforeVersioning(crud_action, id);
        } else {

          switch(crud_action) {
            case "delete":
              if(id > 0) {
                this.continueOnRemove(id);
              } else {
                this.continueOnRemoveSelected();
              }
              break;
            default:
            // do nothing
          }

        }
      });
  }

  private beforeVersioning(crud_action: string, id: number = 0) {
    let ids = [];

    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedVersioningComponent,
      {
        task_name: this.ta_revised.task_name
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          // If confirmed then begin versioning of Task Analysis
          if(results.confirmed) {

            if(id > 0) {
              ids.push(id);
            } else {
              ids = this.dataSource.selection.selected;
            }

            this.createNewVersion(results.amendment_title, results.reason_for_amendment, crud_action, ids);
          }
        }
      }
    );
  }

  private createNewVersion(amendment_title: string, reason_for_amendment: string, crud_action:string, remove_ids:number[] = []) {

    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows-version`, {}, {
        site_id: this.dataSource.site_id,
        workflow_id: 0,
        amendment_title: amendment_title,
        reason_for_amendment: reason_for_amendment,
        crud_action: crud_action,
        remove_ids: remove_ids.length ? remove_ids.join(',') : ''
      })
      .then((response) => {
        this.onSuccess(`Created a new version of the "${this.dataSource.ta_revised_name}" TA/JSA/SWMS.`);
        this.redirectBack();
      });

  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class SitesTaskAnalysesRevisedWorkflowsDataSource extends ApiDataSource {
  order_by = 'step_no';
  order = 'asc';
  searchBy = 'description';

  ta_revised_id = 0;
  ta_revised_name = "";
  site_id = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/task-analyses-revised/${this.ta_revised_id}/workflows`, resetOffset, {
      searchBy: this.searchBy,
      site_id: this.site_id
    });
  }
}
