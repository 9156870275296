import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {NetworkedAccountsSelectorComponent} from "../../shared/networked-accounts-selector/networked-accounts-selector.component";
import {NetworkedEmployeesSelectorComponent} from "../../shared/networked-employees-selector/networked-employees-selector.component";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-access-records-filter',
  templateUrl: './access-records-filter.component.html',
  styleUrls: ['./access-records-filter.component.scss']
})
export class AccessRecordsFilterComponent implements OnInit {

  // Define the default site access activities filters.
  filters: SiteAccessActivitiesFilter = {
    type: '',
    site_ids: [],
    contractor_ids: [],
    user_ids: [],
    employee_ids: [],
    date_range: []
  } as SiteAccessActivitiesFilter;

  faHardHat = faHardHat;

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AccessRecordsFilterComponent>
  ) { }

  ngOnInit() {
    // Set the filters based on inbound data.
    this.filters = {
      type: this.data['type'] || '',
      site_ids: this.data['site_ids'] || [],
      contractor_ids: this.data['contractor_ids'] || [],
      user_ids: this.data['user_ids'] || [],
      employee_ids: this.data['employee_ids'] || [],
      date_range: this.data['date_range'] || []
    } as SiteAccessActivitiesFilter;

    // Remap the dates as new Date objects. Storing it in storage stores it as string.
    this.filters.date_range = this.filters.date_range.map((date: Date|string) => new Date(date));
  }

  /**
   * Sets the date range filter for the output.
   *
   * @param {string} event - The selected date range.
   * @return {void}
   */
  getOutputDateRange(event) {
    this.filters.date_range = event;
  }

  /**
   * Method to open a dialog and allow the user to select sites.
   *
   * @returns {void}
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }, (results) => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      }
    );
  }

  /**
   * Launches a dialog to select multiple users.
   *
   * @returns {void}
   */
  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.user_ids,
      multiple: true,
      selectedAccountId: this.filters.contractor_ids.length ? this.filters.contractor_ids[0] : 0,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.user_ids = results;
    });
  }

  /**
   * Opens a dialog to select contractors.
   *
   * @returns {void}
   */
  onSelectContractors() {
    this.utils.showComponentDialog(NetworkedAccountsSelectorComponent, {
        selected: this.filters.contractor_ids,
        multiple: true
      }, {
        width: '1024px'
      }, (results) => {
        if (typeof results !== 'undefined') {
          this.filters.contractor_ids = results;
        }
      }
    );
  }

  /**
   * Opens a component dialog to select employees and updates the selected employees in the filters.
   *
   * @return {void}
   */
  onSelectEmployees() {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.filters.employee_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.employee_ids = results;
    });
  }

  /**
   * Clears the date range for the incident.
   *
   * @returns {void}
   */
  onClearDateRange(): void {
    this.filters.date_range = [];
  }

  /**
   * Clears all the filters and closes the dialog.
   *
   * @return {void}
   */
  onClearAndClose() {
    // Reset the filters.
    this.filters.type = "";
    this.filters.site_ids = [];
    this.filters.contractor_ids = [];
    this.filters.user_ids = [];
    this.filters.employee_ids = [];
    this.filters.date_range = [];
    // Close the dialog.
    this.onSaveAndClose();
  }

  /**
   * Saves the filters and closes the dialog.
   *
   * @return {void}
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }
}

/**
 * Represents a filter object used to filter site access activities.
 * @interface
 */
export interface SiteAccessActivitiesFilter {
  type: string;
  site_ids: number[];
  contractor_ids: number[];
  user_ids: number[];
  employee_ids: number[];
  date_range: Date[];
}
