import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
    MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule
} from '@angular/material';
import { DirectivesModule } from '../directives/directives.module';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { ErpRoutingModule } from './erp-routing.module';
import { ErpsEditDialogComponent } from './erps-edit-dialog/erps-edit-dialog.component';
import { ErpsEditComponent } from './erps-edit/erps-edit.component';
import { ErpsSelectorComponent } from './erps-selector/erps-selector.component';
import { ErpsViewComponent } from './erps-view/erps-view.component';
import { ErpsComponent } from './erps/erps.component';
import { ErpTeamsEditComponent } from './erps-teams/erps-teams-edit/erp-teams-edit.component';
import { ErpTeamsViewComponent } from './erps-teams/erps-teams-view/erp-teams-view.component';
import { ErpTeamsComponent } from './erps-teams/erps-teams/erp-teams.component';
import { FilesModule } from '../files/files.module';

@NgModule({
  declarations: [
    ErpsComponent,
    ErpsEditComponent,
    ErpsEditDialogComponent,
    ErpsSelectorComponent,
    ErpsViewComponent,
    ErpTeamsComponent,
    ErpTeamsViewComponent,
    ErpTeamsEditComponent
  ],
  imports: [
    CommonModule,
    ErpRoutingModule,
    FormsModule,
        MatBadgeModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    DirectivesModule,
    FilesModule
  ],
  entryComponents: [SitesSelectorComponent]
})
export class ErpModule {}
