import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { tap } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/utils.service';
import { MatPaginator, MatSort } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'icons',
    'contact_person',
    'email',
    'mobile_country_code',
    'mobile',
    'employer',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  dataSource = new UsersDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order = this.sort.direction;


      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  composeNewMessage(userId: number = null) {
    if (this.dataSource.selection.selected.length == 0 && !userId) {
      this.utils.showToast('You need to select some users first.');
      return; // early termination
    }

    this.utils.showModal('Sending Messages', 'You are about to send the message on behalf of ' + this.app.account.name + '. Switch to a different account if this is not intended.');

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: userId ? [userId] : this.dataSource.selection.selected
    })
      .then(() => {
        this.utils.showToast('You can view the message stats in the messages section.');
      });
  }

  onUnlockUser(userId: number = null) {
    if (!(userId > 0)) {
      return this.utils.showModal(
        'Error',
        'User ID is incorrect.'
      );
    }
    this.api.makeRequest('post', `v2/admin/users/${userId}/unlock`).then((res) => {
      this.utils.showModal(
        'User unlocked successfully',
        'The user can now login again.'
      );
    })
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }
}

export class UsersDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/users', resetOffset);
  }
}
