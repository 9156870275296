import { Component, OnInit, Inject } from '@angular/core';
import { ItemModel } from 'src/app/items/item.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-my-items-notes',
  templateUrl: './my-items-notes.component.html',
  styleUrls: ['./my-items-notes.component.scss']
})
export class MyItemsNotesComponent implements OnInit{
  item: ItemModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { item: ItemModel },
    private dialogRef: MatDialogRef<MyItemsNotesComponent>
  ) {}

  ngOnInit() {
    this.item = this.data.item;
  }

  onSaveAndClose() {
    this.dialogRef.close(this.item.pivot.notes);
  }
}
