import { ActionsUploadMappingDialogComponent } from './../actions-upload-mapping-dialog/actions-upload-mapping-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { CurrentTimezoneStateService } from 'src/app/shared/current-timezone-state.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-actions-upload-dialog',
  templateUrl: './actions-upload-dialog.component.html',
  styleUrls: ['./actions-upload-dialog.component.scss']
})
export class ActionsUploadDialogComponent implements OnInit {
  priorities = ['Low', 'Medium', 'High', 'Critical'];
  selectedFile: any;
  sheetNames: any;
  excelData: XLSX.WorkBook = null;
  actionsDataBefore: any[] = [];
  actionsDataAfter: any[] = [];
  inProgress = false;
  selection: any;
  initialSelection: any = [];
  allowMultiSelect: boolean = true;
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  displayedColumns: string[] = [
    'select',
    'task_name',
    'timezone',
    'due_date',
    'priority',
    'individual',
    'buttons',
  ];

  constructor(
    private dialog: MatDialog,
    public utils: UtilsService,
    private app: AppService,
    private dialogRef: MatDialogRef<ActionsUploadDialogComponent>,
    private api: ApiRequestService,
    private cTmzState: CurrentTimezoneStateService
  ) { }

  ngOnInit() {
  }

  onFileChange(event) {
    const selectedFile = event;
    this.selectedFile = selectedFile;
    const reader = new FileReader();
    reader.readAsBinaryString(selectedFile)

    if (selectedFile.name.split('.').at(-1) ==='xls' || selectedFile.name.split('.').at(-1) === 'xlsx' ||
      selectedFile.name.split('.').at(-1) === 'csv') {
        reader.onload = (event: any) => {
          let data = event.target.result;
          let workbook = XLSX.read(data, {
            type: 'binary',
            raw: true
          });
          // Hold excelData temporaryly
          this.excelData = workbook;
          this.sheetNames = workbook.SheetNames;
        }
      } else {
        this.utils.showModal(
          'File Type Invalid',
          'The file you are trying to upload is not valid. Only CSV, XLS and XLSX are allowed. Please try again.'
        );
      }
  }

  fileIsExcel(name: any) {
    return name.split('.').at(-1) ==='xls' || name.split('.').at(-1) === 'xlsx' ||
      name.split('.').at(-1) === 'csv' ? true : false;
  }

  excelSheetSelected(selectedSheet) {
    let jsonData = XLSX.utils.sheet_to_json(this.excelData.Sheets[selectedSheet], {
      header: 0,
      defval: ""
    });
    this.actionsDataBefore = jsonData;
  }

  onConfirm() {
    this.utils.showComponentDialog(ActionsUploadMappingDialogComponent, {
      actionsData: this.actionsDataBefore,
      actionsMappingFields: [
        {value: 'name', content: 'Task Name'},
        {value: 'priority', content: 'Task Priority'},
        {value: 'due', content: 'Task Due'},
        {value: 'timezone', content: 'Timezone'},
      ]
    }, {width: '1440px'},
    (results) => {
      if (typeof results.dataToOutput !== 'undefined') {
        const data = results.dataToOutput.length > 100 ? results.dataToOutput.slice(0, 100) : results.dataToOutput;
        let index = 1;

        // Prepare a few date formats to check.
        const dateFormats: string[] = [
          'DD/MM/YYYY',
          'DD-MM-YYYY',
          'MM/DD/YYYY',
          'MM-DD-YYYY',
          'YYYY/DD/MM',
          'YYYY-DD-MM',
          'YYYY/MM/DD',
          'YYYY/MM-DD'
        ];

        data.forEach((item: any) => {

          // Check the date format for the due date and prepare the moment object.
          let dueDate = moment(moment(item.due, dateFormats[0]).unix()*1000);
          for ( let i = 1; i < dateFormats.length && !dueDate.isValid(); i++) {
            dueDate = moment(moment(item.due, dateFormats[i]).unix()*1000);
          }

          item.id = index++;
          item.due = dueDate;
          item.site_id = null;
          item.user_ids = [];
          item.changeTz = false;
          item.individual_actions = false;
          item.timezone = !handleTimezoneValid(item.timezone) ? '' : item.timezone;
        });
        this.actionsDataAfter = data;
        this.dataSource = new MatTableDataSource(data);
        this.selection = new SelectionModel(this.allowMultiSelect, this.initialSelection);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  onDateChange(value, action) {
    action.due = value;
  }

  onAllDateChange(value) {
    this.onAllChange(value, 'due');
  }

  onAllPriorityChange(value) {
    this.onAllChange(value, 'priority');
  }

  onAllTimezoneChange(value) {
    this.onAllChange(value, 'timezone');
  }

  onSelectSite(action: any) {
    const dialogRef = this.dialog.open(SitesSelectorComponent, {
      data: { multiple: false, selected: [action.site_id] }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'number') { return; }
      action.site_id = result;
    });
  }

  onAllSelectSite() {
    const dialogRef = this.dialog.open(SitesSelectorComponent, {
      data: { multiple: false, selected: [] }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'number') { return; }
      this.onAllChange(result, 'site_id');
    });
  }

  onAddUsers(action: any) {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: action.user_ids,
        multiple: true,
        selectedAccountId: this.app.account.id,
        visitors_from_all_sites: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        action.user_ids = results;
      }
    );
  }

  onAllAddUsers() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: [],
        multiple: true,
        selectedAccountId: this.app.account.id,
        visitors_from_all_sites: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.onAllChange(results, 'user_ids');
      }
    );
  }

  onAllChange(value, property) {
    this.actionsDataAfter.forEach((action: any) =>
      this.selection.selected.length === 0 ? action[property] = value :
      (this.selection.selected.includes(action) && (action[property] = value)));
  }

  checkActionsValid() {
    let error = false;
    this.actionsDataAfter.forEach((action: any) => {
      if (action.name === null || action.name === undefined || action.name === '') {
        this.utils.showModal(
          'Task Name Required',
          'Please make sure all tasks you are importing have names.'
        );
        error = true
        return;
      } else if (action.user_ids.length === 0) {
        this.utils.showModal(
          'Users Required',
          'Please select at least one or more users per task. You can bulk select tasks an assign users as well.'
        );
        error = true;
        return;
      } else if (!handleTimezoneValid(action.timezone)) {
        this.utils.showModal(
          'Timezone Required',
          'Please make sure all timezones are valid.'
        );
        error = true;
        return;
      }
    });
    return error;
  }

  checkPriorityValid() {
    let error = false;
    this.actionsDataAfter.forEach((action: any) => {
      if (action.priority === null || action.priority === undefined || action.priority === '' || !this.priorities.includes(action.priority)) {
        error = true
        return;
      }
    });
    return error;
  }

  sendImportRequest(data: any) {
    this.inProgress = true;
    this.api.makeRequest('post', 'v1/actions/import', data)
    .then(response => {
      this.utils.showModal(
        'Task Created Successfully',
        response.message + ' You will receive an email once tasks are imported.'
      );
      this.dialogRef.close(true);
    }).finally(() => this.inProgress = false);
  }

  onSaveAndClose() {
    if (!this.checkActionsValid()) {
      const data = this.actionsDataAfter.map((action: any) => {
        return {
          name: action.name,
          due: action.due && moment(action.due).isValid() ? moment.tz(action.due.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a',
            (action.timezone && handleTimezoneValid(action.timezone) ? action.timezone : this.cTmzState.getCurrentTimezone()))
            .format('YYYY-MM-DD') : null,
          priority: action.priority === null || action.priority === undefined || action.priority === '' || !this.priorities.includes(action.priority) ? 'Low' : action.priority,
          individual_actions: action.individual_actions,
          site_id: action.site_id,
          user_ids: action.user_ids
        };
      })

      if (this.checkPriorityValid()) {
        this.utils.showModal(
          'Task Priority Required',
          'Some priority values are not valid and will be defaulted to \'Low\' when imported.',
          () => {
            this.sendImportRequest(data);
          }
        )
      } else {
        this.sendImportRequest(data);
      }
    }
  }



}

export const convertExcelDateToUnixDate = (excelDate: number) => {
  return Math.floor(excelDate - 25569) * 86400;
}

// get all timezones and add UTC in the front
export const getAllTimezonesWithUTC = () => {
  let timeZones = moment.tz.names();

  const tzList = [];

  for (let timezone in timeZones) {
    tzList.push({
      offset: moment.tz(timeZones[timezone]).format('Z'),
      offsetString: '(UTC' + moment.tz(timeZones[timezone]).format('Z') + ')',
      timezone: timeZones[timezone]
    });
  }

  tzList.sort(function(a, b) {
    return parseFloat(b.offset.replace(':', '.')) - parseFloat(a.offset.replace(':', '.'));
  })

  return tzList;
}

export const handleTimezoneValid = (timezone) => {
  let valid = false
  getAllTimezonesWithUTC().forEach((item: any) => {
    if (item.timezone === timezone) {
      valid = true;
    }
  })
  return valid;
}
