import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {ActivatedRoute, Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {TaskAnalysesRevisedWorkflowModel} from "../../models/task-analyses-revised-workflow.model";
import {ApiDataSource} from "../../utils/api-data-source";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {TaskAnalysesRevisedWorkflowsViewComponent} from "../task-analyses-revised-workflows-view/task-analyses-revised-workflows-view.component";
import {TaskAnalysesRevisedWorkflowsTabsComponent} from "../task-analyses-revised-workflows-tabs/task-analyses-revised-workflows-tabs.component";
import {TaskAnalysesRevisedWorkflowsStepsComponent} from "../task-analyses-revised-workflows-steps/task-analyses-revised-workflows-steps.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-task-analyses-revised-workflows',
  templateUrl: './task-analyses-revised-workflows.component.html',
  styleUrls: ['./task-analyses-revised-workflows.component.scss']
})
export class TaskAnalysesRevisedWorkflowsComponent implements OnInit {

  @Input() private taRevisedId: number;
  @Input() public taRevisedPath: string;

  path: string;

  displayedColumns: string[] = [
    'select',
    'id',
    'step_no',
    'description',
    'created_by_user',
    'date_created',
    'actions'
  ];

  dataSource = new TaskAnalysesRevisedWorkflowsDataSource(this.app, this.api);
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // get the ta revised id and name from the params
    this.dataSource.ta_revised_id = this.taRevisedId;

    // get sites path edit/view
    this.path = this.route.parent.snapshot.routeConfig.path;

    if(this.dataSource.ta_revised_id) {
      this.dataSource.getData();
      this.findTA(this.dataSource.ta_revised_id);
    } else {
      this.redirectBack();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData();
    })).subscribe();
  }

  private redirectBack() {
      this.router.navigate([`/task-analyses-revised/`]);
  }

  private findTA(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {})
      .then((response) => {
        this.ta_revised = response;

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onAdd() {
    this.onEdit(new TaskAnalysesRevisedWorkflowModel());
  }

  onView(ta_revised_workflow: TaskAnalysesRevisedWorkflowModel) {
    this.utils.showComponentDialog(
      TaskAnalysesRevisedWorkflowsViewComponent,
      {
        ta_revised_workflow: ta_revised_workflow,
        ta_revised_id: this.dataSource.ta_revised_id,
        path: this.path
      },
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onEdit(ta_revised_workflow: TaskAnalysesRevisedWorkflowModel) {
    this.dialog
      .open(TaskAnalysesRevisedWorkflowsTabsComponent, {
        width: '1024px',
        data: {
          ta_revised_workflow: ta_revised_workflow,
          ta_revised_id: this.dataSource.ta_revised_id,
          path: this.path
        }
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onReorderSteps() {
    this.utils.showComponentDialog(
      TaskAnalysesRevisedWorkflowsStepsComponent,
      {
        ta_revised_id: this.dataSource.ta_revised_id,
        path: this.path
      },
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onRemove(id: number) {
    this.utils.showModal('Delete TA/JSA/SWMS Template Workflow Step', 'Are you sure you want to delete the TA/JSA/SWMS Template Workflow Step?', () => {
      this.continueOnRemove(id);
    });
  }

  onRemoveSelected() {
    this.utils.showModal('Delete TA/JSA/SWMS Template Workflow Steps', 'Are you sure you want to delete the selected TA/JSA/SWMS Template Workflow Steps?', () => {
      this.continueOnRemoveSelected();
    });
  }

  continueOnRemove(id: number) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${id}`, {}, {})
      .then(() => {
        // If the item was added to the list selection, remove it after deleting the record.
        if ( this.dataSource.isSelected(id) ) {
          this.dataSource.toggleRowSelection(id);
        }
        this.utils.showToast('The TA/JSA/SWMS Template Workflow Step was deleted.');
      })
      .finally(() => {
        this.dataSource.getData();
      });
  }

  continueOnRemoveSelected() {
    const ids = this.dataSource.selection.selected.join(',');
    this.api.makeRequest('delete', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${ids}`, {}, {})
      .then(() => {
        // Remove all selected records on successful deletion.
        this.dataSource.deselectAll();
        this.utils.showToast('The selected TA/JSA/SWMS Template Workflow Steps were deleted.');
      })
      .finally(() => {
        this.dataSource.getData();
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class TaskAnalysesRevisedWorkflowsDataSource extends ApiDataSource {
  order_by = 'step_no';
  order = 'asc';
  searchBy = 'description';

  ta_revised_id = 0;
  ta_revised_name = "";

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/task-analyses-revised/${this.ta_revised_id}/workflows`, resetOffset, {
      searchBy: this.searchBy
    });
  }
}
