import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MAT_DIALOG_DATA } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from '../api-data-source';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {

  dataSource: FileManagerDataSource = new FileManagerDataSource(this.app, this.api);

  listColumns: string[] = [
    'id',
    'thumbnail',
    'name',
    'date_created',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  uploadedFiles: any = [];

  selectedFiles: any = [];

  parentObjectType: string = '';
  parentObjectId: number = 0;
  parentRemove: boolean = false;
  parentUpload: boolean = false;

  @Input() view = false;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<FileManagerComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {

    if ( this.dialogData['parentObjectType'] ) {
      this.parentObjectType = this.dialogData['parentObjectType'];
    }

    if ( this.dialogData['parentObjectId'] ) {
      this.parentObjectId = this.dialogData['parentObjectId'];
    }

    if ( this.dialogData['parentRemove'] ) {
      this.parentRemove = this.dialogData['parentRemove'];
    }

    if ( this.dialogData['parentUpload'] ) {
      this.parentUpload = this.dialogData['parentUpload'];
    }

    if ( this.dialogData['parentObjectType'] && this.dialogData['parentObjectId'] ) {
      this.dataSource.parentObjectType = this.parentObjectType;
      this.dataSource.parentObjectId = this.parentObjectId;
      this.dataSource.getData();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Add the files to the selected files array and upload it.
   * @param files The files that were selected by the user.
   */
  onSelectFiles(files: any) {
    this.selectedFiles.push(...files);
    this.onUploadSelectedFiles();
  }

  /**
   * Upload the selected files to the relevant object.
   */
  onUploadSelectedFiles() {
    if ( this.selectedFiles.length > 0 ) {
      if(this.parentUpload) {
        this.dialogRef.close(this.selectedFiles);
      } else {
        this.api.makeUploadRequest(`v2/file-manager/${this.parentObjectType}/${this.parentObjectId}`, this.selectedFiles)
          .then((response) => {
            this.dataSource.getData();
          })
          .finally(() => {
            this.selectedFiles.length = 0;
          });
      }
    }
  }

  onRemoveUploadedFile(fileId: number, event: any) {
    if ( this.parentObjectType && this.parentObjectId && fileId ) {

      this.utils.showQuickActions(event.target, 'Are you sure you want to remove this uploaded file?', [
        {
          text: 'Yes',
          handler: () => {
            if(this.parentRemove) {
              this.dialogRef.close(fileId);
            } else {
              this.api.makeRequest('delete', `v2/file-manager/${this.parentObjectType}/${this.parentObjectId}/${fileId}`)
                .then(() => {
                  this.dataSource.getData();
                  this.utils.showToast('The file was successfully removed.');
                });
            }
          }
        },
        {
          text: 'No',
          handler: () => {}
        }
      ]);

    } else {
      this.utils.showToast('Unable to remove the file. Please refresh the page and try again.');
    }
  }

  onOpenUrl(url: string) {
    if ( !url ) {
      return;
    }
    this.utils.openUrl(url);
  }

  onCopiedURL() {
    this.utils.showToast('The URL is copied to your clipboard.');
  }

  isImage(name: string) {
    return name && name.match(/.(jpg|jpeg|png|gif|bmp)/i);
  }

}

export class FileManagerDataSource extends ApiDataSource {
  // Default records ordering
  order_by = 'date_created';
  order = 'desc';

  parentObjectType: string;
  parentObjectId: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/file-manager/${this.parentObjectType}/${this.parentObjectId}`, resetOffset);
  }
}
