import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule
} from '@angular/material';
import { DirectivesModule } from '../directives/directives.module';
import { RepoSearchComponent } from '../shared/repo-search/repo-search.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { HazardsContainerComponent } from './hazards-container/hazards-container.component';
import { HazardsEditComponent } from './hazards-edit/hazards-edit.component';
import { HazardsRoutingModule } from './hazards-routing.module';
import { HazardsSelectorComponent } from './hazards-selector/hazards-selector.component';
import { HazardsViewComponent } from './hazards-view/hazards-view.component';
import { HazardsComponent } from './hazards/hazards.component';
import { FilesModule } from '../files/files.module';
import { HazardsVerifyComponent } from './hazards-verify/hazards-verify.component';
import { HazardsFilterComponent } from './hazards-filter/hazards-filter.component';
import { PipesModule } from '../pipes/pipes.module';
import { HazardsImporterComponent } from './hazards-importer/hazards-importer.component';
import {SharedModule} from "../shared/shared.module";
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  declarations: [
    HazardsComponent,
    HazardsContainerComponent,
    HazardsEditComponent,
    HazardsSelectorComponent,
    HazardsViewComponent,
    HazardsVerifyComponent,
    HazardsFilterComponent,
    HazardsImporterComponent
  ],
    imports: [
        CommonModule,
        HazardsRoutingModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatTabsModule,
        MatDialogModule,
        MatSelectModule,
        MatBadgeModule,
        MatButtonModule,
        MatTooltipModule,
        DirectivesModule,
        FilesModule,
        PipesModule,
        SharedModule,
        ChartsModule,
    ],
  entryComponents: [
    SitesSelectorComponent,
    RepoSearchComponent,
    HazardsEditComponent,
    HazardsFilterComponent
  ]
})
export class HazardsModule {}
