import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuditItemModel } from 'src/app/audit-items/audit-item.model';
import { AuditsViewItemsComponent } from 'src/app/audits/audits-view/audits-view-items/audits-view-items.component';
import { AuditsModel } from 'src/app/audits/audits.model';
import { AuditsService } from 'src/app/audits/audits.service';
import { ContractorsDetailsComponent } from 'src/app/contractors/contractors-details/contractors-details.component';
import { AccountModel } from 'src/app/models/account.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-audits-view',
  templateUrl: './sites-audits-view.component.html',
  styleUrls: ['./sites-audits-view.component.scss']
})
export class SitesAuditsViewComponent implements OnInit {
  audit = new AuditsModel();
  displayedColumns = ['name', 'status', 'actions'];
  displayedContractorColumns = ['name', 'industry', 'address', 'actions'];
  displayedContactsColumns = ['contact_name', 'email', 'user_status'];
  contactDataSource;
  auditId:number;
  editRoute: string;

  /**
   * A unique list of sections.
   */
  sections: string[] = [];

  /**
   * Audit items grouped by section.
   */
  sectionsItems: {[section: string]: AuditItemModel[]} = {};

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private auditsService: AuditsService,
    private dialog: MatDialog,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.auditId = Number(this.route.snapshot.params['audit_id']);
      if ( this.auditId ) {
        this.auditsService
          .find(this.auditId)
          .subscribe((response) => {
            this.audit = response.data;

            this.audit.items.forEach((item) => {
              if ( this.sections.indexOf(item.section) === -1 ) {
                this.sections.push(item.section);
              }

              if ( !this.sectionsItems[item.section] ) {
                this.sectionsItems[item.section] = [];
              }

              this.sectionsItems[item.section].push(item);
            });

          });
      }
    }

    this.getAuditContactsApi();
  }

  getAuditContactsApi(page?){
    this.auditsService.getContacts(this.auditId, page).subscribe(res=>{
      this.contactDataSource = res.data
    })
  }

  handlePageEvent(e) {
    this.getAuditContactsApi(e.pageIndex + 1)
  }


  onOpenUrl(url: string) {
    this.utils.openUrl(url);
  }

  async downloadPdf() {
    this.utils.showToast('You can either wait or continue using the application ' +
    'while you wait for the Inspection/Audit report. You will automatically be redirected to it once it is finished.');

    // get the url of the exported doc instead
    this.api.laravelApiRequest('get', `audits/export/${this.audit.id}`, {}, {}, (response) => {
      const link = document.createElement('a');
      link.href = response.data.url;
      link.target = '_blank';
      link.download = 'inspections-audits-report.pdf';
      link.click();
    }, (errorResponse) => {
      this.utils.showModal('Failed to get export', 'We could not get you your Inspection/Audit export. Please try again.');
    });

  }

  view(item: AuditItemModel) {
    this.utils.showComponentDialog(AuditsViewItemsComponent, item, { width: '700px' });
  }

  onViewContractor(contractor: AccountModel) {
    // Change this to a view component instead.
    this.utils.showComponentDialog(ContractorsDetailsComponent, contractor, { width: '500px' });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
