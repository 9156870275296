import { Component, OnInit, Inject } from '@angular/core';
import { SitesCodeService } from '../sites-code.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { CodeModel } from 'src/app/models/code.model';

@Component({
  selector: 'app-codes-claim',
  templateUrl: './sites-codes-claim.component.html',
  styleUrls: ['./sites-codes-claim.component.scss']
})
export class SitesCodesClaimComponent implements OnInit {
  paper_id: string;

  inProgress: boolean = false;

  constructor(
    private codeService: SitesCodeService,
    @Inject(MAT_DIALOG_DATA) public data: { site_id: number },
    private utils: UtilsService,
    private dialogRef: MatDialogRef<SitesCodesClaimComponent>
  ) {}

  ngOnInit() {}

  claim() {
    
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    this.codeService
      .claimCode(this.data.site_id, this.paper_id)
      .subscribe((result: ApiResponseModel<CodeModel>) => {
        this.utils.showToast(`Claimed QR Code: ${result.data.name}`);
        this.inProgress = false;
        this.dialogRef.close(true);
      }, () => {
        this.inProgress = false;
      });
  }
}
