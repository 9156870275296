import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { UserMessagesViewComponent } from './user-messages-view/user-messages-view.component';
import { UserMessagesFilterComponent } from "../user-messages-filter/user-messages-filter.component";

@Component({
  selector: 'app-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss']
})
export class UserMessagesComponent implements OnInit {

  listColumns: string[] = [
    'subject',
    'priority',
    'reply_to',
    'date_modified', // The date it was technically sent
    // 'date_modified_UTC',
    'actions'
  ];
  listDataSource = new MessagesDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.listDataSource.limit = paginator['pageSize'];
      this.listDataSource.offset = paginator['pageIndex'];
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.listDataSource.order = sorter['direction'];

      // sorting for utc time by parsing original time
      if (sorter['active'] === "date_modified_UTC") {
        this.listDataSource.order_by = "date_modified";
      } else {
        this.listDataSource.order_by = sorter['active'];
      }

      this.listDataSource.getData();
    })).subscribe();
  }

  onViewMessage(messageId: number) {
    this.utils.showComponentDialog(UserMessagesViewComponent, {
      messageId: messageId
    }, {}, () => {
      this.listDataSource.getData();
    });
  }

  onFilter() {
    this.utils.showComponentDialog(
      UserMessagesFilterComponent,
      {
        filter_priority: this.listDataSource.priority,
        date_filter_range: this.listDataSource.date_filter_range
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.listDataSource.priority = typeof results.priority !== 'undefined' ? results.priority : this.listDataSource.priority;
          this.listDataSource.date_filter_range = results.date_filter_range ? results.date_filter_range : this.listDataSource.date_filter_range;
          this.listDataSource.getData();
        }
      }
    );
  }

}

export class MessagesDataSource extends ApiDataSource {

  order_by = 'date_created';
  order = 'desc';

  status: string = '';
  priority: string = '';

  date_filter: string = '';

  date_filter_range: Date[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/user/messages', resetOffset, {
      status: this.status ? this.status : '',
      priority: this.priority ? this.priority : '',
      date_filter: this.date_filter ? this.date_filter : '',
      date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
        this.date_filter_range[0].getTime() / 1000,
        this.date_filter_range[1].getTime() / 1000
      ].join(',') : ''
    });
  }
}
