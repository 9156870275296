import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from "../../utils/api-data-source";
import { UtilsService } from "../../shared/utils.service";
import { AppService } from "../../app.service";
import { ActivatedRoute } from "@angular/router";
import { ApiRequestService } from "../../shared/api-request.service";
import { tap } from "rxjs/operators";
import { MatDialog, MatPaginator, MatSort } from "@angular/material";
import { TaskAnalysesRevisedModel } from "../../models/task-analyses-revised.model";
import { SitesTaskAnalysesRevisedFilterComponent } from "../sites-task-analyses-revised-filter/sites-task-analyses-revised-filter.component";
import { TaskAnalysesRevisedSelectorComponent } from "../../shared/task-analyses-revised-selector/task-analyses-revised-selector.component";
import { SitesTaskAnalysesRevisedAddComponent } from "../sites-task-analyses-revised-add/sites-task-analyses-revised-add.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";


@Component({
  selector: 'app-sites-task-analyses-revised',
  templateUrl: './sites-task-analyses-revised.component.html',
  styleUrls: ['./sites-task-analyses-revised.component.scss']
})
export class SitesTaskAnalysesRevisedComponent implements OnInit, AfterViewInit {

  parent_id: number;
  child_id: number;
  site_id: number;
  path: string;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'site',
    'version_no',
    'task_name',
    // 'contractor', // Temporarily Removed.
    'approved',
    'authorized',
    'supervisor_acknowledged',
    'assignees_acknowledged',
    'created_by_user',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  dataSource = new SitesTaskAnalysesRevisedDataSource(this.app, this.api);

  constructor(
    public utils: UtilsService,
    public app: AppService,
    public route: ActivatedRoute,
    private api: ApiRequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      // get sites path edit/view
      this.path = this.route.parent.snapshot.routeConfig.path;

      this.dataSource.site_id = this.site_id;
      this.dataSource.site_ids.push(this.site_id);

      // get the data
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order = this.sort.direction;
      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }


      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onAddTA(new TaskAnalysesRevisedModel());
  }

  onClone(ta_revised: TaskAnalysesRevisedModel) {
    const newTARevised = new TaskAnalysesRevisedModel();
    newTARevised.cloneFrom(ta_revised);
    this.onAddTA(newTARevised);
  }

  onAddTA(ta_revised: TaskAnalysesRevisedModel) {
    this.dialog
      .open(SitesTaskAnalysesRevisedAddComponent, {
        width: '900px',
        data: {
          ta_revised: ta_revised,
          parent_id: this.parent_id,
          child_id: this.child_id,
          site_id: this.site_id,
          path: this.path
        }
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove TA/JSA/SWMS',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} TA/JSA/SWMS records? TA/JSA/SWMS records created by your ${this.utils.getLangTerm('contractors.plural', 'Contractors')} will not be removed.`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(ta_revised: TaskAnalysesRevisedModel) {
    this.utils.showModal(
      'Remove TA/JSA/SWMS',
      `Are you sure you want to remove the "${ta_revised.task_name}" TA/JSA/SWMS record?`,
      () => {
        this.remove([ta_revised.id]);
      }
    );
  }

  private remove(ids: number[]) {
    // If a Contractor Company created a selected Task analysis then that will be removed from selections within array
    const validatedIds = this.validateRemoval(ids);

    if (validatedIds.length > 0) {
      this.api.makeRequest('delete', `v2/task-analyses-revised/${validatedIds.join(',')}`, [], {
        site_id: this.dataSource.site_id
      })
        .then((response) => {
          this.utils.showToast('The TA/JSA/SWMS record have been removed');
          this.dataSource.selection.clear();
          this.dataSource.getData(false);
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
    } else {
      this.utils.showToast('There are no valid TA/JSA/SWMS records to remove.');
    }
  }

  private validateRemoval(ids: number[]) {
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      if (!this.checkRemovalData(id)) {
        ids.splice(i, 1);
        i--; // decrement
      }
    }

    return ids;
  }

  private checkRemovalData(id: number) {
    let pass = true;

    this.dataSource.dataSubject.value.forEach((ta_revised, index) => {
      if (ta_revised.id == id && ta_revised.contractor) {
        pass = false;
        return pass;
      }
    });

    return pass;
  }

  onFilter() {
    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedFilterComponent,
      {
        approver_ids: this.dataSource.approver_ids,
        authorizer_ids: this.dataSource.authorizer_ids,
        supervisor_ids: this.dataSource.supervisor_ids,
        user_ids: this.dataSource.user_ids,
        ppe_ids: this.dataSource.ppe_ids,
        work_permit_ids: this.dataSource.work_permit_ids,
        tool_ids: this.dataSource.tool_ids,
        contractor_ids: this.dataSource.contractor_ids,
        approver_status: this.dataSource.approver_status,
        authorizer_status: this.dataSource.authorizer_status,
        supervisor_status: this.dataSource.supervisor_status,
        assignees_status: this.dataSource.assignees_status,
        date_range: this.dataSource.date_range,
        site_ids: this.dataSource.site_ids,
        parent_site_id: this.parent_id
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.approver_ids = results.approver_ids || [];
          this.dataSource.authorizer_ids = results.authorizer_ids || [];
          this.dataSource.supervisor_ids = results.supervisor_ids || [];
          this.dataSource.user_ids = results.user_ids || [];
          this.dataSource.ppe_ids = results.ppe_ids || [];
          this.dataSource.work_permit_ids = results.work_permit_ids || [];
          this.dataSource.tool_ids = results.tool_ids || [];
          this.dataSource.contractor_ids = results.contractor_ids || [];
          this.dataSource.approver_status = results.approver_status || 'all';
          this.dataSource.authorizer_status = results.authorizer_status || 'all';
          this.dataSource.supervisor_status = results.supervisor_status || 'all';
          this.dataSource.assignees_status = results.assignees_status || 'all';
          this.dataSource.date_range = results.date_range || [];
          this.dataSource.site_ids = results.site_ids || [];

          if ( this.dataSource.site_ids.length === 0 ) {
            this.dataSource.site_ids.push(this.site_id);
          }

          this.dataSource.getData(true);
        }
      }
    );
  }

  onSelectTemplates() {
    this.utils.showComponentDialog(
      TaskAnalysesRevisedSelectorComponent,
      {
        multiple: true,
        global: false
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.onCopyTemplates(results.length ? results.join(',') : '');
        }
      }
    );
  }

  onCopyTemplates(selected_ta_ids) {
    this.api.makeRequest('put', `v2/task-analyses-revised`, [], {
      selected_ta_ids: selected_ta_ids,
      site_id: this.dataSource.site_id,
      global: false
    })
      .then((response) => {
        this.dataSource.getData(true);
        this.utils.showToast('TA/JSA/SWMS template(s) were copied.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Exports the list of task analysis into the specified format and sends a download request to the browser.
   * @param type csv, xls, xlsx, pdf.
   */
  onExportSelected(type: string = 'csv') {
    this.api.makeDownloadRequest(`v2/task-analyses-revised/export/${type}` + (this.dataSource.selection.selected.length ? '/' + this.dataSource.selection.selected.join(',') : ''), {}, {
      site_id: this.dataSource.site_id
    })
      .then((response) => {
        // For IE and Edge browsers.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `ta-jsa-swms-${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class SitesTaskAnalysesRevisedDataSource extends ApiDataSource {
  order_by = 'id';
  order = 'desc';
  searchBy = 'task_name';

  site_id = 0;

  site_ids: number[] = [];

  // Filter Variables
  approver_ids = [];
  authorizer_ids = [];
  supervisor_ids = [];
  user_ids = [];
  ppe_ids = [];
  work_permit_ids = [];
  tool_ids = [];
  contractor_ids = [];
  approver_status = 'all';
  authorizer_status = 'all';
  supervisor_status = 'all';
  assignees_status = 'all';
  date_range: Date[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/task-analyses-revised', resetOffset, {
      searchBy: this.searchBy,
      // site_id: this.site_id,
      site_ids: this.site_ids.join(','),
      // contractor_id: this.contractor_id
      approver_ids: this.approver_ids ? this.approver_ids.join(',') : "",
      authorizer_ids: this.authorizer_ids ? this.authorizer_ids.join(',') : "",
      supervisor_ids: this.supervisor_ids ? this.supervisor_ids.join(',') : "",
      user_ids: this.user_ids ? this.user_ids.join(',') : "",
      ppe_ids: this.ppe_ids.length ? this.ppe_ids.join(',') : '',
      work_permit_ids: this.work_permit_ids.length ? this.work_permit_ids.join(',') : '',
      tool_ids: this.tool_ids.length ? this.tool_ids.join(',') : '',
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
      approver_status: this.approver_status,
      authorizer_status: this.authorizer_status,
      supervisor_status: this.supervisor_status,
      assignees_status: this.assignees_status,
      date_range: this.date_range && this.date_range.length > 1 ? [
        this.date_range[0].getTime() / 1000,
        this.date_range[1].getTime() / 1000
      ].join(',') : ""
    });
  }
}
