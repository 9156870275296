import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ActionsEditComponent } from 'src/app/actions/actions-edit/actions-edit.component';
import { ActionModel } from 'src/app/actions/action.model';
import { ActionsService } from 'src/app/actions/actions.service';
import { SafetyObservation } from 'src/app/models/safety-observation.model';

@Component({
  selector: 'app-safety-observations-view-corrective-actions',
  templateUrl: './safety-observations-view-corrective-actions.component.html',
  styleUrls: ['./safety-observations-view-corrective-actions.component.scss']
})
export class SafetyObservationsViewCorrectiveActionsComponent implements OnInit {

  @Input('safety_observation_id') safety_observation_id: number;
  @Input('safety_observation') safety_observation: SafetyObservation;

  dataSource: SafetyObservationsCorrectiveActionsDataSource = new SafetyObservationsCorrectiveActionsDataSource(this.app, this.api);

  listColumns: string[] = [
    // 'select',
    'id',
    'name',
    'priority',
    'due',
    // 'due_UTC',
    'completed',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService, // Used in view.
    private actionsService: ActionsService
  ) { }

  ngOnInit() {
    this.dataSource.safety_observation_id = this.safety_observation_id;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns based on its corresponding date fields.
      if ( sorter['active'] == 'due_UTC' ) {
        this.dataSource.order_by = 'due';
      }

      // Sort UTC columns based on its corresponding date fields.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Create new or update existing corrective actions.
   * @param correctiveActionData The corrective action data from the list.
   */
  onEditCorrectiveAction(correctiveActionData?: ActionModel) {
    // Create a new action and apply the data if any.
    let action: ActionModel = new ActionModel();
    if ( correctiveActionData ) {
      action = correctiveActionData as ActionModel;
    }
    // Assign the action to the safety observation to the corrective action.
    action.safety_observation_id = this.safety_observation_id; // This will only be stored when the corrective action is created, not updated.

    // Set the corrective action text, site id and users if any.
    action.name = this.safety_observation.so_action_taken;
    action.site_id = this.safety_observation.site_id
    action.user_ids = this.safety_observation.user_ids;
    // The above fields will be overridden when editing an existing action.

    this.utils.showComponentDialog(ActionsEditComponent, action)
    .then((response) => {
      // This response is a boolean response value.
      if ( response ) {
        this.dataSource.getData();
      }
    });
  }

  /**
   * Remove the corrective action. This also removes it from the task list of the associated users.
   */
  onRemove(action: ActionModel) {
    this.utils.showModal(
      'Remove Corrective Action',
      `Are you sure you want to remove the corrective action?`,
      () => {
        this.actionsService.remove([action.id]).subscribe(() => {
          this.utils.showToast('The corrective action(s) were removed.');
          this.dataSource.getData();
        });
      }
    );
  }
}

export class SafetyObservationsCorrectiveActionsDataSource extends ApiDataSource {
  // Order the records by the date it was created.
  order_by = 'date_created';
  order = 'desc';

  safety_observation_id: number;

  priority: string = '';
  completed: number  = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/safety-observations/${this.safety_observation_id}/corrective-actions`, resetOffset, {
      priority: this.priority,
      completed: this.completed
    });
  }
}
