import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyItemsComponent } from './my-items.component';
import {
  MatButtonModule,
  MatCardModule,
  MatBadgeModule,
  MatIconModule,
  MatTooltipModule,
  MatInputModule,
  MatDatepickerModule,
  MatDialogModule,
  MatButtonToggleModule
} from '@angular/material';
import { FilesModule } from '../files/files.module';
import { FormsModule } from '@angular/forms';
import { MyItemsNotesComponent } from './my-items-notes/my-items-notes.component';

@NgModule({
  declarations: [MyItemsComponent, MyItemsNotesComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    FilesModule,
    MatBadgeModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonToggleModule
  ],
  exports: [MyItemsComponent],
  entryComponents: [MyItemsNotesComponent]
})
export class MyItemsModule {}
