import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from 'src/app/app.service';
import { SafetyObservation } from 'src/app/models/safety-observation.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SafetyObservationsViewFilesComponent } from '../safety-observations-view-files/safety-observations-view-files.component';
import { SafetyObservationsViewCorrectiveActionsComponent } from '../safety-observations-view-corrective-actions/safety-observations-view-corrective-actions.component';
import { SafetyObservationsViewHazardsComponent } from '../safety-observations-view-hazards/safety-observations-view-hazards.component';
import { SafetyObservationsViewContractorsComponent } from '../safety-observations-view-contractors/safety-observations-view-contractors.component';
import { SafetyObservationsViewUsersComponent } from '../safety-observations-view-users/safety-observations-view-users.component';
import { SafetyObservationsViewMessagesComponent } from '../safety-observations-view-messages/safety-observations-view-messages.component';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-safety-observations-view',
  templateUrl: './safety-observations-view.component.html',
  styleUrls: ['./safety-observations-view.component.scss']
})
export class SafetyObservationsViewComponent implements OnInit {

  safety_observation: SafetyObservation = new SafetyObservation();

  // Index 0 = The safety observation.
  @ViewChild('safetyObservationsViewFilesComponent', {static: false}) safetyObservationsViewFilesComponent: SafetyObservationsViewFilesComponent; // Index 1.
  @ViewChild('safetyObservationsViewCorrectiveActionsComponent', {static: false}) safetyObservationsViewCorrectiveActionsComponent: SafetyObservationsViewCorrectiveActionsComponent; // Index 2.
  @ViewChild('safetyObservationsViewHazardsComponent', {static: false}) safetyObservationsViewHazardsComponent: SafetyObservationsViewHazardsComponent; // Index 3.
  @ViewChild('safetyObservationsViewContractorsComponent', {static: false}) safetyObservationsViewContractorsComponent: SafetyObservationsViewContractorsComponent; // Index 4.
  @ViewChild('safetyObservationsViewUsersComponent', {static: false}) safetyObservationsViewUsersComponent: SafetyObservationsViewUsersComponent; // Index 5.
  @ViewChild('safetyObservationsViewMessagesComponent', {static: false}) safetyObservationsViewMessagesComponent: SafetyObservationsViewMessagesComponent; // Index 6.

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<SafetyObservationsViewComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Get the id of the safety observation.
    const safety_observation_id = Number(this.dialogData['safety_observation_id']);
    if ( safety_observation_id ) {
      // Get the safety observation
      this.getSafetyObservation(safety_observation_id);
    }
  }

  /**
   * Attempt to get the safety observation by id.
   * @param safety_observation_id The safety observation id.
   */
  private getSafetyObservation(safety_observation_id: number) {
    this.api.makeRequest('get', `v2/safety-observations/${safety_observation_id}`)
    .then((response) => {
      this.safety_observation.apply(response);
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  /**
   * When tabs are changed, reload lists. If the tab order changes this need to be updated.
   * @param tabData The tab to check.
   */
  onTabChanged(tabData) {
    // index 0 is the main safety observation view tab.
    switch ( tabData.index ) {
      case 1:
        this.safetyObservationsViewFilesComponent.dataSource.getData();
        break;
      case 2:
        this.safetyObservationsViewCorrectiveActionsComponent.dataSource.getData();
        break;
      case 3:
        this.safetyObservationsViewHazardsComponent.dataSource.getData();
        break;
      case 4:
        this.safetyObservationsViewContractorsComponent.dataSource.getData();
        break;
      case 5:
        this.safetyObservationsViewUsersComponent.dataSource.getData();
        break;
      case 6:
        this.safetyObservationsViewMessagesComponent.dataSource.getData();
        break;
      default:
        // No action needed.
    }
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
