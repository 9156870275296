import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ApiRequestService} from "../../../shared/api-request.service";
import {UtilsService} from "../../../shared/utils.service";
import {
  SafetyObservationsFilter
} from "../../../safety-observations/safety-observations-filter/safety-observations-filter.component";
import {ChartsUtilsService} from "../../charts-utils.service";

declare var google;

@Component({
  selector: 'app-safety-observations-historical-combo-chart',
  templateUrl: './safety-observations-historical-combo-chart.component.html',
  styleUrls: ['./safety-observations-historical-combo-chart.component.scss']
})
export class SafetyObservationsHistoricalComboChartComponent implements OnInit, OnDestroy {

  // The chart container.
  @ViewChild('chart_ref', { static: false }) chart_ref: ElementRef;

  // The chart object.
  chart: any;

  // Input to switch near realtime data polling on or off.
  @Input('enable_realtime_data') enable_realtime_data: boolean;
  // Input to change how long between intervals in seconds.
  @Input('realtime_data_interval_seconds') realtime_data_interval_seconds: number;
  // The realtime data interval for polling data.
  private realtime_data_interval: any;

  // The filters to use for API requests.
  @Input('filters') filters: SafetyObservationsFilter;

  // Input to switch near realtime data polling on or off. This can only be single or multiple.
  @Input('query_type') query_type: string;

  // Used to pass a reference to this component back to the parent component.
  @Output() referenceEvent: EventEmitter<SafetyObservationsHistoricalComboChartComponent> = new EventEmitter<SafetyObservationsHistoricalComboChartComponent>();

  // Determine if the chart can be downloaded or not.
  @Input('canBeDownloaded') canBeDownloaded: boolean;

  // The name of the file that will be used when the chart is downloaded.
  @Input('chartDownloadFilename') chartDownloadFilename: string;

  // Set the card title for the parent component.
  cardTitle: string = 'Historical Safety Observations';

  // Used to store the chart data.
  chartData: any = [];

  constructor(
    private api: ApiRequestService,
    private utils: UtilsService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit(): void {
    // Validate the default config.
    this.validateDefaultConfig();
    // Load the Google charts library into the software. It will only be loaded as needed.
    this.utils.loadExternalScript('google-charts', 'https://www.gstatic.com/charts/loader.js', () => {
      // Load the required libraries.
      google.charts.load('current', { packages: ['corechart'] });
      // Add a callback that will be executed when the chart libraries fully loaded.
      google.charts.setOnLoadCallback(() => {
        // Initialize the chart.
        this.chart = new google.visualization.ComboChart(this.chart_ref.nativeElement);
        // Get the data from the api.
        this.getData();
        // Check if realtime data should be pulled.
        if ( this.enable_realtime_data ) {
          // Create an interval to poll the API.
          this.realtime_data_interval = setInterval(() => {
            // Make a request to get the data.
            this.getData();
          }, this.realtime_data_interval_seconds * 1000);
        }
      });
    });
    // Send a reference to the parent component.
    this.referenceEvent.emit(this);
  }

  /**
   * Lifecycle hook called when the component is about to be destroyed.
   * It clears the near realtime data interval if it was set.
   */
  ngOnDestroy(): void {
    // Check if near realtime data polling is enabled and if the interval is set.
    if ( this.enable_realtime_data && this.realtime_data_interval ) {
      // Clear the interval to stop the data polling.
      clearInterval(this.realtime_data_interval);
    }
  }

  /**
   * Validates the configuration of a chart.
   * Ensures that certain properties are defined and sets default values if not provided.
   *
   * @return {void}
   */
  validateDefaultConfig(): void {
    // Ensure realtime data is defined and set to false if not provided.
    if ( typeof this.enable_realtime_data == 'undefined' ) {
      this.enable_realtime_data = false;
    }
    // Ensure realtime data interval seconds is defined and set to 60 if not provided.
    if ( typeof this.realtime_data_interval_seconds == 'undefined' ) {
      this.realtime_data_interval_seconds = 60;
    }
    // Ensure the query type is defined and set to 'single' if not provided. It can be 'multiple'.
    if ( typeof this.query_type == 'undefined' ) {
      this.query_type = 'single';
    }
    // Ensure "can be downloaded" is defined and set to true if not provided.
    if ( typeof this.canBeDownloaded == 'undefined' ) {
      setTimeout(() => {
        this.canBeDownloaded = true;
      }, 1000); // Need delay due to change detection errors in dev.
    }
    // Ensure "chart download filename" is defined and set a default if not provided.
    if ( typeof this.chartDownloadFilename == 'undefined' ) {
      this.chartDownloadFilename = this.cardTitle + ' Combo Chart';
    }
  }

  /**
   * Updates the filters from the parent component.
   *
   * @param {any} filters - The new filters to update.
   * @return {void}
   */
  updateFiltersFromParentComponent(filters: any): void {
    // Update the filters.
    this.filters = filters;
  }

  /**
   * Retrieves historical data from the API and updates the chart visuals.
   *
   * @returns {void}
   */
  getData(): void {
    // Normalize the query params.
    const queryParams:{[p: string]: any} = this.utils.normalizeQueryParams({
      ...this.filters,
      query_type: this.query_type,
      date_range: this.filters.date_range.map((date: Date) => {
        return date.getTime() / 1000;
      }).join(',')
    });
    // Get historical data from the API.
    this.api.makeRequest('get', 'v2/safety-observations/charts/historical', {}, queryParams)
      .then((response) => {
        // Store the chart data.
        this.chartData = response;
        // Draw the chart.
        this.drawChart();
      });
  }

  /**
   * Draw the chart with optional title.
   *
   * @param {boolean} includeTitle - Flag to indicate whether to include title in the chart.
   *
   * @return {void}
   */
  drawChart(includeTitle: boolean = false): void {
    // Draw the chart after fetching the data. This will redraw the chart for any subsequent calls.
    this.chart.draw(google.visualization.arrayToDataTable(this.chartData), {
      title: includeTitle ? this.cardTitle : '',
      titleTextStyle: {
        fontSize: 16,
        bold: true,
        color: 'darkgray'
      },
      tooltip: {
        ignoreBounds: true,
        isHtml: true
      },
      vAxis: {
        title: 'Safety Observations',
        format: '0'
      },
      hAxis: {
        title: 'Month'
      },
      seriesType: 'bars',
      series: {
        5: {
          type: 'line'
        }
      },
      colors: this.chartsUtils.defaultSystemChartColors()
    });
  }
}
