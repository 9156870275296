import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FolderLinkModel} from "../../shared/folder-link.model";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {AccountFoldersLinksEditComponent} from "../account-folders-links-edit/account-folders-links-edit.component";
import {AccountFoldersLinksViewComponent} from "../account-folders-links-view/account-folders-links-view.component";

@Component({
  selector: 'app-account-folders-links',
  templateUrl: './account-folders-links.component.html',
  styleUrls: ['./account-folders-links.component.scss']
})
export class AccountFoldersLinksComponent implements OnInit {

  @Input() public folderId: number;

  displayedColumns: string[] = [
    'select',
    'id',
    'url',
    'description',
    'created_by_user',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  dataSource = new AccountFoldersLinksDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<AccountFoldersLinksComponent>
  ) { }

  ngOnInit() {
    // get the Training/Competency Document id
    this.dataSource.folder_id = this.dialogData.folder.id;

    if(this.dataSource.folder_id) {
      this.dataSource.getData(true);
    } else {
      this.dialogRef.close(true);
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns based on their corresponding date fields.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  onView(id: number) {
    this.dialog
      .open(AccountFoldersLinksViewComponent, {
        width: '1024px',
        data: {
          folder_link_id: id,
          folder_id: this.dataSource.folder_id
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onAdd() {
    this.onEdit(new FolderLinkModel());
  }

  onEdit(folder_link: FolderLinkModel) {
    this.dialog
      .open(AccountFoldersLinksEditComponent, {
        width: '1024px',
        data: {
          folder_link: folder_link,
          folder_id: this.dataSource.folder_id
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.remove([id]);
  }

  onRemoveSelected() {
    this.remove(this.dataSource.selection.selected);
  }

  private remove(ids: number[]) {
    this.utils.showModal('Delete Document Link(s)', 'Are you sure you want to delete the selected Document Link(s)?', () => {
      this.api.makeRequest('delete', `v2/folders/${this.dataSource.folder_id}/links/${ids.join(',')}`)
        .then(() => {
          this.utils.showToast('The Document Link(s) were deleted.');
          this.dataSource.selection.clear();
        })
        .finally(() => {
          this.dataSource.getData(true);
        });
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData(true);
      }
    );
  }

}

export class AccountFoldersLinksDataSource extends ApiDataSource {
  order_by = 'date_created';
  order = 'desc';
  searchBy = 'url';

  folder_id = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/folders/${this.folder_id}/links`, resetOffset, {
      searchBy: this.searchBy
    });
  }
}
