import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FolderLinkModel} from "../../shared/folder-link.model";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-account-folders-links-edit',
  templateUrl: './account-folders-links-edit.component.html',
  styleUrls: ['./account-folders-links-edit.component.scss']
})
export class AccountFoldersLinksEditComponent implements OnInit {

  inProgress = false;

  folder_id: number;
  folder_link: FolderLinkModel = new FolderLinkModel();

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<AccountFoldersLinksEditComponent>,
    public router: Router,
    public renderer: Renderer
  ) { }

  ngOnInit() {
    // get the folder link id
    this.folder_link.id = this.dialogData.folder_link.id;

    // get the folder id
    this.folder_id = this.dialogData.folder_id;

    if(this.folder_link.id) {
      this.find(this.folder_link.id);
    }
  }

  private find(folder_link_id: number) {
    this.api.makeRequest('get', `v2/folders/${this.folder_id}/links/${folder_link_id}`)
      .then((response) => {
        this.folder_link = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  async onSubmit(form: NgForm) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(!this.folder_link.id) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this.api
      .makeRequest('post', `v2/folders/${this.folder_id}/links`, this.folder_link, {})
      .then((response) => {
        this.onSuccess(`A new Document Link was created.`);
        this.folder_link = response;
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private update() {
    this.api
      .makeRequest('put', `v2/folders/${this.folder_id}/links/${this.folder_link.id}`, this.folder_link, {})
      .then((response) => {
        this.onSuccess(`The Document Link was updated.`);
        this.folder_link = response;
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  public onDelete() {
    this.utils.showModal('Delete Document Link', 'Are you sure you want to delete the Document Link?', () => {
      this.api.makeRequest('delete', `v2/folders/${this.folder_id}/links/${this.folder_link.id}`)
        .then(() => {
          this.onSuccess('The Document Link was deleted.');
        });
    });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }

}
