import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { CourseModuleModel } from '../../../models/course-module.model';
import { ApiService } from '../../../shared/api.service';
import { UtilsService } from '../../../shared/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-courses-modules-view',
  templateUrl: './courses-modules-view.component.html',
  styleUrls: ['./courses-modules-view.component.scss']
})
export class CoursesModulesViewComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public module: CourseModuleModel,
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}
}
