import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { CourseModuleQuestionModel } from '../../../../models/course-module-question.model';
import { ApiService } from '../../../../shared/api.service';
import { UtilsService } from '../../../../shared/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-courses-modules-questions-edit',
  templateUrl: './courses-modules-questions-edit.component.html',
  styleUrls: ['./courses-modules-questions-edit.component.scss']
})
export class CoursesModulesQuestionsEditComponent implements OnInit {
  course_id: number;
  module_id: number;

  question_data: CourseModuleQuestionModel = new CourseModuleQuestionModel();

  inProgress = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      question_data: CourseModuleQuestionModel;
      course_id: number;
      module_id: number;
    },
    private dialogRef: MatDialogRef<CoursesModulesQuestionsEditComponent>
  ) {}

  ngOnInit() {
    this.question_data = this.data.question_data;
    this.course_id = this.data.course_id;
    this.module_id = this.data.module_id;

    if (!isNaN(this.question_data.id) && this.question_data.id !== 0) {
      return this.api.laravelApiRequest(
        'get',
        `inductions/${this.course_id}/modules/${this.module_id}/questions/${
          this.question_data.id
        }`,
        {},
        {},
        (response) => {
          this.question_data = response.data;
          this.question_data.answers_to_remove = [];
        }
      );
    }
    else {
      this.onAddAnswer();
    }
  }

  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      if (this.inProgress) {
        return;
      }
      
      this.inProgress = true;

      // check if the site should be created or updated
      if (this.question_data.id) {
        this.api.laravelApiRequest(
          'put',
          `inductions/${this.course_id}/modules/${this.module_id}/questions/${
            this.question_data.id
          }`,
          this.question_data,
          {},
          () => {
            this.utils.showToast('The question was updated');
            this.dialogRef.close(true);
            this.inProgress = false;
          },
          (_error: any) => this.inProgress = false
        );

        // update the site
      } else {
        this.api.laravelApiRequest(
          'post',
          `inductions/${this.course_id}/modules/${this.module_id}/questions`,
          this.question_data,
          {},
          () => {
            this.utils.showToast('The question was added');
            this.dialogRef.close(true);
            this.inProgress = false;
          },
          (_error: any) => this.inProgress = false
        );
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }

  onAddAnswer() {
    this.question_data.answers.push({
      id: 0,
      answer_text: '',
      answer_value: false
    });
  }

  onRemoveAnswer(i: number) {
    this.question_data.answers_to_remove.push(this.question_data.answers[i].id);
    this.question_data.answers.splice(i, 1);
  }

  onRemove() {
    this.utils.showModal(
      'Remove Question',
      'Are you sure you want to remove this question?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.course_id}/modules/${this.module_id}/questions/${
            this.question_data.id
          }`,
          {},
          {},
          (response) => {
            this.utils.showToast('The question was removed');
            this.router.navigate([
              'courses',
              this.course_id,
              'modules',
              this.module_id,
              'questions'
            ]);
          }
        );
      }
    );
  }

}
