import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountModel } from 'src/app/models/account.model';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { MatPaginator, MatSort, MatTable } from '@angular/material';
import { tap } from 'rxjs/operators';
import { UsersSelectorComponent } from '../../users/users-selector/users-selector.component';
import {IndustriesModel} from "../../industries/industries.model";
import { AccountCopyTemplatesDialogComponent } from './../account-copy-templates-dialog/account-copy-templates-dialog.component';
import {
  AdminEmployeesSelectorComponent
} from "../../../shared/admin-employees-selector/admin-employees-selector.component";

@Component({
  selector: 'app-accounts-edit',
  templateUrl: './accounts-edit.component.html',
  styleUrls: ['./accounts-edit.component.scss']
})
export class AccountsEditComponent implements OnInit {

  account: AccountModel = new AccountModel();

  industries: IndustriesModel[] = [];

  usersDisplayedColumns: string[] = [
    'user_id',
    'icons',
    'contact_person',
    'email',
    'mobile',
    'date_created',
    'permissions',
    'actions'
  ];

  dataSource = new AccountsUsersDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  clientColumns: string[] = [
    'id',
    'name',
    'subscription'
  ];
  @ViewChild('contractorOf', {static: false}) contractorOfTable: MatTable<any>;

  inProgress: boolean = false;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.account.id = Number(this.route.snapshot.params['id']);
    if ( this.account.id ) {
      this.getAccount();
    }

    // Get industries from the API.
    this.getIndustries();
  }

  ngAfterViewInit() {
    if ( !this.account.id ) {
      return;
    }

    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  getOutputTimezone($event) {
    this.account.timezone = $event;
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  getAccount() {
    return this.api.makeRequest('get', `v2/admin/accounts/${this.account.id}`)
    .then((account: AccountModel) => {
      this.account.reset();
      this.account.apply(account);

      // Need to call this since the table listing contractors references an array and not an observable data source.
      this.contractorOfTable.renderRows();
      // Set the account id in the datasource and get the data
      this.dataSource.accountId = this.account.id;
      this.dataSource.getData();
    });
  }

  // Redirect the user to the specified account by id
  onRedirectToAccount(clientId: number) {
    this.utils.refreshPage(['/', 'admin', 'accounts', clientId, 'edit']);
  }

  // Redirect the user to the specified user by id
  onRedirectToUser(userId: number) {
    this.utils.refreshPage(['/', 'admin', 'users', userId, 'edit']);
  }

  /**
   * Send a request to the APi to create or update an account.
   * @param form
   */
  onSubmit(form: NgForm) {
    // Check if the form is valid.
    if( !form.valid ) {
      this.utils.showModal('Form Validation Failed','Please enter all required fields.');
      return;
    }
    // Set the in progress status to true.
    this.inProgress = true;
    // If the account id is present then we are updating an existing account.
    if ( this.account.id ) {
      // Update an existing account.
      this.api.makeRequest('put', `v2/admin/accounts/${this.account.id}`, this.account)
      .then((response: AccountModel) => {
        this.account.apply(response);
        this.utils.showToast('The account was successfully updated.');
      })
      .finally(() => {
        this.inProgress = false;
      });
    } else {
      // Create a new account.
      this.api.makeRequest('post', `v2/admin/accounts`, this.account)
      .then((response: AccountModel) => {
        this.utils.showToast('The new account was successfully created.');
        this.utils.refreshPage(['/admin', 'accounts', response.id, 'edit']);
      })
      .finally(() => {
        this.inProgress = false;
      });
    }
  }

  onSelectUsers() {
    this.utils.showComponentDialog(UsersSelectorComponent, {
      multiple: true,
      selected: this.account.users.map(user => user.id)
    }, {
      disableClose: false
    }, (userIds: any[]) => {
      if ( userIds && userIds.length > 0 ) {
        this.utils.showModal('Link Selected Users', 'Are you sure you want to link the selected users with this account?', () => {
          this.inProgress = true;
          this.api.makeRequest('post', `v2/admin/users-accounts`, {
            permissions: 'Employee',
            account_ids: [this.account.id],
            user_ids: userIds
          })
          .then(() => {
            this.utils.showToast('The users were linked to the account.');
            this.dataSource.getData(true);
            this.inProgress = false;
          })
          .catch(() => {
            this.inProgress = false;
          })
          .then(() => {
            this.getAccount();
          });
        });
      }
    });
  }

  onUpdateUserPermissions(user: any) {
    this.utils.showModal('Update User Permissions', 'Are you sure you want to update the permissions for "' + user.contact_person + '" to ' + user.permissions + '?', () => {
      this.inProgress = true;
      this.api.makeRequest('put', `v2/admin/users-accounts`, {
        permissions: user.permissions,
        account_id: this.account.id,
        user_id: user.user_id
      }).then(() => {
        this.utils.showToast('The user\'s account permissions were updated.');
        this.dataSource.getData(true);
        this.inProgress = false;
      });
    });
  }

  /**
   * Send an API request to unlink the user from the account.
   * @param user
   * @param evt
   */
  onDestroyUser(user: any, evt: any) {
    // Get confirmation.
    this.utils.showQuickActions(evt.target, `Are you sure you want to remove the user '${user.contact_person}' from the account?`, [
      {
        text: 'Yes',
        handler: () => {
          this.inProgress = true;
          this.api.makeRequest('delete', `v2/admin/users-accounts/${user.account_id}/${user.user_id}`)
          .then(() => {
            this.utils.showToast('The user was removed from the account.');
            this.dataSource.getData(true);
            if ( this.account.id ) {
              this.getAccount();
            }
          })
          .finally(() => {
            this.inProgress = false;
          });
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Open the dialog to copy templates from.
   */
  onOpenCopyTemplates() {
    this.utils.showComponentDialog(AccountCopyTemplatesDialogComponent,
      {
        targetAccountId: this.account.id
      },
      {width: '768px'},
      (results) => {});
  }

  /**
   * Make an API request to re-sync account inductions.
   */
  onResyncAccountInductons() {
    // Confirm before running the induction sync process.
    this.utils.showModal('Re-sync Account Inductions', 'Are you sure you want to re-sync account inductions?', () => {
      this.inProgress = true;
      // Make the API request to re-sync account inductions.
      this.api.makeRequest('get', `v2/admin/account/re-sync-inductions/${this.account.id}`)
        .then(response => {
          this.utils.showToast('Account inductions were re-synced.')
        }).catch(err => {
        this.utils.showToast('Failed to run account inductions sync.');
      })
        .finally(() => {
          this.inProgress = false;
        });
    });
  }

  onSelectMainContact() {
    this.utils.showComponentDialog(
      AdminEmployeesSelectorComponent,
      {
        multiple: false,
        deselect: false,
        selected: [this.account.main_contact_id],
        accountId: this.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.account.main_contact_id = userId;
      }
    );
  }
}

export class AccountsUsersDataSource extends ApiDataSource {

  order_by = 'contact_person';
  order = 'asc';

  accountId: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/admin/users-accounts`, resetOffset, {
      admin_account_id: this.accountId
    });
  }
}
