import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";

@Component({
  selector: 'app-sites-hazards-filter',
  templateUrl: './sites-hazards-filter.component.html',
  styleUrls: ['./sites-hazards-filter.component.scss']
})
export class SitesHazardsFilterComponent implements OnInit {

  /**
   * The filter object and default values.
   */
  filters: SiteHazardsFilters = {
    parent_site_id: null,
    site_ids: [],
    archived: 'false'
  } as SiteHazardsFilters;

  constructor(
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesHazardsFilterComponent>
  ) { }

  ngOnInit() {
    // Store the incoming values.
    this.filters.parent_site_id = this.data['parent_site_id'] || [];
    this.filters.site_ids = this.data['selected_site_ids'] || [];
    this.filters.archived = this.data['archived'] || 'false';
  }

  /**
   * Open the site selector.
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        parent_site_id: this.filters.parent_site_id,
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }
    )
      .then((results) => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      });
  }

  /**
   * Clear the filters and close the dialog.
   */
  onClearAndClose() {
    this.filters.site_ids = [];
    this.filters.archived = "false";
    // Close the dialog.
    this.onSaveAndClose();
  }

  /**
   * Close the dialog and send the filters back.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }

}

export interface SiteHazardsFilters {
  parent_site_id: number;
  site_ids: number[] | string;
  archived: string;
}
