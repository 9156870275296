import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { SafetyObservation } from 'src/app/models/safety-observation.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';

@Component({
  selector: 'app-safety-observations-view-files',
  templateUrl: './safety-observations-view-files.component.html',
  styleUrls: ['./safety-observations-view-files.component.scss']
})
export class SafetyObservationsViewFilesComponent implements OnInit {

  @Input('safety_observation_id') safety_observation_id: number;
  @Input('safety_observation') safety_observation: SafetyObservation;

  dataSource: SafetyObservationsFilesDataSource = new SafetyObservationsFilesDataSource(this.app, this.api);

  listColumns: string[] = [
    // 'select',
    'id',
    'thumbnail',
    'name',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService // Used in view.
  ) { }

  ngOnInit() {
    this.dataSource.safety_observation_id = this.safety_observation_id;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC dates based on their corresponding date column.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * View the uploaded files.
   * @param url The URL to view.
   */
  onViewFile(url: string) {
    this.utils.openUrl(url);
  }
}

export class SafetyObservationsFilesDataSource extends ApiDataSource {
  // Default records ordering
  order_by = 'date_created';
  order = 'desc';

  safety_observation_id: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/safety-observations/${this.safety_observation_id}/files`, resetOffset);
  }
}
