<div class="container-fluid">
  <nav class="navbar navbar-expand-lg">
    <span class="mr-auto">

        <mat-menu #linkMenu="matMenu">
          <button mat-menu-item (click)="onAdd()">
            New Hazard
          </button>
          <button mat-menu-item (click)="onSelectHazards()">
            Link Hazards
          </button>
          <button mat-menu-item (click)="onCopy()">
            Select Templates
          </button>
        </mat-menu>

        <button
          mat-raised-button
          color="primary"
          matTooltip="Add a new or existing Incident Hazard & Risk."
          [matMenuTriggerFor]="linkMenu"
          id="menu-button">
          <mat-icon>add</mat-icon>
          Add Hazard
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onUnlinkSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Unlink Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Incident Hazards & Risks." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

    <form class="form-inline my-2 my-lg-0"
          style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)"
    >
      <input class="form-control mr-sm-2" type="search"
             [(ngModel)]="dataSource.search" name="search" placeholder="Search"
             aria-label="Search" />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              (click)="dataSource.getData()"
      >
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </nav>


  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef id="th-select">
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
                      [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
                      [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? dataSource.selection.toggle(row.id) : null"
                      [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id">
        #
      </th>
      <td mat-cell *matCellDef="let hazard">
        {{ hazard.id }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-name">Hazard/Risk</th>
      <td mat-cell *matCellDef="let hazard" class="text-wrap">
        <b>{{ hazard.name }}</b> <br />
        <small>{{ hazard.description }}</small>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-status">Status</th>
      <td mat-cell *matCellDef="let hazard">{{ hazard.status }}</td>
    </ng-container>

    <!-- Initial Risk Assessment Column -->
    <ng-container matColumnDef="ira">
      <th mat-header-cell *matHeaderCellDef id="th-ira">
        <span matTooltip="Initial Risk Assessment">IRA</span>
      </th>
      <td mat-cell *matCellDef="let hazard">
          <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity))">
            {{ utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity) }}
          </span>
      </td>
    </ng-container>

    <!-- Residual Risk Assessment Column -->
    <ng-container matColumnDef="rra">
      <th mat-header-cell *matHeaderCellDef id="th-rra">
        <span matTooltip="Residual Risk Assessment">RRA</span>
      </th>
      <td mat-cell *matCellDef="let hazard">
          <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity))">
            {{ utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity) }}
          </span>
      </td>
    </ng-container>

    <!-- Controls Column -->
    <ng-container matColumnDef="controls">
      <th mat-header-cell *matHeaderCellDef id="th-controls">Controls</th>
      <td mat-cell *matCellDef="let hazard">
        {{ getHazardControlsCount(hazard.controls) }}
      </td>
    </ng-container>

    <!-- Date Created Column -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by the date the Hazard/Risk was created.">Created At</th>
      <td mat-cell *matCellDef="let hazard">
        {{ (hazard.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Date Created (UTC) Column -->
    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created_UTC" matTooltip="Toggle sorting by the date the Hazard/Risk was created.">Created At (UTC)</th>
      <td mat-cell *matCellDef="let hazard">
        {{ (hazard.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef id="th-actions"></th>

      <td mat-cell *matCellDef="let hazard">
        <button type="button" mat-button (click)="onView(hazard)" matTooltip="View the incident hazard information." matTooltipPosition="above">
          View
        </button>

        <button type="button" mat-button (click)="onEdit(hazard)" matTooltip="Edit the incident hazard." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onUnlink(hazard.id)" matTooltip="Unlink the incident hazard." matTooltipPosition="above">
          Unlink
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async"
                 [pageSize]="dataSource.limit"
                 [pageSizeOptions]="dataSource.pageSizeOptions"
                 showFirstLastButtons>
  </mat-paginator>

</div>
