import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {IncidentsFilter} from "../../incidents/incidents-filter/incidents-filter.component";

@Component({
  selector: 'app-sites-incidents-filter',
  templateUrl: './sites-incidents-filter.component.html',
  styleUrls: ['./sites-incidents-filter.component.scss']
})
export class SitesIncidentsFilterComponent implements OnInit {

  // The filters object.
  filters: IncidentsFilter = {
    parent_site_id: null,
    site_ids: [],
    user_ids: [],
    contractor_ids: [],
    tag_ids: [],
    type: '',
    status: '',
    date_range: []
  }

  datepickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesIncidentsFilterComponent>
  ) { }

  ngOnInit() {
    this.datepickerConfig = Object.assign(
      {},
      {
        containerClass: 'theme-orange',
        dateInputFormat: 'DD MMM YYYY',
        rangeInputFormat: 'DD MMM YYYY',
        showWeekNumbers: true
      }
    );

    // Get and apply the incidents filters from storage.
    this.filters = {
      parent_site_id: this.data['parent_site_id'] || null,
      site_ids: this.data['site_ids'] || [],
      user_ids: this.data['user_ids'] || [],
      contractor_ids: this.data['contractor_ids'] || [],
      tag_ids: this.data['tag_ids'] || [],
      type: this.data['type'] || '',
      status: this.data['status'] || '',
      date_range: this.data['date_range'] || []
    }
    // Remap the dates. Storing it in storage stores it as string.
    this.filters.date_range = this.filters.date_range.map((date: Date|string) => new Date(date));
  }

  /**
   * Opens a dialog to select multiple sites.
   *
   * @return {void}
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        parent_site_id: this.filters.parent_site_id,
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      }
    );
  }

  /**
   * Opens a dialog for selecting multiple users from a networked users selector component.
   *
   * @return {void}
   */
  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
        selected: this.filters.user_ids,
        multiple: true,
        visitors_from_all_sites: true
      }, {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.filters.user_ids = results;
        }
      }
    );
  }

  /**
   * Clears all the filters and closes the dialog.
   *
   * @return {void}
   */
  onClearAndClose() {
    // Clear all the filters.
    this.filters.site_ids = [];
    this.filters.user_ids = [];
    this.filters.contractor_ids = [];
    this.filters.tag_ids = [];
    this.filters.type = '';
    this.filters.status = '';
    this.filters.date_range = [];
    // Save and close the dialog.
    this.onSaveAndClose();
  }

  /**
   * Sets the incident date in the filters object based on the provided event.
   *
   * @param {any} $event - The event object containing the incident date.
   * @return {void}
   */
  getOutputDateRange($event): void {
    // Get the incident date from the date selector.
    this.filters.date_range = $event;
  }

  /**
   * Saves the filters and closes the dialog.
   *
   * @return {void}
   */
  onSaveAndClose() {
    // Close the dialog, passing the filters as is.
    this.dialogRef.close(this.filters);
  }

  /**
   * Clears the date range for filtering incidents.
   *
   * @return {void}
   */
  onClearDateRange(): void {
    this.filters.date_range = [];
  }
}
