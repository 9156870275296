import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,  MatDialogRef} from "@angular/material";
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-codes-filter',
  templateUrl: './codes-filter.component.html',
  styleUrls: ['./codes-filter.component.scss']
})
export class CodesFilterComponent implements OnInit {

  // Codes search filters.
  filters: {
    search_by: string,
    only_archived: string
  } = {
    search_by: 'id',
    only_archived: 'false'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CodesFilterComponent>,
    public utils: UtilsService
  ) {
    // Set a default searchBy value of 'id' if no values are passed through.
    if (typeof this.data['search_by'] == 'undefined') {
      this.data['search_by'] = 'id';
    }

    // Set a default only_archived value of false if no values are passed through.
    if (typeof this.data['only_archived'] == 'undefined') {
      this.data['only_archived'] = false;
    }
  }

  ngOnInit() {
    // Apply the new inbound searchBy filter.
    this.filters.search_by = this.data['search_by'];
    this.filters.only_archived = this.data['only_archived'];
  }

  /**
   * Used to clear the search filters.
   */
  onClearAndClose() {
    // Reset search_by to 'id' and only_archived to false.
    this.filters.search_by = 'id';
    this.filters.only_archived = 'false';
    // Save and close the dialog.
    this.onSaveAndClose();
  }

  /**
   * Used to send the filters back to the calling component.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }
}
