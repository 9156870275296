import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginCredentialsComponent } from './login-credentials.component';
import { FormsModule } from '@angular/forms';
import {
  MatInputModule,
  MatIconModule,
  MatButtonModule
} from '@angular/material';
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  declarations: [
    LoginCredentialsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    SharedModule
  ]
})
export class LoginCredentialsModule {}
