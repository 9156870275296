<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Tasks</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter Tasks" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectSites()"
        [matBadge]="'' + (filters.site_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectUsers()"
        [matBadge]="'' + (filters.assigned_users.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> Assigned Users
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="priority">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Priority</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 100px;">
        <mat-select placeholder="Priority" [(ngModel)]="filters.priority"
                    name="priority"
                    matTooltip="Filter records by Priority">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let priority of priorities" [value]="priority">
            {{priority}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 100px;">
        <mat-select placeholder="Status" [(ngModel)]="filters.status"
                    name="status"
                    matTooltip="Filter records by Status">
          <mat-option value="">All</mat-option>
          <mat-option value="0">Open</mat-option>
          <mat-option value="1">Completed</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Due Date" dateRangePlaceholder="Select Due Date Range" dateRangeTooltip="Filter Tasks Due Date by date range." [dateRangeInput]="filters.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </td>
  </ng-container>
</div>




