import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatComponentsModule} from "../../mat-components/mat-components.module";
import {FormsModule} from "@angular/forms";
import {MATERIAL_MODULES} from "../../app.const";
import {PipesModule} from "../../pipes/pipes.module";
import {FilesModule} from "../../files/files.module";
import {MatIconModule, MatMenuModule} from "@angular/material";
import {TaskAnalysesRevisedComponent} from "./task-analyses-revised.component";
import {TaskAnalysesRevisedViewComponent} from "./task-analyses-revised-view/task-analyses-revised-view.component";
import {TaskAnalysesRevisedEditComponent} from "./task-analyses-revised-edit/task-analyses-revised-edit.component";
import { TaskAnalysesRevisedFilterComponent } from './task-analyses-revised-filter/task-analyses-revised-filter.component';
import {RouterModule} from "@angular/router";
import {DirectivesModule} from "../../directives/directives.module";


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatComponentsModule,
    FormsModule,
    ...MATERIAL_MODULES,
    PipesModule,
    FilesModule,
    RouterModule,
    DirectivesModule
  ],
  declarations: [
    TaskAnalysesRevisedComponent,
    TaskAnalysesRevisedViewComponent,
    TaskAnalysesRevisedEditComponent,
    TaskAnalysesRevisedFilterComponent
  ],
  entryComponents: [
    TaskAnalysesRevisedViewComponent,
    TaskAnalysesRevisedEditComponent,
    TaskAnalysesRevisedFilterComponent
  ]
})
export class TaskAnalysesRevisedModule { }
