import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { EmployeesAddComponent } from '../employees-add/employees-add.component';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit, AfterViewInit {

  // Columns to display in the list
  listColumns: string[] = [
    'select',
    'icons',
    'contact_person',
    'email',
    'mobile',
    'trade',
    'permissions',
    'notes',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  listDataSource = new EmployeesDataSource(this.app, this.api);

  // The paginator and sorter for the list
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.listDataSource.limit = this.paginator.pageSize;
      this.listDataSource.offset = this.paginator.pageIndex;
      this.listDataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.listDataSource.order_by = "date_created";
      } else {
        this.listDataSource.order_by = this.sort.active;
      }

      this.listDataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onExport(type: string = 'csv', id?: number) {
    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.listDataSource.selection.selected);
    }

    // Get the current date object
    const date = new Date();

    this.api.makeDownloadRequest(`v2/employees/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      order_by: this.listDataSource.order_by,
      order: this.listDataSource.order,
      search: this.listDataSource.search
    })
      .then((file) => {
        saveAs(file, `${ this.utils.getLangTerm('employees.plural', 'Employees') } - ${this.app.account.name} - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`);
      });
  }

  onTrainingDocsExport(type: string = 'csv', id?: number) {
    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.listDataSource.selection.selected);
    }

    // Get the current date object
    const date = new Date();

    this.api.makeDownloadRequest(`v2/employees-folders/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      order_by: this.listDataSource.order_by,
      order: this.listDataSource.order,
      search: this.listDataSource.search,
      eager_load: true
    })
      .then((file) => {
        saveAs(file, `${ this.utils.getLangTerm('employees.plural', 'Employees') } Training Documents - ${this.app.account.name} - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`);
      });
  }

  /**
   * Adding a new employee.
   */
  onAddEmployee() {
    this.utils.showComponentDialog(EmployeesAddComponent, {}, {
      width: '800px'
    })
      .then(() => {
        this.listDataSource.getData();
      });
  }

  /**
   * Delete the employee record.
   */
  onDelete(employeeId: number) {
    this.utils.showModal(
      'Delete ' + this.utils.getLangTerm('employees.singular', 'Employee'),
      'Are you sure you want to delete this ' + this.utils.getLangTerm('employees.singular', 'Employee') + '?',
      () => {
        this.api.makeRequest('delete', `v2/employees/${employeeId}`)
          .then(() => {
            this.utils.showToast('The ' + this.utils.getLangTerm('employees.singular', 'Employee') + ' was deleted.');
            this.listDataSource.getData();
          })
          .catch((_error) => {
            this.utils.handleAPIErrors(_error);
          });
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Delete Selected ' + this.utils.getLangTerm('employees.plural', 'Employees'),
      'Are you sure you want to delete the selected ' + this.utils.getLangTerm('employees.plural', 'Employees') + '?',
      () => {
        this.api.makeRequest('delete', `v2/employees/` + this.listDataSource.selection.selected.join(','))
          .then(() => {
            this.utils.showToast('The selected ' + this.utils.getLangTerm('employees.plural', 'Employees') + ' was deleted.');
            this.listDataSource.selection.clear();
            this.listDataSource.getData();
          })
          .catch((_error) => {
            this.utils.handleAPIErrors(_error);
          });
      }
    );
  }

  composeNewMessage() {
    if (this.listDataSource.selection.selected.length == 0) {
      this.utils.showToast('You need to select some ' + this.utils.getLangTerm('employees.plural', 'Employees') + ' first.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: this.listDataSource.selection.selected
    })
      .then(() => {
        this.utils.showToast('You can view the message stats in the messages section.');
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.listDataSource.getData();
      }
    );
  }

}

export class EmployeesDataSource extends ApiDataSource {

  order_by = 'contact_person';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/employees', resetOffset);
  }
}
