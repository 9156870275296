<ngx-loading-bar color="#F4901E"></ngx-loading-bar>
<mat-sidenav-container>
  <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="routerService.isAuthRoute()">
    <a routerLink="/dashboard">
      <img src="./assets/images/logo.png" class="logo" alt="SiteSoft New Zealand Ltd" />
    </a>

    <mat-nav-list dense>
      <h3 matSubheader *ngIf="app.isAuthenticated">Main Account</h3>

      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="dashboard" *ngIf="app.isAuthenticated">
        <span>Dashboard</span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="sites" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_sites')">
        <span> {{ utils.getLangTerm('sites.plural', 'Sites') }} </span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="contractors" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_contractors')">
        <span>{{ utils.getLangTerm('contractors.plural', 'Contractors') }}</span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="employees" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_employees')">
        <span>{{ utils.getLangTerm('employees.plural', 'Employees') }}</span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="visitors" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_visitors')">
        <span>Visitors</span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="sssp" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_sssp')">
        <span>Safety Plan Master File</span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" [routerLink]="['courses']" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_inductions')">
        <span>Inductions</span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="incidents" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_incidents')">
        <span>Incidents</span>
      </a>
      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="actions" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_tasks')">
        <span>Tasks</span>
      </a>

      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="safety-observations" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_safety_observations')">
        <span>Safety Observations</span>
      </a>

      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="tools" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_tools')">
        <span>Tools</span>
      </a>

      <mat-expansion-panel class="mat-elevation-z" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          app.userCan('manage_dynamic_forms')">
        <mat-expansion-panel-header>
          <mat-panel-title id="dynamic-forms">Forms</mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list dense>
          <a mat-list-item routerLinkActive="active-submenu-item" [routerLink]="['dynamic-forms']" *ngIf="
              app.userAccountLink.permissions != 'Employee' &&
              app.isAuthenticated &&
              app.userCan('manage_dynamic_forms')">
            <span>Form Templates</span>
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" [routerLink]="['dynamic-forms-categories']" *ngIf="
              app.userAccountLink.permissions != 'Employee' &&
              app.isAuthenticated &&
              app.userCan('manage_dynamic_forms')">
            <span>Form Categories</span>
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" [routerLink]="['dynamic-forms-assigned']" *ngIf="
              app.userAccountLink.permissions != 'Employee' &&
              app.isAuthenticated &&
              app.userCan('manage_dynamic_forms')">
            <span>Assigned Forms</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          (app.userCan('manage_sssp') ||
            app.userCan('manage_task_analysis') ||
            app.userCan('manage_hazardous_substances') ||
            app.userCan('manage_hazards') ||
            app.userCan('manage_erps') ||
            app.userCan('manage_toolbox_talks'))">
        <mat-expansion-panel-header>
          <mat-panel-title id="templates">Templates</mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list dense>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="hazards"
            *ngIf="app.userCan('manage_hazards')">
            Hazards & Risks
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="hazardous_substances"
            *ngIf="app.userCan('manage_hazardous_substances')">
            Hazardous Substances
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="task-analysis">
            Task Analysis Documents
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="task-analyses-revised">
            TA/JSA/SWMS
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="folders" *ngIf="
              app.userAccountLink.permissions != 'Employee' &&
              app.isAuthenticated &&
              app.userCan('manage_documents')">
            Documents
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="prequalifications"
            [routerLinkActiveOptions]="{ exact: true }" *ngIf="
              app.userAccountLink.permissions != 'Employee' &&
              app.userCan('manage_prequalifications')">
            {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Pre-Qualifications
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="audits-templates">
            Inspection/Audit Templates
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="audit-items-template">
            Inspection/Audit Questions
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated &&
          (
            app.userCan('view_access_records') ||
            app.userCan('view_onsite_records') ||
            app.userCan('view_time_onsite_records') ||
            (app.userCan('view_pandemic_records') && app.account.pandemic_enabled)
          )">
        <mat-expansion-panel-header>
          <mat-panel-title id="reports">Reports</mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list dense>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="reports/access-records"
            *ngIf="app.userCan('view_access_records')">
            Sign In/Out
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="reports/onsite-records"
            *ngIf="app.userCan('view_onsite_records')">
            Currently Onsite
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="reports/time-onsite-records"
            *ngIf="app.userCan('view_time_onsite_records')">
            Time Spent Onsite
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="reports/pandemic-records"
            *ngIf="app.userCan('view_pandemic_records') && app.account.pandemic_enabled">
            Covid-19 Records
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="audits">
            Inspections & Audits
          </a>
          <a mat-list-item routerLinkActive="active-submenu-item" routerLink="toolbox-talks">
            {{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }}
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="messages"
        *ngIf="app.isAuthenticated && app.userAccountLink.permissions != 'Employee'">
        <span>Messages</span>
      </a>

      <h3 matSubheader *ngIf="app.isAuthenticated">
        {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Accounts <br>
        (for {{ utils.getLangTerm('contractors.singular', 'Contractor').toLowerCase() }} use only)
      </h3>

      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="user/accessible-sites" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated">
        <span>PCBU1 {{ utils.getLangTerm('sites.plural', 'Sites') }}</span>
      </a>

      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="my-prequalifications" *ngIf="
          app.userAccountLink.permissions != 'Employee' &&
          app.isAuthenticated">
        <span>PCBU1 Pre-Qualifications</span>
      </a>

      <a mat-list-item routerLinkActive="active-submenu-item" routerLink="sign-in" *ngIf="!app.isAuthenticated">
        <span>Login</span>
      </a>

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-top-nav [sidenav]="sidenav"></app-top-nav>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-modal></app-modal>
<app-toast></app-toast>

<!-- toggleable overlay with a spinner -->
<tour-step-template></tour-step-template>
