<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/hazards" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Hazard & Risk Templates
    </button>
  </span>
</nav>

<mat-tab-group>
  <mat-tab label="Details">
    <div class="container-fluid" appPadding>
      <div class="row">
        <div class="col-12 col-md-6">
          <ng-container *ngIf="hazard.name">
            <h6>Hazard/Risk</h6>
            <p>{{ hazard.name }}</p>
          </ng-container>

          <ng-container *ngIf="hazard.description">
            <h6>Description</h6>
            <p>{{ hazard.description }}</p>
          </ng-container>

          <ng-container *ngIf="hazard.status">
            <h6>Status</h6>
            <p>{{ hazard.status }}</p>
          </ng-container>

          <ng-container *ngIf="hazard.ira_likelihood && hazard.ira_severity">
            <h6>Initial Risk Assessment</h6>
            <p>
              {{
                utils.getRiskAssessmentText(
                  hazard.ira_likelihood,
                  hazard.ira_severity
                )
              }}
            </p>
          </ng-container>

          <ng-container *ngIf="hazard.rra_likelihood && hazard.rra_severity">
            <h6>Residual Risk Assessment</h6>
            <p>
              {{
                utils.getRiskAssessmentText(
                  hazard.rra_likelihood,
                  hazard.rra_severity
                )
              }}
            </p>
          </ng-container>

          <ng-container *ngIf="hazard.controls.length">
            <h6>Hazard/Risk Controls</h6>

            <p *ngFor="let control of hazard.controls; let i = index">
              <b>{{ i + 1 }} - {{ control.control }}</b> <br />
              {{ loc_list[control.loc - 1] }}
            </p>
          </ng-container>

          <ng-container>
            <h6>Created By</h6>
            <p>
              <a (click)="onUserPublicView(hazard.created_by_hash)"
                 matTooltip="View User Public Profile">
                {{ hazard.created_by_name }}
              </a>
            </p>
          </ng-container>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Files">
    <div class="container-fluid">
      <div class="mat-elevation-z8">
        <ng-container *ngIf="media?.length">
          <app-files-list [view]="true" [files]="media"></app-files-list>
        </ng-container>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
