import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../api-request.service";
import {UtilsService} from "../utils.service";
import {tap} from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort} from "@angular/material";

@Component({
  selector: 'app-site-hazards-selector',
  templateUrl: './site-hazards-selector.component.html',
  styleUrls: ['./site-hazards-selector.component.scss']
})
export class SiteHazardsSelectorComponent implements OnInit, AfterViewInit {

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'status',
    'ira',
    'rra',
    'date_created',
    // 'date_created_UTC',
  ];

  site_id: number;

  dataSource: SiteHazardsSelectorDataSource;
  returnAllSelected = false;

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<SiteHazardsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // set multiple if it is not present to false
    if (typeof this.data['multiple'] === 'undefined') {
      this.data['multiple'] = false;
    }

    // construct the datasource
    this.dataSource = new SiteHazardsSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // set a default selection of nothing if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // loop and select the values
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }

    if (typeof this.data['site_id'] !== 'undefined') {
      this.dataSource.site_id = this.data['site_id'];
    }

    // set a default linked ids of nothing if no values are passed through
    if (typeof this.data['incident_link_id'] !== 'undefined') {
      this.dataSource.incident_link_id = this.data['incident_link_id'];
    }

    // get the list of sites
    this.dataSource.getData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC by its corresponding date column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  // clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * before the dialog close we want to store the new active site id
   */
  onSaveAndClose() {
    let response: number | number[];

    if (this.data['multiple']) {
      response = this.dataSource.selection.selected;
    } else {
      if (this.dataSource.selection.selected.length) {
        response = this.dataSource.selection.selected[0];
      } else {
        response = 0;
      }
    }

    this.dialogRef.close(response);
  }

}

export class SiteHazardsSelectorDataSource extends ApiDataSource {

  sort_by = 'name';
  sort_order = 'asc';

  archived = 'false';
  site_id: number = 0;

  incident_link_id: number = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest('v1/sites/' + this.site_id + '/hazards', resetOffset, {
      archived: this.archived,
      incident_link_id: this.incident_link_id
    });
  }
}
