<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="mr-auto">

      <button
        mat-raised-button
        color="primary"
        matTooltip="Click this button to add a new TA/JSA/SWMS Template Workflow Step Hazard/Risk."
        [matMenuTriggerFor]="linkMenu">
        <mat-icon>add</mat-icon>
        New Hazard/Risk
      </button>

      <mat-menu #linkMenu="matMenu">
        <button mat-menu-item (click)="onAdd()">
          Create New
        </button>
        <button mat-menu-item (click)="onSelectHazards()">
          Copy from {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Hazards
        </button>
        <button mat-menu-item (click)="onCopyHazards('all')">
          Copy all {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Hazards
        </button>
      </mat-menu>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect TA/JSA/SWMS Template Workflow Step Hazards." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <form class="form-inline my-2 my-lg-0"
          style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2" type="search"
        [(ngModel)]="dataSource.search" name="search" placeholder="Search"
        aria-label="Search" id="search-input"
        matTooltip="Search for TA/JSA/SWMS Template Workflow Step Notes." />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              (click)="dataSource.getData(true)"
              id="search-button"
              matTooltip="Click this button to run the current search.">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </nav>

  <mat-dialog-content class="my-3 mx-0 py-0 pr-3 pl-0" style="max-height: 40vh">
    <div class="scroll-box">
      <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef width="65px">
            <mat-checkbox
              (change)="$event ? dataSource.selectAllToggle() : null"
              [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? dataSource.selection.toggle(row.id) : null"
              [checked]="dataSource.selection.isSelected(row.id)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            #
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard">
            {{ ta_revised_workflow_hazard.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard">
            <b>{{ ta_revised_workflow_hazard.name  | ellipsis: [30] }}</b> <br />
            <div><small style="white-space: pre-wrap">{{ ta_revised_workflow_hazard.description  | ellipsis: [100] }}</small></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard">
            {{ ta_revised_workflow_hazard.status }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ira">
          <th mat-header-cell *matHeaderCellDef>Initial <br />Risk Assessment</th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard">
          <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(ta_revised_workflow_hazard.ira_likelihood, ta_revised_workflow_hazard.ira_severity))">
            {{ utils.getRiskAssessmentText(ta_revised_workflow_hazard.ira_likelihood, ta_revised_workflow_hazard.ira_severity) }}
          </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="rra">
          <th mat-header-cell *matHeaderCellDef>
            Residual <br />Risk Assessment
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard">
          <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(ta_revised_workflow_hazard.rra_likelihood, ta_revised_workflow_hazard.rra_severity))">
            {{ utils.getRiskAssessmentText(ta_revised_workflow_hazard.rra_likelihood, ta_revised_workflow_hazard.rra_severity) }}
          </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_by_user">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created By
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard" class="no-padding">
            <span *ngIf="ta_revised_workflow_hazard?.created_by_user else showSystemCreator">
              <a (click)="onUserPublicView(ta_revised_workflow_hazard.created_by_user.hash)"
                matTooltip="View User Public Profile">
                {{ ta_revised_workflow_hazard.created_by_user.contact_person }}
              </a>
            </span>
            <ng-template #showSystemCreator>System</ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="date_created">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created At
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard" class="no-padding">
        <span>
          {{ (ta_revised_workflow_hazard.date_created * 1000) | timezoneConvertMedium }}
        </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="date_created_UTC">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created At (UTC)
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_hazard" class="no-padding">
        <span>
          {{ (ta_revised_workflow_hazard.date_created * 1000) | timezoneConvertUTCMedium }}
        </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let ta_revised_workflow_hazard">
            <button type="button" mat-button (click)="onView(ta_revised_workflow_hazard)" matTooltip="Click this button to view this TA/JSA/SWMS Template Workflow Step Hazard.">
              View
            </button>

            <button type="button" mat-button (click)="onEdit(ta_revised_workflow_hazard)" matTooltip="Click this button to edit this TA/JSA/SWMS Template Workflow Step Hazard.">
              Edit
            </button>

            <button type="button" mat-button (click)="onRemove(ta_revised_workflow_hazard.id)" matTooltip="Click this button to remove this TA/JSA/SWMS Template Workflow Step Hazard.">
              Delete
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
    </div>
  </mat-dialog-content>

</div>

