import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {TaskAnalysesRevisedWorkflowNoteModel} from "../../models/task-analyses-revised-workflow-note.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort} from "@angular/material";
import {SitesTaskAnalysesRevisedWorkflowsNotesEditComponent} from "../sites-task-analyses-revised-workflows-notes-edit/sites-task-analyses-revised-workflows-notes-edit.component";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-notes',
  templateUrl: './sites-task-analyses-revised-workflows-notes.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-notes.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsNotesComponent implements OnInit {

  @Input() private taRevisedId: number;
  @Input() private taRevisedWorkflowId: number;
  @Input() public taRevisedPath: string;

  path: string;

  displayedColumns: string[] = [
    'select',
    'id',
    'note',
    'created_by_user',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  dataSource = new SitesTaskAnalysesRevisedWorkflowsNotesDataSource(this.app, this.api);
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedWorkflowsNotesComponent>
  ) { }

  ngOnInit() {
    // get the ta revised workflow id
    this.dataSource.ta_revised_workflow_id = this.dialogData.ta_revised_workflow.id;

    // get the ta revised id
    this.dataSource.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id
    this.dataSource.site_id = this.dialogData.site_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.dataSource.ta_revised_id) {
      this.dataSource.getData();
      this.findTa(this.dataSource.ta_revised_id);
    } else {
      this.redirectBack();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns based on their corresponding date fields.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  private redirectBack() {
    if(this.dataSource.site_id && this.path) {
      this.router.navigate([`/sites/${this.dataSource.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  private findTa(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.dataSource.site_id
    })
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onAdd() {
    this.onEdit(new TaskAnalysesRevisedWorkflowNoteModel());
  }

  onEdit(ta_revised_workflow_note: TaskAnalysesRevisedWorkflowNoteModel) {
    this.dialog
      .open(SitesTaskAnalysesRevisedWorkflowsNotesEditComponent, {
        width: '1024px',
        data: {
          ta_revised_workflow_note: ta_revised_workflow_note,
          site_id: this.dataSource.site_id,
          ta_revised_id: this.dataSource.ta_revised_id,
          ta_revised_workflow_id: this.dataSource.ta_revised_workflow_id,
          path: this.path
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if(result == "version") {
          this.dialogRef.close(true);
        }

        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.utils.showModal('Delete TA/JSA/SWMS Workflow Step Note', 'Are you sure you want to delete the TA/JSA/SWMS Workflow Step Note? Notes created by someone else will not be deleted.', () => {
      this.continueOnRemoveSelected([id]);
    });
  }

  onRemoveSelected() {
    // if this note was created by some other user then that will be removed from selections within array
    const validatedIds = this.validateRemoval(this.dataSource.selection.selected);

    if(validatedIds.length > 0) {
      this.utils.showModal('Delete TA/JSA/SWMS Workflow Step Note', 'Are you sure you want to delete the selected TA/JSA/SWMS Workflow Step Notes? Notes created by someone else will not be deleted.', () => {
        this.continueOnRemoveSelected(validatedIds);
      });
    } else {
      this.utils.showToast('You have not selected any valid TA/JSA/SWMS Workflow Step Notes.');
    }

  }

  private validateRemoval(ids: number[]) {
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      if (!this.checkRemovalData(id)) {
        ids.splice(i, 1);
        i--; // decrement
      }
    }

    return ids;
  }

  private checkRemovalData(id: number) {
    let pass = true;

    this.dataSource.dataSubject.value.forEach((ta_revised_workflow_note, index) => {
      if(ta_revised_workflow_note.id == id && ta_revised_workflow_note.created_by_user.id != this.app.user.id) {
        pass = false;
        return pass;
      }
    });

    return pass;
  }

  continueOnRemoveSelected(ids: number[] = []) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${this.dataSource.ta_revised_workflow_id}/notes/${ids.join(',')}`, [], {
      site_id: this.dataSource.site_id
    })
      .then(() => {
        this.utils.showToast('The TA/JSA/SWMS Workflow Step Note(s) were deleted.');
      })
      .finally(() => {
        this.dataSource.getData();
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class SitesTaskAnalysesRevisedWorkflowsNotesDataSource extends ApiDataSource {
  order_by = 'id';
  order = 'desc';
  searchBy = 'note';

  ta_revised_id = 0;
  ta_revised_workflow_id = 0;
  site_id = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/notes`, resetOffset, {
      searchBy: this.searchBy,
      site_id: this.site_id
    });
  }
}
