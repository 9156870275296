import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatPaginator,
  MatSort
} from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../../shared/api-request.service";

@Component({
  selector: 'app-audits-templates-selector',
  templateUrl: './audits-templates-selector.component.html',
  styleUrls: ['./audits-templates-selector.component.scss']
})
export class AuditsTemplatesSelectorComponent implements OnInit, AfterViewInit {

  // The columns that should show in the list.
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'date_created'
    // 'date_created_UTC'
  ];

  // The datasource to use for the table.
  dataSource: AuditsTemplatesSelectorDataSource;

  returnAllSelected = false;

  // The paginator and sorter.
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<AuditsTemplatesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Set multiple if it is not present and default it to false.
    if (typeof this.data['multiple'] === 'undefined') {
      this.data['multiple'] = false;
    }

    // Construct the datasource.
    this.dataSource = new AuditsTemplatesSelectorDataSource(this.app, this.api, this.data['multiple']);

    // Set the default selected array to an empty array if none is defined.
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // Set allow_no_selection if it is not present and set the default value to false.
    if (typeof this.data['allow_no_selection'] === 'undefined') {
      this.data['allow_no_selection'] = false;
    }

    // Select all selected values in the list.
    if (  this.data['selected'].length > 0 ) {
      this.dataSource.selection.select(...this.data['selected']);
    }

    this.returnAllSelected = this.data.returnAllSelected;

    // Get the first set of data.
    this.dataSource.getData(true);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      // Get the data.
      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Clear the search bar and perform a search.
   */
  onClearSearch() {
    // Clear the search field in the datasource.
    this.dataSource.search = '';
    // Search for records.
    this.dataSource.getData(true);
  }

  /**
   * Clear the current selection.
   */
  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * before the dialog close we want to store the new active site id
   */
  onSaveAndClose() {
    // Check if we need to return all selected values.
    if (this.returnAllSelected) {
      return this.dialogRef.close({
        selected: this.dataSource.selection.selected,
        isAllSelected: this.dataSource.isAllSelected()
      });
    }

    // The response can be a single id or an array of ids.
    let response: number | number[];

    // Check if we need to respond with an array of ids.
    if (this.data['multiple']) {
      response = this.dataSource.selection.selected;
    } else {
      // Check if we have anything selected.
      if (this.dataSource.selection.selected.length) {
        // Respond with the first selected item.
        response = this.dataSource.selection.selected[0];
      } else {
        response = null;
      }
    }

    this.dialogRef.close(response);
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class AuditsTemplatesSelectorDataSource extends ApiDataSource {

  order_by: string = 'name';
  order: string = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v1/audits?template=true', resetOffset);
  }
}
