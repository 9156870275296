import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {SitesTaskAnalysesRevisedVersioningComponent} from "../sites-task-analyses-revised-versioning/sites-task-analyses-revised-versioning.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TaskAnalysesRevisedWorkflowHazardModel} from "../../models/task-analyses-revised-workflow-hazard.model";
import {RISK_LIKELIHOODS} from "../../shared/risk-likelihoods";
import {RISK_SEVERITIES} from "../../shared/risk-severities";
import {RISK_LEVEL_OF_CONTROLS} from "../../shared/risk-level-of-controls";
import {HazardControlModel} from "../../models/hazard-control.model";
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import {FileService} from "../../shared/file.service";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-hazards-edit',
  templateUrl: './sites-task-analyses-revised-workflows-hazards-edit.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-hazards-edit.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsHazardsEditComponent implements OnInit {

  inProgress = false;

  likelihood_list: string[] = RISK_LIKELIHOODS;
  severity_list: string[] = RISK_SEVERITIES;
  loc_list: string[] = RISK_LEVEL_OF_CONTROLS;

  path: string;
  selectedFiles: File[] = [];
  newFiles: FileList;
  site_id: number;
  ta_revised_id: number;
  ta_revised_workflow_id: number;
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();
  ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel = new TaskAnalysesRevisedWorkflowHazardModel();

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private fileService: FileService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedWorkflowsHazardsEditComponent>,
    // private dialogWorkflowRef: MatDialogRef<SitesTaskAnalysesRevisedWorkflowsTabsComponent>,
    public router: Router,
    public renderer: Renderer
  ) { }

  ngOnInit() {

    // get the task analysis workflow hazard id
    this.ta_revised_workflow_hazard.id = this.dialogData.ta_revised_workflow_hazard.id;

    // get the task analysis workflow id
    this.ta_revised_workflow_id = this.dialogData.ta_revised_workflow_id;

    // get the task analysis id
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id
    this.site_id = this.dialogData.site_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.ta_revised_workflow_hazard.id) {
      this.find(this.ta_revised_workflow_hazard.id);
    }

    if(this.ta_revised_id) {
      this.findTa(this.ta_revised_id);
    }
  }

  private redirectBack() {
    if(this.site_id && this.path) {
      this.router.navigate([`/sites/${this.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  private find(ta_revised_workflow_hazard_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${ta_revised_workflow_hazard_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised_workflow_hazard = response;

        if (typeof this.ta_revised_workflow_hazard.controls == 'string') {
          this.ta_revised_workflow_hazard.controls = JSON.parse(this.ta_revised_workflow_hazard.controls);
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private findTa(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onSubmitHazard(form: NgForm, closeDialog:boolean = true, findOnly: boolean = false) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(!this.ta_revised_workflow_hazard.id) {
      this.create();
    } else {
      if(findOnly) {
        this.find(this.ta_revised_workflow_hazard.id);
        this.inProgress = false;
      } else {
        this.update(closeDialog);
      }
    }
  }

  onSelectReviewer() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised_workflow_hazard.reviewer_id],
        selectedAccountId: this.app.account.id,
        visitors_from_all_sites: true
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.ta_revised_workflow_hazard.reviewer_id = userId;
      }
    );
  }

  onRestoreFromRef() {
    this.acknowledgementCheck("restore");
  }

  continueOnRestoreFromRef() {
    this.utils.showModal('Restore Step Hazard/Risk from ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' Hazard/Risk used to create it', 'Are you sure you want to restore the TA/JSA/SWMS Workflow Step Hazard/Risk? This will overwrite all data and replace with Reference data!', () => {
      this.inProgress = true;
      this.api
        .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards-restore`, this.ta_revised_workflow_hazard, {
          site_id: this.site_id
        })
        .then((response) => {
          this.utils.showToast(`The TA/JSA/SWMS Workflow Step Hazard has been restored.`);
        })
        .finally(() => {
          this.find(this.ta_revised_workflow_hazard.id);
          this.inProgress = false;
        });
    });
  }

  getRiskAssessmentColorChange(likelihood: number, severity: number) {
    const riskAssessment = this.utils.getRiskAssessmentText(
      likelihood,
      severity
    );

    if (['Very Low', 'Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High', 'Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  create() {
    this.acknowledgementCheck("create");
  }

  private continueCreate() {
    let request: Promise<any>;

    request = this.api
      .makeRequest('post', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards`, this.ta_revised_workflow_hazard, {
        site_id: this.site_id
      })
      .then((response) => {
        this.onSuccess(`A new TA/JSA/SWMS Workflow Step Hazard/Risk was created.`);
        this.ta_revised_workflow_hazard = response;

        // Convert hazard controls from a JSON encoded string to an array.
        if (typeof this.ta_revised_workflow_hazard.controls == 'string') {
          this.ta_revised_workflow_hazard.controls = JSON.parse(this.ta_revised_workflow_hazard.controls);
        }
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_step_hazard/${this.ta_revised_workflow_hazard.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            this.inProgress = false;
            this.close(false);
          });
      } else {
        this.inProgress = false;
        this.close(false);
      }
    });
  }

  update(closeDialog:boolean = true) {
    this.acknowledgementCheck("update", closeDialog); // Versioning no longer required for Notes Management
  }

  private continueUpdate(closeDialog:boolean = true) {
    let request: Promise<any>;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${this.ta_revised_workflow_hazard.id}`, this.ta_revised_workflow_hazard, {
        site_id: this.site_id
      })
      .then((response) => {
        this.onSuccess(`The TA/JSA/SWMS Workflow Step Hazard/Risk was updated.`);
        this.ta_revised_workflow_hazard = response;

        // Convert hazard controls from a JSON encoded string to an array.
        if (typeof this.ta_revised_workflow_hazard.controls == 'string') {
          this.ta_revised_workflow_hazard.controls = JSON.parse(this.ta_revised_workflow_hazard.controls);
        }
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_step_hazard/${this.ta_revised_workflow_hazard.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            if(!closeDialog) {
              this.find(this.ta_revised_workflow_hazard.id);
              this.inProgress = false;
            } else {
              this.close(false);
            }
          });
      } else {
        if(!closeDialog) {
          this.find(this.ta_revised_workflow_hazard.id);
          this.inProgress = false;
        } else {
          this.close(false);
        }
      }
    });
  }

  onDelete() {
    this.acknowledgementCheck("delete"); // Versioning no longer required for Notes Management
  }

  private continueOnDelete() {
    this.utils.showModal('Delete TA/JSA/SWMS Workflow Step Hazard/Risk', 'Are you sure you want to delete the TA/JSA/SWMS Workflow Step Hazard/Risk?', () => {
      this.api.makeRequest('delete', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${this.ta_revised_workflow_hazard.id}`, [], {
        site_id: this.site_id
      })
        .then(() => {
          this.onSuccess('The TA/JSA/SWMS Workflow Step Hazard/Risk was deleted.');
          this.close(false);
        });
    });
  }

  onAddControl() {
    this.ta_revised_workflow_hazard.controls.push(new HazardControlModel());
  }

  onRemoveControl(i: number) {
    this.ta_revised_workflow_hazard.controls.splice(i, 1);
  }

  private acknowledgementCheck(crud_action: string, closeDialog:boolean = true, file_id: number = 0) {
    this.api
      .makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/acknowledgement`, this.ta_revised, {
        site_id: this.site_id
      })
      .then((response) => {
        if(response.data.acknowledgementsDone) {
          this.beforeVersioning(crud_action, file_id);
        } else {

          switch(crud_action) {
            case "update":
              this.continueUpdate(closeDialog);
              break;
            case "create":
              this.continueCreate();
              break;
            case "delete":
              this.continueOnDelete();
              break;
            case "restore":
              this.continueOnRestoreFromRef();
              break;
            case "file_delete":
              this.continueOnRemoveFile(file_id);
              break;
            default:
            // do nothing
          }

        }
      });
  }

  private beforeVersioning(crud_action: string, file_id: number = 0) {
    this.inProgress = false;

    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedVersioningComponent,
      {
        task_name: this.ta_revised.task_name
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          // If confirmed then begin versioning of Task Analysis
          if(results.confirmed) {
            this.createNewVersion(results.amendment_title, results.reason_for_amendment, crud_action, [this.ta_revised_workflow_hazard.id], file_id);
          }
        }
      }
    );
  }

  private createNewVersion(amendment_title: string, reason_for_amendment: string, crud_action:string, remove_ids:number[] = [], file_id: number = 0) {

    let request: Promise<any>;
    let upload_step_hazard_id: number = 0;

    this.inProgress = true;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards-version`, this.ta_revised_workflow_hazard, {
        site_id: this.site_id,
        hazard_id: this.ta_revised_workflow_hazard.id,
        amendment_title: amendment_title,
        reason_for_amendment: reason_for_amendment,
        crud_action: crud_action,
        remove_ids: remove_ids.length ? remove_ids.join(',') : '',
        selected_hazard_ids: '',
        file_remove_id: file_id,
        hazard_upload_id: this.selectedFiles.length > 0 ? this.ta_revised_workflow_hazard.id : 0
      })
      .then((response) => {
        this.utils.showToast(`Created a new version of the "${this.ta_revised.task_name}" TA/JSA/SWMS.`);
        upload_step_hazard_id = response.upload_id;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 && upload_step_hazard_id > 0  ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_step_hazard/${upload_step_hazard_id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            this.dialogRef.close("version");
            this.redirectBack();
          });
      } else {
        this.dialogRef.close("version");
        this.redirectBack();
      }
    });

  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    // this.dialogRef.close(true);
  }

  private close(shouldCloseAll: boolean = false) {
    this.inProgress = false;

    // Code Review 30/08/2021: Temp fix. Close all dialogs when a new TA version is created.
    if ( shouldCloseAll ) {
      this.dialog.closeAll();
    } else {
      this.dialogRef.close();
    }

  }

  onSelectFiles(form: NgForm, files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
      this.onSubmitHazard(form, false);
    }
  }

  onRemoveFile(file_id: any) {
    if ( file_id > 0 ) {
      // Do not process if already in progress.
      if (this.inProgress) {
        return;
      }

      // this.inProgress = true;
      // this.acknowledgementCheck('file_delete', false, file_id); // Removed to avoid Versioning when Managing Files
      this.utils.showToast('Your file was removed successfully.');
    }
  }

  continueOnRemoveFile(file_id: number) {
    this.api.makeRequest('delete',`v2/file-manager/task_analysis_revised_step_hazard/${this.ta_revised_workflow_hazard.id}/${file_id}`, [], {})
      .then((response) => {
        this.utils.showToast('Your file was removed successfully.');
        this.find(this.ta_revised_workflow_hazard.id);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

}
