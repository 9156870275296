import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { SsspTabsDialogComponent } from '../sssp-tabs/sssp-tabs-dialog.component';
import { SsspFilterComponent } from "../sssp-filter/sssp-filter.component";

@Component({
  selector: 'app-sssp-list',
  templateUrl: './sssp-list.component.html',
  styleUrls: ['./sssp-list.component.scss']
})
export class SsspListComponent implements OnInit {

  listColumns: string[] = [
    'select',
    'id',
    'site_name',
    'site_location',
    'contractor',
    'tags',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];
  listDataSource = new SSSPDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.listDataSource.limit = paginator['pageSize'];
      this.listDataSource.offset = paginator['pageIndex'];
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.listDataSource.order = sorter['direction'];
      // sorting for utc time by parsing original time
      if (sorter['active'] === "date_modified_UTC") {
        this.listDataSource.order_by = "date_modified";
      } else {
        this.listDataSource.order_by = sorter['active'];
      }

      this.listDataSource.getData();
    })).subscribe();
  }

  onManageSSSP(sssp_hash: string) {
    if (!sssp_hash) {
      return;
    }

    this.utils.showComponentDialog(SsspTabsDialogComponent, {
      sssp_hash: sssp_hash
    })
      .then(() => { });
  }

  onRemove(id: number, evt: any) {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to remove this Safety Plan?', [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('delete', `v1/sssp/${id}`)
            .then(() => {
              this.listDataSource.getData();
              this.utils.showToast('The Safety Plan was removed.');
            })
            .catch((error) => {
              this.utils.handleAPIErrors(error);
            });
        }
      },
      {
        text: 'No',
        handler: () => { }
      }
    ]);
  }

  // Remove multiple selected messages.
  onRemoveSelected() {
    this.utils.showModal(
      'Remove Selected Safety Plans',
      'Are you sure you want to remove the selected Safety Plans?',
      () => {
        const ssspIds = this.listDataSource.selection.selected.join(',');
        this.api.makeRequest('delete', `v1/sssp/${ssspIds}`)
          .then(() => {
            this.listDataSource.selection.clear();
            this.listDataSource.getData();
            this.utils.showToast('The selected Safety Plans was removed.');
          })
          .catch((error) => {
            this.utils.handleAPIErrors(error);
          });
      }
    );
  }

  onFilter() {
    this.utils.showComponentDialog(
      SsspFilterComponent,
      {
        selected_site_ids: this.listDataSource.site_ids,
        selected_contractor_ids: this.listDataSource.contractor_ids
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.listDataSource.site_ids = results.site_ids ? results.site_ids : this.listDataSource.site_ids;
          this.listDataSource.contractor_ids = results.contractor_ids ? results.contractor_ids : this.listDataSource.contractor_ids;
          this.listDataSource.getData();
        }
      }
    );
  }

}

export class SSSPDataSource extends ApiDataSource {

  order_by = 'date_created';
  order = 'desc';

  contractor_ids: number[] = [];
  site_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v1/sssp', resetOffset, {
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
      site_ids: this.site_ids.length ? this.site_ids.join(',') : ''
    });
  }
}
