import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SafetyObservationsEditComponent } from '../safety-observations-edit/safety-observations-edit.component';
import { SafetyObservationsViewComponent } from '../safety-observations-view/safety-observations-view.component';
import { SafetyObservationsService } from '../safety-observations.service';
import * as moment from 'moment';
import { SafetyObservationsFilterComponent } from "../safety-observations-filter/safety-observations-filter.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-safety-observations',
  templateUrl: './safety-observations.component.html',
  styleUrls: ['./safety-observations.component.scss'],
  providers: [
    SafetyObservationsService
  ]
})
export class SafetyObservationsComponent implements OnInit {

  listColumns: string[] = [
    'select',
    'id',
    'so_description',
    'so_category',
    'so_type',
    'so_priority',
    'so_status',
    'so_rating',
    'so_action_taken',
    'site',
    'reported_by_user',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];

  // The paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService, // Used in view.
    public safetyObservationsService: SafetyObservationsService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    this.safetyObservationsService.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place.
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.safetyObservationsService.dataSource.limit = paginator['pageSize'];
      this.safetyObservationsService.dataSource.offset = paginator['pageIndex'];

      this.safetyObservationsService.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      // sorting for utc time by parsing original time
      if (sorter['active'] === "date_created_UTC") {
        this.safetyObservationsService.dataSource.order_by = "date_created";
      } else if (sorter['active'] === "date_modified_UTC") {
        this.safetyObservationsService.dataSource.order_by = "date_modified";
      } else {
        this.safetyObservationsService.dataSource.order_by = sorter['active'];
      }

      this.safetyObservationsService.dataSource.order = sorter['direction'];
      this.safetyObservationsService.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Open an existing or report a new safety observation.
   * @param safety_observation_id The id of the safety observation. Null if a new safety observation gets reported.
   */
  onEditSafetyObservation(safety_observation_id?: number) {
    this.utils.showComponentDialog(SafetyObservationsEditComponent, {
      safety_observation_id: safety_observation_id
    }, {
      width: '90%'
    })
      .then(() => {
        // Refresh the list after the dialog closed.
        this.safetyObservationsService.dataSource.getData();
      });
  }

  /**
   * View the safety observation.
   * @param safety_observation_id The id of the safety observation to view.
   */
  onViewSafetyObservation(safety_observation_id: number) {
    this.utils.showComponentDialog(SafetyObservationsViewComponent, {
      safety_observation_id: safety_observation_id
    })
      .then(() => {
        // Refresh the list after the dialog closed.
        this.safetyObservationsService.dataSource.getData();
      });
  }

  /**
   * Delete the safety observation.
   * @param safety_observation_id The id of the safety observation to delete.
   * @param evt The element reference to place the confirmation dialog in the right place.
   */
  onDeleteSafetyObservation(safety_observation_id: number, evt: any) {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to remove the safety observation?', [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('delete', `v2/safety-observations/${safety_observation_id}`)
            .then(() => {
              // Refresh the list after the safety observation was deleted.
              this.safetyObservationsService.dataSource.getData();
              this.utils.showToast('The safety observation was removed.');
            })
            .catch((error) => {
              this.utils.handleAPIErrors(error);
            });
        }
      },
      {
        text: 'No',
        handler: () => { }
      }
    ]);
  }

  /**
   * Delete multiple safety observations.
   */
  onRemoveSelectedSafetyObservations() {
    const selectedIds = this.safetyObservationsService.dataSource.selection.selected;
    if (selectedIds.length == 0) {
      this.utils.showModal('Delete Safety Observations', 'Please select some safety observations to remove.');
      return;
    }
    this.utils.showModal('Delete Safety Observations', `Are you sure you want to delete (${selectedIds.length}) safety observation/s?`, () => {
      this.api.makeRequest('delete', `v2/safety-observations/${selectedIds.join(',')}`)
        .then(() => {
          // Refresh the list after the safety observation was deleted.
          this.safetyObservationsService.dataSource.deselectAll();
          this.safetyObservationsService.dataSource.getData();
          this.utils.showToast('The safety observations were removed. If some remain in the list then you don\'t have permissions to remove them.');
        })
        .catch((error) => {
          this.utils.handleAPIErrors(error);
        });
    });
  }

  onExportSafetyObservations(exportType: string = 'csv') {
    if ( exportType == 'pdf' ) {
      this.exportSafetyObservationsPDF()
    }  else {
      this.exportSafetyObservationsCSV(exportType)
    }
  }

  exportSafetyObservationsPDF(){
    this.safetyObservationsService.dataSource.exportDataPDF().subscribe((response)=>{
        const link = document.createElement('a');
        link.href = response.url;
        link.target = '_blank';
        link.download = 'safety-observation.pdf';
        link.click();
      });
  }

  exportSafetyObservationsCSV(exportType){
    this.safetyObservationsService.dataSource.exportData(exportType)
    .then((response) => {
      saveAs(response, 'Safety Observations - ' + moment().toString() + '.' + 'csv');
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  onFilter() {
    this.utils.showComponentDialog(
      SafetyObservationsFilterComponent,
      {
        so_category: this.safetyObservationsService.dataSource.so_category,
        so_type: this.safetyObservationsService.dataSource.so_type,
        so_priority: this.safetyObservationsService.dataSource.so_priority,
        so_status: this.safetyObservationsService.dataSource.so_status,
        so_rating: this.safetyObservationsService.dataSource.so_rating,
        site_ids: this.safetyObservationsService.dataSource.site_ids,
        contractor_ids: this.safetyObservationsService.dataSource.contractor_ids,
        user_ids: this.safetyObservationsService.dataSource.user_ids,
        reported_by_ids: this.safetyObservationsService.dataSource.reported_by_ids,
        date_range: this.safetyObservationsService.dataSource.date_filter_range
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.safetyObservationsService.dataSource.so_category = typeof results.so_category !== 'undefined' ? results.so_category : this.safetyObservationsService.dataSource.so_category;
          this.safetyObservationsService.dataSource.so_type = typeof results.so_type !== 'undefined' ? results.so_type : this.safetyObservationsService.dataSource.so_type;
          this.safetyObservationsService.dataSource.so_priority = typeof results.so_priority !== 'undefined' ? results.so_priority : this.safetyObservationsService.dataSource.so_priority;
          this.safetyObservationsService.dataSource.so_status = typeof results.so_status !== 'undefined' ? results.so_status : this.safetyObservationsService.dataSource.so_status;
          this.safetyObservationsService.dataSource.so_rating = typeof results.so_rating !== 'undefined' ? results.so_rating : this.safetyObservationsService.dataSource.so_rating;
          this.safetyObservationsService.dataSource.site_ids = results.site_ids ? results.site_ids : this.safetyObservationsService.dataSource.site_ids;
          this.safetyObservationsService.dataSource.contractor_ids = results.contractor_ids ? results.contractor_ids : this.safetyObservationsService.dataSource.contractor_ids;
          this.safetyObservationsService.dataSource.user_ids = results.user_ids ? results.user_ids : this.safetyObservationsService.dataSource.user_ids;
          this.safetyObservationsService.dataSource.reported_by_ids = results.reported_by_ids ? results.reported_by_ids : this.safetyObservationsService.dataSource.reported_by_ids;
          this.safetyObservationsService.dataSource.date_filter_range = results.date_range ? results.date_range : this.safetyObservationsService.dataSource.date_filter_range;
          this.safetyObservationsService.dataSource.getData();
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.safetyObservationsService.dataSource.getData();
      }
    );
  }
}

