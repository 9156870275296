import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiService} from "../../shared/api.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {tap} from "rxjs/operators";
import {MatDialog, MatPaginator, MatSort} from "@angular/material";
import {PpeModel} from "./ppe.model";
import {PpeEditComponent} from "./ppe-edit/ppe-edit.component";
import {PpeViewComponent} from "./ppe-view/ppe-view.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";


@Component({
  selector: 'app-ppe',
  templateUrl: './ppe.component.html',
  styleUrls: ['./ppe.component.scss']
})
export class PpeComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource = new PpeDataSource(this.app, this.api);

  displayedColumns = [
    'select',
    'id',
    'title',
    'creator',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];

  constructor(
    private utils: UtilsService,
    private app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource.getData(false);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Using date created when sorting by the UTC column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new PpeModel());
  }

  onEdit(ppe: PpeModel) {
    this.dialog
      .open(PpeEditComponent, {
        width: '900px',
        data: ppe
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      PpeViewComponent,
      id,
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove PPE',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} ppe items?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(ppe: PpeModel) {
    this.utils.showModal(
      'Remove PPE',
      `Are you sure you want to remove "${ppe.title}"?`,
      () => {
        this.remove([ppe.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/admin/ppe/${ids.join(',')}`)
      .then((response) => {
        this.utils.showToast('PPE items have been removed');
        this.dataSource.selection.clear();
        this.dataSource.getData(false);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class PpeDataSource extends ApiDataSource {
  order_by = 'title';
  order = 'asc';
  searchBy = 'title';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/ppe', resetOffset, {
      searchBy: this.searchBy
    });
  }
}
