import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {PpeSelectorComponent} from "../../shared/ppe-selector/ppe-selector.component";
import {WorkPermitsSelectorComponent} from "../../shared/work-permits-selector/work-permits-selector.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ToolsSelectorComponent} from "../../shared/tools-selector/tools-selector.component";
import {AppService} from "../../app.service";
import {NetworkedEmployeesSelectorComponent} from "../../shared/networked-employees-selector/networked-employees-selector.component";

@Component({
  selector: 'app-task-analyses-revised-filter',
  templateUrl: './task-analyses-revised-filter.component.html',
  styleUrls: ['./task-analyses-revised-filter.component.scss']
})
export class TaskAnalysesRevisedFilterComponent implements OnInit {

  dataSource = new TaskAnalysesRevisedFilterDataSource(); // No API calls needed

  constructor(
    private utils: UtilsService,
    public app: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedFilterComponent>
  ) {
    // set a default approver selection of nothing if no selected values are passed through
    if (typeof this.data['selected_approver_ids'] == 'undefined') {
      this.data['selected_approver_ids'] = [];
    }

    // set a default authorizer selection of nothing if no selected values are passed through
    if (typeof this.data['selected_authorizer_ids'] == 'undefined') {
      this.data['selected_authorizer_ids'] = [];
    }

    // set a default supervisor selection of nothing if no selected values are passed through
    if (typeof this.data['selected_supervisor_ids'] == 'undefined') {
      this.data['selected_supervisor_ids'] = [];
    }

    // set a default ppe selection of nothing if no selected values are passed through
    if (typeof this.data['selected_ppe_ids'] == 'undefined') {
      this.data['selected_ppe_ids'] = [];
    }

    // set a default work permits selection of nothing if no selected values are passed through
    if (typeof this.data['selected_work_permit_ids'] == 'undefined') {
      this.data['selected_work_permit_ids'] = [];
    }

    // set a default tools selection of nothing if no selected values are passed through
    if (typeof this.data['selected_tool_ids'] == 'undefined') {
      this.data['selected_tool_ids'] = [];
    }

    // set a default Approver Status of all if no selected values are passed through
    if (typeof this.data['filter_approver_status'] == 'undefined') {
      this.data['filter_approver_status'] = 'all';
    }

    // set a default Authorizer Status of all if no selected values are passed through
    if (typeof this.data['filter_authorizer_status'] == 'undefined') {
      this.data['filter_authorizer_status'] = 'all';
    }

    // set a default Supervisor Status of all if no selected values are passed through
    if (typeof this.data['filter_supervisor_status'] == 'undefined') {
      this.data['filter_supervisor_status'] = 'all';
    }

    // set a default date range of nothing if no date range values are passed through.
    if (typeof this.data['date_range'] == 'undefined') {
      this.data['date_range'] = [];
    }

    // set a default archived value of false if no values are passed through
    if (typeof this.data['filter_archived'] === 'undefined') {
      this.data['filter_archived'] = "false";
    }
  }

  ngOnInit() {
    this.dataSource.selected_approver_ids = this.data['selected_approver_ids'];
    this.dataSource.selected_authorizer_ids = this.data['selected_authorizer_ids'];
    this.dataSource.selected_supervisor_ids = this.data['selected_supervisor_ids'];
    this.dataSource.selected_ppe_ids = this.data['selected_ppe_ids'];
    this.dataSource.selected_work_permit_ids = this.data['selected_work_permit_ids'];
    this.dataSource.selected_tool_ids = this.data['selected_tool_ids'];
    this.dataSource.filter_approver_status = this.data['filter_approver_status'];
    this.dataSource.filter_authorizer_status = this.data['filter_authorizer_status'];
    this.dataSource.filter_supervisor_status = this.data['filter_supervisor_status'];
    this.dataSource.date_range = this.data['date_range'];
    this.dataSource.filter_archived = this.data['filter_archived'];
  }

  getOutputDateRange($event) {
    this.dataSource.date_range = $event;
  }

  onSelectApprovers() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent, {
      selected: this.dataSource.selected_approver_ids,
      multiple: true,
      selectedAccountId: this.app.account.id
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.dataSource.selected_approver_ids = results;
    });
  }

  onSelectAuthorizers() {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.dataSource.selected_authorizer_ids,
      multiple: true,
      selectedAccountId: this.app.account.id
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.dataSource.selected_authorizer_ids = results;
    });
  }

  onSelectSupervisors() {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.dataSource.selected_supervisor_ids,
      multiple: true,
      selectedAccountId: this.app.account.id
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.dataSource.selected_supervisor_ids = results;
    });
  }

  onSelectPPE() {
    this.utils.showComponentDialog(
      PpeSelectorComponent,
      {
        selected: this.dataSource.selected_ppe_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.selected_ppe_ids = results;
        }
      }
    );
  }

  onSelectWorkPermits() {
    this.utils.showComponentDialog(
      WorkPermitsSelectorComponent,
      {
        selected: this.dataSource.selected_work_permit_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.selected_work_permit_ids = results;
        }
      }
    );
  }

  onSelectTools() {
    this.utils.showComponentDialog(
      ToolsSelectorComponent,
      {
        selected: this.dataSource.selected_tool_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.selected_tool_ids = results;
        }
      }
    );
  }

  onClearAndClose() {
    this.dataSource.selected_approver_ids = [];
    this.dataSource.selected_authorizer_ids = [];
    this.dataSource.selected_supervisor_ids = [];
    this.dataSource.selected_ppe_ids = [];
    this.dataSource.selected_work_permit_ids = [];
    this.dataSource.selected_tool_ids = [];
    this.dataSource.filter_approver_status = 'all';
    this.dataSource.filter_authorizer_status = 'all';
    this.dataSource.filter_supervisor_status = 'all';
    this.dataSource.date_range = [];
    this.dataSource.filter_archived = "false";

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      approver_ids: this.dataSource.selected_approver_ids,
      authorizer_ids: this.dataSource.selected_authorizer_ids,
      supervisor_ids: this.dataSource.selected_supervisor_ids,
      ppe_ids: this.dataSource.selected_ppe_ids,
      work_permit_ids: this.dataSource.selected_work_permit_ids,
      tool_ids: this.dataSource.selected_tool_ids,
      approver_status: this.dataSource.filter_approver_status,
      authorizer_status: this.dataSource.filter_authorizer_status,
      supervisor_status: this.dataSource.filter_supervisor_status,
      date_range: this.dataSource.date_range,
      archived: this.dataSource.filter_archived
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class TaskAnalysesRevisedFilterDataSource {
  selected_approver_ids: number[] = [];
  selected_authorizer_ids: number[] = [];
  selected_supervisor_ids: number[] = [];
  selected_ppe_ids: number[] = [];
  selected_work_permit_ids: number[] = [];
  selected_tool_ids: number[] = [];
  filter_approver_status: string = 'all';
  filter_authorizer_status: string = 'all';
  filter_supervisor_status: string = 'all';
  date_range: Date[] = [];
  filter_archived: string = 'false';

  constructor() {
    this.selected_approver_ids = [];
    this.selected_authorizer_ids = [];
    this.selected_supervisor_ids = [];
    this.selected_ppe_ids = [];
    this.selected_work_permit_ids = [];
    this.selected_tool_ids = [];
    this.filter_approver_status = 'all';
    this.filter_authorizer_status = 'all';
    this.filter_supervisor_status = 'all';
    this.date_range = [];
    this.filter_archived = 'false';
  }
}
