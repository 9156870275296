import { Component, ElementRef, OnInit, Renderer, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { DynamicFormModel } from '../dynamic-form.model';
import { DynamicFormsAssignedUsersComponent } from '../dynamic-forms-assigned-users/dynamic-forms-assigned-users.component';
import { DynamicFormsEditComponent } from '../dynamic-forms-edit/dynamic-forms-edit.component';
import * as moment from 'moment';
import { DynamicFormsFilterComponent } from '../dynamic-forms-filter/dynamic-forms-filter.component';
import { DynamicFormsCloneComponent } from '../dynamic-forms-clone/dynamic-forms-clone.component';
import {DynamicFormsService} from "../../shared/dynamic-forms.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {UserModel} from "../../models/user.model";
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-dynamic-forms-assigned',
  templateUrl: './dynamic-forms-assigned.component.html',
  styleUrls: ['./dynamic-forms-assigned.component.scss']
})
export class DynamicFormsAssignedComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'row-expander',
    'title',
    'form_type',
    'is_active',
    'users_count',
    'const_sites_count',
    'status',
    'verified',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];

  dataSource = new DynamicFormsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @ViewChild('importFormStructureFileInput', {static: false}) importFormStructureFileInput: ElementRef;

  // Enable the expanded rows to show additional form information.
  enableExpandedRows: boolean = false;
  expandedRow: any;

  shouldRefreshPage: boolean = false;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public renderer: Renderer,
    private route: ActivatedRoute,
    public dynamicFormsService: DynamicFormsService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    // Check if a category id is present in the query params.
    const category_id: number = Number(this.route.snapshot.queryParamMap.get('category_id'));
    if ( category_id ) {
      // Apply the category id.
      this.dataSource.category_ids = [category_id];
    }
    // Load the list of forms.
    this.dataSource.getData(true)
    .then(() => {
      // Check if we have a form id present in the URL params.
      const form_id: number = Number(this.route.snapshot.queryParamMap.get('form_id'));
      if ( form_id ) {
        // Open the user responses.
        this.onViewResponses(form_id);
        // Remove the form id from the browser location.
        this.utils.removeBrowserLocationQueryParam('form_id');
        // Mark the page as one that should be refreshed to reset the browser location state.
        this.shouldRefreshPage = true;
      }
    });
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns based on their corresponding date columns.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      if ( sorter['active'] == 'date_modified_UTC' ) {
        this.dataSource.order_by = 'date_modified';
      }

      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Open a dialog that will show a list of all assigned users.
   * @param form_id The form id.
   */
  onViewResponses(form_id: number = 0) {
    // Open the assigned user list in a dialog.
    this.utils.showComponentDialog(DynamicFormsAssignedUsersComponent, {
      form_id: form_id
    }, {
      minWidth: '80%'
    })
    .then(() => {
      // Refresh the list when the dialog closes.
      if ( this.shouldRefreshPage ) {
        // Refresh the page so that the browser state can be updated.
        this.shouldRefreshPage = false;
        this.utils.refreshPage();
      } else {
        this.dataSource.getData();
      }

    });
  }

  /**
   * Open the form for editing in order to make changes.
   * @param form_id The form id.
   */
  onEdit(form_id: number = 0) {
    // Open the form editor in a popup dialog.
    this.utils.showComponentDialog(DynamicFormsEditComponent, {
      form_id: form_id
    }, {
      minWidth: '90%',
      minHeight: '90%',
      disableClose: true
    })
    .then(() => {
      // Refresh the list when the dialog closes.
      this.dataSource.getData();
    });
  }

  /**
   * Archive a single form.
   * @param form The form data.
   * @param evt The html element reference.
   */
  onArchive(form: DynamicFormModel, evt: any) {
    // Show a quick actions dialog with options for the user to confirm archiving the form.
    this.utils.showQuickActions(evt.target, `Are you sure you want to archive the "${form.title}" form?`, [
      {
        text: 'Yes',
        handler: () => {
          // Archive the form.
          this.archiveSelected([form.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Archive the selected forms.
   */
  onArchiveSelected() {
    // Show a confirmation dialog before archiving the selected forms.
    this.utils.showModal(
      'Archive Selected Forms',
      'Are you sure you want to archive the selected forms?',
      () => {
        const archived = this.archiveSelected(this.dataSource.selection.selected);
        if ( archived != null ) {
          archived.then(() => {
            // Clear the selection list.
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Archive the forms.
   * @param formIds Form ids to submit for archiving.
   * @returns
   */
  private archiveSelected(formIds: number[]): null|Promise<any> {
    if ( !formIds.length ) {
      this.utils.showModal('Archiving Selected Forms', 'You need to select at least one form to archive.');
      return;
    }
    return this.api.makeRequest('delete', `v2/dynamic-forms/${formIds.join(',')}`)
    .then(() => {
      // Refresh the list after archiving the forms.
      this.dataSource.getData();
      this.utils.showToast('The selected forms were archived.');
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  /**
   * Restore a single form.
   * @param form The form data.
   * @param evt The html element reference where this click event came from.
   */
  onRestore(form: DynamicFormModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to restore the "${form.title}" form?`, [
      {
        text: 'Yes',
        handler: () => {
          this.restoreSelected([form.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Restore selected forms.
   */
  onRestoreSelected() {
    // Show a confirmation dialog before restoring selected forms.
    this.utils.showModal(
      'Restore Selected Forms',
      'Are you sure you want to restore the selected forms?',
      () => {
        const request = this.restoreSelected(this.dataSource.selection.selected);
        if ( request != null ) {
          request.then(() => {
            // Refresh the list after restoring forms.
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Restores forms by id.
   * @param formIds The form ids to submit for restoration.
   * @returns
   */
  private restoreSelected(formIds: number[]): null|Promise<any> {
    if ( !formIds.length ) {
      this.utils.showModal('Restoring Selected Forms', 'You need to select at least one form to restore.');
      return;
    }
    return this.api.makeRequest('put', `v2/dynamic-forms/restore/${formIds.join(',')}`)
    .then(() => {
      // Refresh the list after restoring forms.
      this.dataSource.getData();
      this.utils.showToast('The selected forms were restored.');
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  /**
   * Export the selected forms captured data into a PDF or CSV for reporting.
   * CSVs are exported in the background and combined into one XLSX file.
   * @returns
   */
   onExportFormsData(export_type: string = 'pdf', export_submitted_forms: boolean = false) {
    // Check if there are any forms selected.
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showModal('Export Form Structures', 'You need to select forms to export.');
      return;
    }

    // If export submitted forms is true then we want to export forms for all assigned users.
    const params = {};
    if ( export_submitted_forms ) {
      params['user_ids'] = 'all';
    }

    // If we are exporting a pdf document and for all assigned users, it should be executed as a background task.
    if ( ['pdf', 'csv'].indexOf(export_type) > -1 && export_submitted_forms ) {
      // Generating PDF exports takes longer than generating CSV exports.
      // It is therefore executed as a background task instead and emailed to the user that made the request.
      // Make a simple get request to start the exporting process.
      this.api.makeRequest('get', `v2/dynamic-forms/export-data/${export_type}/${this.dataSource.selection.selected.join(',')}`, {}, params)
      .then((response) => {
        this.utils.showToast(response.message);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      // Make an API download request to get the form structures.
      // Empty PDF forms and CSV exports are much faster that populated PDF exports.
      this.api.makeDownloadRequest(`v2/dynamic-forms/export-data/${export_type}/${this.dataSource.selection.selected.join(',')}`, {}, params)
      .then((response) => {
        // Ask the browser to save the file download response.
        saveAs(response, 'Exported Forms - ' + moment().format('DD-MM-YYYY') + '.zip');
      })
      .catch((errorResponse) => {
        // Show a standard error message.
        this.utils.showModal('Form Data Export Error', 'We could not export the data for your selected forms. Please try again and contact support if the issue persists.');
      });
    }
  }

  /**
   * Export the selected forms into a JSON file for re-import.
   * @returns
   */
   onExportFormStructures() {
    // Check if there are any forms selected.
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showModal('Export Form Structures', 'You need to select forms to export.');
      return;
    }
    // Make an API download request to get the form structures.
    this.api.makeDownloadRequest(`v2/dynamic-forms/export/${this.dataSource.selection.selected.join(',')}`)
    .then((response) => {
      // Ask the browser to save the file download response.
      saveAs(response, 'Form Structures - ' + moment().format('YYYY-MM-DD') + '.json');
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  /**
   * Trigger the form structure import file selector click event.
   * @param evt
   */
  onTriggerImportFormStructureFileSelector(evt: Event) {
    const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(
      this.importFormStructureFileInput.nativeElement,
      'dispatchEvent',
      [clickEvt]
    );
  }

  /**
   *
   * @param evt The form structure import file selector element reference.
   */
  onImportFormStructures(evt: any) {
    // Check if any files were selected.
    if ( evt.target.files.length == 0 ) {
      this.utils.showModal('Form Structure Import', 'You need to select at least one JSON file to import.');
      return;
    }

    // TBD: Validate that JSON files are selected.

    const files: any = [];
    for ( let i = 0; i < evt.target.files.length; i++ ) {
      files.push(evt.target.files[i]);
    }

    // Show the user a confirmation dialog before importing the file.
    this.utils.showModal('Import Form Structures', 'Are you sure you want to import the form structures from your selected files?', () => {
      // Upload the selected files to import.
      this.api.makeUploadRequest(`v2/dynamic-forms/import`, files)
      .then((response) => {
        this.utils.showToast(response);
        this.dataSource.getData(true);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    });

    // clear the target value
    evt.target.value = '';
  }

  /**
   * Open the form clone component for the user to update the form title and description before cloning.
   * @param form The form data.
   */
   onClone(form: DynamicFormModel) {
    this.utils.showComponentDialog(DynamicFormsCloneComponent, {
      form_id: form.id,
      form_title: form.title,
      form_description: form.description
    }, {
      width: '600px'
    })
    .then((response) => {
      // If the form was cloned successfully, open it for editing.
      if ( typeof response != 'undefined' && response.id ) {
        this.onEdit(response.id);
      }
      // Always refresh the list.
      this.dataSource.getData();
    });
  }

  /**
   * Open the list filters.
   */
   onOpenFilters() {
    this.utils.showComponentDialog(DynamicFormsFilterComponent, {
      only_archived: this.dataSource.only_archived,
      category_ids: this.dataSource.category_ids,
      site_ids: this.dataSource.site_ids,
      contractor_ids: this.dataSource.contractor_ids,
      user_ids: this.dataSource.user_ids,
      industry_ids: this.dataSource.industry_ids,
      trade_ids: this.dataSource.trade_ids,
      date_range: this.dataSource.date_range
    }, {
      width: '350px'
    })
    .then((response) => {
      if ( response ) {
        this.dataSource.only_archived = response.only_archived;
        this.dataSource.category_ids = response.category_ids;
        this.dataSource.site_ids = response.site_ids;
        this.dataSource.contractor_ids = response.contractor_ids;
        this.dataSource.user_ids = response.user_ids;
        this.dataSource.industry_ids = response.industry_ids;
        this.dataSource.trade_ids = response.trade_ids;
        this.dataSource.date_range = response.date_range;
        this.dataSource.getData(true);
      }
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

  /**
   * Validates and checks what the status of the form submission is. It then
   * returns a corresponding color e.g.
   * - red = Assigned or Rejected.
   * - orange/yellow = In Progress or Work in Progress.
   * - green = Submitted or Completed.
   * - blue = Approved.
   * @param user The user data to validate and check.
   * @returns
   */
  getFormSubmissionStatusColors(user: UserModel) {
    return {
      'text-danger': user && user.pivot && user.pivot.dynamic_form_status && ['Assigned', 'Rejected'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-warning': user && user.pivot && user.pivot.dynamic_form_status && ['Pending', 'In Progress', 'Approved: Work in Progress'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-success': user && user.pivot && user.pivot.dynamic_form_status && ['Submitted', 'Work Completed'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-info': user && user.pivot && user.pivot.dynamic_form_status && ['Approved'].indexOf(user.pivot.dynamic_form_status) > -1
    };
  }

}

export class DynamicFormsDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'desc';

  category_ids: number[] = [];

  site_ids: number[] = [];

  contractor_ids: number[] = [];

  user_ids: number[] = [];

  industry_ids: number[] = [];

  trade_ids: number[] = [];

  // The date range for the forms.
  date_range: Date[] = [];

  getData(resetOffset: boolean = false) {
    return this.makeRequest(`v2/dynamic-forms`, resetOffset, {
      only_templates: false,
      category_ids: this.category_ids.join(','),
      site_ids: this.site_ids.join(','),
      contractor_ids: this.contractor_ids.join(','),
      user_ids: this.user_ids.join(','),
      industry_ids: this.industry_ids.join(','),
      trade_ids: this.trade_ids.join(','),
      date_range: this.date_range.map((date: Date) => {
        return date.getTime() / 1000;
      }).join(',')
    });
  }
}
