import {Component, Inject, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-messages-filter',
  templateUrl: './messages-filter.component.html',
  styleUrls: ['./messages-filter.component.scss']
})
export class MessagesFilterComponent implements OnInit {

  filters: MessagesFilters = {
    date_filter: 'all',
    date_range: [],
    priority: '',
    status: '',
    type: ''
  } as MessagesFilters;

  constructor(
    public location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MessagesFilterComponent>
  ) { }

  ngOnInit(): void {
    this.filters.type = this.data['type'];
    this.filters.status = this.data['status'];
    this.filters.priority = this.data['priority'];
    this.filters.date_filter = this.data['date_filter'];
    this.filters.date_range = this.data['date_range'];
  }

  /**
   * Sets the output date range in the filters object.
   *
   * @param {any} event - The event object containing the selected date range.
   * @return {void}
   */
  getOutputDateRange(event: any): void {
    this.filters.date_range = event;
  }

  /**
   * Clears the filter data and closes the window.
   *
   * @return {void}
   */
  onClearAndClose(): void {
    // Clear the data.
    this.filters.type = '';
    this.filters.status = '';
    this.filters.priority = '';
    this.filters.date_filter = 'all';
    this.filters.date_range = [];
    // Save and close.
    this.onSaveAndClose();
  }

  /**
   * Saves the updated filters and closes the dialog.
   *
   * @return {void}
   */
  onSaveAndClose(): void {
    this.dialogRef.close(this.filters);
  }
}

export interface MessagesFilters {
  type: string;
  status: string;
  priority: string;
  date_filter: string;
  date_range: Date[];
}
