import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {SitesTaskAnalysesRevisedVersioningComponent} from "../sites-task-analyses-revised-versioning/sites-task-analyses-revised-versioning.component";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TaskAnalysesRevisedWorkflowHazardModel} from "../../models/task-analyses-revised-workflow-hazard.model";
import {SitesTaskAnalysesRevisedWorkflowsHazardsEditComponent} from "../sites-task-analyses-revised-workflows-hazards-edit/sites-task-analyses-revised-workflows-hazards-edit.component";
import {SitesTaskAnalysesRevisedWorkflowsHazardsViewComponent} from "../sites-task-analyses-revised-workflows-hazards-view/sites-task-analyses-revised-workflows-hazards-view.component";
import {SiteHazardsSelectorComponent} from "../../shared/site-hazards-selector/site-hazards-selector.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-hazards',
  templateUrl: './sites-task-analyses-revised-workflows-hazards.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-hazards.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsHazardsComponent implements OnInit {

  @Input() private taRevisedId: number;
  @Input() private taRevisedWorkflowId: number;
  @Input() public taRevisedPath: string;

  path: string;

  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'status',
    'ira',
    'rra',
    'created_by_user',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  dataSource = new SitesTaskAnalysesRevisedWorkflowsHazardsDataSource(this.app, this.api);
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedWorkflowsHazardsComponent>
  ) { }

  ngOnInit() {
    // get the ta revised workflow id
    this.dataSource.ta_revised_workflow_id = this.dialogData.ta_revised_workflow.id;

    // get the ta revised id
    this.dataSource.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id
    this.dataSource.site_id = this.dialogData.site_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.dataSource.ta_revised_id) {
      this.dataSource.getData();
      this.findTa(this.dataSource.ta_revised_id);
    } else {
      this.redirectBack();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns based on their corresponding date fields.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  private redirectBack() {
    if(this.dataSource.site_id && this.path) {
      this.router.navigate([`/sites/${this.dataSource.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  private findTa(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.dataSource.site_id
    })
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onView(ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel) {
    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedWorkflowsHazardsViewComponent,
      {
        ta_revised_workflow_hazard: ta_revised_workflow_hazard,
        site_id: this.dataSource.site_id,
        ta_revised_id: this.dataSource.ta_revised_id,
        ta_revised_workflow_id: this.dataSource.ta_revised_workflow_id,
        path: this.path
      },
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onAdd() {
    this.onEdit(new TaskAnalysesRevisedWorkflowHazardModel());
  }

  onEdit(ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel) {
    this.dialog
      .open(SitesTaskAnalysesRevisedWorkflowsHazardsEditComponent, {
        width: '1024px',
        data: {
          ta_revised_workflow_hazard: ta_revised_workflow_hazard,
          site_id: this.dataSource.site_id,
          ta_revised_id: this.dataSource.ta_revised_id,
          ta_revised_workflow_id: this.dataSource.ta_revised_workflow_id,
          path: this.path
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if(result == "version") {
          this.dialogRef.close("version");
        } else {
          // Refresh the list regardless of how the dialog is closed.
          this.dataSource.getData(true);
        }
      });
  }

  onSelectHazards() {
    this.utils.showComponentDialog(
      SiteHazardsSelectorComponent,
      {
        selected: [],
        multiple: true,
        selectedAccountId: this.app.account.id,
        site_id: this.dataSource.site_id
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.onCopyHazards(results.length ? results.join(',') : '');
      }
    );
  }

  onCopyHazards(selected_hazard_ids) {
    this.acknowledgementCheck("copy", [], selected_hazard_ids);
  }

  continueOnCopyHazards(selected_hazard_ids) {
    this.api.makeRequest('put', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${this.dataSource.ta_revised_workflow_id}/hazards-copy`, [], {
      selected_hazard_ids: selected_hazard_ids,
      site_id: this.dataSource.site_id
    })
      .then((response) => {
        this.dataSource.getData(true);
        this.utils.showToast(this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' Hazards were copied.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onRemove(id: number) {
    this.utils.showModal('Delete TA/JSA/SWMS Workflow Step Hazard', 'Are you sure you want to delete the TA/JSA/SWMS Workflow Step Hazard?', () => {
      this.acknowledgementCheck("delete", [id]);
    });
  }

  onRemoveSelected() {
      this.utils.showModal('Delete TA/JSA/SWMS Workflow Step Hazard', 'Are you sure you want to delete the selected TA/JSA/SWMS Workflow Step Hazards?', () => {
        this.acknowledgementCheck("delete", this.dataSource.selection.selected);
      });
  }

  continueOnRemoveSelected(ids: number[] = []) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${this.dataSource.ta_revised_workflow_id}/hazards/${ids.join(',')}`, [], {
      site_id: this.dataSource.site_id
    })
      .then(() => {
        this.utils.showToast('The TA/JSA/SWMS Workflow Step Hazard(s) were deleted.');
        this.dataSource.selection.clear();
      })
      .finally(() => {
        this.dataSource.getData();
      });
  }

  private acknowledgementCheck(crud_action: string, ids: number[] = [], selected_hazard_ids: string = '') {
    this.api
      .makeRequest('get', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/acknowledgement`, this.ta_revised, {
        site_id:  this.dataSource.site_id
      })
      .then((response) => {
        if(response.data.acknowledgementsDone) {
          this.beforeVersioning(crud_action, ids, selected_hazard_ids);
        } else {
          switch(crud_action) {
            case "delete":
              this.continueOnRemoveSelected(ids);
              break;
            case "copy":
              this.continueOnCopyHazards(selected_hazard_ids);
              break;
            default:
            // do nothing
          }
        }
      });
  }

  private beforeVersioning(crud_action: string, ids: number[] = [], selected_hazard_ids: string = '') {
    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedVersioningComponent,
      {
        task_name: this.ta_revised.task_name
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          // If confirmed then begin versioning of Task Analysis
          if(results.confirmed) {
            this.createNewVersion(results.amendment_title, results.reason_for_amendment, crud_action, ids, selected_hazard_ids);
          }
        }
      }
    );
  }

  private createNewVersion(amendment_title: string, reason_for_amendment: string, crud_action:string, ids: number[] = [], selected_hazard_ids: string = '') {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${this.dataSource.ta_revised_workflow_id}/hazards-version`, [], {
        site_id: this.dataSource.site_id,
        hazard_id: 0,
        amendment_title: amendment_title,
        reason_for_amendment: reason_for_amendment,
        crud_action: crud_action,
        remove_ids: ids ? ids.join(',') : '',
        selected_hazard_ids: selected_hazard_ids
      })
      .then((response) => {
        this.utils.showToast(`Created a new version of the "${this.ta_revised.task_name}" TA/JSA/SWMS.`);
        this.dialogRef.close('version');
        this.redirectBack();
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class SitesTaskAnalysesRevisedWorkflowsHazardsDataSource extends ApiDataSource {
  order_by = 'id';
  order = 'desc';
  searchBy = 'name';

  ta_revised_id = 0;
  ta_revised_workflow_id = 0;
  site_id = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards`, resetOffset, {
      searchBy: this.searchBy,
      site_id: this.site_id
    });
  }
}
