import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatPaginator,
  MatSort
} from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';

@Component({
  selector: 'app-repo-search',
  templateUrl: './repo-search.component.html',
  styleUrls: ['./repo-search.component.scss']
})
export class RepoSearchComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = []; // 'name', 'ira', 'rra', 'controls', 'actions'
  dataSource: RepoSearchSelectorDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<RepoSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // set multiple if it is not present to false
    if (typeof this.data['multiple'] === 'undefined') {
      this.data['multiple'] = false;
    }

    // construct the datasource
    this.dataSource = new RepoSearchSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    this.dataSource.repo = this.data['repo'];

    if (this.data['display_fields']) {
      this.displayedColumns.push(...this.data['display_fields']); // .split(',')
    }

    this.displayedColumns.push('actions');

    // set a default selection of nothing if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // loop and select the values
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(this.data['selected'][i]);
    }
  }

  ngOnInit() {
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  // clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  /**
   * before the dialog close we want to store the new active site id
   */
  onClose() {
    this.dialogRef.close();
  }

  onSelect(selected) {
    this.dialogRef.close(selected);
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class RepoSearchSelectorDataSource extends CustomDataSource {
  //// record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  repo = '';

  getData(resetOffset: boolean = false) {
    if (this.repo !== '') {
      this.getDataFromLaravelAPI('repo_' + this.repo, resetOffset);
    }
  }
}
