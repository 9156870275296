import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/utils.service';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ManageTimeOnsiteRecordComponent } from 'src/app/dialog-components/manage-time-onsite-record/manage-time-onsite-record.component';
import { TimeOnsiteRecordsFilterComponent } from "../time-onsite-records-filter/time-onsite-records-filter.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-time-onsite-records',
  templateUrl: './time-onsite-records.component.html',
  styleUrls: ['./time-onsite-records.component.css']
})
export class TimeOnsiteRecordsComponent implements OnInit, AfterViewInit {

  // columns to show and the data source
  displayedColumns: string[] = [
    'id',
    'contact_person',
    'contact_person_account',
    'site_name',
    'reason',
    'acknowledged',
    'time_spent_onsite', // actual during converted from seconds to days/hours/minutes etc.
    'method'
  ];
  dataSource = new TimeOnsiteRecordsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  contractors: any[] = [];

  constructor(
    private oldApi: ApiService,
    private api: ApiRequestService,
    public app: AppService,
    public utils: UtilsService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    // Update the list columns.
    this.updateListColumns();
    // Get the time onsite records.
    this.dataSource.getData(true);
  }

  /**
   * Dynamically update the list columns.
   */
  updateListColumns() {
    // Check if the covid-19 feature is enabled.
    if ( this.app.account.pandemic_enabled ) {
      this.displayedColumns.push(...[
        'has_covid_symptoms'
      ]);
    }
    // Add the remaining default fields.
    this.displayedColumns.push(...[
      'notes',
      'date_created',
      // 'date_created_UTC',
      'export'
    ]);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order = sorter['direction'];

      // sorting for utc time by parsing original time
      if (sorter['active'] === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = sorter['active'];
      }

      this.dataSource.getData();
    })).subscribe();
  }

  onExport() {
    this.dataSource.exportData()
      .then((response) => {
        saveAs(response, 'Time Onsite Records.csv');
      });
  }

  composeNewMessage(userId: number) {
    if (!userId) {
      this.utils.showToast('We are unable to open the message composer. Please refresh the page and try again.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: [userId]
    })
      .then(() => {
        this.utils.showToast('You can view the message stats in the messages section.');
      });
  }

  onUpdateTimeOnsiteRecord(id: number) {
    this.utils.showComponentDialog(ManageTimeOnsiteRecordComponent, {
      id: id
    })
      .then(() => {
        // Reload the list of time onsite records
        this.dataSource.getData();
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      TimeOnsiteRecordsFilterComponent,
      {
        selected_site_ids: this.dataSource.site_ids,
        selected_contractor_ids: this.dataSource.contractor_ids,
        selected_user_ids: this.dataSource.user_ids,
        selected_employee_ids: this.dataSource.selected_employee_ids,
        time_spent_onsite_date_range: this.dataSource.time_spent_onsite_date
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.contractor_ids = results.contractor_ids ? results.contractor_ids : this.dataSource.contractor_ids;
          this.dataSource.user_ids = results.user_ids ? results.user_ids : this.dataSource.user_ids;
          this.dataSource.selected_employee_ids = results.selected_employee_ids ? results.selected_employee_ids : this.dataSource.selected_employee_ids;
          this.dataSource.time_spent_onsite_date = results.time_spent_onsite_date ? results.time_spent_onsite_date : this.dataSource.time_spent_onsite_date;
          this.dataSource.getData();
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class TimeOnsiteRecordsDataSource extends ApiDataSource {

  order_by = 'date_created';
  order = 'desc';

  site_ids: number[] = [];
  contractor_ids: number[] = [];
  user_ids: number[] = [];
  time_spent_onsite_date: Date[] = [];
  selected_employee_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/time-onsite-records', resetOffset, {
      site_ids: this.site_ids.length ? this.site_ids.join(',') : "",
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : "",
      user_ids: this.user_ids.length ? this.user_ids.join(',') : "",
      selected_employee_ids: this.selected_employee_ids.length ? this.selected_employee_ids.join(',') : "",
      date_range: this.time_spent_onsite_date && this.time_spent_onsite_date.length > 1 ? [
        this.time_spent_onsite_date[0].getTime() / 1000,
        this.time_spent_onsite_date[1].getTime() / 1000
      ].join(',') : ""
    });
  }

  exportData() {
    return this.makeDownloadRequest('v2/time-onsite-records/export', {
      site_ids: this.site_ids.length ? this.site_ids.join(',') : "",
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : "",
      user_ids: this.user_ids.length ? this.user_ids.join(',') : "",
      selected_employee_ids: this.selected_employee_ids.length ? this.selected_employee_ids.join(',') : "",
      date_range: this.time_spent_onsite_date && this.time_spent_onsite_date.length > 1 ? [
        this.time_spent_onsite_date[0].getTime() / 1000,
        this.time_spent_onsite_date[1].getTime() / 1000
      ].join(',') : ""
    })
  }
}
