import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemModel } from 'src/app/items/item.model';
import { ItemsService } from 'src/app/items/items.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ItemPivotModel } from 'src/app/items/item-pivot.model';
import { ContractorsItemsEditComponent } from './contractors-items-edit/contractors-items-edit.component';
import { AppService } from 'src/app/app.service';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { ContractorsItemsViewComponent } from './contractors-items-view/contractors-items-view.component';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ApiRequestService } from 'src/app/shared/api-request.service';

@Component({
  selector: 'app-contractors-items',
  templateUrl: './contractors-items.component.html',
  styleUrls: ['./contractors-items.component.scss']
})
export class ContractorsItemsComponent implements OnInit, AfterViewInit {
  @Output() change = new EventEmitter();
  @Input() prequalId: number;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  dataSource = new ItemsDataSource(this.app, this.api);
  contractorId: number;
  displayedColumns = [
    'icon',
    'status',
    'name',
    'requireDocuments',
    'expiry',
    // 'expiry_UTC',
    'rating',
    'notes',
    'buttons'
  ];

  constructor(
    private utils: UtilsService,
    private itemsService: ItemsService,
    private route: ActivatedRoute,
    public app: AppService,
    private api: ApiRequestService
  ) {}

  ngOnInit(): void {
    this.contractorId = Number(this.route.snapshot.parent.params.contractor_id);
    this.dataSource.prequalId = this.prequalId;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Sort UTC columns based on corresponding date fields.
      if ( this.sort.active == 'expiry_UTC' ) {
        this.dataSource.order_by = 'expiry';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onEdit(pivotId: number) {
    this.utils.showComponentDialog(
      ContractorsItemsEditComponent,
      pivotId,
      { width: '700px', disableClose: true },
      (success: boolean) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      }
    );
  }

  onView(pivotId: number) {
    this.utils.showComponentDialog(
      ContractorsItemsViewComponent,
      pivotId,
      {width: '700px'},
      (success: boolean) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      }
    );
  }

  async ratePivot(id: number, rating: number) {
    await this.itemsService.updatePivot(id, {
      rating: rating
    } as ItemPivotModel);
    this.change.emit();
    this.dataSource.getData();
  }

  public delete(item: ItemModel) {
    this.utils.showModal(
      'Remove Item',
      `Are you sure you want to remove "${item.name}"?`,
      () => {
        this.itemsService.delete(item.id).subscribe((response: ItemModel) => {
          this.utils.showToast(`Item "${response.name}" was removed`);
          this.change.emit();
          this.dataSource.getData();
        });
      }
    );
  }

  onApprove(item: ItemModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to approve '${item.name}'?`, [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('put', `v1/items/approve/${item.pivot.id}`)
          .then(() => {
            this.utils.showToast(`Pre-Qualification item "${item.name}" was approved.`);
            this.change.emit();
            this.dataSource.getData();
          });
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  onReject(item: ItemModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to reject '${item.name}'?`, [
      {
        text: 'Yes',
        handler: () => {
          this.api.makeRequest('put', `v1/items/reject/${item.pivot.id}`, {
            contractor_id: this.contractorId
          })
          .then(() => {
            this.utils.showToast(`Pre-Qualification item "${item.name}" was rejected.`);
            this.change.emit();
            this.dataSource.getData();
          });
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  public getIcon(item: ItemModel) {
    const mappings = {
      Pending: 'more_horiz',
      Required: 'cancel',
      Approved: 'check_circle'
    };
    return mappings[item.pivot.status];
  }
}

class ItemsDataSource extends ApiDataSource {

  order_by = 'sort_order';
  order = 'asc';

  prequalId: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v1/prequalifications/items/${this.prequalId}`, resetOffset);
  }
}
