import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AppService } from '../app.service';
import { UtilsService } from '../shared/utils.service';
import { PrequalificationService } from '../prequalifications/prequalification.service';
import { ApiService } from '../shared/api.service';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from '../utils/custom-data-source';
import { MyPrequalificationsViewComponent } from './my-prequalifications-view/my-prequalifications-view.component';
import { MyPrequalificationsEditComponent } from './my-prequalifications-edit/my-prequalifications-edit.component';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../shared/router.service';

@Component({
  selector: 'app-my-prequalifications',
  templateUrl: './my-prequalifications.component.html',
  styleUrls: ['./my-prequalifications.component.scss']
})
export class MyPrequalificationsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  dataSource = new MyPrequalificationsDataSource(this.app, this.api);
  displayed_columns = [
    'id',
    'name',
    'client',
    'score',
    'items',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'buttons'
  ];
  query: string;

  constructor(
    private app: AppService,
    private utils: UtilsService,
    public prequalService: PrequalificationService,
    private api: ApiService,
    private dialog: MatDialog,
    private routerService: RouterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.dataSource.getData(false);
    this.handleParams(this.route.snapshot.queryParams);
  }

  handleParams(queryParams) {
    if (queryParams['view']) {
      this.view(queryParams['view']);
    } else if (queryParams['edit']) {
      this.edit(queryParams['edit']);
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      if ( this.sort.active == 'date_modified_UTC' ) {
        this.dataSource.sort_by = 'date_modified';
      }

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  public view(id: number) {
    this.routerService.setQueryParams({view: id});
    this.dialog
      .open(MyPrequalificationsViewComponent, {
        width: '900px',
        data: id
      })
      .afterClosed()
      .subscribe((success) => {
        this.routerService.setQueryParams({});
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  public edit(id: number) {
    this.routerService.setQueryParams({edit: id});
    this.dialog
      .open(MyPrequalificationsEditComponent, {
        width: '900px',
        data: id
      })
      .afterClosed()
      .subscribe((success) => {
        this.routerService.setQueryParams({});
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }
}

export class MyPrequalificationsDataSource extends CustomDataSource {
  sort_by = 'name';
  sort_order = 'asc';
  pageSizeOptions = [5, 10, 15];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('my/prequalifications', resetOffset);
  }
}
