<div mat-dialog-title class="position-relative w-100">
  <h5>Filter TA/JSA/SWMS Templates</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectPPE()"
        [matBadge]="'' + (dataSource.selected_ppe_ids.length || 'All')"
        class="mx-3"
        color="" >
        <mat-icon>headset</mat-icon> PPE
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectWorkPermits()"
        [matBadge]="'' + (dataSource.selected_work_permit_ids.length || 'All')"
        class="mx-3"
        color="" >
        <mat-icon>assignment_ind</mat-icon> Work Permits
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Active/Archived</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field style="width: 100px;" class="mx-3">
        <mat-select [(ngModel)]="dataSource.filter_archived"
                    placeholder="Active/Archived"
                    name="status"
                    matTooltip="Toggle between Archived and Active records">
          <mat-option value="false" aria-selected="true">Active</mat-option>
          <mat-option value="true">Archived</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()" >
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()" >
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </td>
  </ng-container>
</div>




