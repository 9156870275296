import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {IndustriesModel} from "../industries.model";
import {MAT_DIALOG_DATA} from "@angular/material";
import {UserPublicProfileComponent} from "../../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-industries-view',
  templateUrl: './industries-view.component.html',
  styleUrls: ['./industries-view.component.scss']
})
export class IndustriesViewComponent implements OnInit {

  industry: IndustriesModel = new IndustriesModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const industry_id = this.id;
    if(industry_id) {
      this.getIndustry(industry_id);
    }
  }

  private getIndustry (industry_id: number) {
    this.api.makeRequest('get', `v2/admin/industries/${industry_id}`)
      .then((response) => {
        this.industry = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
