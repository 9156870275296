import { HttpClientTestingModule } from '@angular/common/http/testing';
import { FormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDialogRef,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatPaginatorModule,
    MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatCardModule,
  MatSlideToggleModule,
  MatDividerModule,
  MatChipsModule
} from '@angular/material';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { EditorModule } from '@tinymce/tinymce-angular';
import { environment } from 'src/environments/environment';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';

/**
 *
 * Before building, make sure the correct environment is set in the getEnvMode()
 * function below.
 *
 * Build the app for the dev and staging environment using the following command:
 *
 * $ ng build
 *
 * Build the app for the production environment using:
 * AOT is automatically executed with --prod.
 *
 * $ ng build --prod
 *
 */

export function getEnvModeAsText() {
  return environment.MODE;
}

export function getAppVersion() {
  return environment.APP_VERSION;
}

/**
 * Check if the environment is production.
 */
export function isProduction() {
  return environment.production;
}

export let SERVICE_URL = environment.SERVICE_URL;
export let LARAVEL_API_URL = environment.LARAVEL_API_URL;
export let API_URL = environment.API_URL;

/**
 * Get the URL of the knowledgebase.
 * @returns The name of the app.
 */
export function getKnowledgebaseUrl() {
  return environment.PROJECT_KNOWLEDGEBASE_URL;
}

/**
 * Get the URL of the release notes for the software.
 * @returns The name of the app.
 */
export function getReleaseNotesUrl() {
  return environment.PROJECT_RELEASE_NOTES_URL;
}

/**
 * Get the name of the app.
 * @returns The name of the app.
 */
export function getAppName() {
  return environment.APP_NAME;
}

/**
 * Get the URL to the mobile app in the app store for Android.
 * @returns The Android mobile app store URL.
 */
export function getAndroidMobileAppStoreUrl() {
  return environment.ANDROID_STORE_URL_MOBILE;
}

/**
 * Get the URL to the kiosk app in the app store for Android.
 * @returns The Android kiosk app store URL.
 */
export function getAndroidKioskAppStoreUrl() {
  return environment.ANDROID_STORE_URL_KIOSK;
}

/**
 * Get the URL to the mobile app in the app store for iOS.
 * @returns The iOS mobile app store URL.
 */
export function getIosMobileAppStoreUrl() {
  return environment.IOS_STORE_URL_MOBILE;
}

/**
 * Get the URL to the privacy policy from the environment file.
 */
export function getPrivacyPolicyUrl() {
  return environment.PRIVACY_POLICY_URL;
}

/**
 * Get the URL to the terms and conditions from the environment file.
 */
export function getTermsAndConditionsUrl() {
  return environment.TERMS_CONDITIONS_URL;
}

/**
 * Get the URL to the kiosk app in the app store for iOS.
 * @returns The iOS kiosk app store URL.
 */
export function getIosKioskAppStoreUrl() {
  return environment.IOS_STORE_URL_KIOSK;
}

/**
 * Get the sales email from the environment files.
 * @returns The sales email address.
 */
export function getSalesEmailAddress() {
  return environment.SALES_EMAIL;
}


/**
 * Get the urgent notice link from the environment files.
 * @returns The sales email address.
 */
export function getUrgentNoticeUrl() {
  return environment.URGENT_NOTICE_URL;
}

/**
 * Get the support email from the environment files.
 * @returns The support email address.
 */
export function getSupportEmailAddress() {
  return environment.SUPPORT_EMAIL;
}

/**
 * Retrieves the Upvoty link.
 *
 * @returns {string} The Upvoty link.
 */
export function getUpvotyLink(): string {
  return environment.UPVOTY_LINK;
}

export const TEST_IMPORTS = [
  MatIconModule,
  FormsModule,
  MatButtonModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  HttpClientTestingModule,
  MatListModule,
  MatFormFieldModule,
  RouterTestingModule,
  MatCheckboxModule,
  MatMenuModule,
    MatOptionModule,
  MatSelectModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  NoopAnimationsModule,
  MatSortModule,
  PipesModule,
  MatTabsModule,
  EditorModule,
  MatExpansionModule,
  MatSidenavModule,
  DirectivesModule,
  MatSlideToggleModule,
  MatTooltipModule
];

export const COMPONENT_MODULES = [
  MatIconModule,
  FormsModule,
  MatButtonModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  RouterTestingModule,
  MatSortModule
];

export const TEST_PROVIDERS = [
  { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: {} }
];

export const MATERIAL_MODULES = [
  MatIconModule,
  MatSelectModule,
  MatButtonModule,
  MatInputModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatListModule,
  MatCheckboxModule,
  MatDialogModule,
  MatBadgeModule,
  MatMenuModule,
  MatRadioModule,
  MatCardModule,
  MatTabsModule,
  MatDividerModule,
  MatChipsModule
];
