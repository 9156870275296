import {Component, OnInit, ViewChild, AfterViewInit, Input} from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { TourService } from 'ngx-tour-md-menu';
import { tap } from 'rxjs/operators';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import {SitesFilter, SitesFilterComponent} from "../sites-filter/sites-filter.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-sites-children',
  templateUrl: './sites-children.component.html',
  styleUrls: ['./sites-children.component.scss']
})
export class SitesChildrenComponent implements OnInit, AfterViewInit {

  // The list of columns that should be displayed.
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'location',
    'site_type',
    'site_status',
    'site_risk_assessment',
    'geofence',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  // The data source to use for the list of child sites.
  dataSource = new ChildSitesDataSource(this.app, this.api);

  // The paginator and sorter.
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  // Get the parent site id as a numeric input.
  parent_site_id: number;

  constructor(
    public app: AppService,
    private api: ApiService,
    public tour: TourService,
    public utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // Extract the parent site id from the route params.
    this.parent_site_id = Number(this.route.parent.snapshot.params['parent_id']);
    // Redirect the user back to the list of sites if the parent site id is missing.
    if ( !this.parent_site_id ) {
      this.router.navigate(['/sites']);
      return;
    }
    // Store the parent site id.
    this.dataSource.parent_site_id = this.parent_site_id;
    // Get the list data from the API.
    this.dataSource.getData();

    // this.tour.initialize(
    //   [
    //     {
    //       anchorId: 'tour.add.site',
    //       content:
    //         'Click here to add a new site. You can set a geofence for the site, add the onsite manager\'s contact information and a site-specific induction.',
    //       title: 'Adding a new site'
    //     },
    //     {
    //       anchorId: 'tour.search',
    //       content:
    //         'Enter your search term here and click the filter button to search for specific records. Try to use whole words and if you want to exclude results with specific words, you can put a minus (-) sign in front of the word. E.g. \'Auckland -Takapuna\'. It will find all records for Auckland but exclude records containing Takapuna.',
    //       title: 'Searching for records'
    //     },
    //     {
    //       anchorId: 'tour.sort.headers',
    //       content:
    //         'Click on any of these headers to sort the records in ascending or descending order.',
    //       title: 'Sorting records'
    //     },
    //     {
    //       anchorId: 'tour.paginator',
    //       content:
    //         'You can page through large sets of data by using the pagination controls.',
    //       title: 'Paging through records'
    //     },
    //     {
    //       anchorId: 'tour.actions',
    //       content:
    //         'You can manage records by clicking on the relevant actions for each record. You can view, edit or remove sites. You can also manage QR Codes for each site.',
    //       title: 'List actions'
    //     }
    //   ],
    //   {
    //     enableBackdrop: true
    //   }
    // );
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // Sorting for UTC time by parsing original time.
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    // Subscribe to the paginator and list sorting tap events.
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Filter sites by status.
   * @param status
   */
  onSiteStatusFilter(status: string) {
    this.dataSource.status = status;
    this.dataSource.getData(true);
  }

  /**
   * Filter sites by type.
   * @param type
   */
  onSiteTypeFilter(type: string) {
    this.dataSource.type = type;
    this.dataSource.getData(true);
  }

  /**
   * Remove a single site.
   * @param id
   */
  onRemove(id: number) {
    // make a request to delete this site
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm('child-sites.singular', 'Subsite'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm('child-sites.singular', 'Subsite').toLowerCase() + '?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' + id,
          {},
          {},
          () => {
            this.remove();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  /**
   * Remove selected sites.
   */
  onRemoveSelected() {
    // delete the sellection
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm('child-sites.plural', 'Subsites'),
      'Are you sure you want to remove the selected ' + this.utils.getLangTerm('child-sites.plural', 'Subsites').toLowerCase() + '?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' + this.dataSource.selection.selected.join(','),
          {},
          {},
          () => {
            this.remove();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  /**
   * Show a success message after site removal.
   */
  remove() {
    this.utils.showToast('The selected ' + this.utils.getLangTerm('child-sites.plural', 'Subsites').toLowerCase() + ' was successfully deleted.');
    this.dataSource.selection.clear();
    this.dataSource.getData();
  }

  /**
   * Clone a site.
   * @param id
   */
  onCloneSite(id: number) {
    this.utils.showModal(
      'Cloning ' + this.utils.getLangTerm('child-sites.singular', 'Subsite'),
      'You are about to clone a ' + this.utils.getLangTerm('child-sites.singular', 'Subsite').toLowerCase() + '. Cloning a ' + this.utils.getLangTerm('child-sites.singular', 'Subsite').toLowerCase() + ' will also clone linked ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + ', managers, documents, hazards & risks, hazardous substances, emergency response plans and TA/JSA/SWMS records. <br><br>Are you sure you want to continue?',
      () => {
        this.api.laravelApiRequest(
          'post',
          `sites/${id}/clone`,
          {},
          {},
          (response) => {
            this.utils.showToast(response.message);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  /**
   * Compose a new message with a site pre-seelcted.
   * @param siteId
   */
  composeNewMessage(siteId: number = null) {
    if (this.dataSource.selection.selected.length == 0) {
      this.utils.showToast('You need to select some ' + this.utils.getLangTerm('child-sites.plural', 'Subsites').toLowerCase() + ' first.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectSiteIds: this.dataSource.selection.selected
    })
      .then(() => {
        this.utils.showToast('You can view the message stats in the messages section.');
      });
  }

  getOutputTagsFilter($event) {
    this.dataSource.tag_ids = $event;
    this.dataSource.getData(true);
  }

  /**
   * Filter the list of sites.
   */
  onFilter() {
    this.utils.showComponentDialog(
      SitesFilterComponent,
      {
        site_status: this.dataSource.status,
        site_type: this.dataSource.type,
        date_range: this.dataSource.date_filter_range
      } as SitesFilter,
      {
        width: '768px'
      },
      (results: SitesFilter): void => {
        if (typeof results != 'undefined') {
          this.dataSource.status = typeof results.site_status != 'undefined' ? results.site_status : this.dataSource.status;
          this.dataSource.type = typeof results.site_type != 'undefined' ? results.site_type : this.dataSource.type;
          this.dataSource.date_filter_range = results.date_range ? results.date_range : this.dataSource.date_filter_range;
          this.dataSource.getData();
        }
      }
    );
  }
}

/**
 * The data source to get a list of sites filtered by a parent site.
 */
export class ChildSitesDataSource extends CustomDataSource {

  // List filtering options.
  sort_by = 'name';
  sort_order = 'asc';

  status = '';
  type = '';
  tag_ids: number[] = [];
  date_filter_range: Date[] = [];

  // The parent site id to filter by.
  parent_site_id: number;

  // Get the data from the API.
  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('sites', resetOffset, {}, {
      status: this.status ? this.status : '',
      type: this.type ? this.type : '',
      tag_ids: this.tag_ids.length ? this.tag_ids.join(',') : '',
      date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
        this.date_filter_range[0].getTime() / 1000,
        this.date_filter_range[1].getTime() / 1000
      ].join(',') : '',
      parent_site_id: this.parent_site_id
    });
  }

  /**
   * Export data from the API.
   * @param type
   * @param id
   */
  onExport(type: string = 'pdf', id?: number) {
    // Check if a single site or list of sites should be downloaded.
    let ids: number[] = [];
    if ( id ) {
      ids.push(id);
    } else {
      ids.push(...this.selection.selected);
    }

    // Make a download request for the list of sites.
    this.api.makeDownloadRequest(`sites/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      status: this.status ? this.status : '',
      type: this.type ? this.type : '',
      date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
        this.date_filter_range[0].getTime() / 1000,
        this.date_filter_range[1].getTime() / 1000
      ].join(',') : '',
      parent_site_id: this.parent_site_id
    }).then((response) => {
      saveAs(response, `${this.app.account.name} - Records.${type}`);
    });
  }
}
