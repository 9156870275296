import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesListComponent } from './files-list/files-list.component';
import {
  MatIconModule,
  MatListModule,
  MatButtonModule,
  MatTooltipModule,
  MatBadgeModule,
  MatDialogModule,
  MatCardModule
} from '@angular/material';
import { PipesModule } from '../pipes/pipes.module';
import { FilesAddComponent } from './files-add/files-add.component';
import { FilesRemoveComponent } from './files-remove/files-remove.component';
import { FilesSelectorComponent } from './files-selector/files-selector.component';

@NgModule({
  declarations: [
    FilesListComponent,
    FilesAddComponent,
    FilesRemoveComponent,
    FilesSelectorComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDialogModule,
    PipesModule
  ],
  exports: [FilesListComponent, FilesAddComponent, FilesSelectorComponent],
  entryComponents: [FilesRemoveComponent]
})
export class FilesModule {}
