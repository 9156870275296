import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToolsRoutingModule } from './tools-routing.module';
import {ToolsViewComponent} from "./tools-view/tools-view.component";
import {ToolsEditComponent} from "./tools-edit/tools-edit.component";
import {ToolsComponent} from "./tools.component";
import {MatComponentsModule} from "../mat-components/mat-components.module";
import {FormsModule} from "@angular/forms";
import {MATERIAL_MODULES} from "../app.const";
import {PipesModule} from "../pipes/pipes.module";
import {FilesModule} from "../files/files.module";
import {MatIconModule, MatMenuModule} from "@angular/material";
import { ToolsFilterComponent } from './tools-filter/tools-filter.component';
import {DirectivesModule} from "../directives/directives.module";
import {UtilsModule} from "../utils/utils.module";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        MatComponentsModule,
        FormsModule,
        ...MATERIAL_MODULES,
        PipesModule,
        FilesModule,
        ToolsRoutingModule,
        DirectivesModule,
        UtilsModule
    ],
  declarations: [
    ToolsComponent,
    ToolsViewComponent,
    ToolsEditComponent,
    ToolsFilterComponent
  ],
  entryComponents: [
    ToolsViewComponent,
    ToolsEditComponent,
    ToolsFilterComponent
  ]
})
export class ToolsModule { }
