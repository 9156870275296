import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import {ActionsService} from "../../actions/actions.service";
import {ActionModel} from "../../actions/action.model";
import {ActionsEditComponent} from "../../actions/actions-edit/actions-edit.component";
import {ActionsViewTabsComponent} from "../../actions/actions-view-tabs/actions-view-tabs.component";
import {ActionsFilterComponent} from "../../actions/actions-filter/actions-filter.component";
import {ActionsUploadDialogComponent} from "../../actions/actions-upload-dialog/actions-upload-dialog.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {MonoTypeOperatorFunction} from "rxjs";
import {UserTasksEditComponent} from "../user-tasks-edit/user-tasks-edit.component";
import {UserTasksFilterComponent} from "../user-tasks-filter/user-tasks-filter.component";

@Component({
  selector: 'app-user-tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss']
})
export class UserTasksComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  // The authenticated user's tasks.
  dataSource: UserTasksDataSource = new UserTasksDataSource(this.app, this.api);

  displayedColumns = [
    'select',
    'id',
    'name',
    'users',
    'priority',
    'completed',
    'date_created',
    'due',
    'buttons'
  ];

  constructor(
    private actionsService: ActionsService,
    public utils: UtilsService,
    private app: AppService,
    private api: ApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // Get the data from the API.
    this.dataSource.getData(false);
  }

  ngAfterViewInit(): void {
    // Set the sort and paginator after the view init as they are only available then.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    // Set the tap event to update the paginator and sort values.
    const _tap: MonoTypeOperatorFunction<any> = tap(() => {
      // Set the limit, offset and sort order.
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // Sorting for utc time by parsing original time.
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      } else if (this.sort.active === "due_UTC") {
        this.dataSource.sort_by = "due";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }
      // Get the data from the API.
      this.dataSource.getData(false);
    });
    // Set the paginator and sort events to update the data.
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Add a new Task. This opens a new dialog where the task can be created.
   */
  add(): void {
    // Open the edit dialog.
    this.edit(new ActionModel());
  }

  /**
   * Edit the Task. This opens a new dialog where the task can be updated.
   * @param action
   */
  edit(action: ActionModel): void {
    this.dialog
      .open(UserTasksEditComponent, {
        width: '900px',
        data: action
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  /**
   * View the Task. This opens a new dialog where the task can be viewed.
   * @param action
   */
  view(action: ActionModel): void {
    this.utils.showComponentDialog(ActionsViewTabsComponent, {
      action: action
    });
  }

  /**
   * Update the Task Status. This toggles the completion status of a task.
   * @param action
   */
  update(action: ActionModel): void {
    action.completed
      ? this.actionsService.complete(action.id).subscribe(() => {
        this.utils.showToast(`Completed task: "${action.name}"`);
        this.dataSource.getData();
      })
      : this.actionsService.open(action.id).subscribe(() => {
        this.utils.showToast(`Opened task: "${action.name}"`);
        this.dataSource.getData();
      });
  }

  /**
   * Remove the selected tasks. This opens a confirmation dialog.
   */
  onRemoveSelected(): void {
    this.utils.showModal(
      'Remove Tasks',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} tasks?`,
      () => {
        // Remove the selected tasks.
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  /**
   * Remove the task. This opens a confirmation dialog.
   * @param action
   */
  onRemove(action: ActionModel): void {
    this.utils.showModal(
      'Remove Task',
      `Are you sure you want to remove "${action.name}"?`,
      () => {
        // Remove the task.
        this.remove([action.id]);
      }
    );
  }

  /**
   * Remove the tasks. This makes the API call to soft-delete the task.
   * @param ids The task ids to remove.
   * @private
   */
  private remove(ids: number[]): void {
    this.actionsService.remove(ids).subscribe(() => {
      this.utils.showToast('Tasks have been removed');
      this.dataSource.selection.clear();
      this.dataSource.getData(false);
    });
  }

  /**
   * Filter the tasks. This opens a new dialog where the tasks can be filtered.
   */
  onFilter(): void {
    this.utils.showComponentDialog(
      UserTasksFilterComponent,
      {
        selected_site_ids: this.dataSource.site_ids,
        selected_assigned_users: this.dataSource.user_ids,
        filter_priority: this.dataSource.priority,
        filter_status: this.dataSource.status,
        due_date_range: this.dataSource.due_date
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          // Set the filter values.
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.user_ids = results.user_ids ? results.user_ids : this.dataSource.user_ids;
          this.dataSource.priority = typeof results.priority !== 'undefined' ? results.priority : this.dataSource.priority;
          this.dataSource.status = typeof results.status !== 'undefined' ? results.status : this.dataSource.status;
          this.dataSource.due_date = results.due_date ? results.due_date : this.dataSource.due_date;
          // Get the data from the API.
          this.dataSource.getData();
        }
      }
    );
  }

  /**
   * Shows user's public profile. This opens a new dialog where the user's public profile can be viewed.
   * @param hash
   */
  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

  /**
   * Export the all or the selected actions to a PDF.
   * @param type The type of export. This can be pdf, csv or xlsx.
   * @param id The id of the task to export.
   */
  onExport(type: string = 'pdf', id?: number) {
    // Get the selected ids.
    const ids: number[] = id ? [id] : this.dataSource.selection.selected;
    // Make the request to the API.
    this.api.makeRequest('get', `actions/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {}, {
      searchBy: this.dataSource.searchBy,
      search: this.dataSource.search,
      site_ids: this.dataSource.site_ids.length ? this.dataSource.site_ids.join(',') : '',
      user_ids: this.dataSource.user_ids.length ? this.dataSource.user_ids.join(',') : '',
      priority: this.dataSource.priority ? this.dataSource.priority : '',
      status: this.dataSource.status ? this.dataSource.status : '',
      due_date_range: this.dataSource.due_date_range,
      export: 'own'
    }).then((response) => {
      this.utils.showToast(response.message || 'Your export is queued in the background. You will receive an email when it is ready.');
    });
  }
}

/**
 * The data source for the User Tasks Table.
 */
export class UserTasksDataSource extends CustomDataSource {

  sort_by = 'name';
  sort_order = 'asc';
  searchBy = 'name';

  // Filter Variables
  site_ids = [];
  user_ids = [];
  priority = "";
  status = "";
  due_date: Date[] = [];

  due_date_range = "";

  getData(resetOffset = false) {
    this.due_date_range = this.due_date && this.due_date.length > 1 ? [
      this.due_date[0].getTime() / 1000,
      this.due_date[1].getTime() / 1000
    ].join(',') : '';

    this.getDataFromLaravelAPI(
      `actions/user`,
      resetOffset,
      () => { },
      {
        searchBy: this.searchBy,
        site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
        user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
        priority: this.priority ? this.priority : '',
        status: this.status ? this.status : '',
        due_date_range: this.due_date_range
      }
    );
  }
}
