import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { SsspModel } from 'src/app/models/sssp-model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { SsspDocDialogComponent } from '../sssp-doc/sssp-doc-dialog.component';

@Component({
  selector: 'app-sssp-docs',
  templateUrl: './sssp-docs.component.html',
  styleUrls: ['./sssp-docs.component.scss']
})
export class SsspDocsComponent implements OnInit {

  @Input('sssp_hash') sssp_hash: string;

  @Input('sssp') sssp: SsspModel;

  listDataSource = new SSSPDocsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    // Set the Safety Plan hash in the list data source to get the Safety Plan docs.
    this.listDataSource.sssp_hash = this.sssp_hash;

    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.listDataSource.limit = paginator['pageSize'];
      this.listDataSource.offset = paginator['pageIndex'];
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();
  }

  /**
   * Open the Safety Plan Doc dialog for the user to add a new Safety Plan doc.
   */
  onAddSSSPDoc() {
    this.utils.showComponentDialog(SsspDocDialogComponent, {
      sssp_hash: this.sssp_hash,
      sssp: this.sssp
    })
    .then((response) => {
      // Reload the list data
      this.listDataSource.getData();
    });
  }

  onDeleted(onDeleted: boolean = false) {
    if ( onDeleted == true ) {
      // Reload the list data
      this.listDataSource.getData();
    }
  }

}

export class SSSPDocsDataSource extends ApiDataSource {

  order_by = 'date_created';
  order = 'desc';

  sssp_hash: string;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v1/sssp/${this.sssp_hash}/docs`, resetOffset);
  }
}
