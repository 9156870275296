import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { DynamicFormModel } from 'src/app/dynamic-forms/dynamic-form.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { UserDynamicFormsCreateWizardComponent } from '../user-dynamic-forms-create-wizard/user-dynamic-forms-create-wizard.component';
import { UserDynamicFormsEditComponent } from '../user-dynamic-forms-edit/user-dynamic-forms-edit.component';
import { UserDynamicFormsFilterComponent } from '../user-dynamic-forms-filter/user-dynamic-forms-filter.component';

@Component({
  selector: 'app-user-dynamic-forms',
  templateUrl: './user-dynamic-forms.component.html',
  styleUrls: ['./user-dynamic-forms.component.scss']
})
export class UserDynamicFormsComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'from',
    'form_type',
    'dynamic_form_status',
    'date_created',
    'date_modified',
    'actions'
  ];

  dataSource = new UserDynamicFormsDataSource(this.app, this.api);

  // The paginator and sorter
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Open the form which allows the user to complete it.
   * @param form_id The form id to open.
   */
  onOpenForm(form_id: number = 0) {
    this.utils.showComponentDialog(UserDynamicFormsEditComponent, {
      form_id: form_id
    }, {
      disableClose: true
    })
    .then(() => {
      this.dataSource.getData();
    });
  }

  /**
   * Open the form creation wizard that will allow the user to create forms from templates.
   */
  onCreateFromTemplate() {
    this.utils.showComponentDialog(UserDynamicFormsCreateWizardComponent)
    .then((response) => {
      this.dataSource.getData();
    });
  }

  onExportToPDF(form_id: number) {
    this.api.makeRequest('get', `v2/user/assigned-forms/${form_id}/export`)
    .then((response) => {
      // Open the URL in a new browser window.
      this.utils.openUrl(response.asset_url);
    })
    .catch((errorResponse) => {
      // Show a standard error message.
      this.utils.showModal('Form Export Error', 'The form could not be exported. Please refresh the list and try again. Contact support if the issue persist.');
    });
  }

  /**
   * Open the list filters.
   */
   onOpenFilters() {
    this.utils.showComponentDialog(UserDynamicFormsFilterComponent, {
      upstream_account_ids: this.dataSource.upstream_account_ids,
      site_ids: this.dataSource.site_ids,
      statuses: this.dataSource.statuses,
      form_types: this.dataSource.form_types
    }, {
      width: '350px'
    })
    .then((response) => {
      if ( response ) {
        this.dataSource.upstream_account_ids = response.upstream_account_ids;
        this.dataSource.site_ids = response.site_ids;
        this.dataSource.statuses = response.statuses;
        this.dataSource.form_types = response.form_types;
        this.dataSource.getData(true);
      }
    });
  }

  /**
   * Validates and checks what the status of the form submission is. It then
   * returns a corresponding color e.g.
   * - red = Assigned or Rejected.
   * - orange/yellow = In Progress or Work in Progress.
   * - green = Submitted or Completed.
   * - blue = Approved.
   * @param form The form data to validate and check.
   * @returns
   */
  getFormSubmissionStatusColors(form: DynamicFormModel) {
    return {
      'text-danger': form && form.pivot && form.pivot.dynamic_form_status && ['Assigned', 'Rejected'].indexOf(form.pivot.dynamic_form_status) > -1,
      'text-warning': form && form.pivot && form.pivot.dynamic_form_status && ['Pending', 'In Progress', 'Approved: Work in Progress'].indexOf(form.pivot.dynamic_form_status) > -1,
      'text-success': form && form.pivot && form.pivot.dynamic_form_status && ['Submitted', 'Work Completed'].indexOf(form.pivot.dynamic_form_status) > -1,
      'text-info': form && form.pivot && form.pivot.dynamic_form_status && ['Approved'].indexOf(form.pivot.dynamic_form_status) > -1
    };
  }
}

export class UserDynamicFormsDataSource extends ApiDataSource {

  upstream_account_ids: number[] = [];
  site_ids: number[] = [];
  statuses: string[] = [];
  form_types: string[] = [];

  order_by = 'title';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/user/assigned-forms`, resetOffset, {
      upstream_account_ids: this.upstream_account_ids.join(','),
      site_ids: this.site_ids.join(','),
      statuses: this.statuses.join(','),
      form_types: this.form_types.join(',')
    });
  }
}
