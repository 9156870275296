import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {TradeModel} from "../trades.model";
import {MAT_DIALOG_DATA} from "@angular/material";
import {UserPublicProfileComponent} from "../../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-trades-view',
  templateUrl: './trades-view.component.html',
  styleUrls: ['./trades-view.component.scss']
})
export class TradesViewComponent implements OnInit {

  trade: TradeModel = new TradeModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const trade_id = this.id;
    if(trade_id) {
      this.getTrade(trade_id);
    }
  }

  private getTrade (trade_id: number) {
    this.api.makeRequest('get', `v2/admin/trades/${trade_id}`)
      .then((response) => {
        this.trade = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
