import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-dynamic-forms-assigned-users-audit-logs',
  templateUrl: './dynamic-forms-assigned-users-audit-logs.component.html',
  styleUrls: ['./dynamic-forms-assigned-users-audit-logs.component.scss']
})
export class DynamicFormsAssignedUsersAuditLogsComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'user',
    'form',
    'description',
    'actioned_by',
    'actioned_at'
  ];

  dataSource = new DynamicFormsAssignedUsersAuditLogsDataSource(this.app, this.api);

  // Define the paginators.
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<DynamicFormsAssignedUsersAuditLogsComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { form_id: number, user_id: number }
  ) { }

  ngOnInit(): void {
    // Check if the form id and user id is present. Close the dialog if it is not.
    if ( !this.dialogData.form_id || !this.dialogData.user_id ) {
      this.utils.showToast('The form id and user id is not valid.');
      this.dialogRef.close();
      return;
    }
    // Set the form id and user id in the datasource.
    this.dataSource.form_id = this.dialogData.form_id;
    this.dataSource.user_id = this.dialogData.user_id;
    // Get the data from the API.
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Create a tap event for the paginator.
    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();
  }

  /**
   * Show the user's public profile.
   * @param userHash The unique hash of the user.
   */
  onUserPublicView(userHash: string) {
    this.utils.showComponentDialog(UserPublicProfileComponent, userHash, {
      width: '90%'
    });
  }
}

export class DynamicFormsAssignedUsersAuditLogsDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'desc';

  form_id: number;
  user_id: number;

  getData(resetOffset: boolean = false) {
    return this.makeRequest(`v2/dynamic-forms/assigned-users-form-audit-logs/${this.form_id}/${this.user_id}`, resetOffset);
  }
}
